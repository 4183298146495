import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { useText } from 'common/hooks';

import {
  Wrapper,
  Title,
  Subtitle,
  DropDownWrapper,
  DropDown,
  RadioButtonsWrapper,
  RadioButton,
  HorizontalRule,
  Button,
  ButtonWrapper,
} from './elements';

export default connect(
  {
    linksData: state`admin.campaigns.edit.computedCampaignLinks`,
    languageSelected: state`admin.campaigns.edit.languageSelected.optionKey`,
    sectionOptions: state`admin.campaigns.edit.sectionOptions`,
    sectionSelected: state`admin.campaigns.edit.sectionSelected`,
    checkedComparison: state`admin.campaigns.edit.checkedComparison`,
    dropdownSelected: sequences`admin.campaigns.edit.dropdownSelected`,
    comparisonOptionClicked: sequences`admin.campaigns.edit.comparisonOptionClicked`,
  },
  function CampaignReport({
    linksData,
    languageSelected,
    sectionOptions,
    sectionSelected,
    checkedComparison,
    dropdownSelected,
    comparisonOptionClicked,
  }) {
    const t = useText();

    return (
      <Wrapper>
        <Title>{t(`admin.overview.reports.campaign`)}</Title>
        <Subtitle>
          {t(`admin.overview.reports.download_campaign_summary`)}
        </Subtitle>
        <RadioButtonsWrapper>
          <RadioButton
            checked={checkedComparison === 'sections'}
            onClick={() =>
              comparisonOptionClicked({
                value: 'sections',
                type: 'checkedComparison',
              })
            }
            label={t(`admin.overview.reports.compare_sections`)}
          />
          <RadioButton
            checked={checkedComparison === 'teams'}
            onClick={() =>
              comparisonOptionClicked({
                value: 'teams',
                type: 'checkedComparison',
              })
            }
            label={t(`admin.overview.reports.compare_teams`)}
          />
        </RadioButtonsWrapper>
        <HorizontalRule />
        <Subtitle>{t(`admin.overview.reports.select_audience`)}</Subtitle>
        <DropDownWrapper>
          <DropDown
            options={sectionOptions}
            placeholder={t(`admin.overview.reports.filter_sections`)}
            value={sectionSelected.optionValue}
            onDropdownItemClick={option =>
              dropdownSelected({
                context: 'section',
                option,
              })
            }
          />
        </DropDownWrapper>
        <ButtonWrapper>
          <Button
            href={
              linksData?.[languageSelected]?.downloads?.reports?.report?.url ??
              ''
            }
            target="_blank"
          >
            {t(`admin.overview.reports.download_report`)}
          </Button>
        </ButtonWrapper>
        <HorizontalRule />
      </Wrapper>
    );
  },
);

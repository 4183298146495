import admin from './admin';
import challenge from './challenge';
import account from './account';
import appContainer from './appContainer';
import authentication from './authentication';
import errors from './errors';
import translate from './translate';
import privacy from './privacy';
import social from './social';
import messages from './messages';
import topics from './topics';
import campaign from './campaign';
import countries from './countries';
import tools from './tools';
import indicators from './indicators';
import championship from './championship';
import menu from './menu';
import a11y from './a11y';
import activity_category from './activity_category';

export default {
  locale: 'en',
  fullLocale: 'en-GB',
  format: {
    locale: 'en',
    weekStart: 1,
    formats: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D MMM YYYY',
      LLL: 'D MMMM YYYY HH:mm',
      LLLL: 'dddd, D MMMM YYYY HH:mm',
    },
  },
  phrases: {
    admin,
    challenge,
    account,
    appContainer,
    authentication,
    errors,
    translate,
    privacy,
    social,
    messages,
    topics,
    campaign,
    countries,
    tools,
    indicators,
    championship,
    menu,
    a11y,
    activity_category,
  },
};

import styled from 'styled-components';
import { Typ20, Typ30, Typ51 } from '../Typ';
import Ico36 from '../Ico36';

export const TitleText = styled(Typ51)`
  color: ${props => (props.color ? props.color : '#455A64')};
  @media only screen and (max-width: 767px) {
    line-height: 24px;
    font-size: 18px;
  }
`;

export const ElementWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  width: 100%;
  height: 76px;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  background-color: #fff;
  &:hover ${TitleText} {
    color: ${props => (props.disabled ? null : '#0099a9 !important')};
  }
  &:after {
    position: absolute;
    left: 15px;
    bottom: 0;
    height: 1px;
    width: calc(100% - 16px);
    border-bottom: 1px solid #bcbbc1;
    content: '';
  }
  @media only screen and (max-width: 767px) {
    height: 56px;
    padding-left: 13px;
  }
`;

export const SmallText = styled(Typ20)`
  color: #78909c;
  @media only screen and (max-width: 767px) {
    line-height: 12px;
    font-size: 10px;
  }
`;

export const MainContent = styled.div`
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 767px) {
    height: 44px;
    padding-right: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonText = styled(Typ30)`
  color: #0099a9;
  @media only screen and (max-width: 767px) {
    line-height: 12px;
    font-size: 10px;
  }
`;

export const Icon = styled(Ico36)`
  color: ${props => (props.color ? props.color : '#0099A9')};
  @media only screen and (max-width: 767px) {
    line-height: 24px;
    font-size: 24px;
  }
`;

import Ava24 from '../Ava24';
import Ico24 from '../Ico24';
import React from 'react';
import * as PropTypes from 'prop-types';
import { WrapperNarrow, WrapperLink } from './elements';

function IcoW01(props) {
  if (props.href) {
    return (
      <WrapperLink className={props.className} href={props.href}>
        {props.avatar ? (
          <Ava24 image={props.avatar} />
        ) : (
          <Ico24 icon={props.icon} color={props.color} />
        )}
      </WrapperLink>
    );
  }

  return (
    <WrapperNarrow className={props.className} onClick={props.onClick}>
      {props.avatar ? (
        <Ava24 image={props.avatar} />
      ) : (
        <Ico24 icon={props.icon} color={props.color} />
      )}
    </WrapperNarrow>
  );
}

IcoW01.propTypes = {
  avatar: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
};

export default IcoW01;

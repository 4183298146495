import * as React from 'react';
import Ava24 from '../Ava24';
import Ico24 from '../Ico24';
import * as PropTypes from 'prop-types';
import { WrapperSmall } from './elements';

type IcoW02Props = {
  className?: string;
  avatar?: string;
  icon: string;
  color?: string;
  onClick?: () => void;
};

function IcoW02(props: IcoW02Props): React.ReactElement {
  if (props.avatar) {
    return (
      <WrapperSmall className={props.className} onClick={props.onClick}>
        <Ava24 image={props.avatar} />
      </WrapperSmall>
    );
  }

  return (
    <WrapperSmall className={props.className} onClick={props.onClick}>
      <Ico24 icon={props.icon} color={props.color} />
    </WrapperSmall>
  );
}

IcoW02.propTypes = {
  avatar: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export default IcoW02;

export default {
  set_up: {
    set_up_challenge: 'Do you want to set up a Challenge?',
    team_competition:
      'The challenge is a team competition, saving as much CO\u2082e as possible during a period of 2-3 weeks with your team. By registering daily climate-friendly activities, you and those around you become more conscious of your own behavior.',
    add: 'Add',
    remove: 'Remove',
    skip: 'Skip to next tool',
  },
  activities: {
    choose_activities: 'Choose your challenge activities',
    no_activities_added:
      'No activities have been added to the challenge. Please add at least one activity.',
    activities_recommendations:
      'Available activities depends on chosen topics in the campaign. We recommend you select between 10 and 25 activities. Missing a particular activity?',
    select_all: 'Select all',
    see_details: 'See details',
  },
  description: {
    prize: 'Prize',
    prizes: 'Prizes...',
  },
  duration: {
    choose_duration: 'Choose challenge duration',
    recommended_duration:
      'Recommended duration is 2/3 weeks. Challenges start at 00.01 on your chosen start date and end at 23.59 on your chosen end date in the timezone you specified.',
    start_date: 'Start date',
    end_date: 'End date',
  },
  terms: {
    set_up_terms: 'Set up terms of competition',
    participants_compete_on: 'Participants will compete on:',
    compete_on: {
      _enum: 'indicator',
      co2: ' CO\u2082e per participant',
      points: 'Points per participant',
      time: 'Time per participant',
      money: 'Money per participant',
      landuse: 'Landuse per participant',
      social: 'Social per participant',
      biodiversity: 'Biodiversity per participant',
      health: 'Health per participant',
      handprint: 'Handprint per participant',
    },
    participation_rate:
      'Participation rate required in order to win and ensure a fairer competition:',
    participation_rates_choices: {
      0: 'Do not compete on participation',
      10: '10%',
      20: '20%',
      30: '30%',
      40: '40%',
      50: '50%',
      60: '60% (recommended)',
      70: '70%',
      80: '80%',
    },
    who_win_challenge: 'Who will win the challenge?',
    winner_selection: {
      highest_score: 'Highest average (indicator chosen)',
      random: 'lottery/draw',
    },
    number_days_backlog: 'Number of days participants can log back in time',
    days_backlog: {
      0: '0',
      1: '1',
      2: '2 (recommended)',
      3: '3',
      4: '4',
    },
    prize_shown_on_challenge:
      'Add a prize description as you’d like it to appear on your challenge page on Ducky, alongside descriptions of the overall campaign. Text entered will only be shown in the language you provide it in.',
    prize_description: 'Prize description',
    prize_recommendations:
      'We recommend awarding group efforts over individual efforts. The prize should be in line with what you’re trying to achieve. Can you amplify the efforts of your participants? What can the organization do to support this initiative long-term?',
    what_series: 'What series does this campaign belong to?',
    select_series: 'Select series',
  },
};

import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Wrapper,
  ActivityImage,
  ActivityName,
  ActivityDescription,
  HabitButton,
  SubHeader,
} from './elements';

type Props = {
  className?: string;
  name: string;
  description: string;
  onHabitClick: () => void;
  habitText: string;
  headerText: string;
  activityIcon: string;
  activityIconAlt: string;
};

function ActMod13(props: Props): React.ReactElement {
  return (
    <div>
      <Wrapper className={props.className}>
        <ActivityImage alt={props.activityIconAlt} icon={props.activityIcon} />
        <ActivityName>{props.name}</ActivityName>
        <ActivityDescription>{props.description}</ActivityDescription>
        <HabitButton onClick={props.onHabitClick}>
          {props.habitText}
        </HabitButton>
      </Wrapper>
      <SubHeader text={props.headerText} />
    </div>
  );
}

ActMod13.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  onHabitClick: PropTypes.func,
  habitText: PropTypes.string,
  headerText: PropTypes.string,
  activityIcon: PropTypes.string,
  activityIconAlt: PropTypes.string,
};

export default ActMod13;

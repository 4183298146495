import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state } from 'app/pwa/app.cerebral';

import { Wrapper } from './elements';
import SnB01 from 'dc/SnB01';
import SnBP001 from 'dc/SnBP001';
import ChaSnB1 from './ChaSnB1';
import ChaSnB2 from './ChaSnB2';

import { useText } from 'common/hooks';

type Props = {
  readonly className?: string;
};

const deps = {
  message: state.appContainer.snackbar.message,
  show: state.appContainer.snackbar.show,
  isMobile: state.app.isMobile,
};

export function Snackbar({
  className,
  message,
  show,
  isMobile,
}: Props & typeof deps & ConnectedProps) {
  const t = useText();
  // eslint-disable-next-line max-statements
  function renderMessage() {
    if (!message) {
      return null;
    }

    if (message.type === 'dailyStreak') {
      return (
        <SnBP001
          text={t(
            `messages.snackbar.daily_streak.${message.data.consecutiveDaysLogged}`
          )}
        />
      );
    }

    if (message.type === 'participantJoined') {
      return (
        <ChaSnB1
          profileKey={message.data.profileKey}
          joinedCount={message.data.joinedCount}
        />
      );
    }

    if (message.type === 'dailySummaryUpdates') {
      return (
        <ChaSnB2
          profileKey={message.data.profileKey}
          activitiesCount={message.data.activitiesCount}
        />
      );
    }

    if (message.type === 'accountDeleted') {
      return <SnBP001 text={t('messages.snackbar.account.is_deleted')} />;
    }

    if (message.type === 'feedbackSent') {
      return <SnBP001 text={t('messages.snackbar.www.feedback_sent')} />;
    }

    if (message.type === 'resetPasswordSent') {
      return (
        <SnBP001 text={t('messages.snackbar.account.password_reset_sent')} />
      );
    }

    return <SnBP001 text={message.type} />;
  }

  return (
    <Wrapper $mobile={isMobile} className={className} $show={show}>
      <SnB01>{renderMessage()}</SnB01>
    </Wrapper>
  );
}

export default connect(deps, Snackbar);

export default {
  back: 'BACK',
  did_you_know: 'Did you know?',
  main_goal: 'Main goal',
  why_relevant: 'Why is this relevant?',
  learn_more_un: 'Learn more on the UN webpage',
  new: 'new',
  actions_matter: 'Your actions matter',
  useful: 'Was this infomation useful?',
  learn_more: 'learn more',
  tag_categories: {
    categories: 'Categories',
    sdgs: 'Sustainable Development Goals',
  },
  tutorial: {
    name: 'Tutorial',
  },
  all: {
    name: 'All',
  },
  habits: {
    name: 'Habits',
  },
  popular: {
    name: 'Popular',
  },
  sustainable_food: {
    name: 'Sustainable Food',
    header: 'Sustainable Food',
    title: 'Why is sustainable food important?',
    didYouKnow: {
      food_matters: {
        title: 'Our food matters.',
        text: 'For our health, socially, culturally and not least for our planet. Most of us shop our food in the supermarket, without necessarily giving much thought into resources, efforts and long distances before it ends up on our plates.',
        secondary_info: {
          title: 'Our food matters.',
          text: `MARKDOWN:
Our daily food choices are among the habits with the greatest impact on our climate. Globally, agriculture accounts for approximately 70% of the world’s water use and 30% of all human induced greenhouse gas emissions. More than one third of all land is used for food production. The amount of meat and soy fed salmon in our diet also contributes to an inefficient use and unfair distribution of land, while threatening animal life and biodiversity. In addition, one third of all food produced for humans ends up as waste. The UN estimates that there will be 10 billion people in the world in 2050, increasing the demand for food by 60%. This means we will need a more clever way to distribute our food resources than we do today.

The good news is that, in many ways, we are on the right track! And that each of us can make a difference. A more plant based and varied diet, wasting less food and demanding fair trade products are some of the ways each of us can contribute to a more sustainable food system. Additionally, we can influence those around us by sharing advice and positive effects that a more sustainable diet can have on our health, our economy and our planet.

The selection of vegetarian alternatives and more ethical food products has increased in recent years. The same goes for initiatives to reduce food waste and internet sites inspiring for more eco friendly food. More sustainable food habits does not need to require much. More and more people state the wish to eat more sustainably, and by choosing or demanding sustainable food, we let the food industry and politicians know that the environmental and ethical impact of the food we choose matters to us as consumers. 

Together, each of us can contribute to a more healthy, fair and sustainable food system.`,
        },
      },
    },
    description: `MARKDOWN:
###Why is sustainable food important?
Our food matters. For our health, socially, culturally and not least for our planet. Most of us shop our food in the supermarket, without necessarily giving much thought into resources, efforts and long distances before it ends up on our plates.

But our daily food choices are among the habits with the greatest impact on our climate. Globally, agriculture accounts for approximately 70% of the world’s water use and 30% of all human induced greenhouse gas emissions. More than one third of all land is used for food production. The amount of meat and soy fed salmon in our diet also contributes to an inefficient use and unfair distribution of land, while threatening animal life and biodiversity. In addition, one third of all food produced for humans ends up as waste. The UN estimates that there will be 10 billion people in the world in 2050, increasing the demand for food by 60%. This means we will need a more clever way to distribute our food resources than we do today.

The good news is that, in many ways, we are on the right track! And that each of us can make a difference. A more plant based and varied diet, wasting less food and demanding fair trade products are some of the ways each of us can contribute to a more sustainable food system. Additionally, we can influence those around us by sharing advice and positive effects that a more sustainable diet can have on our health, our economy and our planet.

The selection of vegetarian alternatives and more ethical food products has increased in recent years. The same goes for initiatives to reduce food waste and internet sites inspiring for more eco friendly food. More sustainable food habits does not need to require much. More and more people state the wish to eat more sustainably, and by choosing or demanding sustainable food, we let the food industry and politicians know that the environmental and ethical impact of the food we choose matters to us as consumers. 

Together, each of us can contribute to a more healthy, fair and sustainable food system.`,
  },
  plastic: {
    name: 'Plastic',
    header: 'Plastic',
    title: 'Why is plastic important?',
    stat: {
      title: '9%',
      description: 'of all plastic waste ever produced has been recycled',
    },
    didYouKnow: {
      disappearing_plastic: {
        title:
          "Plastic doesn't disappear from the natural environment, even when we can't see it with the naked eye.",
        secondary_info: {
          title:
            "Plastic doesn't disappear from the natural environment, even when we can't see it with the naked eye.",
          text: `MARKDOWN:
Plastic is a relatively new product in human history, therefore we know very little about what happens to plastic over a long period of time. Still, research shows that plastics stay in nature for a very long time - maybe forever.

The plastic is broken up into smaller and smaller pieces, ranging from so-called "macroplastics" (large, visible pieces) to "microplastics" and finally to "nanoplastics". 

Microplastics are pieces of plastic between 1 micron to 5 millimeters - nanoplastics are even smaller!`,
        },
      },
      microplastics: {
        title: 'Microplastics can eventually end up in the food you eat.',
        secondary_info: {
          title: 'Microplastics can eventually end up in the food you eat.',
          text: `MARKDOWN:
In addition to polluting the environment, microplastics can also be mistaken by birds and animals for food which can then make them sick, or even kill them. 

The microplastics also act as a sponge that attracts other environmental toxins. Thus, environmental toxins are spread via microplastics, into food chains, ecosystems, and animals and can eventually end up in the food you eat.`,
        },
      },
    },
    statement: {
      avoid_plastic: {
        title: 'Your actions matter',
        secondary_info: {
          title: 'Your actions matter',
          text: `MARKDOWN:
The best thing is to avoid using plastic where you can. A big contribution is to avoid using your car and to use your legs instead! Believe or not, tires are a major source of microplastics as they wear down. 

You can make sure that the products you buy do not contain microplastics, and you can wash your clothes less often. You can also help by pushing politicians and businesses to find better alternatives and measures to prevent microplastic emissions.`,
        },
      },
    },
    description: `MARKDOWN:
###Why is plastic important?    
Since its invention in 1907, plastic has helped transform our lives – for better or worse.
However, the plastic revolution has not come for free. It has cost the environment and climate a lot. The *plastic revolution* has become the *plastic problem*.

But the reality behind the plastic problem is not the plastic itself. Plastic is a fantastic product that has contributed to a lot of good. It has made our homes safer and more energy efficient, it has contributed to easier transport, and has contributed to food security and improved food durability. Unfortunately the positive aspects of plastics don’t outweigh the negatives. Our massive consumption rate, throw away mentality, and lack of effective waste management have made plastics one of the biggest environmental problems of our time.

Although the plastic problem is so large and prevalent that system changes are essential, individual actions are important too. We have control of our own consumption. Your choices as an individual are of great importance to both nature and the climate. *You* can make a difference.`,
  },
  food_waste: {
    name: 'Food Waste',
    header: 'Food Waste',
    title: 'Why is saving food important?',
    didYouKnow: {
      food_matters: {
        title: 'How we treat our food matters',
        text: 'A big part of our global emissions comes from our food consumption.',
        secondary_info: {
          title: 'How we treat our food matters',
          text: `MARKDOWN:
Not all people have access to the food they need to live completed lives - both in far away societies, but also in our local societies. A number of people have in addition spent time, cared for and put effort into growing and distributing the food we find on our plates. In 2050, the UN estimates that we will be close to 10 billion people on the planet, and that the need for food will at that time be 60% higher than what it is today. This is why we have to make better use of our food.
    
So it’s great that we are on the right track! And that each of us can contribute a lot. The food waste in Norway is decreasing and many of us have become aware of how much the food we save from the garbage have to say for the environment, solidarity and our own time and wallets. And it doesn't require much of us. Food waste happens through small choices and habits every day, which we often do not notice. By discovering what we throw away and why, we can make small adjustments that means a lot to our own finances. And by sharing this discovery with others, we can inspire more people to do the same. Together, we can influence a healthy, solidarity and sustainable food system.`,
        },
      },
    },
    description: `MARKDOWN:
###Why is saving food important?
How we treat our food matters, because a big part of our global emissions comes from our food consumption. Not all people have access to the food they need to live completed lives - both in far away societies, but also in our local societies. A number of people have in addition spent time, cared for and put effort into growing and distributing the food we find on our plates. In 2050, the UN estimates that we will be close to 10 billion people on the planet, and that the need for food will at that time be 60% higher than what it is today. This is why we have to make better use of our food.
    
So it’s great that we are on the right track! And that each of us can contribute a lot. The food waste in Norway is decreasing and many of us have become aware of how much the food we save from the garbage have to say for the environment, solidarity and our own time and wallets. And it doesn't require much of us. Food waste happens through small choices and habits every day, which we often do not notice. By discovering what we throw away and why, we can make small adjustments that means a lot to our own finances. And by sharing this discovery with others, we can inspire more people to do the same. Together, we can influence a healthy, solidarity and sustainable food system.`,
  },
  food: {
    name: 'Food',
    header: 'Food',
    title: 'Why is food important?',
    stat: {
      title: '17%',
      description: 'of all food available to consumers is wasted',
    },
    didYouKnow: {
      food_production: {
        title: "The global food production system simply isn't sustainable",
        text: 'Feeding the planet is challenging - agriculture requires enormous land areas. Converting natural spaces to agricultural land destroys natural habitats, and can lead to the extinction of species.',
      },
      eating_habits: {
        title:
          'By adopting new eating habits, you can contribute to a more sustainable planet.',
        text: 'Reducing food waste means less food needs to be produced. Plant-based foods require less resources to produce.',
        secondary_info: {
          title:
            'By adopting new eating habits, you can contribute to a more sustainable planet.',
          text: `MARKDOWN:
Reducing food waste means less food needs to be produced. Plant-based foods require less resources to produce.

As a rule of thumb, 10 kg of plant matter is needed to produce 1 kg of meat. If we used the area used to produce animal feed to grow plants consumed by humans, we wouldn't have to destroy more natural ecosystems to feed humanity.

Less food production would ensure that we can save large amounts of fresh water, we can free up large land areas and significantly reduce greenhouse gases.`,
        },
      },
    },
    statement: {
      eating_habits: {
        title: 'Your actions matter',
        secondary_info: {
          title: 'Your actions matter',
          text: `MARKDOWN:
By adopting new eating habits, you also let industry and politicians know that you are willing to make changes in your life and create a demand for both sustainable food products and political regulations that will help move our food systems in a sustainable direction.`,
        },
      },
    },
    description: `MARKDOWN:
###Why is food important?
The way we run our food production and distribution today is simply not sustainable. One of the challenges is that food production requires enormous land areas which is a large reason why animal species and insects are wiped out at such a rapid pace. Many species have few natural habitats left.
  
The good news is that by making new food habits you can contribute to a more sustainable planet in various ways. For example eating more plant based foods or reducing food waste reduces the need of producing additional food. (It requires much more calories to feed an animal than the calories you get back from meat or milk) Less food production would ensure that  we can save large amounts of fresh water, we can free up large land areas and significantly reduce greenhouse gases.
  
By implementing new food habits you also let industry and politicians know that you are willing to make changes in your life and create a demand for both sustainable food products and political regulations that will force the industry even faster into a sustainable direction.`,
  },
  transport: {
    name: 'Transport',
    header: 'Transport',
    title: 'Why is transport important?',
    stat: {
      title: '50%',
      description:
        "of the world's urban population have convenient access to public transport",
    },
    didYouKnow: {
      using_transport: {
        title:
          'How we use transport really matters, as a large chunk of global emissions comes from this sector',
        text: 'With increasing population growth in cities, a large proportion of our transportation needs must be reduced or shifted to public transport in order to maintain acceptable air quality and to reach climate goals.',
        secondary_info: {
          title:
            'How we use transport really matters, as a large chunk of global emissions comes from this sector',
          text: `MARKDOWN:
With increasing population growth in cities, a large proportion of our transportation needs must be reduced or shifted to public transport in order to maintain acceptable air quality and to reach climate goals.

A change in our transport system can certainly be achieved - and you can contribute by changing your transportation habits.

Firstly, most of the transport actions have a significant CO\u2082e savings, so reducing transportation helps us maintain a habitable planet.

Secondly, reducing traffic contributes to better local air quality, reducing the health problems of your fellow citizens. 

Thirdly, the need for parking spaces, large roads and the production of many vehicles can be reduced, leading to more space for nature, healthier animals, plants and insects.`,
        },
      },
    },
    statement: {
      politicians: {
        title: 'Your actions matter',
        secondary_info: {
          title: 'Your actions matter',
          text: `MARKDOWN:
Of possible greater significance, by performing a transportation action, you show politicians and industry that there is demand for a more sustainable transport system and that you are willing to play a part in exploring how this system will look in the future.`,
        },
      },
    },
    description: `MARKDOWN:
###Why is transport important?
How we use transport really matters as a large chunk of global emissions comes from this sector. With increasing population growth in cities, a large proportion of our transportation needs must be reduced or shifted to public transport in order to maintain acceptable air quality and to reach climate goals.

A change in our transport system can certainly be achieved. By doing a transport action you contribute in many ways. Firstly, most of the transport actions have a significant CO\u2082 saving leading to a healthier planet. Secondly you contribute to better local air quality reducing the health problems of your fellow citizens. Thirdly the need for parking spaces, large roads and the production of many vehicles can be reduced, leading to more space for our amazing nature,  healthier animals, plants and insects.

Maybe most important still is that when you do a transport action you show politicians and industry that there is a significant demand for a more sustainable transport system and that you are willing to play a part in exploring how this system will look in the future.`,
  },
  consumption: {
    name: 'Goods & Services',
    header: 'Goods & Services',
    title: 'Why are goods & services important?',
    stat: {
      title: '85%',
      description:
        'of textiles end up in landfills or are incinerated although most could be reused',
    },
    didYouKnow: {
      not_buying: {
        title: "Buying nothing isn't always better than buying something",
        text: "Money is rarely saved indefinitely. So even if you don't buy anything today, it doesn’t mean that you won't spend that money tomorrow - on something worse for the environment.",
        secondary_info: {
          title: "Buying nothing isn't always better than buying something",
          text: `MARKDOWN:
Being green is not only about doing or buying less; it is also about how you spend the money you have. Money is rarely saved indefinitely. So even if you don’t buy anything today, it doesn’t mean that you won’t spend that money tomorrow - on something worse for the environment. 

Donating money to a good cause, or buying used items when possible, are good alternatives to doing nothing, because they are examples of sustainable spending pattern. By creating activities related to consumption of goods and services, Ducky’s aim is to show that every time you spend money, you vote for what you believe in. Our activities encourage you to think actively about the consequences of how you consume.`,
        },
      },
      voting: {
        title:
          'Every time you use your wallet you vote for the society you want to live in',
        text: 'For example when you need new clothing you have many choices. You can repair what you already have, buy used or choose high quality items that last, all of which would all be good environmental choices.',
        secondary_info: {
          title:
            'Every time you use your wallet you vote for the society you want to live in',
          text: `MARKDOWN:
For example when you need new clothing you have many choices. You can repair what you already have, buy used or choose high quality which would all be good environmental choices. 

Let us say you purchase 200 euros worth of clothing, without thinking about sustainability, from an international clothing giant. This would lead to around 100 kgCO\u2082e emissions from the entire value chain, from production of cotton, running factories, transport, storage etc. That’s the equivalent to driving 500 km in a car. 
          
Producing new clothing require large land areas and lots of fresh water, meaning that your choices have a significant impact on our precious biodiversity. 
          
If you instead were to spend money on repairing your clothing you would have close to zero emissions and drive the demand for sustainable workplaces in your community.`,
        },
      },
    },
    description: `MARKDOWN:
###Why is consumption important?    
We have defined this category as all products and services that you spend money on except from food, energy and transport since they are covered in their own categories.

Every time you use your wallet you vote for the society you want to live in. For example when you need new clothing you have many choices. You can repair, buy used or choose high quality which would all be good environmental choices. Let us say you spend 200 Euro, without thinking on sustainability, from an international clothing giant. This would lead to around 100 Kg of CO\u2082e emissions from the entire value chain, from production of cotton, running factories, transport, storage etc. That’s the equivalent to driving 500 km in a car. These processes require large land areas and lots of fresh water, meaning that your choices have a significant impact on our precious biodiversity. If you instead were to spend money on repairing your clothing you would have close to zero emissions and drive the demand for sustainable workplaces in your community.

Being green is not only about doing or buying less; it is also about how you spend the money you have. Money is rarely saved indefinitely. So even if you don’t buy anything today, it doesn’t mean that you won’t spend that money tomorrow - on something worse for the environment. That’s why activities such as donating money, or buying what you need second-hand, are good alternatives - they represent sustainable spending patterns. By creating consumption activities, Ducky’s aim is to show that every time you spend money, you vote for what you believe in. Our activities encourage you to think actively around the consequences of how you consume.`,
  },
  energy: {
    name: 'Energy',
    header: 'Energy',
    title: 'Why is energy important?',
    stat: {
      title: '84%',
      description: "of the world's energy still comes from fossil fuels",
    },
    didYouKnow: {
      producing_electricity: {
        title:
          'Every year, the greenhouse gas emissions associated with producing electricity in Europe decrease',
        text: 'Down nearly 50% since 2000!',
        secondary_info: {
          title:
            'Every year, the greenhouse gas emissions associated with producing electricity in Europe decrease',
          text: `MARKDOWN:
However, we increasingly rely on electricity, for example to power electric cars. That means if we don't improve efficiency and reduce energy consumption, the electricity demand will keep growing. 

The more electricity we need, the more land area and resources we'll need to power the future by building windmills and solar farms.`,
        },
      },
      renewable_energy: {
        title:
          'Constructing new, renewable energy sources will take up time and large land areas, having huge impacts on resource use and biodiversity',
        text: 'There is therefore no way around the fact that we need to prioritize the easiest and possibly most important task, which is to reduce our energy consumption.',
      },
    },
    statement: {
      save_energy: {
        title: 'Your actions matter',
        secondary_info: {
          title: 'Your actions matter',
          text: `MARKDOWN:
When we save energy in our habits such as taking shorter showers or by installing a heat pump, we demonstrate to both politicians and industry that we are willing to change. We show that we have a demand for politics that can regulate the energy market in a sustainable direction and we want solutions that make it easier for us to save energy.`,
        },
      },
    },
    description: `MARKDOWN:
###Why is energy important?
All energy production has an impact on nature and our eco-systems. But there is a huge difference in environmental impact between renewable energy sources and fossil energy sources. For example using one kWh (running your heater for one hour) in central Europe leads to the emissions of approx. 500 grams of CO\u2082e, while this leads to only around 130 grams in the nordic countries. The reason for this huge difference is that the nordic countries get more energy from renewable energy sources than central Europe. This means that taking a shower in central Europe leads to around four times more emissions than a shower in the nordic countries.

This demonstrates the importance of creating more clean energy. However, constructing new energy sources will take up time and large land areas, having huge impacts on resource use and our biodiversity. There is therefore no way around the fact that we need to prioritize the easiest and possibly most important task, which is to reduce our energy consumption.

When we save energy in our habits such as taking shorter showers or by installing a heat pump we demonstrate to both politicians and industry that we are willing to change. We show that we have a demand for politics that can regulate the energy market in a sustainable direction and we have a demand for industry that can deliver solutions that make it easier for us to save energy.`,
  },
  handprint: {
    name: 'Handprint',
    header: 'Handprint',
    title: 'Why is handprint important?',
    didYouKnow: {
      positive_impact: {
        title:
          'When you do a handprint activity you have the opportunity to create a positive impact beyond reducing your own footprint',
        secondary_info: {
          title:
            'When you do a handprint activity you have the opportunity to create a positive impact beyond reducing your own footprint',
          text: `MARKDOWN:
The first time we heard the expression handprint was from Harvard University* who defined the difference between a handprint and a footprint as follows:

“Footprints are the negative consequences of all that it takes to sustain a person or an organisation for a year—the total planetary “cost” of your presence.
Handprints represent the benefits of your presence: they’re the positive changes that you bring into the world during this same year. If footprints are what we unavoidably take, handprints are what we intentionally give.” 

As an example you can reduce your transport footprint by biking to work, and increase your handprint by introducing a bike-to-work competition at your workplace to inspire others to reduce their footprints

Can you imagine a world where we all strive towards having a larger handprint than footprint?`,
        },
      },
    },
    description: `MARKDOWN:
###Why is handprint important?
When you do a handprint activity you have the opportunity to create a positive impact beyond reducing your own footprint. The first time we heard the expression handprint was from Harvard University* who defined the difference between a handprint and a footprint as follows:

“Footprints are the negative consequences of all that it takes to sustain a person or an organisation for a year—the total planetary “cost” of your presence.
Handprints represent the benefits of your presence: they’re the positive changes that you bring into the world during this same year. If footprints are what we unavoidably take, handprints are what we intentionally give.” 

As an example you can reduce your transport footprint by biking to work, and increase your handprint by introducing a bike-to-work competition at your workplace to inspire others to reduce their footprints

Can you imagine a world where we all strive towards having a larger handprint than footprint? 

[*https://www.extension.harvard.edu/introducing-handprints](https://www.extension.harvard.edu/introducing-handprints)`,
  },
  overuse: {
    name: 'Overuse',
    header: 'Overuse',
    title: 'Why is reducing overuse important?',
    didYouKnow: {
      so_much_plastic: {
        title: 'In 1950, the world produced 2 million tons of plastic.',
        text: 'In 2015, this had increased to more than 380 million tonnes annually!',
        secondary_info: {
          title: 'In 1950, the world produced 2 million tons of plastic.',
          text: `MARKDOWN:
One of the biggest reasons for this extreme growth was the transition from reusable to disposable items. We have come to expect a throw-away society that harms our environment, animals and climate.

Packaging is by far the largest market for plastic, accounting for around 40% of total use. Most packaging items are disposable. So products that are made to be used only once are created using a material that is said to last forever. Why? The reason is simple: plastic has many wonderful properties that make it an attractive product. In addition, it is extremely cheap to produce. Today, it is actually cheaper to produce plastic products made from so-called "virgin" oil, than to use recycled plastics or other more environmentally friendly alternatives.

Many of the required changes need to happen at a structural level, but every time you choose reusable options instead of disposable plastic, or every time you say "no thanks" to unnecessary plastic, you help push the system in the right direction.`,
        },
      },
    },
    description: `MARKDOWN:
###Why is reducing overuse important?
In 1950, the world produced 2 million tons of plastic. In 2015, this had increased to more than 380 million tonnes annually! One of the biggest reasons for this extreme growth was the transition from reusable to disposable items. We have come to expect a throw-away society that harms our environment, animals and climate.

Packaging is by far the largest market for plastic, accounting for around 40% of total use. Most packaging items are disposable. So products that are made to be used only once are created using a material that is said to last forever. Why? The reason is simple: plastic has many wonderful properties that make it an attractive product. In addition, it is extremely cheap to produce. Today, it is actually cheaper to produce plastic products made from so-called "virgin" oil, than to use recycled plastics or other more environmentally friendly alternatives.

Many of the required changes need to happen at a structural level, but every time you choose reusable options instead of disposable plastic, or every time you say "no thanks" to unnecessary plastic, you help push the system in the right direction.`,
  },
  plastic_on_the_go: {
    name: 'Plastic on the go',
    header: 'Plastic on the go',
    title: 'Why is plastic on the go important?',
    didYouKnow: {
      ocean_plastic: {
        title:
          'More than 8 million tonnes of plastic end up in the ocean every year',
        text: 'As much as 15 tonnes per minute!',
        secondary_info: {
          title:
            'More than 8 million tonnes of plastic end up in the ocean every year',
          text: `MARKDOWN:
Since plastic is a product that is meant to last "forever", all the plastic ever produced has accumulated and still exists in one form or another. It is estimated that only about 9% of this plastic has been recycled. Some has been burned, while most of it is dumped on landfills or thrown straight out into the environment.

The most important step we can take to solve the plastic problem is  to reduce plastic consumption. In cases where plastic must be used, it is very important to ensure that the plastic waste is handled correctly. This can be done by reusing products where possible, repairing instead of buying new stuff, and recycling everything. Always. If you are not satisfied with your municipality's waste management, you have the right to raise your voice!`,
        },
      },
    },
    description: `MARKDOWN:
###Why is plastic on the go important?
More than 8 million tonnes of plastic end up in the ocean every year: *as much as 15 tonnes per minute!* Since plastic is a product that is meant to last "forever", all the plastic ever produced has accumulated and still exists in one form or another. It is estimated that only about 9% of this plastic has been recycled. Some has been burned, while most of it is dumped on landfills or thrown straight out into the environment.

The most important step we can take to solve the plastic problem is  to reduce plastic consumption. In cases where plastic must be used, it is very important to ensure that the plastic waste is handled correctly. This can be done by reusing products where possible, repairing instead of buying new stuff, and recycling everything. Always. If you are not satisfied with your municipality's waste management, you have the right to raise your voice!`,
  },
  microplastics: {
    name: 'Microplastics',
    header: 'Microplastics',
    title: 'Why are microplastics important?',
    stat: {
      title: '9%',
      description: 'of all plastic waste ever produced has been recycled',
    },
    didYouKnow: {
      disappearing_plastic: {
        title:
          "Plastic doesn't disappear from the natural environment, even when we can't see it with the naked eye.",
        secondary_info: {
          title:
            "Plastic doesn't disappear from the natural environment, even when we can't see it with the naked eye.",
          text: `MARKDOWN:
Plastic is a relatively new product in human history, therefore we know very little about what happens to plastic over a long period of time. Still, research shows that plastics stay in nature for a very long time - maybe forever.

The plastic is broken up into smaller and smaller pieces, ranging from so-called "macroplastics" (large, visible pieces) to "microplastics" and finally to "nanoplastics". 

Microplastics are pieces of plastic between 1 micron to 5 millimeters - nanoplastics are even smaller!`,
        },
      },
      microplastics: {
        title: 'Microplastics can eventually end up in the food you eat.',
        secondary_info: {
          title: 'Microplastics can eventually end up in the food you eat.',
          text: `MARKDOWN:
In addition to polluting the environment, microplastics can also be mistaken by birds and animals for food which can then make them sick, or even kill them. 

The microplastics also act as a sponge that attracts other environmental toxins. Thus, environmental toxins are spread via microplastics, into food chains, ecosystems, and animals and can eventually end up in the food you eat.`,
        },
      },
    },
    statement: {
      avoid_plastic: {
        title: 'Your actions matter',
        secondary_info: {
          title: 'Your actions matter',
          text: `MARKDOWN:
The best thing is to avoid using plastic where you can. A big contribution is to avoid using your car and to use your legs instead! Believe or not, tires are a major source of microplastics as they wear down. 

You can make sure that the products you buy do not contain microplastics, and you can wash your clothes less often. You can also help by pushing politicians and businesses to find better alternatives and measures to prevent microplastic emissions.`,
        },
      },
    },
    description: `MARKDOWN:
###Why is microplastic important?
Plastic is a relatively new product in human history, therefore we know very little about what happens to plastic over a long period of time. Still, there is a lot of research which shows that plastics stay in nature for a very long time - maybe forever.

It is more correct to say that plastic is broken up than broken down. The plastic is broken up into smaller and smaller pieces, ranging from so-called "macroplastics" (large, visible pieces) to "microplastics" and finally to "nanoplastics". Microplastics are pieces of plastic between 1 micron to 5 millimeters - nanoplastics are even smaller! In other words, the plastic does not disappear from nature even though our human eye cannot see it.

In addition to polluting the environment, microplastics can also be mistaken by birds and animals for food which can then make them sick, or even kill them. The microplastics also act as a sponge that attracts other environmental toxins. Thus, environmental toxins are spread via microplastics, into food chains, ecosystems, and animals and can eventually end up in the food you eat.

So what can you do? 

The best thing is to avoid using plastic where you can. A big contribution is to avoid using your car and to use your legs instead! Believe or not, tires are a major source of microplastics as they wear down. You can make sure that the products you buy do not contain microplastics, and you can wash your clothes less often. You can also help by pushing politicians and businesses to find better alternatives and measures to prevent microplastic emissions.`,
  },
  1: {
    name: 'SDG 1',
    header: 'SDG 1',
    title: 'Why is SDG 1 important?',
    description: `MARKDOWN:
###No poverty

**Main goal: End poverty in all its forms everywhere**

While global poverty rates have been cut by more than half since 2000, one in ten people in developing regions are still living with their families on less than the international poverty line of US$1.90 a day, and there are millions more who make little more than this daily amount. Significant progress has been made in many countries within Eastern and Southeastern Asia, but up to 42% of the population in Sub-Saharan Africa continues to live below the poverty line.

Poverty is more than the lack of income and resources to ensure a sustainable livelihood. Its manifestations include hunger and malnutrition, limited access to education and other basic services, social discrimination and exclusion as well as the lack of participation in decision-making.

Economic growth must be inclusive to provide sustainable jobs and promote equality. Social protection systems need to be implemented to help alleviate the suffering of disaster-prone countries and provide support in the face of great economic risks. These systems will help strengthen responses by afflicted populations to unexpected economic losses during disasters and will eventually help to end extreme poverty in the most impoverished areas.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/poverty/)_`,
  },
  2: {
    name: 'SDG 2',
    header: 'SDG 2',
    title: 'Why is SDG 2 important?',
    description: `MARKDOWN:
###Zero hunger

**Main goal: Zero Hunger**

It is time to rethink how we grow, share and consume our food. If done right, agriculture, forestry and fisheries can provide nutritious food for all and generate decent incomes, while supporting people-centered rural development and protecting the environment.

Right now, our soils, freshwater, oceans, forests and biodiversity are being rapidly degraded. Climate change is putting even more pressure on the resources we depend on, increasing risks associated with disasters, such as droughts and floods. Many rural women and men can no longer make ends meet on their land, forcing them to migrate to cities in search of opportunities. Poor food security is also causing millions of children to be stunted, or too short for the ages, due to severe malnutrition.

A profound change of the global food and agriculture system is needed if we are to nourish the 815 million people who are hungry today and the additional 2 billion people expected to be undernourished by 2050. Investments in agriculture are crucial to increasing the capacity for agricultural productivity and sustainable food production systems are necessary to help alleviate the perils of hunger.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/hunger/)_`,
  },
  3: {
    name: 'SDG 3',
    header: 'Good health and well-being',
    subheader: 'Sustainable Development Goal 3',
    title: 'Why is SDG 3 important?',
    didYouKnow: {
      healthy_lives: {
        title:
          'Ensure healthy lives and promote well-being for all at all ages',
      },
      urgent_call: {
        title:
          'Sustainable development goals are an urgent call to action for the world',
        text: `MARKDOWN:
The 17 goals aim to end poverty, protect the planet and improve the lives and prospects of everyone, everywhere. 

All UN Member States adopted these goals in 2015, as part of the 2030 Agenda for Sustainable Development. 

By connecting relevant goals to activities we can better understand the small steps to take to help reach them. `,
      },
    },
    description: `MARKDOWN:
###Good health and well-being

**Main goal: Ensure healthy lives and promote well-being for all at all ages**

Ensuring healthy lives and promoting the well-being at all ages is essential to sustainable development.

Significant strides have been made in increasing life expectancy and reducing some of the common killers associated with child and maternal mortality, but working towards achieving the target of less than 70 maternal deaths per 100,000 live births by 2030 would require improvements in skilled delivery care.

Achieving the target of reducing premature deaths due to incommunicable diseases by 1/3 by the year 2030 would also require more efficient technologies for clean fuel use during cooking and education on the risks of tobacco.

Many more efforts are needed to fully eradicate a wide range of diseases and address many different persistent and emerging health issues. By focusing on providing more efficient funding of health systems, improved sanitation and hygiene, increased access to physicians and more tips on ways to reduce ambient pollution, significant progress can be made in helping to save the lives of millions.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/health/)_`,
  },
  4: {
    name: 'SDG 4',
    title: 'Why is SDG 4 important?',
    description: `MARKDOWN:
###Quality education

**Main goal: Quality education**

Obtaining a quality education is the foundation to creating sustainable development. In addition to improving quality of life, access to inclusive education can help equip locals with the tools required to develop innovative solutions to the world’s greatest problems.

Over 265 million children are currently out of school and 22% of them are of primary school age. Additionally, even the children who are attending schools are lacking basic skills in reading and math. In the past decade, major progress has been made towards increasing access to education at all levels and increasing enrollment rates in schools particularly for women and girls. Basic literacy skills have improved tremendously, yet bolder efforts are needed to make even greater strides for achieving universal education goals. For example, the world has achieved equality in primary education between girls and boys, but few countries have achieved that target at all levels of education.

The reasons for lack of quality education are due to lack of adequately trained teachers, poor conditions of schools and equity issues related to opportunities provided to rural children. For quality education to be provided to the children of impoverished families, investment is needed in educational scholarships, teacher training workshops, school building and improvement of water and electricity access to schools.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/education/)_`,
  },
  5: {
    name: 'SDG 5',
    title: 'Why is SDG 5 important?',
    description: `MARKDOWN:
###Gender equality

**Main goal: Achieve gender equality and empower all women and girls**

While the world has achieved progress towards gender equality and women’s empowerment under the Millennium Development Goals (including equal access to primary education between girls and boys), women and girls continue to suffer discrimination and violence in every part of the world.

Gender equality is not only a fundamental human right, but a necessary foundation for a peaceful, prosperous and sustainable world. Unfortunately, at the current time, 1 in 5 women and girls between the ages of 15-49 have reported experiencing physical or sexual violence by an intimate partner within a 12-month period and 49 countries currently have no laws protecting women from domestic violence. Progress is occurring regarding harmful practices such as child marriage and FGM (Female Genital Mutilation), which has declined by 30% in the past decade, but there is still much work to be done to complete eliminate such practices.

Providing women and girls with equal access to education, health care, decent work, and representation in political and economic decision-making processes will fuel sustainable economies and benefit societies and humanity at large. Implementing new legal frameworks regarding female equality in the workplace and the eradication of harmful practices targeted at women is crucial to ending the gender-based discrimination prevalent in many countries around the world.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/gender-equality/)_`,
  },
  6: {
    name: 'SDG 6',
    header: 'Clean water and sanitation',
    subheader: 'Sustainable Development Goal 6',
    title: 'Why is SDG 6 important?',
    didYouKnow: {
      water_access: {
        title: 'Ensure access to water and sanitation for all',
      },
      urgent_call: {
        title:
          'Sustainable development goals are an urgent call to action for the world',
        text: `MARKDOWN:
The 17 goals aim to end poverty, protect the planet and improve the lives and prospects of everyone, everywhere. 

All UN Member States adopted these goals in 2015, as part of the 2030 Agenda for Sustainable Development. 

By connecting relevant goals to activities we can better understand the small steps to take to help reach them. `,
      },
    },
    description: `MARKDOWN:
###Clean water and sanitation

**Main goal: Ensure access to water and sanitation for all**

Clean, accessible water for all is an essential part of the world we want to live in and there is sufficient fresh water on the planet to achieve this. However, due to bad economics or poor infrastructure, millions of people including children die every year from diseases associated with inadequate water supply, sanitation and hygiene.

Water scarcity, poor water quality and inadequate sanitation negatively impact food security, livelihood choices and educational opportunities for poor families across the world. At the current time, more than 2 billion people are living with the risk of reduced access to freshwater resources and by 2050, at least one in four people is likely to live in a country affected by chronic or recurring shortages of fresh water. Drought in specific afflicts some of the world’s poorest countries, worsening hunger and malnutrition. Fortunately, there has been great progress made in the past decade regarding drinking sources and sanitation, whereby over 90% of the world’s population now has access to improved sources of drinking water.

To improve sanitation and access to drinking water, there needs to be increased investment in management of freshwater ecosystems and sanitation facilities on a local level in several developing countries within Sub-Saharan Africa, Central Asia, Southern Asia, Eastern Asia and South-Eastern Asia.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/water-and-sanitation/)_`,
  },
  7: {
    name: 'SDG 7',
    title: 'Why is SDG 7 important?',
    description: `MARKDOWN:
###Affordable and clean energy

**Main goal: Ensure access to affordable, reliable, sustainable and modern energy**

Energy is central to nearly every major challenge and opportunity the world faces today. Be it for jobs, security, climate change, food production or increasing incomes, access to energy for all is essential. Working towards this goal is especially important as it interlinks with other Sustainable Development Goals. Focusing on universal access to energy, increased energy efficiency and the increased use of renewable energy through new economic and job opportunities is crucial to creating more sustainable and inclusive communities and resilience to environmental issues like climate change.

At the current time, there are approximately 3 billion people who lack access to clean-cooking solutions and are exposed to dangerous levels of air pollution. Additionally, slightly less than 1 billion people are functioning without electricity and 50% of them are found in Sub-Saharan Africa alone. Fortunately, progress has been made in the past decade regarding the use of renewable electricity from water, solar and wind power and the ratio of energy used per unit of GDP is also declining.

However, the challenge is far from being solved and there needs to be more access to clean fuel and technology and more progress needs to be made regarding integrating renewable energy into end-use applications in buildings, transport and industry. Public and private investments in energy also need to be increased and there needs to be more focus on regulatory frameworks and innovative business models to transform the world’s energy systems.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/energy/)_`,
  },
  8: {
    name: 'SDG 8',
    header: 'Decent work and economic growth',
    subheader: 'Sustainable Development Goal 8',
    title: 'Why is SDG 8 important?',
    didYouKnow: {
      sustainable_growth: {
        title:
          'Promote inclusive and sustainable economic growth, employment and decent work for all',
      },
      urgent_call: {
        title:
          'Sustainable development goals are an urgent call to action for the world',
        text: `MARKDOWN:
The 17 goals aim to end poverty, protect the planet and improve the lives and prospects of everyone, everywhere. 

All UN Member States adopted these goals in 2015, as part of the 2030 Agenda for Sustainable Development. 

By connecting relevant goals to activities we can better understand the small steps to take to help reach them. `,
      },
    },
    description: `MARKDOWN:
###Decent work and economic growth

**Main goal: Promote inclusive and sustainable economic growth, employment and decent work for all**

Roughly half the world’s population still lives on the equivalent of about US$2 a day with global unemployment rates of 5.7% and having a job doesn't guarantee the ability to escape from poverty in many places. This slow and uneven progress requires us to rethink and retool our economic and social policies aimed at eradicating poverty.

A continued lack of decent work opportunities, insufficient investments and under-consumption lead to an erosion of the basic social contract underlying democratic societies: that all must share in progress. Even though the average annual growth rate of real GDP per capita worldwide is increasing year on year, there are still many countries in the developing world that are decelerating in their growth rates and moving farther from the 7% growth rate target set for 2030. As labor productivity decreases and unemployment rates rise, standards of living begin to decline due to lower wages.

Sustainable economic growth will require societies to create the conditions that allow people to have quality jobs that stimulate the economy while not harming the environment. Job opportunities and decent working conditions are also required for the whole working age population. There needs to be increased access to financial services to manage incomes, accumulate assets and make productive investments. Increased commitments to trade, banking and agriculture infrastructure will also help increase productivity and reduce unemployment levels in the world’s most impoverished regions.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/economic-growth/)_`,
  },
  9: {
    name: 'SDG 9',
    header: 'Industries, innovation and infrastructure',
    subheader: 'Sustainable Development Goal 9',
    title: 'Why is SDG 9 important?',
    didYouKnow: {
      resilient_infrastructure: {
        title:
          'Build resilient infrastructure, promote sustainable industrialization and foster innovation',
      },
      urgent_call: {
        title:
          'Sustainable development goals are an urgent call to action for the world',
        text: `MARKDOWN:
The 17 goals aim to end poverty, protect the planet and improve the lives and prospects of everyone, everywhere. 

All UN Member States adopted these goals in 2015, as part of the 2030 Agenda for Sustainable Development. 

By connecting relevant goals to activities we can better understand the small steps to take to help reach them. `,
      },
    },
    description: `MARKDOWN:
###Industries, innovation and infrastructure

**Main goal: Build resilient infrastructure, promote sustainable industrialization and foster innovation**

Investments in infrastructure – transport, irrigation, energy and information and communication technology – are crucial to achieving sustainable development and empowering communities in many countries. It has long been recognized that growth in productivity and incomes, and improvements in health and education outcomes require investment in infrastructure

Manufacturing is an important driver of economic development and employment. At the current time, however, manufacturing value added per capita is only US$100 in the least developed countries compared to over US$4,500 in Europe and Northern America. Another important factor to consider is the emission of Carbon Dioxide during manufacturing processes. Emissions have decreased over the past decade in many countries but the pace of decline has not been even around the world.

Technological progress is the foundation of efforts to achieve environmental objectives, such as increased resource and energy-efficiency. Without technology and innovation, industrialization will not happen, and without industrialization, development will not happen. There needs to be more investments in high-tech products that dominate the manufacturing productions to increase efficiency and a focus on mobile cellular services that increase connections between people.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/infrastructure-industrialization/)_`,
  },
  10: {
    name: 'SDG 10',
    header: 'Reduced inequalities',
    subheader: 'Sustainable Development Goal 10',
    title: 'Why is SDG 10 important?',
    didYouKnow: {
      reduce_inequality: {
        title: 'Reduce inequality within and among countries',
      },
      urgent_call: {
        title:
          'Sustainable development goals are an urgent call to action for the world',
        text: `MARKDOWN:
The 17 goals aim to end poverty, protect the planet and improve the lives and prospects of everyone, everywhere. 

All UN Member States adopted these goals in 2015, as part of the 2030 Agenda for Sustainable Development. 

By connecting relevant goals to activities we can better understand the small steps to take to help reach them. `,
      },
    },
    description: `MARKDOWN:
###Reduced inequalities

**Main goal: Reduce inequality within and among countries**

The international community has made significant strides towards lifting people out of poverty.  The most vulnerable nations – the least developed countries, the landlocked developing countries and the small island developing states – continue to make inroads into poverty reduction.  However, inequality persists and large disparities remain regarding access to health and education services and other assets.

There is growing consensus that economic growth is not sufficient to reduce poverty if it is not inclusive and if it does not involve the three dimensions of sustainable development – economic, social and environmental. Fortunately, income inequality has been reduced both between and within countries. At the current time, the per capita income of 60 out of 94 countries with data has risen more rapidly than the national average. There has been some progress regarding creating favorable access conditions for exports from least developing countries as well.

To reduce inequality, policies should be universal in principle, paying attention to the needs of disadvantaged and marginalized populations. There needs to be an increase in duty-free treatment and continuation of favoring exports from developing countries, in addition to increasing the share of developing countries’ vote within the IMF. Finally, innovations in technology can help reduce the cost of transferring money for migrant workers.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/inequality/)_`,
  },
  11: {
    name: 'SDG 11',
    header: 'Sustainable cities and communities',
    subheader: 'Sustainable Development Goal 11',
    title: 'Why is SDG 11 important?',
    didYouKnow: {
      inclusive_cities: {
        title: 'Make cities inclusive, safe, resilient and sustainable',
      },
      urgent_call: {
        title:
          'Sustainable development goals are an urgent call to action for the world',
        text: `MARKDOWN:
The 17 goals aim to end poverty, protect the planet and improve the lives and prospects of everyone, everywhere. 

All UN Member States adopted these goals in 2015, as part of the 2030 Agenda for Sustainable Development. 

By connecting relevant goals to activities we can better understand the small steps to take to help reach them. `,
      },
    },
    description: `MARKDOWN:
###Sustainable cities and communities

**Main goal: Make cities inclusive, safe, resilient and sustainable**

Cities are hubs for ideas, commerce, culture, science, productivity, social development and much more. At their best, cities have enabled people to advance socially and economically. With the number of people living within cities projected to rise to 5 billion people by 2030, it’s important that efficient urban planning and management practices are in place to deal with the challenges brought by urbanization.

Many challenges exist to maintaining cities in a way that continues to create jobs and prosperity without straining land and resources. Common urban challenges include congestion, lack of funds to provide basic services, a shortage of adequate housing, declining infrastructure and rising air pollution within cities.

Rapid urbanization challenges, such as the safe removal and management of solid waste within cities, can be overcome in ways that allow them to continue to thrive and grow, while improving resource use and reducing pollution and poverty. One such example is an increase in municipal waste collection. There needs to be a future in which cities provide opportunities for all, with access to basic services, energy, housing, transportation and more.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/cities/)_`,
  },
  12: {
    name: 'SDG 12',
    header: 'Responsible consumption and production',
    subheader: 'Sustainable Development Goal 12',
    title: 'Why is SDG 12 important?',
    didYouKnow: {
      sustainable_consumption: {
        title: 'Ensure sustainable consumption and production patterns',
      },
      urgent_call: {
        title:
          'Sustainable development goals are an urgent call to action for the world',
        text: `MARKDOWN:
The 17 goals aim to end poverty, protect the planet and improve the lives and prospects of everyone, everywhere. 

All UN Member States adopted these goals in 2015, as part of the 2030 Agenda for Sustainable Development. 

By connecting relevant goals to activities we can better understand the small steps to take to help reach them. `,
      },
    },
    description: `MARKDOWN:
###Responsible consumption and production

**Main goal: Ensure sustainable consumption and production patterns**

Sustainable consumption and production is about promoting resource and energy efficiency, sustainable infrastructure, and providing access to basic services, green and decent jobs and a better quality of life for all. Its implementation helps to achieve overall development plans, reduce future economic, environmental and social costs, strengthen economic competitiveness and reduce poverty.

At the current time, material consumption of natural resources is increasing, particularly within Eastern Asia. Countries are also continuing to address challenges regarding air, water and soil pollution.

Since sustainable consumption and production aims at “doing more and better with less,” net welfare gains from economic activities can increase by reducing resource use, degradation and pollution along the whole life cycle, while increasing quality of life. There also needs to be significant focus on operating on supply chain, involving everyone from producer to final consumer. This includes educating consumers on sustainable consumption and lifestyles, providing them with adequate information through standards and labels and engaging in sustainable public procurement, among others.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/sustainable-consumption-production/)_`,
  },
  13: {
    name: 'SDG 13',
    header: 'Climate action',
    subheader: 'Sustainable Development Goal 13',
    title: 'Why is SDG 13 important?',
    didYouKnow: {
      urgent_action: {
        title: 'Take urgent action to combat climate change and its impacts',
      },
      urgent_call: {
        title:
          'Sustainable development goals are an urgent call to action for the world',
        text: `MARKDOWN:
The 17 goals aim to end poverty, protect the planet and improve the lives and prospects of everyone, everywhere. 

All UN Member States adopted these goals in 2015, as part of the 2030 Agenda for Sustainable Development. 

By connecting relevant goals to activities we can better understand the small steps to take to help reach them. `,
      },
    },
    description: `MARKDOWN:
###Climate action

**Main goal: Take urgent action to combat climate change and its impacts**

Climate change is now affecting every country on every continent. It is disrupting national economies and affecting lives, costing people, communities and countries dearly today and even more tomorrow. Weather patterns are changing, sea levels are rising, weather events are becoming more extreme and greenhouse gas emissions are now at their highest levels in history. Without action, the world’s average surface temperature is likely to surpass 3 degrees centigrade this century. The poorest and most vulnerable people are being affected the most.

Affordable, scalable solutions are now available to enable countries to leapfrog to cleaner, more resilient economies. The pace of change is quickening as more people are turning to renewable energy and a range of other measures that will reduce emissions and increase adaptation efforts. Climate change, however, is a global challenge that does not respect national borders. It is an issue that requires solutions that need to be coordinated at the international level to help developing countries move toward a low-carbon economy.

To strengthen the global response to the threat of climate change, countries adopted the Paris Agreement at the COP21 in Paris, which went into force in November of 2016. In the agreement, all countries agreed to work to limit global temperature rise to well below 2 degrees centigrade. As of April 2018, 175 parties had ratified the Paris Agreement and 10 developing countries had submitted their first iteration of their national adaptation plans for responding to climate change.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/climate-change-2/)_`,
  },
  14: {
    name: 'SDG 14',
    header: 'Life below water',
    subheader: 'Sustainable Development Goal 14',
    title: 'Why is SDG 14 important?',
    didYouKnow: {
      conserve_oceans: {
        title:
          'Conserve and sustainably use the oceans, seas and marine resources',
      },
      urgent_call: {
        title:
          'Sustainable development goals are an urgent call to action for the world',
        text: `MARKDOWN:
The 17 goals aim to end poverty, protect the planet and improve the lives and prospects of everyone, everywhere. 

All UN Member States adopted these goals in 2015, as part of the 2030 Agenda for Sustainable Development. 

By connecting relevant goals to activities we can better understand the small steps to take to help reach them. `,
      },
    },
    description: `MARKDOWN:
###Life below water

**Main goal: Conserve and sustainably use the oceans, seas and marine resources**

The world’s oceans – their temperature, chemistry, currents and life – drive global systems that make the Earth habitable for humankind. Our rainwater, drinking water, weather, climate, coastlines, much of our food, and even the oxygen in the air we breathe, are all ultimately provided and regulated by the sea. Throughout history, oceans and seas have been vital conduits for trade and transportation.

Careful management of this essential global resource is a key feature of a sustainable future. However, at the current time, there is a continuous deterioration of coastal waters owing to pollution and ocean acidification is having an adversarial effect on the functioning of ecosystems and biodiversity. This is also negatively impacting small scale fisheries.

Marine protected areas need to be effectively managed and well-resourced and regulations need to be put in place to reduce overfishing, marine pollution and ocean acidification.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/oceans/)_`,
  },
  15: {
    name: 'SDG 15',
    header: 'Life on land',
    subheader: 'Sustainable Development Goal 15',
    title: 'Why is SDG 15 important?',
    didYouKnow: {
      sustainable_management: {
        title:
          'Sustainably manage forests, combat desertification, halt and reverse land degradation, halt biodiversity loss',
      },
      urgent_call: {
        title:
          'Sustainable development goals are an urgent call to action for the world',
        text: `MARKDOWN:
The 17 goals aim to end poverty, protect the planet and improve the lives and prospects of everyone, everywhere. 

All UN Member States adopted these goals in 2015, as part of the 2030 Agenda for Sustainable Development. 

By connecting relevant goals to activities we can better understand the small steps to take to help reach them. `,
      },
    },
    description: `MARKDOWN:
###Life on land

**Main goal: Sustainably manage forests, combat desertification, halt and reverse land degradation, halt biodiversity loss**

Forests cover 30.7 per cent of the Earth’s surface and, in addition to providing food security and shelter, they are key to combating climate change, protecting biodiversity and the homes of the indigenous population. By protecting forests, we will also be able to strengthen natural resource management and increase land productivity.

At the current time, thirteen million hectares of forests are being lost every year while the persistent degradation of drylands has led to the desertification of 3.6 billion hectares. Even though up to 15% of land is currently under protection, biodiversity is still at risk. Deforestation and desertification – caused by human activities and climate change – pose major challenges to sustainable development and have affected the lives and livelihoods of millions of people in the fight against poverty.

Efforts are being made to manage forests and combat desertification. There are two international agreements being implemented currently that promote the use of resources in an equitable way. Financial investments in support of biodiversity are also being provided.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/biodiversity/)_`,
  },
  16: {
    name: 'SDG 16',
    title: 'Why is SDG 16 important?',
    description: `MARKDOWN:
###Peace, justice and strong institutions

**Main goal: Promote just, peaceful and inclusive societies**

The threats of international homicide, violence against children, human trafficking and sexual violence are important to address to promote peaceful and inclusive societies for sustainable development. They pave the way for the provision of access to justice for all and for building effective, accountable institutions at all levels.

While homicide and trafficking cases have seen significant progress over the past decade, there are still thousands of people at greater risk of intentional murder within Latin America, Sub-Saharan Africa and around Asia. Children’s rights violations through aggression and sexual violence continue to plague many countries around the world, especially as under-reporting and lack of data aggravate the problem.

To tackle these challenges and build a more peaceful, inclusive societies, there needs to be more efficient and transparent regulations put in place and comprehensive, realistic government budgets. One of the first steps towards protecting individual rights is the implementation of worldwide birth registration and the creation of more independent national human rights institutions around the world.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/peace-justice/)_`,
  },
  17: {
    name: 'SDG 17',
    title: 'Why is SDG 17 important?',
    description: `MARKDOWN:
###Partnership for the goals

**Main goal: Revitalize the global partnership for sustainable development**

A successful sustainable development agenda requires partnerships between governments, the private sector and civil society. These inclusive partnerships built upon principles and values, a shared vision, and shared goals that place people and the planet at the centre, are needed at the global, regional, national and local level.

Urgent action is needed to mobilize, redirect and unlock the transformative power of trillions of dollars of private resources to deliver on sustainable development objectives. Long-term investments, including foreign direct investment, are needed in critical sectors, especially in developing countries. These include sustainable energy, infrastructure and transport, as well as information and communications technologies. The public sector will need to set a clear direction. Review and monitoring frameworks, regulations and incentive structures that enable such investments must be retooled to attract investments and reinforce sustainable development. National oversight mechanisms such as supreme audit institutions and oversight functions by legislatures should be strengthened.

_The content is copied directly from the [UN webpage](https://www.un.org/sustainabledevelopment/globalpartnerships/)_`,
  },
};

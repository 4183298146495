import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Wrapper, Tooltip, ElementWrapper, Content } from './elements';

type Dimensions =
  | {
      width: number;
      height: number;
    }
  | Record<string, never>;

function computeMarginLeft(
  wrapper: Dimensions,
  tooltip: Dimensions,
  alignment: string
) {
  switch (alignment) {
    case 'left':
      return `${wrapper.width - tooltip.width}px`;
    case 'right':
      return `0px`;
    case 'center':
    default:
      return `${-(tooltip.width / 2 - wrapper.width / 2)}px`;
  }
}

function computeMarginTop(
  wrapper: Dimensions,
  tooltip: Dimensions,
  placement: string
) {
  switch (placement) {
    case 'top':
      return `-${tooltip.height + 8}px`;
    case 'bottom':
    default:
      return `${wrapper.height + 8}px`;
  }
}

type ToolTip01Props = {
  text?: string;
  className?: string;
  children?: React.ReactNode;
  placement?: 'top' | 'bottom';
  alignment?: 'left' | 'center' | 'right';
  maxWidth?: number;
};

function ToolTip01({
  text,
  className,
  children,
  placement,
  alignment,
  maxWidth,
}: ToolTip01Props): React.ReactElement {
  const [wrapperDimensions, setWrapperDimensions] = React.useState<Dimensions>(
    {}
  );
  const [tooltipDimensions, setTooltipDimensions] = React.useState<Dimensions>(
    {}
  );

  const wrapperRef = React.useCallback(
    nd => {
      if (nd !== null) {
        setWrapperDimensions(nd.getBoundingClientRect());
      }
    },
    [children]
  );

  const tooltipRef = React.useCallback(
    nd => {
      if (nd !== null) {
        setTooltipDimensions(nd.getBoundingClientRect());
      }
    },
    [text, maxWidth]
  );

  return (
    <Wrapper className={className}>
      <Tooltip
        role="tooltip"
        ref={tooltipRef}
        $marginTop={computeMarginTop(
          wrapperDimensions,
          tooltipDimensions,
          placement
        )}
        $marginLeft={computeMarginLeft(
          wrapperDimensions,
          tooltipDimensions,
          alignment
        )}
        $maxWidth={maxWidth}
      >
        <ElementWrapper>
          <Content>{text}</Content>
        </ElementWrapper>
      </Tooltip>
      <div ref={wrapperRef}>{children}</div>
    </Wrapper>
  );
}

ToolTip01.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  placement: PropTypes.oneOf(['top', 'bottom']),
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  text: PropTypes.string,
  maxWidth: PropTypes.number,
};

ToolTip01.defaultProps = {
  alignment: 'center',
  maxWidth: 187,
  placement: 'top',
};

export default ToolTip01;

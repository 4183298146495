import styled from 'styled-components';
import IcoW01 from '../IcoW01';
import PoM000 from '../PoM000';

type DropdownWrapperProps = {
  disabled: boolean;
};

export const DropdownWrapper = styled(PoM000)<DropdownWrapperProps>`
  width: 100%;
  padding: 16px 0;
  max-height: 208px;
  overflow: scroll;
  position: absolute;
  top: 48px;
  display: ${props => (props.visible ? 'block' : 'none')};
`;

type ElementWrapperProps = {
  disabled: boolean;
};

export const ElementWrapper = styled.div<ElementWrapperProps>`
  width: 100%;
  position: relative;
  display: ${props => (props.disabled ? 'none' : 'block')};
`;

export const Option = styled.div`
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 20px;
  color: #546e7a;
  cursor: pointer;
  &:hover {
    background: #f4f7f9;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  transition: border-color 0.25s;
  background-color: #fff;
`;

export const TextField = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: #263238;
  padding: 12px 16px;
  text-overflow: ellipsis;
  background: transparent;
  &::placeholder {
    color: #b0bec5;
  }
`;

export const DropdownArrow = styled(IcoW01)`
  cursor: pointer;
`;

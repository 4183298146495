import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Wrapper,
  Title,
  SubTitle,
  ContentWrapper,
  TableHeader,
  TableData,
  SmallHeading,
  SmallBoldText,
  NewButton,
} from './elements';
import ToolTip01 from 'dc/ToolTip01';
import { toCo2Kg } from 'utils/common';

export default connect(
  {
    monthlyStats: state`admin.monthlyStats`,
    isLoadingStats: state`admin.isLoadingStats`,
    getMonthlyStatsClicked: sequences`admin.getMonthlyStatsClicked`,
  },
  function Dashboard({ monthlyStats, isLoadingStats, getMonthlyStatsClicked }) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const monthlyStatsEntries = Object.entries(monthlyStats);

    return (
      <Wrapper>
        <ContentWrapper>
          <Title>{'Welcome to your new admin panel'}</Title>
          <SubTitle>
            {'See this page for all your wildest statistic needs'}
          </SubTitle>
          {monthlyStatsEntries.length === 0 ? (
            <NewButton
              disabled={isLoadingStats}
              onClick={() => getMonthlyStatsClicked()}
            >
              {'GET STATS'}
            </NewButton>
          ) : (
            monthlyStatsEntries
              .sort(([a], [b]) => Number(b) - Number(a))
              .map(([year, monthlyData]) => (
                <table key={year}>
                  <thead>
                    <tr>
                      <TableHeader>
                        <SmallHeading>{year}</SmallHeading>
                      </TableHeader>
                      <TableHeader>
                        <SmallHeading>{'Total Activities Logged'}</SmallHeading>
                      </TableHeader>
                      <TableHeader>
                        <SmallHeading>{'Total CO2 Saved (kg)'}</SmallHeading>
                      </TableHeader>
                      <TableHeader>
                        <SmallHeading>{'Unique Users'}</SmallHeading>
                      </TableHeader>
                      <TableHeader>
                        <ToolTip01 text="Only the days where activities were logged are counted in this calculation">
                          <SmallHeading>
                            {
                              'Average Number Activities Logged Per User Per Day'
                            }
                          </SmallHeading>
                        </ToolTip01>
                      </TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(monthlyData)
                      .sort(([a], [b]) => Number(b) - Number(a))
                      .map(([month, data]) => {
                        const avgActsPerUserPerDay =
                          Object.values(data?.averageNumLogged ?? {}).reduce(
                            (total, currentDateValues) =>
                              total +
                              currentDateValues.activitiesCount /
                                currentDateValues.uniqueUsers.length,
                            0
                          ) /
                          Object.values(data?.averageNumLogged ?? {}).length;

                        return (
                          <tr key={month}>
                            <TableData>
                              <SmallBoldText>
                                {month === 'total'
                                  ? 'Total'
                                  : `${months[month]}: `}
                              </SmallBoldText>
                            </TableData>
                            <TableData>
                              <SubTitle>{data?.activitiesCount ?? 0}</SubTitle>
                            </TableData>
                            <TableData>
                              <SubTitle>{toCo2Kg(data?.co2 ?? 0)}</SubTitle>
                            </TableData>
                            <TableData>
                              <SubTitle>
                                {data?.uniqueUsers?.length ?? 0}
                              </SubTitle>
                            </TableData>
                            <TableData>
                              <SubTitle>
                                {(avgActsPerUserPerDay !== Infinity &&
                                !isNaN(avgActsPerUserPerDay)
                                  ? avgActsPerUserPerDay
                                  : 0
                                ).toFixed(2)}
                              </SubTitle>
                            </TableData>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ))
          )}
        </ContentWrapper>
      </Wrapper>
    );
  }
);

export default {
  check_new_notifications: 'Could not check last messages',
  create_profile: 'Could not create profile, try again',
  edit_post: 'Could not change your post',
  firebase: 'Firebase error',
  get_activities: 'Could not retrieve activities',
  get_challenge: 'Could not get a challenge',
  get_challenges: 'Could not get challenges',
  get_challenge_teams: 'Could not retrieve team challenges',
  get_global_events: 'Could not retrieve recent global events',
  get_global_static_events: 'Global static event error',
  get_department_events: 'Could not retrieve recent events for your department',
  get_team_events: 'Failed to retrieve recent events for your team',
  get_user_events: 'Failed to retrieve your recent events',
  get_latest_comments: 'Could not retrieve recent comments',
  get_leaderboard_users: 'Could not list best users',
  get_logged_activities: 'Failed to retrieve logged-in activities',
  get_section_static_events: 'Section static event error',
  get_selected_team_day_score: 'Failed to get the current score for your team',
  get_team_leaderboard_6: 'Could not list 6 best of your team',
  get_team_score: 'Failed to retrieve team score',
  get_team_static_events: 'Team static event error',
  get_team_total_score: 'Failed to retrieve total score',
  get_posts: 'Failed to fetch posts',
  get_profile: 'Could not load profile, try reloading page',
  get_profile_challenges: 'Could not get challenges',
  get_household_challenges: 'Could not get challenges',
  get_user_static_events: 'User static event error',
  get_users_logged_activities:
    "Unable to retrieve this user's logged-in activity",
  http: 'HTTP error',
  join_challenge: 'Could not join in challenge',
  join_organisation: 'Could not join an organisation',
  log_out: 'Could not log out, try again',
  no_organisations: 'Found no companies',
  no_profiles: 'No profiles found',
  remove_image: 'Could not delete image',
  remove_post: 'Could not delete posts',
  save_bookmark: 'Could not save your bookmark',
  save_comment: 'Could not save your comment',
  save_post_like: 'Could not save yours',
  share_post: 'Could not share posts',
  send_feedback: 'Could not send feedback',
  send_new_password: 'Could not send new password',
  set_profile_challenges: 'Could not save challenges',
  toggle_log_activity: 'Could not change logged-in activities',
  update_last_seen_notifications: 'Could not update last',
  update_profile: 'Could not update profile, please try again',
  error: 'An error occurred',
  E102: 'Wrong password',
  E301: 'This code is not valid',
  E302: 'Your email can not join this challenge',
  E900: 'An error occurred, please try again',
  E901: 'Too many requests. Please wait for a bit and then try again.',
  must_be_numeric: 'The code must consist of 6 numbers',
};

import React from 'react';
import * as PropTypes from 'prop-types';
import { Link, Button, Label } from './elements';

function Btn020(props) {
  if (props.href) {
    return (
      <Link
        className={props.className}
        disabled={Boolean(props.disabled)}
        href={props.href}
      >
        <Label theme={props.theme}>{props.children}</Label>
      </Link>
    );
  }

  return (
    <Button
      className={props.className}
      disabled={Boolean(props.disabled)}
      onClick={props.onClick}
      type={props.type}
      id={props.id}
    >
      <Label theme={props.theme}>{props.children}</Label>
    </Button>
  );
}

Btn020.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string,
  theme: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
};

export default Btn020;

import styled from 'styled-components';
import Mod001 from 'dc/Mod001';

export const Modal = styled(Mod001)`
  pointer-events: auto;
  max-width: 520px;

  > div {
    background-color: #ffffff;
    margin: 8px;
  }
`;

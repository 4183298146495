import styled, { css } from 'styled-components';
import { Typ30, Typ31, Typ40 } from 'dc/Typ';
import { Ico24 } from 'dc/Ico';

export const InnerWrapper = styled.div`
  padding: 20px 16px;
  background-color: #f9fcff;
  overflow-y: scroll;
  margin: 0 16px;
  border-radius: 5px;
  border: 1px solid #e2e6e8;
  li {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: #263238;
    margin: 0;
  }
  a {
    color: #00a6b6;
    text-decoration: underline;
  }
  & > span {
    display: block;
  }
`;

export const UpdatedText = styled(Typ40)`
  margin: 16px;
`;

export const TermsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${props =>
    props.theme.isMobile
      ? css`
          align-items: flex-start;
          justify-content: center;
          margin: 16px;
        `
      : css`
          align-items: flex-start;
          justify-content: flex-start;
          margin: 16px 16px 16px auto;
        `}
`;

export const TermsIcon = styled(Ico24)`
  color: #007079;
  margin-right: 6px;
  cursor: pointer;
`;

export const TermsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
`;

export const TermsText = styled(Typ30)`
  color: #273238;
`;

export const TermsLink = styled(Typ31)`
  & > a {
    color: #007079;
    word-break: break-all;
  }
`;

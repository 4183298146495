import React from 'react';
import { AvatarElement, Loader } from './elements';

type Ava36Props = {
  readonly isLoading?: boolean;
  readonly className?: string;
  readonly onClick?: () => void;
  readonly image?: string;
};

export default function Ava36({
  isLoading,
  className,
  onClick,
  image,
}: Ava36Props) {
  if (isLoading) {
    return (
      <AvatarElement className={className} onClick={onClick}>
        <Loader />
      </AvatarElement>
    );
  }

  return (
    <AvatarElement className={className} onClick={onClick} $image={image} />
  );
}

import * as sequences from './sequences';

export default {
  state: {
    added: false,
    form: {
      widgets: {
        challengeWidget: {
          value: false,
          defaultValue: false,
          validationRules: ['isValue'],
        },
      },
      pages: {
        liveScreen: {
          value: false,
          defaultValue: false,
          validationRules: ['isValue'],
        },
      },
    },
  },
  sequences: {
    addIntegration: sequences.addIntegration,
    skipIntegration: sequences.skipIntegration,
    optionClicked: sequences.toggleIntegration,
  },
};

import React from 'react';
import { connect } from '@cerebral/react';
import { state, props } from 'cerebral';

import { Wrapper, Avatar, Text } from './elements';
import { useText, useImage, useGetAvatarUrl } from 'common/hooks';

function getRandomLoggedActivity(activitiesCount) {
  const possibleActivityKeys = Object.keys(activitiesCount).filter(
    activityKey => activitiesCount[activityKey] > 0
  );

  const randomActivityKey =
    possibleActivityKeys[
      Math.floor(Math.random() * possibleActivityKeys.length)
    ];

  return {
    activityKey: randomActivityKey,
  };
}

export default connect(
  {
    profile: state`appContainer.profiles.${props`profileKey`}`,
    activities: state`appContainer.activities`,
  },
  function ChaSnB2({ profile, activities, activitiesCount }) {
    const getAvatarUrl = useGetAvatarUrl();
    const numberOfLoggedActivities = Object.keys(activitiesCount || {}).length;

    if (numberOfLoggedActivities <= 0) return null;

    const randomLoggedActivity = getRandomLoggedActivity(activitiesCount);
    const activity = activities[randomLoggedActivity.activityKey];

    const t = useText();
    const [url, isLoading] = useImage(getAvatarUrl(profile).small);

    return (
      <Wrapper>
        <Avatar isLoading={isLoading} image={url} />
        <Text>
          {numberOfLoggedActivities === 1
            ? t('messages.snackbar.challenge.participant_logged_activity', {
                name: profile
                  ? profile.name
                  : t('messages.snackbar.deleted_user'),
                activity: activity.title,
              })
            : t('messages.snackbar.challenge.participant_logged_activities', {
                name: profile
                  ? profile.name
                  : t('messages.snackbar.deleted_user'),
                activity: activity.title,
                smart_count: numberOfLoggedActivities - 1,
              })}
        </Text>
      </Wrapper>
    );
  }
);

import { state, props, string } from 'cerebral';
import { unset, set, when, toggle } from 'cerebral/factories';
import { setField } from 'forms/operators';
import { redirect } from 'common/factories';

import * as actions from './actions';

export const render = [set(state`admin.pageSection`, 'info')];

export const fieldChanged = [
  setField(state`${props`field`}`, props`value`),
  actions.validateOrganisation,
];

export const newSectionNameChanged = [
  actions.isNewSectionNameValid,
  {
    true: [
      set(state`${props`field`}`, props`value`),
      unset(
        state`admin.organisations.create.sectionNameErrors.${props`sectionKey`}`
      ),
    ],
    needName: [
      set(state`${props`field`}`, props`value`),
      set(
        state`admin.organisations.create.sectionNameErrors.${props`sectionKey`}`,
        string`This department must have a name`
      ),
    ],
    notUnique: [
      set(state`${props`field`}`, props`value`),
      set(
        state`admin.organisations.create.sectionNameErrors.${props`sectionKey`}`,
        string`The name "${props`value`}" is not unique`
      ),
    ],
  },
  actions.removeSection,
  actions.validateOrganisation,
];

export const addNewEmptySection = [actions.addNewEmptySection];

export const deleteSectionClicked = [
  set(state`admin.organisations.create.deletingSection`, true),
  actions.removeSection,
  actions.validateOrganisation,
];

export const newTeamNameChanged = [
  actions.isNewTeamNameUnique,
  {
    true: [
      set(state`${props`field`}`, props`value`),
      unset(state`admin.organisations.create.teamNameErrors.${props`teamKey`}`),
    ],
    false: [
      set(state`${props`field`}`, props`value`),
      set(
        state`admin.organisations.create.teamNameErrors.${props`teamKey`}`,
        string`The name "${props`value`}" is not unique`
      ),
    ],
  },
  actions.removeTeam,
  actions.validateOrganisation,
];

export const addNewEmptyTeam = [actions.addNewEmptyTeam];

export const deleteTeamClicked = [
  set(state`admin.organisations.create.deletingTeam`, true),
  actions.removeTeam,
  actions.validateOrganisation,
];

export const addStructure = [
  set(state`admin.organisations.create.structure.added`, true),
  set(state`admin.organisations.create.isValid`, false),
  actions.validateOrganisation,
];

export const createOrganisation = [
  set(state`admin.organisations.create.isCreatingOrganisation`, true),
  when(state`admin.organisations.create.uploadedImage.desktop.file`),
  {
    true: actions.uploadOrganisationImage,
    false: [],
  },
  actions.createOrganisationObject,
  actions.createOrganisation,
  actions.resetCreateOrganisationData,
  set(state`admin.organisations.create.isValidForm`, false),
  set(state`admin.organisations.create.isCreatingOrganisation`, false),
  redirect('/'),
];

export const setSelectedOrgTypeOption = [
  set(state`admin.organisations.create.selectedOrgType`, props`option`),
  set(
    state`admin.organisations.create.form.orgType.value`,
    props`option.optionKey`
  ),
  set(state`admin.organisations.create.orgTypeDropdownIsOpen`, false),
];

export const toggleOrgTypeDropdown = [
  toggle(state`admin.organisations.create.orgTypeDropdownIsOpen`),
];

export const setSelectedCountryOption = [
  set(state`admin.organisations.create.selectedCountry`, props`option`),
  set(
    state`admin.organisations.create.form.country.value`,
    props`option.optionKey`
  ),
  set(state`admin.organisations.create.countryDropdownIsOpen`, false),
];

export const toggleCountryDropdown = [
  toggle(state`admin.organisations.create.countryDropdownIsOpen`),
];

export const uploadOrganisationImage = [
  set(state`admin.organisations.create.uploadedImage.desktop.url`, props`url`),
  set(
    state`admin.organisations.create.uploadedImage.desktop.width`,
    props`width`
  ),
  set(
    state`admin.organisations.create.uploadedImage.desktop.height`,
    props`height`
  ),
  set(
    state`admin.organisations.create.uploadedImage.desktop.file`,
    props`file`
  ),
  actions.validateOrganisation,
];

export const removeOrganisationImage = [
  set(state`admin.organisations.create.uploadedImage.desktop`, {
    url: null,
    width: 180,
    height: 180,
  }),
  actions.validateOrganisation,
];

import React, { Component, ErrorInfo } from 'react';
import * as Sentry from '@sentry/browser';
import { Wrapper, Image, Title, Body, Button } from './elements';

type Props = {
  readonly title: string;
  readonly body: string;
  readonly buttonText: string;
  readonly imageSrc: string;
  readonly children: React.ReactNode;
};

type State = {
  error: boolean;
};

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error: true });

    Sentry.withScope(scope => {
      scope.setExtras({ errorInfo });

      Sentry.captureException(error);
    });

    console.error(error); // eslint-disable-line no-console
  }

  render(): React.ReactNode {
    if (this.state.error) {
      return (
        <Wrapper>
          <Image src={this.props.imageSrc} />
          <Title>{this.props.title}</Title>
          <Body as={'p'}>{this.props.body}</Body>
          <Button onClick={() => window.location.reload()}>
            {this.props.buttonText}
          </Button>
        </Wrapper>
      );
    }

    return this.props.children;
  }
}

import organisation from './organisation';
import info from './info';
import access from './access';
import integration from './integration';
import challenge from './challenge';
import type from './type';
import focus from './focus';
import championship from './championship';
import overview from './overview';
import structure from './structure';
import field_errors from './field_errors';

export default {
  organisation,
  info,
  access,
  integration,
  challenge,
  type,
  focus,
  championship,
  overview,
  structure,
  field_errors,
};

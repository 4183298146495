import React from 'react';
import * as PropTypes from 'prop-types';

import { Spacer } from './elements';

function Spa16(props) {
  return <Spacer className={props.className} />;
}

Spa16.propTypes = {
  className: PropTypes.string,
};

export default Spa16;

import React from 'react';

import { ButtonComponent, AnchorComponent } from './elements';

export default function Button(props) {
  if (props.onClick) {
    return (
      <ButtonComponent
        id={props.id}
        className={props.className}
        onClick={props.onClick}
        disabled={props.disabled}
        type={props.type}
        aria-label={props['aria-label']}
        aria-haspopup={props['aria-haspopup']}
        aria-expanded={props['aria-expanded']}
      >
        {props.children}
      </ButtonComponent>
    );
  }

  return (
    <AnchorComponent
      id={props.id}
      className={props.className}
      href={props.href}
      target={props.target || '_self'}
      rel={props.target === '_blank' ? 'noreferrer noopener' : ''}
      aria-label={props['aria-label']}
      aria-haspopup={props['aria-haspopup']}
      aria-expanded={props['aria-expanded']}
    >
      {props.children}
    </AnchorComponent>
  );
}

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences, props } from 'cerebral';
import { Input } from './elements';

export default connect(
  {
    value: state`admin.campaigns.create.info.form.${props`field`}.value`,
    fieldChanged: sequences`admin.campaigns.create.fieldChanged`,
  },
  function TextInput({
    field,
    value,
    fieldChanged,
    placeholder,
    maxLength,
    disabled,
    width,
  }) {
    return (
      <Input
        className
        width={width || null}
        disabled={disabled}
        onChange={event =>
          fieldChanged({
            field: `admin.campaigns.create.info.form.${field}`,
            value: event.target.value,
          })
        }
        value={value}
        icon={'icon-mode_edit'}
        placeholder={placeholder}
        maxLength={maxLength}
      />
    );
  }
);

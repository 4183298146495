import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Form } from 'forms';
import { useText } from 'common/hooks';

import SectionsAndTeams from './SectionsAndTeams';
import ImportStructure from './ImportStructure';
import Warning from './Warning';

import { Wrapper, Header, Paragraph } from './elements';
import Btn022 from 'dc/Btn022';

export default connect(
  {
    isEditCampaign: state`admin.campaigns.edit.isEdit`,
    form: state`admin.structure.structureForm`,
    hasTeamInAllSections: state`admin.campaigns.computedHasTeamInAllSections`,
    saveNewStructureClicked: sequences`admin.campaigns.edit.structure.saveNewStructureClicked`,
  },
  function SectionsAndTeamsWrapper({
    isEditCampaign,
    form,
    saveNewStructureClicked,
    hasTeamInAllSections,
    get,
  }) {
    const t = useText();

    const structureForm = new Form(form, get);

    if (isEditCampaign) {
      return (
        <Wrapper>
          <Warning />
          <SectionsAndTeams isEditCampaign />
          <Btn022
            onClick={() => saveNewStructureClicked()}
            disabled={!structureForm.isValid || !hasTeamInAllSections}
          >
            {t('admin.structure.save_structure')}
          </Btn022>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <Header>{t('admin.structure.set_up_teams')}</Header>
        <Paragraph as={'p'}>{t('admin.structure.info')}</Paragraph>
        <Paragraph as={'p'}>{t('admin.structure.import')}</Paragraph>
        <ImportStructure />
        <Warning />
        <SectionsAndTeams />
      </Wrapper>
    );
  }
);

import { setField } from 'forms/operators';
import { state, props } from 'cerebral';
import * as actions from './actions';

export const setAccessSetting = [
  setField(state`admin.campaigns.create.access.form.access`, props`value`),
];

export const setEmailDomain = [
  setField(
    state`admin.campaigns.create.access.form.emails.${props`field`}`,
    props`value`
  ),
];

export const addNewEmailDomain = [actions.addNewEmailDomainField];

export const removeEmailDomain = [actions.removeEmailDomainField];

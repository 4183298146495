import { set, toggle } from 'cerebral/factories';
import { state, props } from 'cerebral';
import { value } from 'providers/Firebase/src/operators';

import * as actions from './actions';

export const render = [
  value('championships.list'),
  actions.formatChampionships,
  actions.getOrganisations,
  actions.setOrganisationNames,
  set(state`admin.championships.list`, props`championshipList`),
];

export const toggleShowFinished = [
  toggle(state`admin.championships.showFinished`),
];

import styled from 'styled-components';
import { Typ41, Typ30 } from 'dc/Typ';
import Btn021 from 'dc/Btn021';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 56px;
`;

export const Title = styled(Typ41)`
  color: #546e7a;
  margin-bottom: 8px;
`;

export const Text = styled(Typ30)`
  color: #546e7a;
  margin-bottom: 4px;
`;

export const Label = styled(Typ30)`
  color: #546e7a;
  margin-top: 12px;
  margin-bottom: 4px;
`;

export const Link = styled(Typ30)`
  color: #01579b;
`;

export const Button = styled(Btn021)`
  width: 220px;
`;

export const HorizontalRule = styled.hr`
  margin-bottom: 52px;
  width: 100%;
  border-top: 1px solid #90a4ae;
`;

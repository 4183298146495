import React, { useState } from 'react';
import { KEY_CODE } from 'resources/constants';
import {
  ElementWrapper,
  InputWrapper,
  TextField,
  DropdownArrow,
  DropdownWrapper,
  Option,
} from './elements';

type Props = {
  readonly disabled: boolean;
  readonly className?: string;
  readonly onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readonly onFocus: () => void;
  readonly onDropdownItemClick: (option: string) => void;
  readonly placeholder: string;
  readonly value: string;
  readonly options: string[];
};

export default function TxtField11({
  disabled,
  className,
  onChange,
  onFocus,
  onDropdownItemClick,
  placeholder,
  value,
  options,
}: Props): React.ReactElement {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <ElementWrapper
      className={className}
      disabled={disabled}
      onBlur={() => {
        setShowDropdown(false);
      }}
      tabIndex={0}
      onKeyDown={({ keyCode }) => {
        if (keyCode === KEY_CODE.enter) {
          setShowDropdown(!disabled);
        }
        if (keyCode === KEY_CODE.esc) setShowDropdown(false);
      }}
    >
      <InputWrapper onClick={() => !disabled && setShowDropdown(!showDropdown)}>
        <TextField
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
        />
        <DropdownArrow icon={'icon-arrow_drop_down'} />
      </InputWrapper>
      <DropdownWrapper visible={showDropdown && !disabled}>
        {(options || []).map(option => (
          <Option
            key={option}
            onMouseDown={() => {
              onDropdownItemClick(option);
              setShowDropdown(false);
            }}
          >
            {option}
          </Option>
        ))}
      </DropdownWrapper>
    </ElementWrapper>
  );
}

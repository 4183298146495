import { renderPage } from '../../../../factories';
import * as sequences from './sequences';

export default {
  state: {
    isEdit: false,
    organisationKey: '',
    organisation: {},
    championships: {},
    selectedChampionship: {},
    championshipDropdownIsOpen: false,
    isJoiningChampionship: false,
    form: {
      championship: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
    },
  },
  sequences: {
    routed: renderPage('createOrganisation', sequences.rendered),
    championshipOptionClicked: sequences.setSelectedChampionshipOption,
    toggleChampionshipDropdownClicked: sequences.toggleChampionshipDropdown,
    joinChampionship: sequences.joinChampionship,
  },
};

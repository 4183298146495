import { state, props } from 'cerebral';
import { set, when, toggle, debounce } from 'cerebral/factories';
import { isValidForm, setField } from 'forms/operators';

import * as actions from './actions';
import { dropdownSelected } from '../../../../../campaigns/modules/create/actions';

export const setSeriesOption = [
  toggle(state`admin.championships.create.series.form.seriesNeeded.value`),
  when(state`admin.championships.create.series.form.seriesNeeded.value`),
  {
    true: [
      isValidForm(state`admin.championships.create.series.form`),
      {
        true: set(state`admin.championships.create.series.isValid`, true),
        false: set(state`admin.championships.create.series.isValid`, false),
      },
    ],
    false: [set(state`admin.championships.create.series.isValid`, true)],
  },
];

export const setSelectedSeriesTypeOption = [
  dropdownSelected,
  isValidForm(state`admin.championships.create.series.form`),
  {
    true: set(state`admin.championships.create.series.isValid`, true),
    false: set(state`admin.championships.create.series.isValid`, false),
  },
];

export const addSeries = [
  actions.addSeries,
  isValidForm(state`admin.championships.create.series.form`),
  {
    true: set(state`admin.championships.create.series.isValid`, true),
    false: set(state`admin.championships.create.series.isValid`, false),
  },
];

export const removeSeries = [
  actions.removeSeries,
  isValidForm(state`admin.championships.create.series.form`),
  {
    true: set(state`admin.championships.create.series.isValid`, true),
    false: set(state`admin.championships.create.series.isValid`, false),
  },
];

export const seriesFieldChanged = [
  setField(state`${props`field`}`, props`value`),
  debounce(1000),
  {
    continue: [
      isValidForm(state`admin.championships.create.series.form`),
      {
        true: set(state`admin.championships.create.series.isValid`, true),
        false: set(state`admin.championships.create.series.isValid`, false),
      },
    ],
    discard: [],
  },
];

import React from 'react';
import { connect } from '@cerebral/react';
import { sequences, state } from 'cerebral';

import { IndInfoClose, IndInfoWrapper, InfoCloseIcon } from './elements';
import ActInd01 from 'dc/ActInd01';

import { useText } from 'common/hooks';
import { getIndicator, getIndicatorConfig, toCo2 } from 'utils/common';
import { INDICATOR } from 'resources/constants';

export default connect(
  {
    activity: state`appContainer.activities.${state`admin.campaigns.create.challenge.activityModal.activityKey`}`,
    activityKey: state`admin.campaigns.create.challenge.activityModal.activityKey`,
    showMoreInfo: state`admin.campaigns.create.challenge.activityModal.showMoreInfo`,
    closeAdminActivityModalClicked: sequences`admin.campaigns.create.challenge.activityModal.closeAdminActivityModalClicked`,
    toggleIndicatorExpanded: sequences`admin.campaigns.create.challenge.activityModal.toggleIndicatorExpanded`,
    showAdminTagModal: sequences`admin.campaigns.create.challenge.showAdminTagModalClicked`,
  },
  function IndicatorModItem({
    activity,
    activityKey,
    indicator,
    showMoreInfo,
    toggleIndicatorExpanded,
  }) {
    const t = useText();
    const infoPath = `challenge.activities.${activityKey}.${indicator}_explanation`;
    const hasMoreInfo = t.has(infoPath);

    const indicatorObject = {
      ...getIndicatorConfig(indicator),
      value: getIndicator(activity.indicators, indicator),
      showMore: showMoreInfo[indicator],
    };
    if (indicator === INDICATOR.co2)
      indicatorObject.value = toCo2(indicatorObject.value);

    return (
      <div key={`ActInd01-${activity.indicators[indicator]}`}>
        <ActInd01
          showInfoButton={!indicatorObject.showMore}
          last
          icon={indicatorObject.icon}
          color={indicatorObject.color}
          expandable={hasMoreInfo}
          onClick={() => toggleIndicatorExpanded({ indicator: indicator })}
          label={t(`challenge.ActMod02.${indicator}_text`)}
          value={indicatorObject.value}
        />
        <IndInfoWrapper hide={!indicatorObject.showMore || !hasMoreInfo}>
          {t(infoPath)}
        </IndInfoWrapper>
        <IndInfoClose
          hide={!indicatorObject.showMore || !hasMoreInfo}
          onClick={() => toggleIndicatorExpanded({ indicator: indicator })}
        >
          <InfoCloseIcon icon={'icon-keyboard_arrow_up'} color={'#78909c'} />
        </IndInfoClose>
      </div>
    );
  }
);

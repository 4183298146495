export default {
  how_much_you_know: {
    consumption:
      'How much do you know about how goods and services relate to climate?',
    energy: 'How much do you know about energy and climate?',
    transport: 'How much do you know about transport and climate?',
    food: 'How much do you know about food and climate?',
    microplastics: 'How much do you know about plastic and climate?',
  },
  back: {
    consumption: 'Goods & Services info',
    energy: 'Energy info',
    transport: 'Transport info',
    food: 'Food info',
    microplastics: 'Microplastics info',
  },
  go_to: {
    consumption: 'Go to goods and services info',
    energy: 'Go to energy info',
    transport: 'Go to transport info',
    food: 'Go to good info',
    microplastics: 'Go to microplastics info',
  },
  test_knowledge: {
    consumption: 'Goods & Services - Test your knowledge',
    energy: 'Energy - Test your knowledge',
    transport: 'Transport - Test your knowledge',
    food: 'Food - Test your knowledge',
    microplastics: 'Microplastics - Test your knowledge',
  },
  end_page: {
    tough: 'That was tough!',
    congrats: 'Congrats!',
    use_knowledge: {
      consumption:
        'Use this Goods & Services knowledge to keep saving the world.',
      energy: 'Use this Energy knowledge to keep saving the world.',
      transport: 'Use this Transport knowledge to keep saving the world.',
      food: 'Use this Food knowledge to keep saving the world.',
      microplastics:
        'Use this Microplastics knowledge to keep saving the world.',
    },
    compare_with_others: 'Compare with others in %{organisationName}',
  },
  common: {
    continue: 'Continue',
    num_questions: '%{currentQuestion}/%{totalQuestions} questions',
    review_answers: 'Review answers',
    answer_questions:
      'answer %{smart_count} question |||| answer %{smart_count} questions',
    true: 'TRUE',
    false: 'FALSE',
  },
  buying_something: {
    question: 'Buying something can be considered better for the environment',
    explanation: `MARKDOWN:
Being green is not only about doing or buying less; it is also about how you spend the money you have. Money is rarely saved indefinitely. So even if you don’t buy anything today, it doesn't mean that you won't spend that money tomorrow - on something worse for the environment. 

That’s why activities such as donating money, or buying what you need second-hand, are good alternatives - they represent sustainable spending patterns. 
`,
  },
  buying_clothing: {
    question:
      'Purchasing 200 euros worth of clothing from an international fashion giant would result in approximately how many kilograms of CO₂e emissions?',
    options: {
      25: '25 kgCO\u2082e',
      50: '50 kgCO\u2082e',
      100: '100 kgCO\u2082e',
    },
    explanation: `MARKDOWN:
This would lead to around 100 kgCO₂e emissions from the entire value chain, from production of cotton, running factories, transport, storage etc. That’s the equivalent to driving 500 km in a car.

Additionally, clothing production processes require large land areas and lots of fresh water, meaning that your choices have a significant impact on our precious biodiversity. 
`,
  },
  e_waste: {
    question:
      'Currently, the world disposes of electronic and electrical equipment waste (e-waste) responsibly',
    explanation: `MARKDOWN:
The world generated 53.6 million metric tons of electronic and electrical equipment waste (e-waste) in 2019, an increase of more than 20 percent compared to 2014.

The improper disposal of e-waste releases poisonous chemicals into the soil and water, posing a health and environmental risk. 
`,
  },
  energy_greenhouse: {
    question:
      'Every year, the greenhouse gas emissions associated with producing electricity in Europe...',
    options: {
      decrease: 'decrease',
      increase: 'increase',
    },
    explanation: `MARKDOWN:
Every year, the greenhouse gas emissions associated with producing electricity in Europe decrease -  down nearly 50% since 2000! 

However, we increasingly rely on electricity, for example to power electric cars. That means if we don't improve efficiency and reduce energy consumption, the electricity demand will keep growing. 

The more electricity we need, the more land area and resources we'll need to power the future by building windmills and solar farms.
`,
  },
  heating_cooling: {
    question: 'Most energy in households is used for heating and cooling.',
    explanation: `MARKDOWN:
The most energy in households is used for heating and cooling.

Adjust the thermostat in your house to reduce heating or cooling needs and save energy, or invest in energy efficient technologies such as insulation and heat pumps to reduce your overall energy consumption.
`,
  },
  fossil_fuel_percentage: {
    question:
      'Roughly what percent of the world’s energy still comes from fossil fuels?',
    options: {
      30: '30%',
      50: '50%',
      80: '80%',
    },
    explanation: `MARKDOWN:
Despite deployment renewable energy at record speed, fossil fuels still provide most of the world's energy - around 84% in 2019.
`,
  },
  convenient_public: {
    question:
      "Roughly what percent of the world's urban population have convenient access to public transport?",
    options: {
      30: '30%',
      50: '50%',
      70: '70%',
    },
    explanation: `MARKDOWN:
Only half of the world's urban population have convenient access to public transport. (49.5% to be exact)

Convenient access means residing within 500m walking distance of a bus stop/low capacity transport system or 1000m of a railway or ferry terminal. 
`,
  },
  long_distance_train: {
    question:
      'The lowest-carbon option for long distance travel is taking the train',
    explanation: `MARKDOWN:
Over longer distances, rail travel is most often the best option. Train travel is associated with lower direct emission than a plane, especially if it is electric. (Emissions from diesel trains are twice as high as those from electric trains)
`,
  },
  bike_infrastructure: {
    question:
      'If the infrastructure does not exist for safe walking and bike riding, there is not much you can do about it',
    explanation: `MARKDOWN:
People are definitely more likely to want to walk and cycle if the streets feel safe and pleasant - such as low speed zones for cars, wider pavements or bike lanes. But just because this does not exist it does not mean there is nothing you can do about it. 

You can campaign in your local community. Show politicians and industry that there is a significant demand for a more sustainable transport system and that you are willing to play a part in exploring how this system will look in the future.
`,
  },
  food_waste_percentage: {
    question: 'What percent of food available to consumers is wasted?',
    options: {
      4: '4%',
      17: '17%',
      36: '36%',
    },
    explanation: `MARKDOWN: 
According to UN research, 17% of total food available to consumers in 2019, went into the waste bins of households, retailers, restaurants and other food services.

That's 931 million tonnes of food, which roughly equals that of 23 million fully loaded 40-tonne trucks — bumper-to-bumper, enough to circle the Earth 7 times.
`,
  },
  half_agriculture: {
    question: 'Half of the world’s habitable land is used for agriculture',
    explanation: `MARKDOWN:
Half of the world’s habitable land is used for agriculture. The expansion of agriculture creates great pressure on biodiversity, threatening our environment.

24,000 of the 28,000 species evaluated to be threatened with extinction on the IUCN Red List have agriculture listed as a threat.
`,
  },
  sea_food_transport: {
    question: 'Most food is transported by sea, not air',
    explanation: `MARKDOWN:
It is often thought that buying foods produced locally is an effective way to reduce our carbon footprint. However, how your food is produced is much more impactful that where it is produced. Only a very few foods are transported by plane, and the  emissions associated with shipping food by sea are very low.  
`,
  },
  plastic_recycling_percentage: {
    question: 'What percent of all plastic waste produced has been recycled?',
    options: {
      9: '9%',
      29: '29%',
      49: '49%',
    },
    explanation: `MARKDOWN:
Currently, only 9% of all plastic waste produced has been recycled. About 12% of the waste has been incinerated, while the remaining 79% has been dumped in landfills or the natural environment.
`,
  },
  million_plastic_bottles: {
    question: 'One million plastic bottles are purchased globally every...',
    options: {
      minute: 'minute',
      hour: 'hour',
      day: 'day',
    },
    explanation: `MARKDOWN:
1 million plastic bottles are purchased globally every minute. 

Most plastic soft drink bottles are made from polyethylene terephthalate (PET), which can be recycled efficiently. Nonetheless, efforts to collect and recycle them and prevent polluting the oceans are still falling short.
`,
  },
  plastic_lasts: {
    question: 'For how long is plastic thought to last in nature?',
    options: {
      '1to2years': '1 - 2 years',
      '50to100years': '50 - 100 years',
      forever: 'Possibly forever',
    },
    explanation: `MARKDOWN:
There is a lot of research which shows that plastics stay in nature for a very long time - possibly forever.

The plastic is broken up into smaller and smaller pieces, ranging from so-called "macroplastics" (large, visible pieces) to "microplastics" and finally to "nanoplastics". 

Microplastics are pieces of plastic between 1 micron to 5 millimeters - nanoplastics are even smaller!
`,
  },
};

import styled, { css } from 'styled-components';

import Btn020 from 'dc/Btn020';
import { Typ30, Typ41 } from 'dc/Typ';
import Ico24 from 'dc/Ico24';
import Mod001 from 'dc/Mod001';

export const DesktopModal = styled(Mod001)`
  & > div {
    display: flex;
    flex-direction: column;
    max-width: 676px;
    min-width: initial;
  }

  height: 700px !important;
`;

export const MobileModal = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DuckyIcon = styled(Ico24)`
  color: #607d8b;
  margin: 16px;
`;

export const Updated = styled(Typ30)`
  color: #607d8b;
  margin-bottom: 24px;
`;

export const PrivacyTitle = styled(Typ30)`
  color: #263238;
`;

export const PrivacyHeader = styled(Typ41)``;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  ${props =>
    props.theme.isMobile &&
    css`
      justify-content: space-between;
    `}
`;

type PrimaryButtonProps = {
  color?: string;
  disabled?: boolean;
};

export const PrimaryButton = styled(Btn020)<PrimaryButtonProps>`
  background-color: ${props =>
    props.disabled ? 'rgba(96, 125, 139, 0.12)' : props.color || '#007079'};
  margin: 16px;
  span {
    color: ${props => (props.disabled ? 'rgba(96, 125, 139, 0.4)' : '#fff')};
  }
`;

type SecondaryButtonProps = {
  color?: string;
  disabled?: boolean;
};

export const SecondaryButton = styled(Btn020)<SecondaryButtonProps>`
  background-color: transparent;
  margin: 16px 0 16px 16px;
  span {
    color: ${props => (props.disabled ? 'rgba(96, 125, 139, 0.4)' : '#007079')};
  }
`;

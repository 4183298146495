import React from 'react';
import * as PropTypes from 'prop-types';
import { Wrapper, TextInput, Icon, IconPlaceholder } from './elements';

function TxtField13(props) {
  return (
    <Wrapper className={props.className}>
      <TextInput
        version={'default'}
        label={props.placeholder}
        value={props.value}
        onKeyDown={props.onKeyDown}
        onChange={props.onChange}
        maxLength={props.maxLength}
      />
      {props.value ? (
        <Icon icon={'icon-cancel'} onClick={props.onDeleteClick} />
      ) : (
        <IconPlaceholder />
      )}
    </Wrapper>
  );
}

TxtField13.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onDeleteClick: PropTypes.func,
};

export default TxtField13;

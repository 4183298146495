export default {
  total_co2_saved: 'In total you have saved %{co2} kgCO\u2082e',
  total_co2_potential_saving: 'You could save %{co2} kgCO\u2082e per year',
  total_co2_footprint: 'In total your footprint is %{co2} kgCO\u2082e',
  average_co2_footprint:
    'The average footprint in %{sectionName} is %{co2} kgCO\u2082e',
  average_saving:
    'On average, each participant has saved %{translateCo2} kgCO\u2082e',
  congratulations: 'Congratulations!',
  reached_new_level: 'Your team has reached a new level.',
  vehicles: {
    car: 'car',
    plane: 'plane',
    train: 'Train',
  },
  transport_methods: {
    car: 'driving',
    plane: 'flying',
    train: 'a train journey',
  },
  transport_text: {
    saving_equivalent_to:
      'This saving is equivalent to traveling %{convertedDistance}km by %{vehicle}. Which is roughly the same as %{transportMethod} from %{from} to %{to}.',
    footprint_equivalent_to:
      'These emissions are equivalent to traveling %{convertedDistance}km by %{vehicle}. Which is roughly the same as %{transportMethod} from %{from} to %{to}.',
    actual_distance:
      'Actual distance between %{from} and %{to} is %{actualDistance}km',
  },
  locations: {
    copenhagen: 'Copenhagen',
    london: 'London',
    malmo: 'Malmø',
    rotterdam: 'Rotterdam',
    hague: 'Hague',
    nice: 'Nice',
    cannes: 'Cannes',
    brighton: 'Brighton',
    vienna: 'Vienna',
    bratislava: 'Bratislava',
    amsterdam: 'Amsterdam',
    brussels: 'Brussels',
    budapest: 'Budapest',
    glasgow: 'Glasgow',
    paris: 'Paris',
    berlin: 'Berlin',
    cairo: 'Cairo',
    jerusalem: 'Jerusalem',
    barcelona: 'Barcelona',
    madrid: 'Madrid',
    marrakech: 'Marrakech',
    lisbon: 'Lisbon',
    rome: 'Rome',
    oslo: 'Oslo',
    narvik: 'Narvik',
    vardo: 'Vardø',
    prague: 'Prague',
    athens: 'Athens',
    moscow: 'Moscow',
    istanbul: 'Istanbul',
    tromso: 'Tromsø',
    singapore: 'Singapore',
    seattle: 'Seattle',
    miami: 'Miami',
    nairobi: 'Nairobi',
    dubai: 'Dubai',
    anchorage: 'Anchorage',
    ulaanbaatar: 'Ulaanbaatar',
    magadan: 'Magadan',
    las_vegas: 'Las Vegas',
    new_dehli: 'New Delhi',
    new_delhi: 'New Delhi',
    cape_town: 'Cape Town',
    new_york: 'New York',
    hakone: 'Hakone',
    tsuru: 'Tsuru',
    kashima: 'Kashima',
    joetsu: 'Joetsu',
    hamamatsu: 'Hamamatsu',
    aizuwakamatsu: 'Aizuwakamatsu',
    kyoto: 'Kyoto',
    kobe: 'Kobe',
    morioka: 'Morioka',
    izumo: 'Izumo',
    imabari: 'Imabari',
    hakodate: 'Hakodate',
    hiroshima: 'Hiroshima',
    sapporo: 'Sapporo',
    shimonoseki: 'Shimonoseki',
    shanghai: 'Shanghai',
    miyakojima: 'Miyakojima',
    dalian: 'Dalian',
    guam: 'Guam',
    hong_kong: 'Hong Kong',
    taipei: 'Taipei',
    hanoi: 'Hanoi',
    cebu: 'Cebu',
    ho_chi_minh_city: 'Ho Chi Minh City',
    brunei: 'Brunei',
    bangkok: 'Bangkok',
    honolulu: 'Honolulu',
    agra: 'Agra',
    ayers_rock: 'Ayers Rock',
    melbourne: 'Melbourne',
    san_francisco: 'San Francisco',
    trondheim: 'Trondheim',
    la_habana: 'Havana',
    sahara: 'Sahara',
    antarctica: 'Antarctica',
    quito: 'Quito',
    rio_de_janeiro: 'Rio de Janeiro',
    tokyo: 'Tokyo',
    manchester: 'Manchester',
    liverpool: 'Liverpool',
    luton: 'Luton',
    cambridge: 'Cambridge',
    oxford: 'Oxford',
    cardiff: 'Cardiff',
    sheffield: 'Sheffield',
    newcastle_upon_tyne: 'Newcastle upon Tyne',
    inverness: 'Inverness',
    john_o_groats: "John O' Groats",
    lands_end: "Land's End",
  },
  energy_text: {
    small_usage: {
      saving_equivalent_to:
        'This saving is equivalent to using %{convertedKwh}kWh of energy. This is roughly the same as using your %{appliance} for %{quantity} %{units}!',
      footprint_equivalent_to:
        'These emissions are equivalent to using %{convertedKwh}kWh of energy. This is roughly the same as using your %{appliance} for %{quantity} %{units}!',
      actual_energy_used:
        'Actual energy used to power an average %{appliance} for %{quantity} %{units} is %{actualKwh}kWh.',
      saving_equivalent_to_times:
        'This saving is equivalent to using %{convertedKwh}kWh of energy. This is roughly the same as using your %{appliance} %{quantity} %{units}!',
      footprint_equivalent_to_times:
        'These emissions are equivalent to using %{convertedKwh}kWh of energy. This is roughly the same as using your %{appliance} %{quantity} %{units}!',
      actual_energy_used_times:
        'Actual energy used to power an average %{appliance} %{quantity} %{units} is %{actualKwh}kWh.',
    },
    large_usage: {
      saving_equivalent_to:
        'This saving is equivalent to using %{convertedKwh}kWh of energy. This is roughly the same as powering an entire %{appliance} for %{quantity} %{units}!',
      footprint_equivalent_to:
        'These emissions are equivalent to using %{convertedKwh}kWh of energy. This is roughly the same as powering an entire %{appliance} for %{quantity} %{units}!',
      actual_energy_used:
        'Average energy used to power a %{appliance} for %{quantity} %{units} is %{actualKwh}kWh.',
    },
    production: {
      saving_equivalent_to:
        'This saving is equivalent to using %{convertedKwh}kWh of energy. This is roughly how much energy a %{appliance} produces in %{quantity} %{units}.',
      footprint_equivalent_to:
        'These emissions are equivalent to using %{convertedKwh}kWh of energy. This is roughly how much energy a %{appliance} produces in %{quantity} %{units}.',
      actual_energy_used:
        'Average energy produced by a %{appliance} in %{quantity} %{units} is %{actualKwh}kWh.',
    },
  },
  appliances: {
    microwave: 'microwave',
    plasma_tv: 'plasma TV',
    clothes_dryer: 'clothes dryer',
    lawnmower: 'lawnmower',
    portable_heater: 'portable heater',
    oven: 'oven',
    hair_dryer: 'hair dryer',
    desktop_computer: 'desktop computer',
    dishwasher: 'dishwasher',
    vacuum_cleaner: 'vacuum cleaner',
    coffee_brewer: 'coffee brewer',
    total_household: 'house',
    stereo: 'stereo',
    playstation_tv: 'PS4 and plasma TV',
    windmill: 'windmill',
    laptop: 'laptop',
  },
  units: {
    times: 'times',
    hours: 'hours',
    days: 'days',
    years: 'years',
  },
  nature_text: {
    10: 'This is roughly as much as 1 tree absorbs \n in a year',
    25: 'This is roughly as much CO\u2082e as 30 trees absorb in a month.',
    50: 'This is roughly as much CO\u2082e as 60 trees absorbing in a month.',
    100: 'This is roughly as much CO\u2082e as 120 trees absorb in a month.',
    150: 'This is roughly as much CO\u2082e as 181 trees absorb in a month.',
    200: 'This is roughly as much CO\u2082e as 240 trees absorbs in a month.',
    300: 'This is roughly as much CO\u2082e as 361 trees absorbs in a month.',
    500: 'This is roughly as much CO\u2082e as a forest the size of Trondheim Torg shopping center would absorb in a whole month!',
    1000: 'This is roughly as much CO\u2082e as a forest the size of Kristiansten Fortress in Trondheim would absorb in a whole month!',
    1500: 'This is roughly as much CO\u2082e as a forest the size of the Viking ship at Hamar would absorb in a whole month!',
    2000: 'This is roughly as much CO\u2082e as a forest the size of the Opera house in Bjørvika would absorb in a whole month!',
    2500: 'This is roughly as much CO\u2082e as a forest the size of Oslo City Hall would absorb in a whole month!',
    3000: 'This is roughly as much CO\u2082e as a forest the size of the White House would absorb in a whole month!',
    4000: "This is roughly as much CO\u2082e as a forest the size of the Louvre in Paris, the world's largest museum, would absorb in a whole month!",
    6000: 'This is roughly as much CO\u2082e as a forest the size of Flesland in Bergen would absorb in a whole month!',
    8000: 'This is roughly as much CO\u2082e as a forest twice the size of the Louvre in Paris would absorb in a whole month!',
    10000:
      'This is roughly as much CO\u2082e as a forest the size of Akershus Fortress would absorb in a whole month!',
    12000:
      'This is roughly as much CO\u2082e as a forest the size of Tøyenparken in Oslo would absorb in a whole month!',
    14000:
      'This is roughly as much CO\u2082e as a forest the size of Hunderfossen Family Park in Lillehammerv',
    16000:
      'This is roughly as much CO\u2082e as a forest the size of Oslo Gardermoen would absorb in a whole month!',
    20000:
      'This is roughly as much CO\u2082e as a forest twice the size of Akershus Fortress would absorb in a whole month!',
    25000:
      'This is roughly as much CO\u2082e as a forest the size of the Vatican City would absorb in a whole month!',
    30000:
      'This is roughly as much CO\u2082e as a forest the size of the Frogner Park would absorb in a whole month!',
    35000:
      'This is roughly as much CO\u2082e as a forest the size of Tusenfryd would absorb in a whole month!',
    40000:
      'This is roughly as much CO\u2082e as a forest the size of the Dyreparken in Kristiansand would absorb in a whole month!',
  },
  actual_area: {
    500: {
      place: 'Trondheim Torg shopping center',
      size: '8428 m\u00B2',
    },
    1000: {
      place: 'Kristiansten Fortress',
      size: '16884 m\u00B2',
    },
    1500: {
      place: 'the Viking ship',
      size: '25301 m\u00B2',
    },
    2000: {
      place: 'the Opera house',
      size: '33735 m\u00B2',
    },
    2500: {
      place: 'Oslo City Hall',
      size: '42169 m\u00B2',
    },
    3000: {
      place: 'the White House',
      size: '50602 m\u00B2',
    },
    4000: {
      place: 'the Louvre',
      size: '67470 m\u00B2',
    },
    6000: {
      place: 'Flesland',
      size: '0.10 km\u00B2',
    },
    8000: {
      place: 'two Louvres',
      size: '0.13 km\u00B2',
    },
    10000: {
      place: 'Akershus Fortress',
      size: '0.17 km\u00B2',
    },
    12000: {
      place: 'Tøyenparken',
      size: '0.20 km\u00B2',
    },
    14000: {
      place: 'Hunderfossen Family Park',
      size: '0.224 km\u00B2',
    },
    16000: {
      place: 'Oslo Gardermoen',
      size: '0.227 km\u00B2',
    },
    20000: {
      place: 'two Akershus Fortresses',
      size: '0.33 km\u00B2',
    },
    25000: {
      place: 'the Vatican City',
      size: '0.42 km\u00B2',
    },
    30000: {
      place: 'Frogner Park',
      size: '0.50 km\u00B2',
    },
    35000: {
      place: 'Tusenfryd',
      size: '0.59 km\u00B2',
    },
    40000: {
      place: 'Dyreparken',
      size: '0.67 km\u00B2',
    },
  },
  static: {
    car_low: {
      title: 'In total you have saved %{kg}kg CO\u2082e',
      title_collective: 'In total you have saved %{kg}kg CO\u2082e',
      body: 'This is equivalent to travelling %{distance}km by car. Which is roughly the same as driving from Copenhagen to Malmö %{times} times.',
      actual: 'Actual distance between Copenhagen and Malmö is 44.4km',
    },
    car_med: {
      title: 'In total you have saved %{kg}kg CO\u2082e',
      title_collective: 'In total you have saved %{kg}kg CO\u2082e',
      body: 'This is equivalent to travelling %{distance}km by car. Which is roughly the same as driving from Rome to Budapest %{times} times.',
      actual: 'Actual distance between Rome and Budapest is 1485km',
    },
    appliance_low: {
      title: 'In total you have saved %{kg}kg CO\u2082e',
      title_collective: 'In total you have saved %{kg}kg CO\u2082e',
      body: 'This saving is equivalent to %{kWh}kWh of energy. This is roughly the same as using your microwave for %{days} days',
      actual: '',
    },
    plane_high: {
      title: 'In total you have saved %{kg}kg CO\u2082e',
      title_collective: 'In total you have saved %{kg}kg CO\u2082e',
      body: 'You have saved the same as travelling %{distance}km by plane. Which is roughly the same as flying all the way around the earth %{times} time. |||| You have saved the same as travelling %{distance}km by plane. Which is roughly the same as flying all the way around the earth %{times} times.',
      actual: 'Actual distance around the earth is 40,076km',
    },
  },
};

import { INDICATOR } from 'resources/constants';

export function closeMoreInfo({ state }) {
  Object.keys(INDICATOR).forEach(indicator => {
    state.set(
      `admin.campaigns.create.challenge.activityModal.showMoreInfo.${indicator}`,
      false
    );
  });
}

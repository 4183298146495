import { useContext } from 'react';
import * as Sentry from '@sentry/browser';

import LanguageContext from '../LanguageContext';
import { createTextApi } from '@ducky/provider-text';

function handleMissingText(message) {
  console.warn(message); // eslint-disable-line no-console

  Sentry.withScope(scope => {
    scope.setLevel('warning');
    Sentry.captureMessage(message);
  });
}

type FunctionType = {
  locale: string;
  fullLocale: string;
  markdown: (content: string) => string;
  has: (path: string) => boolean;
  (path: string, data?: { [key: string]: number | string }): string;
};

export default function useText(): FunctionType {
  const language = useContext(LanguageContext);

  return createTextApi(language, handleMissingText);
}

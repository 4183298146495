import styled from 'styled-components';
import Mod001 from 'dc/Mod001';
import IcoW01 from 'dc/IcoW01';
import TxtField03 from 'dc/TxtField03';
import Btn021 from 'dc/Btn021';
import Ico36 from 'dc/Ico36';
import { Typ20, Typ30, Typ31 } from 'dc/Typ';

export const Modal = styled(Mod001)`
  pointer-events: auto;
  max-width: 520px;
  > div {
    background-color: #ffffff;
    margin: 8px;
  }
`;

export const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  width: 100%;
  background: transparent;
`;

export const CloseIcon = styled(IcoW01)`
  cursor: pointer;
  color: #607d8b;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
`;

export const WarningWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
`;

export const WarningIcon = styled(Ico36)`
  color: #d9464d;
  margin-right: 12px;
`;

export const WarningText = styled(Typ31)`
  color: #d9464d;
`;

export const Text = styled(Typ30)`
  color: #546e7a;
  margin-bottom: 18px;
`;

export const AdditionalText = styled(Typ20)`
  align-self: flex-end;
  cursor: pointer;
`;

export const CheckboxContainer = styled.div`
  border: 2px solid #d9464d;
  padding: 8px;
  border-radius: 5px;
  background: #d9464d11;
  display: flex;
  flex-direction: column;
`;

export const DoNotText = styled(Typ31)`
  color: #d9464d;
  margin-bottom: 8px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
`;

export const Label = styled.label`
  padding-left: 4px;
`;

export const Input = styled(TxtField03)`
  width: 100%;
  margin-bottom: 22px;
`;

export const DeleteButton = styled(Btn021)`
  display: block;
  width: 100%;
  background-color: #d9464d;
`;

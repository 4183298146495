export function validateOrganisation({ forms, state }) {
  const form = forms.toJSON('admin.organisations.create.form');
  const organisationName = form.name.length > 0;
  const isCreatingTemplate = state.get(
    'admin.organisations.create.structure.added'
  );

  let isValid = organisationName;

  if (isCreatingTemplate) {
    const sections = state.get('admin.organisations.create.form.sections');
    const sectionNameErrors = state.get(
      'admin.organisations.create.sectionNameErrors'
    );
    const teamNameErrors = state.get(
      'admin.organisations.create.teamNameErrors'
    );

    let sectionsAreValid = false;
    const validSectionNames = [];

    Object.keys(sections).forEach(sectionKey =>
      sections[sectionKey].value.length > 0
        ? validSectionNames.push(sectionKey)
        : null
    );

    const teamsAreValidArray = [];

    let validTeamNames = [];

    validSectionNames.forEach(sectionKey => {
      validTeamNames = [];
      Object.keys(sections[sectionKey].teams).forEach(teamKey => {
        sections[sectionKey].teams[teamKey].value.length > 0
          ? validTeamNames.push(teamKey)
          : null;
      });
      if (
        validTeamNames.length + 1 ===
          Object.keys(sections[sectionKey].teams).length &&
        Object.keys(sections[sectionKey].teams).length > 1
      ) {
        teamsAreValidArray.push(true);
      } else {
        teamsAreValidArray.push(false);
      }
    });

    if (
      validSectionNames.length + 1 === Object.keys(sections).length &&
      Object.keys(sections).length > 1
    ) {
      sectionsAreValid = true;
    }

    isValid &&=
      sectionsAreValid &&
      !Object.keys(sectionNameErrors).length &&
      teamsAreValidArray.indexOf(false) === -1 &&
      !Object.keys(teamNameErrors).length;
  }

  state.set(`admin.organisations.create.isValidForm`, isValid);
}

export function isNewSectionNameValid({ state, props, path }) {
  const { value, sectionKey } = props;
  const sections = state.get('admin.organisations.create.form.sections');
  const names = Object.keys(sections).map(secKey =>
    sections[secKey].value.toLowerCase()
  );
  const nameExists = names.includes(value.toLowerCase());
  const isOnlyName = names.length === 0;
  const numOfTeams = Object.keys(sections[sectionKey].teams).length;

  if (isOnlyName || (value.length === 0 && numOfTeams === 1)) {
    return path.true();
  }
  if (!nameExists) {
    return path.true();
  }
  if (numOfTeams > 1 && value.length === 0) {
    return path.needName();
  }
  if (value.length !== 0) {
    return path.notUnique();
  }

  return path.needName();
}

export function removeSection({ props, state }) {
  const section = state.get(
    `admin.organisations.create.form.sections.${props.sectionKey}`
  );
  const deletingSection = state.get(
    `admin.organisations.create.deletingSection`
  );

  if (
    (section.value.length === 0 && Object.keys(section.teams).length <= 1) ||
    deletingSection
  ) {
    state.unset(`admin.organisations.create.form.sections.${props.sectionKey}`);
    state.unset(
      `admin.organisations.create.sectionNameErrors.${props.sectionKey}`
    );
    state.set(`admin.organisations.create.deletingSection`, false);
  }
}

export function addNewEmptySection({ props, state }) {
  const { keyCode, sectionKey } = props;
  const sections = state.get('admin.organisations.create.form.sections');

  if (
    sections[sectionKey].value.length === 0 &&
    !sections[sectionKey].teams &&
    keyCode >= 48 &&
    keyCode <= 90
  ) {
    let newKeyNumber = Number(sectionKey.substring(1)) + 1;

    state.set(
      `admin.organisations.create.form.sections.${sectionKey}.teams.t0`,
      {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      }
    );

    while (Object.keys(sections).indexOf(`s${newKeyNumber}`) !== -1) {
      newKeyNumber++;
    }

    state.set(`admin.organisations.create.form.sections.s${newKeyNumber}`, {
      value: '',
      defaultValue: '',
      validationRules: ['isValue'],
    });
  }
}

export function isNewTeamNameUnique({ state, props, path }) {
  const { value } = props;
  const sections = state.get('admin.organisations.create.form.sections');
  const names = [];

  Object.keys(sections).forEach(sectionKey => {
    sections[sectionKey].teams
      ? Object.keys(sections[sectionKey].teams).forEach(teamKey => {
          names.push(sections[sectionKey].teams[teamKey].value);
        })
      : null;
  });
  const nameExists = names.includes(value);
  const isOnlyName = names.length === 0;

  if (isOnlyName || value.length === 0) {
    return path.true();
  }
  if (!nameExists) {
    return path.true();
  }

  return path.false();
}

export function removeTeam({ props, state }) {
  const deletingTeam = state.get(`admin.organisations.create.deletingTeam`);

  if (props.value.length === 0 || deletingTeam) {
    state.unset(
      `admin.organisations.create.form.sections.${props.sectionKey}.teams.${props.teamKey}`
    );
    state.unset(`admin.organisations.create.teamNameErrors.${props.teamKey}`);
    state.set(`admin.organisations.create.deletingTeam`, false);
    removeSection({ props, state });
  }
}

export function addNewEmptyTeam({ props, state }) {
  const { keyCode, sectionKey, teamKey } = props;
  const teams = state.get(
    `admin.organisations.create.form.sections.${sectionKey}.teams`
  );

  if (teams[teamKey].value.length === 0 && keyCode >= 48 && keyCode <= 90) {
    let newKeyNumber = Number(teamKey.substring(1)) + 1;

    while (Object.keys(teams).indexOf(`s${newKeyNumber}`) !== -1) {
      newKeyNumber++;
    }

    state.set(
      `admin.organisations.create.form.sections.${sectionKey}.teams.t${newKeyNumber}`,
      {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      }
    );
  }
}

export function createOrganisationObject({ forms, state, props }) {
  const organisationForm = forms.get('admin.organisations.create.form');
  const isCreatingTemplate = state.get(
    'admin.organisations.create.structure.added'
  );
  const organisationImage =
    props.organisationImage ||
    state.get('admin.organisations.create.uploadedImage.desktop.url');
  const sectionsForm = state.get('admin.organisations.create.form.sections');
  const sections = isCreatingTemplate
    ? Object.keys(sectionsForm)
        .map(sectionKey => {
          if (sectionsForm[sectionKey].value.length > 0) {
            return {
              name: sectionsForm[sectionKey].value,
              teams: Object.keys(sectionsForm[sectionKey].teams)
                .map(teamKey => {
                  if (
                    sectionsForm[sectionKey].teams[teamKey].value.length > 0
                  ) {
                    return {
                      name: sectionsForm[sectionKey].teams[teamKey].value,
                    };
                  }

                  return null;
                })
                .filter(team => team),
            };
          }

          return null;
        })
        .filter(section => section) || []
    : [];

  const organisationObject = {
    name: organisationForm.name.value,
    orgType: organisationForm.orgType.value,
    country: organisationForm.country.value,
    numPeople: Number(organisationForm.numPeople.value),
    organisationImage: organisationImage,
    sections: sections,
  };

  return { organisationObject };
}

export function createOrganisation({ firebase, props }) {
  const { organisationObject } = props;

  return firebase
    .task('create_organisation', {
      organisation: organisationObject,
    })
    .catch(error => {
      console.log('CREATE ORGANISATION TASK ERROR', error); // eslint-disable-line no-console
    });
}

export function resetCreateOrganisationData({ state }) {
  state.unset('admin.organisations.create.structure');
  state.set('admin.organisations.create.form', {
    name: {
      value: '',
      defaultValue: '',
      validationRules: ['isValue'],
    },
    sections: {
      s0: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
    },
  });
}

export function uploadOrganisationImage({ state, firebase }) {
  const { file } = state.get(
    'admin.organisations.create.uploadedImage.desktop'
  );

  return firebase
    .uploadAdminImage(file, { size: '128x128>', path: 'organisationLogos' })
    .then(({ response }) => ({ organisationImage: response.path }))
    .catch(error => {
      console.log('UPLOAD IMAGE ERROR', error); // eslint-disable-line no-console
    });
}

import styled, { css } from 'styled-components';

import Ico24 from 'dc/Ico24';
import { Typ41, Typ30 } from 'dc/Typ';

export const OuterWrapper = styled.div`
  width: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
`;

export const Icon = styled(Ico24)`
  color: #00a6b6;
`;

export const Title = styled(Typ41)`
  color: #263238;
  padding-left: 8px;
`;

type ContentWrapperProps = {
  $expanded: boolean;
};

export const ContentWrapper = styled.div<ContentWrapperProps>`
  padding: 16px 0 0 32px;
  transform: scaleY(0);
  transition: transform 300ms ease;
  transform-origin: top;
  ${props =>
    props.$expanded &&
    css`
      transform: scaleY(1);
    `};
  ${props => (props.$expanded ? 'height: auto' : 'height: 0px')};
`;

export const Content = styled(Typ30)`
  width: 100%;
  max-width: 800px;
`;

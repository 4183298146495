import { Route } from 'common/types';

const routes: Route[] = [
  {
    path: '/',
    name: 'root',
    signal: 'admin.dashboardRouted',
  },
  {
    path: '/organisations',
    name: 'organisations',
    signal: 'admin.organisations.routed',
  },
  {
    path: '/organisations/create',
    name: 'createOrganisation',
    signal: 'admin.organisations.create.routed',
  },
  {
    path: '/organisations/edit/:organisationKey',
    name: 'editOrganisation',
    signal: 'admin.organisations.edit.routed',
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    signal: 'admin.campaigns.routed',
  },
  {
    path: '/campaigns/create',
    name: 'createCampaign',
    signal: 'admin.campaigns.create.routed',
  },
  {
    path: '/campaigns/edit/:campaignKey',
    name: 'editCampaign',
    signal: 'admin.campaigns.edit.routed',
  },
  {
    path: '/championships',
    name: 'championships',
    signal: 'admin.championships.routed',
  },
  {
    path: '/championships/create',
    name: 'createChampionship',
    signal: 'admin.championships.create.routed',
  },
  {
    path: '/championships/edit/:championshipKey',
    name: 'editChampionship',
    signal: 'admin.championships.edit.routed',
  },
  // Below is legacy -- to be refactored
  {
    path: '/statistics/:challengeKey',
    name: 'challengeStatistics',
    signal: 'admin.statistics.challengeStatisticsRouted',
  },
  {
    path: '/duckyStatistics',
    name: 'duckyStatistics',
    signal: 'admin.statistics.duckyStatisticsRouted',
  },
];

export default routes;

export default {
  activity_consumption_01: {
    title: 'I repaired something today',
    description:
      'Log on days you repair clothing or other broken items rather than throwing them away.',
    co2_explanation: `MARKDOWN:
## The facts
- The most effective way to reduce the environmental impact of a product is by choosing durable and high quality products that can be repaired.  
- Being a responsible consumer means reducing consumption through repairing, reusing, and reselling products you don't need. You'll save money, and make sure our resources are used better.

## The assumptions
- Calculations for household consumption are based on macro-economic input-output data, which give data on the emissions per amount of money spent on different services or products in your country. 
- It's assumed that by repairing your products, they will last 20% longer than the average and you have 20% lower environmental impact per currency you spend on purchases.
- Calculations are based on the assumption that you will live according to this new habit if you register this activity every third day during the challenge.

## Additional info
- [https://wwd.com/business-news/technology/think-tank-fashion-circular-economy-1202966172/](https://wwd.com/business-news/technology/think-tank-fashion-circular-economy-1202966172/)`,
    health_explanation: `MARKDOWN:
## The facts
- According to research, the desire for material possessions relate more to unhappiness than to happiness. Less consumption can help you invest more in positive, non-monetary aspects in life such as happiness, leisure, human kindness and connection.
- Redirecting the purpose of our consumption habits and devoting more time and effort on activities that you find meaningful can lead to a worthwhile life and make you happier.

## Additional info
- [https://www.psychologytoday.com/us/blog/buddhist-economics/201803/why-over-consumption-is-making-us-unhappy](https://www.psychologytoday.com/us/blog/buddhist-economics/201803/why-over-consumption-is-making-us-unhappy)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_consumption_02: {
    title: 'I spent money on services instead of goods today',
    description:
      'Log on days you spend money on theatre, movies, news, restaurants, treatments, product rentals or any other services that you would otherwise spend on products.',
    co2_explanation: `MARKDOWN:
## The facts
- About half of the textiles used as clothing are made of synthetic fibers, with crude oil being the main raw material. These fabrics are non-renewable and harm marine life with microplastic emissions. Production of natural clothing fibers such as cotton and wool occupies large areas of land, and involves using pesticides and antibiotics. If you choose services over products, you contribute to biodiversity, animal welfare and accessibility to clean water for more people. You also reduce emissions significantly.

## The assumptions
- The calculations assume that you're a part of an average household with an average spending profile gathered from national consumer surveys.
- This activity assumes that all of the money is spent in one way or another, but there are big differences in emissions depending on what you spend your money on.
- It's assumed that by buying services rather than products, you spend 20% less on products than the average. You therefore have 20% lower environmental impact.
- Calculations are assuming that this activity happens every third day. Registering this activity every day means that your actual savings are higher, and vice versa.

## Additional info
- [https://superegoworld.com/blogs/the-world/the-dangers-of-synthetic-fibers-and-fabrics-on-the-environment](https://superegoworld.com/blogs/the-world/the-dangers-of-synthetic-fibers-and-fabrics-on-the-environment)`,
    health_explanation: `MARKDOWN:
## The facts
- Services can often be done with others, and having meaningful experiences with people you care about will contribute significantly to your happiness.
- Redirecting the purpose of our consumption habits and devoting more time and effort on activities that you find meaningful can lead to a worthwhile life and make you happier.
`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_consumption_03: {
    title: 'I donated money to a good cause today',
    description:
      'MARKDOWN:Log on days you donate at least £2 to **any** good cause. If you donate monthly (£20, for example) you could log this activity on 10 of the days.',
    co2_explanation: `MARKDOWN:
## The facts
- Spending money without increasing carbon emissions is just about impossible, unless you deliberately invest in something that reduces emissions. Donations is a great way of supporting a cause you care about, whether that is poverty, inequality, nature, or social initiatives. Simultaneously, it reduces the amount of money you can spend on personal goods and services. Giving money away is a quick and easy way of reducing your footprint.

## The assumptions
- The CO₂e savings is calculated by finding emissions from what an average person in your country would spend this amount on money on. By donating the money you save all these emissions as they are no longer part of your footprint, but contribute to do good another place.
`,
    health_explanation: `MARKDOWN:
## The facts
- Donations can increase your happiness, well-being, and satisfaction in life because of your social responsibility towards others.
`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_consumption_04: {
    title: 'I recycled as much waste as possible today',
    description:
      'Log on days you sort and recycle all your waste that is feasible to recycle.',
    co2_explanation: `MARKDOWN:
## The facts
- Recycling is one of the most effective ways to reduce our need for raw materials, consume less energy and natural resources compared to using brand new raw material.  
- Recycling decreases harmful chemicals and greenhouse gases released from landfill sites.

## The assumptions
- By doing this activity it's assumed that your recycling rate is increased to 80%.
`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_consumption_05: {
    title:
      'Every time there is no other option than buying a new product I will buy the highest quality available',
    description:
      'Registered everyday to represent your habit of purchasing something with the highest quality possible instead of something unlikely to last.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_consumption_06: {
    title:
      'I checked whether a brand or supplier was sustainable before purchasing today',
    description:
      'Log on days you research the environmental profile of a product or a product manufacturer today before making a purchase.',
    co2_explanation: `MARKDOWN:
## The facts
- Eco-labelling can help businesses produce safer products of better quality. It can help you as a consumer understand the supply chain and support companies that help protect the environment.
- By asking for more sustainable products and showing a willingness to pay a little extra for sustainable products, you demonstrate to both producers and politicians that sustainability is important to you and can help change entire industries.

## The assumptions
- If you choose eco-labelled products as often as possible, calculations assume that you spend 10% more of your money on energy, food and transport, and therefore less on consumption of products and services. In addition, it's assumed that you have 10% less environmental impact per pound/euro spent on products and services.
- Calculations are assuming that this activity happens every third day. Registering this activity every day means that your actual savings are higher, and vice versa.
`,
    health_explanation: `MARKDOWN:
## The facts
- Supporting green initiatives, products and services can help you feel better about the environment because you know that you're supporting a societal change towards sustainability.
`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_consumption_07: {
    title: 'I purchased a second-hand item today rather than buying a new one',
    description:
      'Registered every day you chose a second-hand item rather than buying a new one.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_consumption_08: {
    title: 'I looked for microplastics on a clothing label today',
    description:
      'Log each day you checked a clothing label on a garment and learned about what the fabrics are made of',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- Ordinary wear and tear of clothing leads to microplastics emissions which turns to dust.

## Additional info
- [https://www.ciel.org/wp-content/uploads/2019/02/Plastic-and-Health-The-Hidden-Costs-of-a-Plastic-Planet-February-2019.pdf](https://www.ciel.org/wp-content/uploads/2019/02/Plastic-and-Health-The-Hidden-Costs-of-a-Plastic-Planet-February-2019.pdf)`,

    biodiversity_explanation: `MARKDOWN:
## The facts
- All synthetic fabrics are fibrous plastic that release microplastics when used and washed. More than 60% of textiles are made from synthetic materials, and synthetics are the largest growing type of textile today. The best thing you can do is to reduce the use of synthetic fibers, but it also helps to be aware of what our clothes are made of, and treat them properly to reduce microplastics emissions. A good habit is to check the clothing label and get to know what your clothes are made of.`,
    money_explanation: `MARKDOWN:
## The facts
- By treating your clothes properly, you also take better care of your clothes so that your favorite clothes last longer. That saves your wallet too!

## Tip to clothes without plastic
- [https://www.framtiden.no/gronne-tips/klar/velg-klar-uten-plast.html](https://www.framtiden.no/gronne-tips/klar/velg-klar-uten-plast.html)`,
  },
  activity_consumption_09: {
    title: 'I did not buy any new fabrics today',
    description: 'Log each day you have not purchased any new fabrics',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- In 2018, the consumption of new clothes in Norway corresponded to a total of 1,700,000 tonnes of CO\u2082! 
- All clothes have an environmental and climate footprint. Although natural fibers are a better option for reducing microplastics emissions, they may have a higher climate footprint in production. Thus, it is best to reduce consumption and take better care of what you have.

## Additional info
- [https://www.framtiden.no/gronne-tips/klar/slik-blir-klarne-dine-mer-miljovennlige.html](https://www.framtiden.no/gronne-tips/klar/slik-blir-klarne-dine-mer-miljovennlige.html)

## Tip to take care of your clothes
- [https://www.framtiden.no/gronne-tips/klar/slik-blir-klarne-dine-mer-miljovennlige.html](https://www.framtiden.no/gronne-tips/klar/slik-blir-klarne-dine-mer-miljovennlige.html)`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- All synthetic fabrics are fibrous plastic that release microplastics when used and washed. More than 60% of textiles are made from synthetic materials, and synthetics are the largest growing type of textile today. The best thing you can do is to reduce the use of synthetic fibers, but it also helps to be aware of what our clothes are made of, and treat them properly to reduce microplastics emissions. A good habit is to check the clothing label and get to know what your clothes are made of.

## Additional info
- [https://www.hioa.no/eng/About-HiOA/Centre-for-Welfare-and-Labour-Research/SIFO/Publications-from-SIFO/Microplastic-pollution-from-textiles-A-literature-review](https://www.hioa.no/eng/About-HiOA/Centre-for-Welfare-and-Labour-Research/SIFO/Publications-from-SIFO/Microplastic-pollution-from-textiles-A-literature-review)`,
  },
  activity_consumption_10: {
    title:
      'I did not use cosmetics, hair or skin care products containing microplastics today',
    description:
      'Log each day you did not use any cosmetics, hair or skin care products that contain microplastics',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- A lot of cosmetics are deliberately made with added microplastics. Manufacturers add microplastics to get a grinding effect in products, such as body lotions and scrubs. These microplastic pieces end up in the drain when you shower or wash - and eventually end up in either sewage sludge, scattered in nature or in the sea. The best thing for both you and the environment is to avoid using cosmetics with microplastics.

## Additional info
- [https://www.framtiden.no/gronne-tips/fritid/den-mest-miljovennlige-kosmetikken.html](https://www.framtiden.no/gronne-tips/fritid/den-mest-miljovennlige-kosmetikken.html)

## Tip
- Download the "Beat the Microbead" app and scan your cosmetics for microplastics!`,
    health_explanation: `MARKDOWN:
## The facts
- Microplastics act as a sponge for environmental toxins. Environmental toxins accumulate in the food chain and have a number of negative health and environmental effects. The best choice for both you and the planet is to avoid cosmetics or hygiene products containing microplastics.`,
  },
  activity_consumption_11: {
    title:
      'I looked for microplastics in the list of ingredients of cosmetics and/or a hygiene product today',
    description:
      'Log each day you checked cosmetics and/or a hygiene product for microplastics by looking at the list of ingredients ',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- Microplastics act as a sponge for environmental toxins. Microplastics attract other environmental toxins. Environmental toxins accumulate in the food chain and have a number of negative health and environmental effects. A good choice is products with the Nordic Ecolabel as they are often better alternatives.

## Additional info about microplastics in cosmetics and hygiene products
- [https://www.beatthemicrobead.org](https://www.beatthemicrobead.org)`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- A lot of cosmetics are deliberately made with added microplastics. Manufacturers add microplastics to get a grinding effect in products, such as body lotions and scrubs. These microplastic pieces end up in the drain when you shower or wash and eventually end up in either sewage sludge, scattered in nature or in the sea. The best thing for both you and the environment is to avoid using cosmetics with microplastics.The most common ingredients in cosmetics that you should avoid are: Nylon (PA), Polyethylene (PE), Polypropylene (PP), Polyethylene terephthalate (PET).

## Tip
- Download the "Beat the Microbead" app and scan your cosmetics for microplastics!`,
  },
  activity_consumption_12: {
    title:
      'I used a reusable hygiene product, eg a metal razor or menstrual cup, instead of a plastic hygiene product today',
    description:
      'Log each day you use a reusable hygiene product instead of single use plastic products',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- 10% of Norway's population throws cotton buds into the toilet, and many of these end up in the sea and on beaches. 
- There are also many other plastic bathroom items that you can easily reduce consumption of. For example, you can use a menstrual cup instead of tampons, use olive oil instead og cotton buds made of plastic, or switch to a multi-use blade razor for shaving. A sharpening tip for keeping the blades sharp is to drag the blade over jean denim in the opposite direction from shaving.

## Additional info
- [https://www.blogg.beeorganic.no/post/pusse-tenner-med-bambus-og-plantefiber](https://www.blogg.beeorganic.no/post/pusse-tenner-med-bambus-og-plantefiber)

## Tip for plastic free shaving
- [https://www.blogg.beeorganic.no/post/alt-du-trenger-%C3%A5-vite-om-plastfri-barbering](https://www.blogg.beeorganic.no/post/alt-du-trenger-%C3%A5-vite-om-plastfri-barbering)

## Tip for a plastic free bathroom
- [https://www.blogg.beeorganic.no/post/plasticfreejuly-hvordan-erstatte-plast-p%C3%A5-badet](https://www.blogg.beeorganic.no/post/plasticfreejuly-hvordan-erstatte-plast-p%C3%A5-badet)

## Tip for plastic free period
- [https://www.blogg.beeorganic.no/post/alt-du-trenger-%C3%A5-vite-om-milj%C3%B8vennlige-t%C3%B8ybind-og-truseinnlegg](https://www.blogg.beeorganic.no/post/alt-du-trenger-%C3%A5-vite-om-milj%C3%B8vennlige-t%C3%B8ybind-og-truseinnlegg)

## Tip for plastic free make-up
- [https://www.blogg.beeorganic.no/post/reduser-avfall-p%C3%A5-badet-med-gjenbrukbare-bomullspads](https://www.blogg.beeorganic.no/post/reduser-avfall-p%C3%A5-badet-med-gjenbrukbare-bomullspads)`,
  },
  activity_consumption_13: {
    title: 'I made my own cosmetics and/or other hygiene products today',
    description:
      'Log each day you made your own cosmetics and/or hygiene product',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    handprint_explanation: `MARKDOWN:
## The facts
- When you choose to use a shampoo bar, you help reduce large amounts of waste. Just think about how many plastic bottles you have in the shower today!`,
    health_explanation: `MARKDOWN:
## The facts
- You might be applying microplastics to your face. Many cosmetics manufacturers add microplastics to products for different properties. A test conducted by Beat the Microbead showed that a single box of anti-wrinkle cream contained a full 1.48 million pieces of polyethylene! By making your own products you can reduce the disposable packaging and be sure that the product does not contain microplastics.

## Additional info
- [https://www.beatthemicrobead.org](https://www.beatthemicrobead.org)         
- [https://www.framtiden.no/gronne-tips/fritid/slik-lager-du-kosmetikk-selv.html](https://www.framtiden.no/gronne-tips/fritid/slik-lager-du-kosmetikk-selv.html)

## Tip for plastic free shampoo
- [https://www.blogg.beeorganic.no/post/shampo-i-fast-form-et-milj%C3%B8vennlig-alternativ](https://www.blogg.beeorganic.no/post/shampo-i-fast-form-et-milj%C3%B8vennlig-alternativ)`,
  },
  activity_consumption_14: {
    title: 'I reused a freezer bag for other things today',
    description:
      'Log each day you have reused a plastic freezer bag for other things, eg to cover up leftovers to reduce the need for plastic foil',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Combustion of plastic is the largest source of CO\u2082 emissions from the plastic life cycle. Plastic today is made mainly of oil and gas. When burned, CO\u2082 is released. By reusing the plastic you are already reducing plastic consumption in general.  And we also help minimize CO\u2082 emissions from burning plastics by reusing plastic products.

## Additional info
- [https://klimastiftelsen.no/publikasjoner/plast-og-klima-to-sider-av-samme-sak/](https://klimastiftelsen.no/publikasjoner/plast-og-klima-to-sider-av-samme-sak/)`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- Only about 5% of waste remains in the beach zone. Most of it sinks to the bottom of the sea. The resins on the beaches are exposed to UV rays from the sun, oxygen, weather and wind, and thus break up more quickly to microplastics. The other plastics that fall to the bottom of the sea are not exposed to oxygen and UV rays and break up much more slowly. By reducing the consumption of new plastic, you also help minimize plastic waste!

## Additional info
- [https://ourworldindata.org/plastic-pollution](https://ourworldindata.org/plastic-pollution)

## Tip for alternatives for cling film
- [https://www.blogg.beeorganic.no/post/alt-du-trenger-vite-om-bivokspapir-og-plantebasert-vokspapir-et-milj%C3%B8vennlig-alternativ-til-plast](https://www.blogg.beeorganic.no/post/alt-du-trenger-vite-om-bivokspapir-og-plantebasert-vokspapir-et-milj%C3%B8vennlig-alternativ-til-plast)`,
  },
  activity_consumption_15: {
    title:
      'I used reusable containers or other alternative containers instead of plastic containers today',
    description:
      'Log each day you use containers made of steel, metal, or glass that you already have in your home, instead of using disposable plastic packaging to store items, food, or beverages!',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Annually we consume approx. 78915 tonnes of plastic packaging in Norwegian households. By using reusable containers that you already have at home, you can help reduce CO\u2082 emissions associated with plastic production and burning.

## Additional info
- [https://www.framtiden.no/gronne-tips/fritid/slik-kan-du-redusere-engangsplasten.html](https://www.framtiden.no/gronne-tips/fritid/slik-kan-du-redusere-engangsplasten.html)`,
    health_explanation: `MARKDOWN:
## The facts
- Some types of food packaging may contain carcinogens. For example, Polystyrene (PS) contains carcinogens and is widely used in food packaging. Replacing plastic packaging with, for example, a stainless steel food box will be better for both your health and the environment.`,
  },
  activity_consumption_16: {
    title: 'I sorted and recycled all my plastic waste today',
    description: 'Log each day you sorted and recycled all your plastic waste',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Less than 10% of all plastic produced since 1950 has been recycled. Over half of all plastic production has ended up on landfills. Plastics that ends up on landfills emit CO\u2082 and methane, two strong greenhouse gases. At the same time, the plastic is broken into microplastics. Much is also taken by wind and water and spreads out into the nature and the sea.

## Additional info
- [https://www.framtiden.no/merkeguiden/elektronikk/plastpiler.html](https://www.framtiden.no/merkeguiden/elektronikk/plastpiler.html)`,
    handprint_explanation: `MARKDOWN:
## The facts
- Only plastic packaging is required in the collection scheme in Norway. This is because the packaging manufacturers have an agreement with the authorities and finance the various return schemes. They pay for the packaging to be collected and sent for recycling. The same scheme does not exist for products made of the same material (eg plastic toys, slides etc.). 
The plastic waste should be clean and dry when it is recycled, but remember to rinse in cold water. Waste collection can vary across the country. This is because it is the municipalities that decide what type of waste collection they should have. If you are not satisfied with your municipality, you have the right to say so!

## Additional info
- [https://sortere.no/](https://sortere.no/)

## Tip for how to sort waste right
- [https://sortere.no/slikgjordudet](https://sortere.no/slikgjordudet)`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- Not all bioplastics are the same, so know your type. Compostable bioplastic is the only type guaranteed to break down properly after exposure to soil micro-organisms. Other 'biodegradable' plastics may not, and in fact can be more harmful to the earth due to the high environmental cost of production. Bioplastics also need to be strictly separately recycled to avoid contaminating plastic recycling programs.

## Additional info
- [https://phys.org/news/2017-12-truth-bioplastics.html](https://phys.org/news/2017-12-truth-bioplastics.html)
- [https://www.grontpunkt.no/nyhet/hva-er-egentlig-bioplast/](https://www.grontpunkt.no/nyhet/hva-er-egentlig-bioplast/)`,
    money_explanation: `MARKDOWN:
## The facts
- Plastics being thrown have tremendous value. A 2016 World Economic Forum, Ellen Macarthur Foundation, and McKinsey & Company report showed that the value of all plastic thrown away was around $ 80-120 billion dollars a year!

## Additional info
- [https://www.ellenmacarthurfoundation.org/publications/the-new-plastics-economy-rethinking-the-future-of-plastics](https://www.ellenmacarthurfoundation.org/publications/the-new-plastics-economy-rethinking-the-future-of-plastics)`,
  },
  activity_consumption_17: {
    title: 'I used reusable cutlery while out and about today',
    description:
      "Bring along your own cutlery, so you don't have to use single-use-plastic!",
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- CO\u2082 emissions from the plastic value chain are increasing. This is largely due to a huge increase in the consumption of plastic articles and especially disposable articles. If we continue with the same increase as now, emissions from the plastic value chain could account for 20% of all CO\u2082 emissions in 2050.

## Additional info
- [https://klimastiftelsen.no/wp-content/uploads/2018/09/Plast-og-klima-web.pdf](https://klimastiftelsen.no/wp-content/uploads/2018/09/Plast-og-klima-web.pdf)`,
    health_explanation: `MARKDOWN:
## The facts
- Some types of food packaging may contain carcinogens. For example, Polystyrene (PS) contains carcinogens and is widely used in food packaging. Replacing plastic packaging with, for example, a stainless steel food box will be better for both your health and the environment.

## Additional info
- [https://www.ciel.org/wp-content/uploads/2019/02/Plastic-and-Health-The-Hidden-Costs-of-a-Plastic-Planet-February-2019.pdf](https://www.ciel.org/wp-content/uploads/2019/02/Plastic-and-Health-The-Hidden-Costs-of-a-Plastic-Planet-February-2019.pdf)`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- The largest market for plastics is packaging. Over 40% of all plastic is produced for packaging. Packaging is also the product group with the lowest lifetime, since most consists of disposable items. Unfortunately, much of the packaging ends up going astray and ends up in nature and in the sea.

## Additional info
- [https://www.ellenmacarthurfoundation.org/assets/downloads/New-Plastics-Economy_Catalysing-Action_13-1-17.pdf](https://www.ellenmacarthurfoundation.org/assets/downloads/New-Plastics-Economy_Catalysing-Action_13-1-17.pdf)`,
  },
  activity_consumption_18: {
    title:
      'I used a reusable bottle instead of buying a single-use bottle today',
    description:
      'Log each day you use a reusable bottle - for example made of steel or glass - instead of a bottle made of plastic',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Many plastic bottles are not recycled in Norway, although we have a fantastic bottle recycling system. Figures from Infinitum AS, which operates the bottle recycling system, show that more than 82 million plastic bottles are not recycled, and either end up in residual waste where they are burned, or in nature. Considering that the bottles are made from raw fossil materials, one can help a lot with using a reusable bottle instead of buying bottled water. Around 0.178 kg of CO\u2082 is saved for every single bottle that is recycled and reused as a new product, instead of discarded as waste or recycled using energy. Imagine how much CO\u2082 we could have saved if all the 82 million plastic bottles had been recycled instead of being thrown into residential waste!`,
    health_explanation: `MARKDOWN:
## The facts
- Tests have been carried out which show that plastic drinking bottles leak harmful substances such as phthalates, bisphenol A (BPA), lead and more. The best thing for both your health and the environment is to switch to drinking bottles made of glass or metal.`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- Plastic does not break down. It is more correct to say that it breaks up. When plastic is left in nature or on landfills, it slowly breaks up into smaller and smaller pieces - first to microplastics, then to nanoplastics and then becomes so small you can't see it without a microscope. But plastic does not undergo complete degradation. Since the plastic can be broken up into such small pieces, there is a lack of knowledge and research on what really happens to the plastic. The amount of time it takes for nature to break up plastic depends on the type of plastic the product is made of, what additives it has and where it is located. It can take between 1-5 years to break down a cigarette snip, 400 years to break down a plastic drink container and 50 years to break down an isopor cup.

## Additional info
- [https://miljostatus.miljodirektoratet.no/tema/avfall/avfallstyper/mikroplast/](https://miljostatus.miljodirektoratet.no/tema/avfall/avfallstyper/mikroplast/)`,
  },
  activity_consumption_19: {
    title:
      'I used my own shopping bag instead of buying a new plastic bag today',
    description:
      'Log each day you used a shopping net instead of buying a new plastic bag when shopping',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- It requires approx. 2 kg of oil to produce 1 kg of plastic. Plus the energy needed for production, transport and combustion. We know that most plastic bags in Norway are burnt, and it is this plastic burning that emits the most CO\u2082 throughout the value chain. The more times you use a plastic bag before it becomes waste the better. It is even better if you use something you already have, such as a shopping net. But keep in mind that it doesn't help if you buy a new shopping net every time you shop, as they also require a lot of resources to produce.

## Tip
- Plastic bag or paper bag? [https://www.framtiden.no/gronne-tips/spor-oss/er-papirposer-verre-for-miljoet-enn-plastposer.html](https://www.framtiden.no/gronne-tips/spor-oss/er-papirposer-verre-for-miljoet-enn-plastposer.html)`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- We use over 5 trillion plastic bags annually worldwide. That equates to more than 160,000 bags per minute. In Norway, the vast majority of plastic bags are burned, but in many other countries, plastic bags are by far a major environmental problem, and can harm both nature and animals. Plastic bags that end up in the sea can be misinterpreted as a jellyfish and thus be eaten by larger fish and marine animals. With catastrophic results!`,
    money_explanation: `MARKDOWN:
## The facts
- We consume more than 800 million plastic carrier bags annually, only in Norway? Since you have to pay for the shopping bag, you save a few bucks by bringing your own shopping bags to the store instead.`,
    handprint_explanation: `MARKDOWN:
## The facts
- As a consumer you have a lot of influence. Everytime you say "no thanks" to a new plastic bag, you affect the people around you!`,
  },
  activity_consumption_20: {
    title: 'I said "no thanks" to unnecessary plastic today',
    description:
      'Log each day you say no thanks when you are offered plastic products you can do without',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- It is the richest countries that generate the most plastic waste per person. However, due to poorer waste management, low-income countries still stand as the worst plastic polluters. The plastic that goes astray often finds its way to waterways and by wind to rivers that in turn flow into the sea.       
- Some of the most common bits of debris to find on beaches are unidentifiable pieces of plastic. These can be pieces of plastic from all sorts of strange things that have been broken up and destroyed by the weather. Saying no to unnecessary disposable plastic is a big step in the right direction to reduce this problem.

## Additional info
- [https://europa.eu/rapid/press-release_IP-18-3927_en.htm](https://europa.eu/rapid/press-release_IP-18-3927_en.htm)`,
    handprint_explanation: `MARKDOWN:
## The facts
- Plastic products are not manufactured unless someone requests it. Therefore, you can make a big impact by saying no thanks to plastic products that are completely unnecessary.`,
  },
  activity_consumption_21: {
    title: 'I had a plastic packaging free meal today',
    description:
      'Log each day you make a dinner or another meal completely without plastic packaging',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- On average, a European citizen throws away more than 30 kg of plastic packaging each year. Most of this is disposable packaging where little is recycled and much is burned or ends up on landfills, thereby contributing either to pollution or to CO\u2082 emissions.`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- Multipacks can lead to increased food waste. Large packaging means that many people buy more than they need and food gets thrown away. At the same time, it is wrapped in plastic and contributes to increased packaging usage. Some items are also double-packed with unnecessary use of extra plastic. Try to avoid unnecessary plastic use where you can.

## Additional info
- [https://www.foeeurope.org/unwrapped-throwaway-plastic-food-waste](https://www.foeeurope.org/unwrapped-throwaway-plastic-food-waste)

## Tip on how to reduce food waste
- [https://www.framtiden.no/gronne-tips/mat/matvettreglene.html](https://www.framtiden.no/gronne-tips/mat/matvettreglene.html)`,
  },
  activity_consumption_22: {
    title: 'I chose food products without plastic packaging today',
    description:
      'Log each day you buy bulk goods where plastic packaging is unnecessary, and rather use your own recyclable containers in, for example, glass or steel or fruit and vegetable nettings',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Food waste accounts for 8% of all man-made greenhouse gas emissions. If food waste was a country, only China and the United States would have had higher emissions.

## Tip for a plastic free shopping
- [https://www.beeco.no/2017/03/tips-til-handle-mat-med-minst-mulig.html](https://www.beeco.no/2017/03/tips-til-handle-mat-med-minst-mulig.html)`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- Plastic packaging around some types of food can reduce food waste and maintain the quality of the product. Food waste of grapes, for example, have fallen by 75% after being packed in plastic packages, according to Bama. At the same time, a report by Friends of The Earth Europe shows that food waste has increased in line with plastic packaging usage. In other words, there is a lot of unnecessary plastic around some foods. Fortunately, many products are sold in bulk. Therefore, in some cases it may be good for the climate if you choose some particularly vulnerable foods packed in packaging, if you ensure that it is properly sorted and recycled. But as a general rule, bring a shopping net and drop small plastic bags and plastic packaging when you are at the store.

## Additional info
- [https://nofima.no/wp-content/uploads/2016/10/BAMAs-milj%C3%B8m%C3%A5l-nr-1-Redusert-matsvinn-Sylvia-Lofthus.pdf](https://nofima.no/wp-content/uploads/2016/10/BAMAs-milj%C3%B8m%C3%A5l-nr-1-Redusert-matsvinn-Sylvia-Lofthus.pdf)`,
    handprint_explanation: `MARKDOWN:
## The facts
- You can help create new trends! It may feel a bit strange to bring your own glass jar or fruit net at the store, but the more people do this the more normalized it becomes!`,
  },
  activity_consumption_23: {
    title:
      'I repaired something plastic I had rather than buying a new product',
    description:
      'Log each day you repaired a product of / with plastic instead of buying a new product',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Our private consumption accounts for more than 70% of man-made greenhouse gas emissions globally. Stuff account for 13% of emissions. In addition, very much of what we have in our homes is made of or with plastic. By repairing what you already have, you can help reduce plastic waste and greenhouse gas emissions!

## Additional info
- [https://www.framtiden.no/gronne-tips/fritid/21-klimafotavtrykk-av-forbruket-vart.html](https://www.framtiden.no/gronne-tips/fritid/21-klimafotavtrykk-av-forbruket-vart.html)`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- Plastic is one of the most common materials found in mobile phones. By repairing the phone you already have, you are contributing to reduced plastic waste, reduced plastic consumption and greenhouse gas emissions associated with production, transport and waste management. Other consumer goods that contain plastic include laptops, clothes, upholstery and other textiles, toys, garden furniture and much more.`,
  },
  activity_consumption_24: {
    title: 'I got something plastic used instead of buying something new today',
    description:
      'Log each day you have rented, bought used, exchanged or inherited one or more products made of / with plastic instead of buying a new product',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- Plastic lasts almost forever. The only way to permanently destroy or remove plastic from the soil is through destructive thermal treatment such as combustion or pyrolysis. None of the most common types of plastics are biodegradable and thus accumulate on landfills or in nature. Therefore, it is best to avoid producing more plastic and to get the plastic away from linear economy and into the circular  economy where we take care of what we already have and buy used where we can.

## Additional info
- [https://www.framtiden.no/aktuelle-rapporter/838-et-hav-av-plast/file.html](https://www.framtiden.no/aktuelle-rapporter/838-et-hav-av-plast/file.html)`,
    co2_explanation: `MARKDOWN:
## The facts
- It requires approx. 2 kg of oil to produce 1 kg of plastic. Plus the energy needed for production, transport and combustion. 
This means that every time you buy something used instead of new, you help prevent new CO\u2082 emissions!`,
  },
  activity_consumption_25: {
    title: 'I used a reusable cup instead of buying a single-use cup today',
    description:
      'Log each day you use a reusable cup, for example a thermal cup made of steel instead of buying/using a disposable cup',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    biodiversity_explanation: `MARKDOWN:
## The facts 
- A number of single use plastics will be banned in EU from 2021? Prepare for the change by making it a habit of bringing your own reusable cup instead of buying a single use cup!

## Additional info about the SUP directive
- [https://rethinkplasticalliance.eu/wp-content/uploads/2019/05/ZWE_Unfolding-the-SUP-directive.pdf](https://rethinkplasticalliance.eu/wp-content/uploads/2019/05/ZWE_Unfolding-the-SUP-directive.pdf)`,
  },
  activity_consumption_26: {
    title: 'I used a reusable bag when I went shopping today',
    description:
      'Log on the days you took with you a reusable bag while going shopping instead of buying a plastic bag',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- The environmental concerns associated with plastic carrier bags include the use of non-renewable resources for production (such as crude oil), the impacts of their disposal, and the effects of littering.
- There are two main types of shopping bags - single and multi-use bags. Research has shown that multi-use bags are environmentally superior to single-use carrier bags only if they are used multiple times. According to a study by the Danish EPA, multi-use polypropene bags should be reused at least 52 times to gain the environmental benefits of switching from single-use bags. This highlights the importance of reusing multi-use bags.
## The assumption
- We estimate the average weight of plastic carrier bags used by an individual in a year for a respective country and combine this number with the CO\u2082e emission factor for LDPE bags to calculate the savings from this activity`,
  },
  activity_consumption_27: {
    title: 'I did not use disposable containers such as plastic bottles today',
    description:
      'Log on the days you refrained from buying plastic water bottles',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Production of plastic bottles is resource intensive and has major environmental impacts. The production process requires up to 17 million barrels of oil each year just to meet America's annual demand for bottled water. Furthermore, the manufacturing of a water bottle requires three times the amount of water to fill it.
- Around 8 million tons of plastic enter the oceans yearly, killing more than 1.1 million seabirds and animals annually. Discarded bottles and their caps make up the third and fourth most recovered plastic trash 
- The average person in the UK uses 150 plastic water bottles per year. The recycling rate for plastic bottles in the UK is around 57%, meaning almost half the bottles are either littered, landfilled, or incinerated. According to the waste hierarchy, which ranks waste disposal methods according to their impact on the environment, incineration is the second worst waste management process and landfill is the worst. So reducing the use of plastic bottles is the best thing for sustainability!

## The assumptions
- We estimate the average weight of plastic bottles used by an individual in a year for a respective country and combine this number with the CO\u2082e emission factor for PET bottles to calculate the savings from this activity`,
  },
  activity_consumption_28: {
    title: 'I did not print unnecessary paper today',
    description: 'Log on the days you actively tried to reduce your paper use',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Worldwide, 4 billion trees are cut down every year for paper. That's one percent of the Amazon Rainforest - every single year.
- It takes around 10 litres of water to make just one sheet of A4 paper.

## The assumptions
- We estimate the average weight of paper used by an individual in a year for a respective country and combine this number with the CO\u2082e emission factor for non-coated paper to calculate the savings from this activity`,
  },
  activity_consumption_29: {
    title: 'I chose not to buy new clothes today and instead bought secondhand',
    description:
      'Log on days you spend around £10 on used clothing, instead of buying new clothes.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- When you buy used clothing, you displace the production of a new item while updating your wardrobe!

## The assumptions
- We assume that your used clothing purchase doesn't cause any new emissions, and displaces the need to buy new clothing for a similar amount of money.
- This means your emissions savings are calculated based on each pound you spend on used clothing.`,
  },
  activity_consumption_30: {
    title: 'I made a large used clothing purchase today, instead of buying new',
    description:
      'Log on days you spend around £50 on used clothing, instead of buying new clothes.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- When you buy used clothing, you displace the production of a new item while updating your wardrobe!

## The assumptions
- We assume that your used clothing purchase doesn't cause any new emissions, and displaces the need to buy new clothing for a similar amount of money.
- This means your emissions savings are calculated based on each pound you spend on used clothing.`,
  },
  activity_consumption_31: {
    title:
      'I chose not to buy new electronics today and instead bought secondhand',
    description:
      'Log on days you spend around £50 on used electronic items, instead of buying new electronics.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- The average EU resident generates nearly [17 kg of electronic waste every year](https://globalewaste.org/wp-content/uploads/2018/10/Global-E-waste-Monitor-2017.pdf) (that's 20 toasters).
- That means that even though Europe makes up less than 10% of the population of the world, we are responsible for close to 30% of the total electronic waste.

## The assumptions
- We assume that your electronics purchase displaces the need to buy new electronics worth a similar amount.
- The CO\u2082e is calculated based on the average intensity in gCO\u2082e/GBP of electronics sold, using input-output modelling.

## Additional info
- [Learn more about the CO\u2082 footprint of consumer electronics](https://eeb.org/wp-content/uploads/2019/09/Coolproducts-report.pdf).`,
  },
  activity_consumption_32: {
    title:
      'I made a large used electronics purchase today, instead of buying new',
    description:
      'Log on days you spend around £100 on used electronic items, instead of buying new electronics.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- The average EU resident generates nearly [17 kg of electronic waste every year](https://globalewaste.org/wp-content/uploads/2018/10/Global-E-waste-Monitor-2017.pdf) (that's 20 toasters).
- That means that even though Europe makes up less than 10% of the population of the world, we are responsible for close to 30% of the total electronic waste.

## The assumptions
- We assume that your electronics purchase displaces the need to buy new electronics worth a similar amount.
- The CO\u2082e is calculated based on the average intensity in gCO\u2082e/GBP of electronics sold, using input-output modelling.

## Additional info
- [Learn more about the CO\u2082 footprint of consumer electronics](https://mk0eeborgicuypctuf7e.kinstacdn.com/wp-content/uploads/2019/09/Coolproducts-report.pdf).`,
  },
  activity_consumption_33: {
    title: 'I chose not to buy new today and instead bought secondhand',
    description:
      'Log on days you buy a used item (other than clothing or electronics) that costs around £10, instead of buying something new.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Everything you buy takes materials and energy to produce, and therefore has a footprint.
- When you buy used, you help create a circular economy by extending the lifetime of other products instead of consuming new materials and energy.

## The assumptions
- We assume that your purchase displaces the need for the production of a new item worth a similar amount.
- The footprint savings is calculated based on the average amount of CO\u2082e produced per pound spent on reusable goods.`,
  },
  activity_consumption_34: {
    title: 'I made a large used purchase today, instead of buying new',
    description:
      'Log on days you buy a used item other than clothing or electronics that costs around £100, instead of buying something new.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Everything you buy takes materials and energy to produce, and therefore has a footprint.
- When you buy used, you help create a circular economy by extending the lifetime of other products instead of consuming new materials and energy.

## The assumptions
- We assume that your purchase displaces the need for the production of a new item worth a similar amount.
- The footprint savings is calculated based on the average amount of CO\u2082e produced per pound spent on reusable goods.`,
  },
  activity_consumption_35: {
    title: "I didn't buy any goods today",
    description:
      'Log on days you did not spend money buying non essential items such as furniture, decorative items, clothes and personal purchases.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts 
- Any monetary purchase has a footprint associated with it as the bought good/service requires resources to produce. By not buying anything, you are essentially lowering your consumption and with it your footprint
- This has been a tricky activity to create because part of being green is about how we spend the money we have. Money is rarely saved indefinitely. So even if we don’t buy anything today, it doesn’t mean that that money won't be spent tomorrow - on something worse for the environment. This is known as the [rebound effect](https://www.futurelearn.com/info/courses/sustainability-society-and-you/0/steps/4633)

## The assumptions
- We assume that the money saved by a person when not buying any goods is not used for buying anything else (we assume that your consumption is lower than the average person)
- We calculate the daily footprint associated with the purchase of goods, and this is estimated to be the savings from this activity
`,
  },
};

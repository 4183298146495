import { set } from 'cerebral/factories';
import { state, props } from 'cerebral';

import * as actions from './actions';

export const render = [
  set(state`admin.championships.isLoading`, true),
  set(state`admin.championships.edit.isEdit`, true),
  set(state`admin.championships.edit.championshipKey`, props`championshipKey`),
  set(state`admin.pageSection`, 'publish'),
  actions.getChampionship,
  set(state`admin.championships.edit.championship`, props`championship`),
  actions.getCampaignKeys,
  set(state`admin.championships.edit.campaignKeys`, props`campaignKeys`),
  actions.getCampaigns,
  set(state`admin.championships.edit.campaigns`, props`campaigns`),
  actions.getSeries,
  set(state`admin.championships.edit.series`, props`series`),
  actions.getOrganisations,
  set(state`admin.championships.edit.organisations`, props`organisations`),
  actions.setSections,
  actions.filterOptions,
  actions.getEngagement,
  set(state`admin.championships.edit.engagement`, props`engagement`),
  set(state`admin.championships.isLoading`, false),
];

export const dropdownSelected = [
  actions.selectDropdownItem,
  actions.filterOptions,
];

export const setComparisonOption = [
  set(state`admin.championships.edit.${props`type`}`, props`value`),
  actions.filterOptions,
];

import { Typ33 } from '../Typ';
import Ico20 from '../Ico20';
import styled from 'styled-components';

type ButtonProps = {
  $themeName?: 'light' | 'dark';
};

export const Button = styled.button<ButtonProps>`
  height: 36px;
  min-width: 80px;
  padding: 0 16px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  &:disabled span,
  &:disabled i {
    cursor: auto;
    color: ${props =>
      props.$themeName === 'dark'
        ? 'rgba(255, 255, 255, 0.4)'
        : 'rgba(96 ,125 ,139 , 0.4)'};
  }
`;

type LinkProps = {
  $disabled?: boolean;
};

export const Link = styled.a<LinkProps>`
  height: 36px;
  min-width: 80px;
  padding: 0 16px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  ${props => props.$disabled && 'pointer-events: none; cursor: default;'};
  span,
  i {
    ${props => props.$disabled && 'opacity: 0.4;'};
  }
`;

type IconProps = {
  $themeName?: 'light' | 'dark';
};

export const Icon = styled(Ico20)<IconProps>`
  color: ${props => (props.$themeName === 'dark' ? '#FFF' : '#007079')};
`;

type TextProps = {
  $themeName?: 'light' | 'dark';
};

export const Text = styled(Typ33)<TextProps>`
  color: ${props => (props.$themeName === 'dark' ? '#FFF' : '#007079')};
  padding-left: 8px;
`;

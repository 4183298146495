import React from 'react';
import { Wrapper } from './elements';

function NotAuthorized() {
  return (
    <Wrapper>
      {'You are not authorized to view this page. To visit ducky, go to '}
      <a href="https://www.ducky.eco">{'ducky.eco'}</a>
    </Wrapper>
  );
}

export default NotAuthorized;

import { Compute, state } from 'cerebral';
import moment from 'moment-timezone';

const regionObj = {};
const timezoneObj = {};
const regions = [
  ...new Set(
    moment.tz
      .names()
      .filter(name => name.indexOf('/') !== -1 && name.indexOf('Etc') === -1)
      .map(name => name.split('/')[0])
  ),
];

regions.forEach(region => {
  if (region !== 'Antarctica' && region !== 'Arctic') {
    regionObj[region] = region;
  }
});

export default Compute(get => {
  const selectedRegion = get(state`admin.campaigns.create.info.selectedRegion`);

  if (selectedRegion) {
    const timezones = moment.tz
      .names()
      .filter(name => name.indexOf(selectedRegion) !== -1);

    return {
      timezoneObj: Object.assign({}, ...timezones.map(tz => ({ [tz]: tz }))),
      regionObj,
    };
  }

  return { timezoneObj, regionObj };
});

import styled from 'styled-components';
import CheLab01 from 'dc/CheLab01';
import Lic003 from 'dc/LIC003';

export const Wrapper = styled.div``;

export const CheLab = styled(CheLab01)``;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 11px 0;

  div:first-child {
    align-items: center;
  }
`;

export const Lic = styled(Lic003)``;

export const ModalButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
`;

import * as sequences from './sequences';

export default {
  state: {
    form: {
      access: {
        value: 'link',
        defaultValue: 'link',
        validationRules: ['isValue'],
      },
      emails: [
        {
          value: '',
          defaultValue: '',
          validationRules: ['isValue'],
        },
      ],
    },
  },
  sequences: {
    accessSettingSelected: sequences.setAccessSetting,
    emailDomainChanged: sequences.setEmailDomain,
    addNewEmailDomain: sequences.addNewEmailDomain,
    removeEmailDomain: sequences.removeEmailDomain,
  },
};

export default {
  footprint: {
    title: "%{name}'s annual footprint is",
    description: '%{name} has an annual footprint of %{co2} kgCO\u2082e ',
    levels: {
      1: 'better than average',
      0: 'average',
      '-1': 'worse than average',
    },
  },
};

import * as sequences from './sequences';

export default {
  state: {
    organisations: {},
    filteredOrganisations: {},
    showOrgDropdown: false,
    form: {
      organisation: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      type: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
    },
  },
  sequences: {
    organisationFieldChanged: sequences.organisationFieldChanged,
    organisationSelected: sequences.organisationSelected,
    toggleShowDropdown: sequences.toggleShowDropdown,
    showDropdown: sequences.showDropdown,
    typeSelected: sequences.setType,
  },
};

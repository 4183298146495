import React from 'react';
import MediumSpacer from 'dc/Spa08';
import LargeSpacer from 'dc/Spa16';

import {
  PopoverContainer,
  TopWrapper,
  Avatar,
  Username,
  ActiveText,
  MiddleWrapper,
  BottomWrapper,
  EditProfile,
  Logout,
} from './elements';

type ProMen2Props = {
  readonly show: boolean;
  readonly avatarURL: string;
  readonly isLoading: boolean;
  readonly avatarClicked?: () => void;
  readonly editClicked: () => void;
  readonly logoutClicked: () => void;
  readonly username: string;
  readonly activeSince: string;
  readonly active_text: string;
  readonly edit_profile_text: string;
  readonly log_out_text: string;
};

export default function ProMen2({
  show,
  avatarURL,
  isLoading,
  avatarClicked,
  username,
  active_text,
  activeSince,
  editClicked,
  edit_profile_text,
  logoutClicked,
  log_out_text,
}: ProMen2Props) {
  return (
    <PopoverContainer $show={show}>
      <TopWrapper>
        <Avatar
          image={avatarURL}
          isLoading={isLoading}
          onClick={avatarClicked}
        />
        <LargeSpacer />
        <Username>{username}</Username>
        <MediumSpacer />
        <ActiveText>{`${active_text} ${activeSince}`}</ActiveText>
        <MediumSpacer />
      </TopWrapper>
      <MiddleWrapper>
        <EditProfile
          onClick={editClicked}
          caption={edit_profile_text}
          color={'#607d8b'}
          icon={'icon-mode_edit'}
        />
      </MiddleWrapper>
      <BottomWrapper>
        <Logout
          onClick={logoutClicked}
          caption={log_out_text}
          color={'#607d8b'}
          icon={'icon-power_settings_new'}
        />
      </BottomWrapper>
    </PopoverContainer>
  );
}

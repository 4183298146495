import React from 'react';
import Upload01 from 'dc/Upload01';

export default class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.image = null;
    this.file = null;
    this.url = null;

    this.onLoaded = this.onLoaded.bind(this);
  }

  onLoaded() {
    this.props.onAdded({
      file: this.file,
      width: this.image.width,
      height: this.image.height,
      url: this.url,
    });

    this.image.removeEventListener('load', this.onLoaded);
  }

  handleOnFileSelected(event) {
    if (event.target.files[0]) {
      const image = new Image();

      this.file = event.target.files[0];

      const url = window.URL.createObjectURL(this.file);

      this.image = image;
      this.url = url;

      image.addEventListener('load', this.onLoaded);

      image.src = url;
    }
  }

  componentWillUnmount() {
    if (this.image) {
      this.image.removeEventListener('load', this.onLoaded);
    }
    if (this.file) {
      window.URL.revokeObjectURL(this.file);
    }
  }

  shouldComponentUpdate(prevProps) {
    return this.props.image !== prevProps.image;
  }

  render() {
    return (
      <Upload01
        height={this.props.height}
        width={this.props.width}
        image={this.props.image}
        onAddClick={event => this.handleOnFileSelected(event)}
        onClick={this.props.onClick}
        accept={'image/jpeg, image/png'}
      />
    );
  }
}

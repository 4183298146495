import React from 'react';
import * as PropTypes from 'prop-types';
import { Wrapper, Co2Label } from './elements';

function Lab021(props) {
  return (
    <Wrapper>
      <Co2Label icon="icon-leaf" bold>
        {props.co2}
      </Co2Label>
    </Wrapper>
  );
}

Lab021.propTypes = {
  co2: PropTypes.node,
};
export default Lab021;

import React, { useState } from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import dayjs from 'dayjs';

import { state, sequences } from 'app/pwa/app.cerebral';
import { useText } from 'common/hooks';

import {
  Header,
  DuckyIcon,
  PrivacyTitle,
  PrivacyHeader,
  PrimaryButton,
  Updated,
  SecondaryButton,
  ButtonWrapper,
} from '../elements';
import {
  InnerWrapper,
  UpdatedText,
  TermsIcon,
  TermsLink,
  TermsText,
  TermsTextWrapper,
  TermsWrapper,
} from './elements';

const deps = {
  user: state.appContainer.user,
  profile: state.appContainer.profiles[state.appContainer.user.uid],
  termsUpdatedDatetime: state.appContainer.termsUpdatedDatetime,
  acceptButtonClicked: sequences`appContainer.termsAcceptedClicked`,
  openTermsConfirmed: sequences`appContainer.openTermsConfirmed`,
  declineButtonClicked: sequences`appContainer.termsDeclinedClicked`,
};

export function TermsComponent({
  user,
  profile,
  termsUpdatedDatetime,
  acceptButtonClicked,
  openTermsConfirmed,
  declineButtonClicked,
}: typeof deps & ConnectedProps): React.ReactElement {
  const [isChecked, setIsChecked] = useState(false);
  const t = useText();

  const isShowingUpdatedTerms =
    profile &&
    'termsAccepted' in profile &&
    profile.termsAccepted < termsUpdatedDatetime;

  return (
    <>
      <Header>
        <DuckyIcon icon={'icon-duckylogo'} />
      </Header>
      {isShowingUpdatedTerms && (
        <UpdatedText>{t('appContainer.ModTerms.terms_updated')}</UpdatedText>
      )}
      <InnerWrapper>
        <PrivacyTitle>{t('appContainer.ModTerms.privacy_policy')}</PrivacyTitle>
        <Updated>
          {t('appContainer.ModTerms.last_updated', {
            date: dayjs(termsUpdatedDatetime).format('LL'),
          })}
        </Updated>
        <PrivacyHeader>{t('privacy.welcome')}</PrivacyHeader>
        {t('privacy.terms')}
      </InnerWrapper>
      {isShowingUpdatedTerms && (
        <TermsWrapper>
          <TermsIcon
            icon={isChecked ? 'icon-check_box' : 'icon-check_box_outline_blank'}
            onClick={() => setIsChecked(value => !value)}
          />
          <TermsTextWrapper>
            <TermsText>{t('appContainer.ModRegister.i_have_read')}</TermsText>
            <TermsLink>{t('appContainer.ModTerms.terms_of_use')}</TermsLink>
          </TermsTextWrapper>
        </TermsWrapper>
      )}
      <ButtonWrapper>
        <PrimaryButton
          id={'terms-accept-btn'}
          disabled={isShowingUpdatedTerms && !isChecked}
          onClick={() => acceptButtonClicked()}
        >
          {t('appContainer.ModTerms.accept')}
        </PrimaryButton>
        <SecondaryButton
          onClick={() => {
            // If the user exist, ask if they want to delete their account
            if (user) return openTermsConfirmed();

            // Do not open the "confirm delete" modal if the user is registering
            return declineButtonClicked();
          }}
        >
          {t('appContainer.ModTerms.decline')}
        </SecondaryButton>
      </ButtonWrapper>
    </>
  );
}

export default connect(deps, TermsComponent);

import styled from 'styled-components';

export const ButtonComponent = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
`;

export const AnchorComponent = styled.a`
  display: block;
  text-decoration: none;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
`;

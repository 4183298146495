export default {
  choose_organiser: 'Choose organiser',
  organised_by: 'This campaign is organised by:',
  select_org: 'Select organisation',
  between_individuals: 'Between individuals (campaign)',
  between_organisations: 'Between organisations (championship)',
  a_campaign:
    'The simplest campaign type is between individuals within the same organisation',
  a_championship:
    'A championship allows other organizations to join. This is great to initiate large scale competition within a city, office building or between schools. When a new organization joins they will enter some info, and set up their own teams.',
  participating_orgs: 'Participating organisations',
  take_part: 'What organisations should take part in this championship?',
  add_another: 'Add another organisation',
};

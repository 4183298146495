import React from 'react';
import { connect } from '@cerebral/react';
import { sequences, state } from 'cerebral';
import { Wrapper, Title, Description, PageDescription } from './elements';
import { ChampionshipLabel } from 'admin/onboarding/App/Campaigns/CreateCampaign/elements';
import Spa16 from 'dc/Spa16';
import Spa32 from 'dc/Spa32';
import Spa40 from 'dc/Spa40';
import Spa96 from 'dc/Spa96';
import { useText } from 'common/hooks';

import CheLab01 from 'dc/CheLab01';

export default connect(
  {
    widgets: state`admin.campaigns.create.integration.form.widgets`,
    topic: state`admin.campaigns.create.focus.form.topic.value`,
    isJoiningChampionship: state`admin.campaigns.create.isJoiningChampionship`,
    challengeAdded: state`admin.campaigns.create.challenge.added`,
    optionClicked: sequences`admin.campaigns.create.integration.optionClicked`,
  },
  function Widgets({
    widgets,
    topic,
    isJoiningChampionship,
    challengeAdded,
    optionClicked,
  }) {
    const t = useText();

    return (
      <Wrapper>
        {isJoiningChampionship ? (
          <ChampionshipLabel icon={'icon-visibility'} color={'#03739A'}>
            {t('championship.view_only')}
          </ChampionshipLabel>
        ) : null}
        <Spa32 />
        <Title>{t('admin.integration.widgets.title')}</Title>
        <Spa32 />
        <Description>{t('admin.integration.widgets.description')}</Description>
        <Spa40 />
        {Object.keys(widgets)
          .filter(widget => widget.includes('challenge') && challengeAdded)
          .map(widget => (
            <React.Fragment key={widget}>
              <CheLab01
                disabled={isJoiningChampionship}
                checked={widgets[widget].value}
                onClick={event => {
                  isJoiningChampionship
                    ? null
                    : [
                        event.stopPropagation(),
                        optionClicked({ field: `widgets.${widget}` }),
                      ];
                }}
              >
                {t(`admin.integration.widgets.widgets.${widget}.title`)}
              </CheLab01>
              <PageDescription>
                {t(`admin.integration.widgets.widgets.${widget}.description`)}
              </PageDescription>
              <Spa16 />
            </React.Fragment>
          ))}
        <Spa96 />
      </Wrapper>
    );
  },
);

import * as sequences from './sequences';
import organisationsModule from './modules/organisations';
import campaignsModule from './modules/campaigns';
import championshipsModule from './modules/championships';
import structureModule from './modules/structure';

import { renderPage } from './factories';

export default {
  state: {
    page: null,
    pageSection: null,
    pageSectionExpanded: null,
    monthlyStats: {},
    isLoadingStats: false,
  },
  modules: {
    organisations: organisationsModule,
    campaigns: campaignsModule,
    championships: championshipsModule,
    structure: structureModule,
  },
  sequences: {
    dashboardRouted: renderPage('dashboard'),
    pageClicked: sequences.changePage,
    getMonthlyStatsClicked: sequences.getMonthlyStatsClicked,
    pageSectionClicked: sequences.changePageSection,
    openWithToken: sequences.openWithToken,
  },
};

import styled from 'styled-components';
import { Typ30, Typ60 } from 'dc/Typ';
import Btn020 from 'dc/Btn020';
import Btn021 from 'dc/Btn021';

export const Wrapper = styled.div`
  width: 584px;
  margin: 0 auto;
`;

export const Title = styled(Typ60)`
  color: #546e7a;
`;

export const Description = styled(Typ30)`
  color: #546e7a;

  span {
    color: #546e7a;
    padding-top: 0;
  }

  a {
    color: #03739a;
    text-decoration: underline;
  }
`;

export const PageDescription = styled.span`
  color: #546e7a;
  span {
    color: #546e7a;
    padding-top: 8px;
    margin-left: 32px;
  }

  a {
    color: #03739a;
    text-decoration: underline;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const AddButton = styled(Btn021)`
  min-width: 148px;
  background-color: #03739a;
`;

export const SkipButton = styled(Btn020)`
  min-width: 148px;

  span {
    color: #03739a;
  }
`;

import * as sequences from './sequences';
import infoModule from './modules/info';
import configModule from './modules/config';
import challengeModule from './modules/challenge';
import accessModule from './modules/access';
import organisationModule from './modules/organisation';
import focusModule from './modules/focus';
import integrationModule from './modules/integration';
import { renderPage } from '../../../../factories';

export default {
  state: {
    isCreatingCampaign: false,
    error: null,
    isChampionship: false,
    isValid: false,
  },
  modules: {
    access: accessModule,
    info: infoModule,
    config: configModule,
    challenge: challengeModule,
    integration: integrationModule,
    organisation: organisationModule,
    focus: focusModule,
  },
  sequences: {
    routed: renderPage('createCampaign', sequences.render),
    fieldChanged: sequences.fieldChanged,
    dropdownSelected: sequences.dropdownSelected,
    createCampaignClicked: sequences.createCampaign,
  },
};

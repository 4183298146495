import React from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import { Wrapper, ContentWrapper, Loader } from './elements';
import Organisation from 'admin/onboarding/App/Campaigns/CreateCampaign/Organisation';
import Info from './Info';
import Focus from 'admin/onboarding/App/Campaigns/CreateCampaign/Focus';
import Challenge from 'admin/onboarding/App/Campaigns/CreateCampaign/Challenge';
import ChallengeActivities from 'admin/onboarding/App/Campaigns/CreateCampaign/Challenge/Activities';
import ChallengeTerms from './Challenge/Terms';
import ChallengeDuration from 'admin/onboarding/App/Campaigns/CreateCampaign/Challenge/Duration';
import Integration from 'admin/onboarding/App/Campaigns/CreateCampaign/Integration';
import PublicPages from 'admin/onboarding/App/Campaigns/CreateCampaign/Integration/PublicPages';
import Widgets from 'admin/onboarding/App/Campaigns/CreateCampaign/Integration/Widgets';
import Publish from './Publish';

import ErrorBoundary from 'dc/ErrorBoundary';
import { useConfig, useText } from 'common/hooks';

export default connect(
  {
    pageSection: state`admin.pageSection`,
    isLoading: state`admin.championships.isLoading`,
  },
  function CreateChampionship({ pageSection, isLoading }) {
    const t = useText();
    const { staticUrl } = useConfig();

    function renderSection() {
      const pages = {
        organisation: Organisation,
        focus: Focus,
        info: Info,
        challenge: Challenge,
        challengeActivities: ChallengeActivities,
        challengeTerms: ChallengeTerms,
        challengeDuration: ChallengeDuration,
        integration: Integration,
        publicPages: PublicPages,
        widgets: Widgets,
        publish: Publish,
      };

      const Page = pages[pageSection] || Organisation;

      return <Page />;
    }

    if (isLoading) {
      return <Loader />;
    }

    return (
      <Wrapper>
        <ContentWrapper>
          <ErrorBoundary
            title={t('appContainer.error_boundary.title')}
            body={t('appContainer.error_boundary.body')}
            buttonText={t('appContainer.error_boundary.button_text')}
            imageSrc={`${staticUrl}/images/trophy_investigate.svg`}
          >
            {renderSection()}
          </ErrorBoundary>
        </ContentWrapper>
      </Wrapper>
    );
  },
);

export default {
  activity_handprint_01: {
    title:
      'I contacted a politician or business and encouraged change to reduce plastic consumption and/or plastic waste today',
    description:
      'Log each day you sent an email, Facebook message or similar to a politician or  business with a request for change',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    handprint_explanation: `MARKDOWN:
## The facts 
- As a consumer you can help change the clothing industry! Confront the clothing manufacturers and say that you want more environmentally friendly clothing. It helps! Consumer awareness is also crucial, so talk about good alternatives to friends and family.`,
  },
  activity_handprint_02: {
    title: 'I arranged a plastic-free event or party today',
    description:
      'Log each day you have arranged a plastic free birthday party, celebration, family dinner or other similar events',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    biodiversity_explanation: `MARKDOWN:
## The facts 
- Straws, balloon sticks, plates, glasses and cups made of plastic will be banned in the EU from 2021, and from 2020 in Norway. Unnecessary disposable plastic is a major waste problem. It is fantastic that bans are now being levied on some of the products which are the most common cause of problems.`,
    handprint_explanation: `MARKDOWN:
## The facts
- By arranging a plastic-free dinner or a plastic-free event you help to naturalize a social life without unnecessary disposable products!

## Tip
- [https://www.framtiden.no/gronne-tips/fritid/gronn-feiring.html](https://www.framtiden.no/gronne-tips/fritid/gronn-feiring.html)
- [https://www.framtiden.no/gronne-tips/fritid/slik-kan-du-redusere-engangsplasten.html](https://www.framtiden.no/gronne-tips/fritid/slik-kan-du-redusere-engangsplasten.html)`,
  },
  activity_handprint_03: {
    title:
      'I picked up at least five discarded plastic items I came across today',
    description:
      'Log each day you picked up at least five plastic items that you came across',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    biodiversity_explanation: `MARKDOWN:
## The facts 
- Most of the plastic produced has ended up as waste. It is difficult to determine exactly how much waste is produced annually due to the large variations in the lifetime of the plastic. While some products become waste after a short time, others continue to live for decades. Thus, for some years there may be more waste than it is produced. It is estimated that less than 10% of the plastic produced between 1950-2015 has been recycled, some is burned while most of it ends up on landfills or astray.

## Additional info
- [https://holdnorgerent.no/](https://holdnorgerent.no/)

## Tip for arranging a plastic cleaning event
- [https://holdnorgerent.no/wp-content/uploads/2017/04/Hold-Norge-Rents-ryddeveileder.pdf](https://holdnorgerent.no/wp-content/uploads/2017/04/Hold-Norge-Rents-ryddeveileder.pdf)`,
    co2_explanation: `MARKDOWN:
## The facts
- Over half of all plastic production has ended up on landfills. Plastics that ends up on landfills emit CO\u2082 and methane, two strong greenhouse gases. At the same time, the plastic is broken into microplastics. Much is also taken by wind and water and spreads out into the nature and the sea.`,
    handprint_explanation: `MARKDOWN:
## The facts
- When you pick plastic, you help to make visible the huge amount of plastic that goes astray. Picking up plastic together with others helps reduce plastic waste and raise awareness.`,
  },
  activity_handprint_04: {
    title: "I made sure that I didn't leave plastic anywhere today",
    description:
      'Log each day when you make sure to throw your plastic in the trash cans properly. Make sure to check for the tiny bits of plastic that you can miss on your picnic blanket or that fall from the garbage can etc.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    biodiversity_explanation: `MARKDOWN:
## The facts 
- More than 8 million tons of plastic waste end up in the sea every year! It is even more shocking when you consider that these 8 million correspond to only 3% of the annual amount of plastic waste! Most of the plastic in the balance is due to poor waste management, especially in low-income countries. Even though, the trend is that it is the richest countries that generate the most plastic waste per person. Due to better waste management in high-income countries, low-income countries are the worst plastic polluters. The plastic that goes astray often finds its way to waterways and by wind to rivers that in turn flow into the sea.`,
  },
  activity_handprint_05: {
    title:
      'I encouraged my municipal waste company to improve its waste system today',
    description:
      'Log if you contact your municipal waste company and encourage them to improve the waste system and / or recycling of plastic',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    handprint_explanation: `MARKDOWN:
## The facts 
- The waste collecting systems varies from municipality to municipality in Norway. This is because it is the municipalities that decide what waste sorting schemes they should have. Thus, you can contact municipal politicians and / or your municipal waste disposal company to demand better waste management where it is missing or deficient.

## Additional info
- [https://sortere.no/](https://sortere.no/)`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- It is the richest countries that generate the most plastic waste per person. However, due to poorer waste management, low-income countries still rank as the worst plastic waste producers. Nevertheless, we must take our responsibility here at home by reducing the amount of waste and contributing to better waste management.`,
  },
  activity_handprint_06: {
    title:
      'I encouraged my convenience store to reduce its plastic consumption today',
    description:
      'Can be registered if you encouraged your convenience store to reduce plastic consumption by, for example, finding better packaging alternatives than plastic',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    handprint_explanation: `MARKDOWN:
## The facts 
- Companies and convenience stores are concerned with what consumers think. Stores need customers and depend on what the market wants. If a lot of people encourage or demand that the stores reduce their plastic consumption, we have a huge influence!`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- The largest market for plastics is packaging. Over 40% of all plastic is produced for packaging. Packaging is also the product group with the lowest lifetime, since most consists of disposable items. Unfortunately, much of the packaging ends up going astray and ends up in nature and in the sea.`,
  },
  activity_handprint_07: {
    title: 'I made a friend do a plastic reducing activity today',
    description:
      'Register when you made a friend do one of the plastic reducing activities in the Plastic Challenge',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    handprint_explanation: `MARKDOWN:
## The facts 
- Several social psychology studies have been done that show that action leads to attitude, and that the social network effect (also called the "neighbor effect") is a strong driver of people's actions. For example, it has been shown that neighborhoods where someone starts buying an electric car or installs solar panels on the roof are the same areas with the greatest growth in precisely electric cars and solar cells. In other words, your actions influence what others do. If you get others to reduce plastic consumption or waste, you help create a new green trend. Go you!`,
  },
  activity_handprint_08: {
    title: 'I learned something new about plastic today',
    description: 'Log each day you learned something new about plastic',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    handprint_explanation: `MARKDOWN:
## The facts 
- You have great influence both as a consumer and as an individual. By gaining more knowledge about plastic, you can feel more confident that you are making the right choices at the store and influencing people around you in the right direction.`,
  },
  activity_handprint_09: {
    title:
      'I encouraged the prevention of microplastic emissions from artificial turf pitches today',
    description:
      'Can be registered when you talk to your local sports team about specific measures to prevent microplastic emissions from artificial turf pitches',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts 
- Rubber granules are made from recycled car tires and can therefore also contain environmental toxins such as PAH, phthalates, heavy metals and phenols.`,
    handprint_explanation: `MARKDOWN:
## The facts
- The Norwegian Environment Directorate has made a proposal for a regulation on "the design and operation of sports fields where plastic-containing loose material is being used" to reduce plastic from football fields. Unfortunately, the proposal has stopped and has not yet been adopted. Here, team leaders, sports teams and we as individuals can push politicians to adopt the regulations and implement specific measures to reduce plastic.

## Additional info
- [https://tema.miljodirektoratet.no/Global/dokumenter/horinger/Industri/Forskrift%20om%20kunstgressbaner.pdf?epslanguage=no](https://tema.miljodirektoratet.no/Global/dokumenter/horinger/Industri/Forskrift%20om%20kunstgressbaner.pdf?epslanguage=no)`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- About 1,500 tonnes of rubber granules are emitted from Norwegian artificial turf fields every year! This makes artificial turf pitches the second largest source of microplastics emissions in Norway.
- A survey by Rambøll Norge AS shows that only 5% of sports facilities have taken into account vulnerable recipients in the location of the artificial turf facility and as much as 40% of all courses are near vulnerable recipients such as streams, rivers, lakes and coasts. It is estimated that an average of 5.5 tonnes of rubber granules are supplied to pitches each year.

## Additional info
- Read more about sources of micro plastic in Norway [https://miljostatus.miljodirektoratet.no/tema/avfall/avfallstyper/mikroplast/](https://miljostatus.miljodirektoratet.no/tema/avfall/avfallstyper/mikroplast/) 
- Read more about mapping of norwegian artificial turf fields [https://www.miljodirektoratet.no/globalassets/publikasjoner/M954/M954.pdf](https://www.miljodirektoratet.no/globalassets/publikasjoner/M954/M954.pdf)
- Draft for policy on artificial turf fields [https://tema.miljodirektoratet.no/Global/dokumenter/horinger/Industri/Forskrift%20om%20kunstgressbaner.pdf?epslanguage=no](https://tema.miljodirektoratet.no/Global/dokumenter/horinger/Industri/Forskrift%20om%20kunstgressbaner.pdf?epslanguage=no)`,
  },
  activity_handprint_10: {
    title: 'I influenced someone to make more climate friendly choices today',
    description:
      'Talk with family, friends, colleagues or engage in conversations on social media.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Talking about sustainability more often and in multiple arenas can help us find good solutions to everyday challenges together. 
- The read more section below has some great tips for how to talk about sustainability in a way that creates bridges and not barriers. 
- Having a dialogue around sustainability with others is a good way to gain a better picture for how people around you understand the issue. Agreements and disagreement around sustainability is a healthy way to understand different perspectives in one's society and seek out potential solutions making concrete impact personally and collectively.  

## The assumptions 
- You can have a potentially huge impact by sharing your thoughts with others as the sustainability issues can only be solved through collaboration. The person you talk to might be inspired to talk to others, to vote for green politicians, to buy sustainable products, and change the behaviours of their household.

## Additional info
- [https://www.weforum.org/agenda/2018/06/how-to-talk-about-climate-change-5-tips-from-the-front-lines/](https://www.weforum.org/agenda/2018/06/how-to-talk-about-climate-change-5-tips-from-the-front-lines/)
- [https://www.ted.com/talks/per_espen_stoknes_how_to_transform_apocalypse_fatigue_into_action_on_global_warming](https://www.ted.com/talks/per_espen_stoknes_how_to_transform_apocalypse_fatigue_into_action_on_global_warming)
`,
  },
  activity_handprint_11: {
    title: 'I have inspired someone to eat vegetarian today',
    description: '',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_handprint_12: {
    title: 'I washed my hands frequently today',
    description:
      'Regularly and thoroughly clean your hands with an alcohol-based hand rub or wash them with soap and water.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- Washing your hands with soap and water or using alcohol-based hand rub kills viruses that may be on your hands.

## Additional info
- [https://apps.who.int/iris/bitstream/handle/10665/44102/9789241597906_eng.pdf;jsessionid=8C2C569869D577AFBF4192156149A4CE?sequence=1](https://apps.who.int/iris/bitstream/handle/10665/44102/9789241597906_eng.pdf;jsessionid=8C2C569869D577AFBF4192156149A4CE?sequence=1)`,
  },
  activity_handprint_13: {
    title: 'I maintained physical distancing today',
    description:
      'Log each day that you maintained at least 1 meter (3 feet) distance between yourself and anyone else.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- Physical distancing proved to be the most effective way to slow the spread of COVID-19. When someone coughs or sneezes they spray small liquid droplets from their nose or mouth which may contain the virus. If you are too close, you can get in contact with the droplets, including the COVID-19 virus if the person coughing has the disease. Taking the right measures for physical distancing helps you and others to keep healthy and safe. You also contribute to saving the lives of vulnerable groups with chronic illnesses, immunity problems, and the elderly.

## Additional info
- [https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3372334/](https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3372334/)`,
  },
  activity_handprint_14: {
    title: 'I practiced safe respiratory hygiene today',
    description:
      'Log each day you covered your mouth and nose with your bent elbow, or tissue, when you cough or sneeze, and refrained from touching your eyes, nose and mouth.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- Droplets spread the virus. By following good respiratory hygiene you protect the people around you from viruses such as cold, flu and COVID-19.
- Hands touch many surfaces and can pick up viruses. Once contaminated, hands can transfer the virus to your eyes, nose or mouth. From there, the virus can enter your body and can make you sick.

## Additional info
- [https://link.springer.com/article/10.1007/s00348-015-2078-4](https://link.springer.com/article/10.1007/s00348-015-2078-4)`,
  },
  activity_handprint_15: {
    title: 'I started learning a skill online today',
    description:
      'Log each day that you started a course to learn a new skill online.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- Learning a new skill is fun and helps to fight boredom. It also improves performance and increases self-esteem. Moreover, learning something new makes you happier.

## Additional info
- [https://www.ted.com/talks/carol_dweck_the_power_of_believing_that_you_can_improve?language=en](https://www.ted.com/talks/carol_dweck_the_power_of_believing_that_you_can_improve?language=en)
- [https://www.psychologies.co.uk/want-be-happier-learn-something-new](https://www.psychologies.co.uk/want-be-happier-learn-something-new)
- [https://anuschkarees.com/blog/2015/01/22/how-to-make-learning-and-personal-development-a-regular-part-of-your-life](https://anuschkarees.com/blog/2015/01/22/how-to-make-learning-and-personal-development-a-regular-part-of-your-life)`,
  },
  activity_handprint_16: {
    title: 'I taught a skill today',
    description: 'Log each day you shared your skill with someone.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- Teaching your skills not only allows you to share your passion and inspire others, it also enhances self-esteem while helping your community. Helping others also generates a feeling of calm and reduces stress. How incredible those benefits are!

## Additional info
- [https://www.apa.org/monitor/dec06/helping](https://www.apa.org/monitor/dec06/helping)
- [https://blogs.volunteermatch.org/engagingvolunteers/2018/12/04/volunteering-and-psychological-health/](https://blogs.volunteermatch.org/engagingvolunteers/2018/12/04/volunteering-and-psychological-health/)`,
  },
  activity_handprint_17: {
    title: 'I worked out today',
    description: 'Log each day that you practiced physical exercise.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- Exercise offers incredible benefits that can improve nearly every aspect of your health from the inside out.  It helps to keep in shape, increase energy, and improve mental health including reducing stress, anxiety, and depression. 

## Additional info
- [https://medlineplus.gov/benefitsofexercise.html](https://medlineplus.gov/benefitsofexercise.html)`,
  },
  activity_handprint_18: {
    title: 'I shared good vibes with a friend or loved one today',
    description:
      'Log each day that you took the time to talk with a friend on the phone or in a video call.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    social_explanation: `MARKDOWN:
## The facts
- Talking is reaching out for a connection. It is an opportunity to adjust our thoughts and emotions and brings a feeling of relief. Being listened to brings quieting of emotions. Listening to someone helps to develop patience and builds mutual trust.

## Additional info
- [https://www.psychologytoday.com/us/blog/the-chronicles-infertility/201808/the-power-talking](https://www.psychologytoday.com/us/blog/the-chronicles-infertility/201808/the-power-talking)
- [https://hbr.org/1957/09/listening-to-people](https://hbr.org/1957/09/listening-to-people)`,
  },
  activity_handprint_19: {
    title: 'I stayed at home today',
    description:
      'Log each day that you stayed at home to avoid getting infected or contaminating others.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
- Avoiding contact with others and visits to medical facilities will allow these facilities to operate more effectively and help protect you and others from possible COVID-19 and other viruses.
- Stay at home if you begin to feel unwell until you recover, even if you only have mild symptoms such as a headache or runny nose.`,
  },
  activity_handprint_20: {
    title: 'I stayed updated with health authority guidelines',
    description:
      'Log each that day you stayed informed on the latest developments about COVID-19, from official sources.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
- National and local authorities will have the most up-to-date information on whether COVID-19 is spreading in your area. They are best placed to advise on what people in your area should be doing to protect themselves.
- Stay informed on the latest developments about COVID-19. Follow advices given by your healthcare provider, your national and local public health authority or your employer on how to protect yourself and others from COVID-19.

## Additional info
- [https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/steps-when-sick.html](https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/steps-when-sick.html)`,
  },
  activity_handprint_21: {
    title: 'I talked about mindfulness today',
    description:
      'Log each day that you talked about the benefits of developing awareness and adopting more compassionate actions into our lives',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    handprint_explanation: `MARKDOWN:
## The facts
- Talking about mindfulness can help us find good solutions to everyday challenges together, by creating and contributing to a peaceful and aware community.
- Talking about the benefits of mindfulness (towards yourself and others) will provide bigger chances for social evolution to take place.
- Have a look at the talk from Diana Winston (UCLA's Mindful Awareness Research Center), on how developing compassion for ourselves is a foundation for cultivating compassion for others and the world.

## Additional info
- [https://www.youtube.com/watch?v=oMlaSCxZPN4](https://www.youtube.com/watch?v=oMlaSCxZPN4)
- [https://positivepsychology.com/mindful-self-compassion/](https://positivepsychology.com/mindful-self-compassion/)
- [https://www.health.harvard.edu/mind-and-mood/mindfulness-not-just-for-stress-reduction](https://www.health.harvard.edu/mind-and-mood/mindfulness-not-just-for-stress-reduction)
- [https://www.health.harvard.edu/staying-healthy/the-magic-of-mindfulness](https://www.health.harvard.edu/staying-healthy/the-magic-of-mindfulness)`,
  },
  activity_handprint_22: {
    title: 'I shared fact-checked information today',
    description:
      'Log each day that you talked about the benefits of adopting more compassionate actions into our lives.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    handprint_explanation: `MARKDOWN:
## The facts
- Stigma hurts everyone by creating fear or anger towards other people. It affects the emotional or mental health of communities. Stopping stigma by avoiding hyperbole is important to making communities and community members resilient.
- Everyone can help stop stigma by knowing the facts and sharing them with others in your community.

## Additional info
- [https://research.bangor.ac.uk/portal/files/19296816/2017_Fake_news.pdf](https://research.bangor.ac.uk/portal/files/19296816/2017_Fake_news.pdf)`,
  },
  activity_handprint_23: {
    title: 'I showed empathy with those affected today',
    description:
      'Log each day that you cared for people that are affected with COVID-19.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- Being compassionate and altruistic towards others including the sick create a sense of belonging, a better sense of community, and helps reducing isolation and prejudice. It feels rewarding for oneself to be nice with the vulnerable,  and a big reminder of the interconnected nature of the world. Remember, the more you give for others, the more you give for yourself!

## Additional info
- [https://www.verywellmind.com/how-to-practice-empathy-during-the-covid-19-pandemic-4800924](https://www.verywellmind.com/how-to-practice-empathy-during-the-covid-19-pandemic-4800924)
- [https://www.psychologytoday.com/us/blog/social-empathy/201812/five-ways-empathy-is-good-your-health](https://www.psychologytoday.com/us/blog/social-empathy/201812/five-ways-empathy-is-good-your-health)`,
  },
  activity_handprint_24: {
    title: 'I expressed gratitude to a friend or a coworker today',
    description:
      'Log each day that you took the time to thank a friend or a coworker.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- Expressing gratitude and appreciation can benefit you and others enormously, being thankful in our lives even in times of challenge and change.
- Through grateful actions, people acknowledge the goodness in our lives. It helps us to connect to something larger than ourselves. The psychological impact of practicing gratitude includes enhanced well-being, deeper relations, improved physical and mental health, optimism and happiness.

## Additional info
- [https://positivepsychology.com/gratitude-appreciation/](https://positivepsychology.com/gratitude-appreciation/)
- [https://www.health.harvard.edu/healthbeat/giving-thanks-can-make-you-happier](https://www.health.harvard.edu/healthbeat/giving-thanks-can-make-you-happier)`,
  },
  activity_handprint_25: {
    title: 'I reached out to a neighbor in need today',
    description:
      'Log each day that you asked a neighbor if you could help in any ways.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- It is important to reach out to people in need to show compassion. Also, the quality of the relationships within the neighborhood has an effect on health.

## Additional info
- [https://academic.oup.com/abm/article-abstract/39/2/170/4566651](https://academic.oup.com/abm/article-abstract/39/2/170/4566651)`,
  },
  activity_handprint_26: {
    title: 'I practiced safe greetings today',
    description:
      'Log each day that you greeted the people you met with a wave, a nod, or a bow.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- To prevent the spread of COVID-19, it is safest to avoid physical contact when greeting.
- Respiratory viruses can be passed by shaking hands and touching your eyes, nose and mouth.`,
  },
  activity_handprint_27: {
    title: 'I started to kick a bad habit today',
    description: 'Log each day that you decided to get rid of a bad habit.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- You can take the opportunity to reflect on the habits you would like to change. Breaking bad habits can help you to generate healthier habits improving your physical and mental health.

## Additional info
- [https://www.lemonade.com/blog/breaking-bad-habits/](https://www.lemonade.com/blog/breaking-bad-habits/)`,
    social_explanation: `MARKDOWN:
## The facts
- We create bad habits to cope with stress. In order to break a bad habit, you should simply change it with a short term rewarding habit.

## Additional info
- [https://www.researchgate.net/profile/Wander_Jager/publication/251477649_Breaking_'bad_habits'_a_dynamical_perspective_on_habit_formation_and_change/links/0deec53b4f882d03b0000000/Breaking-bad-habits-a-dynamical-perspective-on-habit-formation-and-change.pdf](https://www.researchgate.net/profile/Wander_Jager/publication/251477649_Breaking_'bad_habits'_a_dynamical_perspective_on_habit_formation_and_change/links/0deec53b4f882d03b0000000/Breaking-bad-habits-a-dynamical-perspective-on-habit-formation-and-change.pdf)`,
  },
  activity_handprint_28: {
    title:
      'I had a conversation with another participant about environmental issues.',
    description:
      'Through conversations about the environment, we can help our members embed a decarbonized lifestyle among themselves.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    handprint_explanation: `MARKDOWN:
## The facts 
- What you know about eco-friendly and decarbonised lifestyles may not be known to the other members of the team who are participating in the challenge with you. By sharing that knowledge with other members, you can contribute to the decarbonization of your entire team.`,
  },
};

import styled from 'styled-components';
import { Typ20 } from 'dc/Typ';
import Btn022 from 'dc/Btn022';

export const Wrapper = styled.div`
  background-color: #f4f7f9;
  border-radius: 3px;
  padding: 24px;
  display: flex;
`;

export const Input = styled.input`
  display: none;
`;

export const Button = styled(Btn022)`
  width: 100%;
  background-color: #03739a;
  :hover:not([disabled]) {
    background-color: #004a66;
  }
`;

export const Explanation = styled(Typ20)`
  margin-top: 10px;
  display: block;
  color: #546e7a;
`;

export const OptionWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  margin: 12px;
`;

export const Bar = styled.div`
  width: 0;
  border: 1px solid #cfd8dc;
  margin: 0 20px;
`;

import { state, props } from 'cerebral';
import { when, set, merge } from 'cerebral/factories';

import { setField } from 'forms/operators';

import * as actions from './actions';

export const sectionFieldChanged = [
  when(props`isNew`),
  {
    true: [actions.addNewSection],
    false: [],
  },
  setField(
    state`admin.structure.structureForm.sections.${props`sectionKey`}.${props`fieldName`}`,
    props`value`
  ),
];

export const teamFieldChanged = [
  when(props`isNew`),
  {
    true: [actions.addNewTeam],
    false: [],
  },
  setField(
    state`admin.structure.structureForm.sections.${props`sectionKey`}.teams.${props`teamKey`}.${props`fieldName`}`,
    props`value`
  ),
];

export const deleteSection = [actions.deleteSection];
export const deleteTeam = [actions.deleteTeam];

export const setStructureFromExcelSheet = [
  set(state`admin.structure.isLoadingStructure`, true),
  actions.parseExcel,
  actions.formatStructureForm,
  merge(state`admin.structure.newIds.teams`, props`newTeamIds`),
  set(state`admin.structure.structureForm.sections`, props`createdSections`),
  set(state`admin.structure.isLoadingStructure`, false),
];

export const setStructureFromOrganisation = [
  set(state`admin.structure.isLoadingStructure`, true),
  actions.getStructureFromOrganisation,
  actions.formatStructureForm,
  merge(state`admin.structure.newIds.teams`, props`newTeamIds`),
  set(state`admin.structure.structureForm.sections`, props`createdSections`),
  set(state`admin.structure.isLoadingStructure`, false),
];

export function getCampaign({ state, database }) {
  const campaignKey = state.get(`admin.campaigns.edit.campaignKey`);

  return database.getCampaign(campaignKey).then(campaign => ({
    campaign: campaign || {},
  }));
}

export function getTeamsExpectedParticipantCounts({ state, firebase }) {
  const campaignKey = state.get(`admin.campaigns.edit.campaignKey`);

  return firebase
    .value(`campaigns.expectedParticipantCounts.${campaignKey}.teams`)
    .then(response => ({
      teamsExpectedParticipantCounts: response.value || {},
    }));
}

export function getTeamLeaderEmails({ state, firebase }) {
  const campaignKey = state.get(`admin.campaigns.edit.campaignKey`);

  return firebase
    .value(`campaigns.leaders.${campaignKey}.sections`)
    .then(response => ({
      teamLeaderEmails: response.value || {},
    }));
}

export function copyToClipboard({ props }) {
  const { str } = props;
  const el = document.createElement('textarea'); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
}

export function setStructureForm({ state, uuid }) {
  const structure = state.get('admin.campaigns.edit.campaign.structure');
  const expectedParticipantCounts = state.get(
    'admin.campaigns.edit.expectedParticipantCounts.teams'
  );
  const teamLeaderEmails = state.get('admin.campaigns.edit.teamLeaderEmails');

  const sections = Object.fromEntries([
    ...Object.entries(structure.sections).map(
      ([sectionKey, { name, teams }]) => [
        sectionKey,
        {
          exists: true,
          name: {
            value: name,
            readOnly: true,
            defaultValue: '',
            validationRules: [
              'isValue',
              `isUnique:{"form":"admin.structure.structureForm.sections","field":"name"}`,
            ],
          },
          teams: Object.fromEntries([
            ...Object.entries(teams).map(([teamKey, team]) => [
              teamKey,
              {
                exists: true,
                name: {
                  value: team.name,
                  readOnly: true,
                  defaultValue: '',
                  validationRules: [
                    'isValue',
                    `isUnique:{"form":"admin.structure.structureForm.sections.${sectionKey}.teams","field":"name"}`,
                  ],
                },
                expectedParticipantCount: {
                  value: String(expectedParticipantCounts[teamKey] ?? ''),
                  readOnly: true,
                  defaultValue: '',
                  validationRules: ['isValue'],
                },
                teamLeaderEmail: {
                  value:
                    teamLeaderEmails?.[sectionKey]?.teams?.[teamKey]?.email ??
                    '',
                  readOnly: true,
                  defaultValue: '',
                },
              },
            ]),
          ]),
        },
      ]
    ),
  ]);

  const newTeamIds = Object.fromEntries([
    ...Object.keys(structure.sections).map(sectionKey => [
      sectionKey,
      uuid.v4(),
    ]),
  ]);

  state.merge('admin.structure.newIds.teams', newTeamIds);
  state.set(`admin.structure.structureForm.sections`, sections);
}

export function selectDropdownItem({ props, state }) {
  const { context, option } = props;

  state.set(`admin.campaigns.edit.${context}Selected`, option);
}

export function filterOptions({ props, state }) {
  const { value } = props;
  const text = state.get(
    'appContainer.language.phrases.admin.overview.reports'
  );
  const sections = state.get(
    `admin.campaigns.edit.campaign.structure.sections`
  );

  const sectionOptions =
    value !== 'sections'
      ? Object.fromEntries(
          Object.keys(sections).map(sectionKey => [
            sectionKey,
            sections[sectionKey].name,
          ])
        )
      : {};

  sectionOptions.noSectionFilter = text.all_sections;

  state.set(`admin.campaigns.edit.sectionOptions`, sectionOptions);

  if (value === 'sections') {
    state.set(`admin.campaigns.edit.sectionSelected`, {
      optionKey: 'noSectionFilter',
      optionValue: text.all_sections,
    });
  }
}

export function getEngagement({ firebase, state }) {
  return firebase
    .task('calculate_engagement', {
      campaignKey: state.get('admin.campaigns.edit.campaignKey'),
    })
    .then(response => ({ engagement: response }));
}

export function getTotalStats({ firebase, state }) {
  const challengeKey = state.get(
    'admin.campaigns.edit.campaign.tools.challengeKey'
  );

  if (!challengeKey) return Promise.resolve({ totalStats: {} });

  return firebase
    .value(`challenges.totalStats.challenges.${challengeKey}`)
    .then(response => ({ totalStats: response.value || {} }));
}

export function getChampionshipKey({ state }) {
  const championshipKey = state.get(
    'admin.campaigns.edit.campaign.championshipKey'
  );

  return { championshipKey };
}
export function deleteCampaign({ firebase, state, props }) {
  const { shouldDeleteUsers } = props;

  return firebase.task('delete_campaign', {
    campaignKey: state.get('admin.campaigns.edit.campaignKey'),
    shouldDeleteUsers: shouldDeleteUsers,
  });
}

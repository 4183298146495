import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import {
  OuterWrapper,
  PlaceholderLabel,
  Input,
  NumberCharacters,
  ErrorMessage,
} from './elements';

function TxtField05(props) {
  const [focused, setFocused] = useState(false);

  return (
    <div className={props.className}>
      <OuterWrapper>
        {focused || props.value ? null : (
          <PlaceholderLabel version={props.version}>
            {props.label}
          </PlaceholderLabel>
        )}
        <Input
          disabled={props.disabled}
          min={props.min}
          max={props.max}
          value={props.value}
          version={props.version}
          type={props.type || 'text'}
          maxLength={props.maxLength}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </OuterWrapper>
      {!props.errorMessage && props.maxLength && (
        <NumberCharacters errorMessage={props.errorMessage}>
          {`${props.value?.length ?? 0}/${props.maxLength}`}
        </NumberCharacters>
      )}
      <ErrorMessage errorMessage={props.errorMessage}>
        {props.errorMessage}
      </ErrorMessage>
    </div>
  );
}

TxtField05.propTypes = {
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  errorMessage: PropTypes.string,
  version: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TxtField05;

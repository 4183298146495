export default {
  api: {
    somethings_new: "Something's New!",
    notice_changes:
      'You may notice some activities have changed value. We always try to bring you the most up-to-date experience and draw from the freshest data sources to bring you these numbers. Enjoy the all-new accuracy of your savings.',
  },
  calculator: {
    better_than_average:
      'BETA - Is your climate footprint better than average? Find out by trying this',
    footprint_calculator: `MARKDOWN:[Climate footprint calculator](https://openfootprint.ducky.eco/)`,
  },
};

import React from 'react';
import * as PropTypes from 'prop-types';
import { Checkbox } from './elements';
import Ico24 from '../Ico24';

function Swi03(props) {
  return (
    <Checkbox
      className={props.className}
      checked={props.checked}
      onKeyPress={event => {
        if (event.key === ' ' || event.which === 32 || event.code === 'Space') {
          props.onClick();
        }
      }}
      onClick={props.disabled ? () => {} : props.onClick}
      disabled={props.disabled}
      role={'checkbox'}
      aria-checked={props.checked}
      tabIndex={'0'}
      aria-labelledby={props.labelledby}
    >
      <Ico24
        icon={props.checked ? 'icon-check_box' : 'icon-check_box_outline_blank'}
      />
    </Checkbox>
  );
}

Swi03.propTypes = {
  checked: PropTypes.bool,
  labelledby: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Swi03;

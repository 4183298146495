import React from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import {
  Wrapper,
  ContentWrapper,
  Title,
  SubTitle,
  NewButton,
  Label,
} from './elements';

export default connect(
  {
    organisations: state`admin.organisations.list`,
  },
  function Organisations({ organisations }) {
    return (
      <Wrapper>
        <ContentWrapper>
          <Title>{'Manage organizations'}</Title>
          <SubTitle>
            {'Set up a new organization or edit an existing one'}
          </SubTitle>
          <NewButton
            onClick={() => {
              location.href = `/organisations/create`;
            }}
          >
            {'Add New Organisation'}
          </NewButton>
          {organisations.map(org => (
            <Label
              key={org.key}
              buttonText={'View'}
              href={`/organisations/edit/${org.key}`}
            >
              {org.name}
            </Label>
          ))}
        </ContentWrapper>
      </Wrapper>
    );
  }
);

import { state, props } from 'cerebral';
import { set, when } from 'cerebral/factories';
import { setActivities } from '../challenge/actions';

export const changeTopic = [
  set(state`admin.campaigns.create.focus.form.topic.value`, props`topic`),
  set(state`admin.pageSectionExpanded`, null),
  when(props`topic`, topic => topic && topic.length > 0),
  {
    true: [
      set(
        props`audience`,
        state`admin.campaigns.create.focus.form.audience.value`
      ),
      setActivities,
    ],
    false: [
      // clear tool forms
    ],
  },
];

export const changeAudience = [
  set(state`admin.campaigns.create.focus.form.audience.value`, props`audience`),
  set(props`topic`, ''),
  changeTopic,
];

export const changeDataSource = [
  set(
    state`admin.campaigns.create.focus.form.dataSource.value`,
    props`dataSource`
  ),
  setActivities,
];

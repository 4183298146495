export default {
  activity_workTransport_01: {
    title:
      'If I were to  book a long distance trip for work today, I would first evaluate the options to minimize travel emissions',
    description: `MARKDOWN:
Can be logged every day, as long as you consider lower carbon options when the next travel opportunity arises.

Some tips:  
1) Can the event be replaced with a video?  
2) Do you have to travel that far?  
3) Do other means of transport exist?  
4) Can you influence the event to reduce travel needs?`,
    co2_explanation: `MARKDOWN:
## Did you know?
- If you were to skip a flight within Europe traveling 1500 km each way, you would save approx. 800 Kg CO\u2082e, which would be approx. the same saving as if an average Norwegian goes vegan for a year.

## HOW DO WE WORK OUT CO\u2082e
- The savings is calculated based on the assumption that by making this part of your evaluation process you would reduce travel emissions by 30%.
- The baseline for the travel emissions in your company is most likely found from a survey prior to this challenge. If not, the data is derived from a company similar to yours.
`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_workTransport_02: {
    title: 'All traveling at work today was done with public transport',
    description:
      'Use public transportation such as bus, tram and/or train when you go to work events today',
    co2_explanation: `MARKDOWN:
## Did you know?
- Many cities around the world have banned cars in their city centers, leading to reduced emissions, better air quality, more life in the city center and less accidents, just to mention a few benefits.

## HOW DO WE WORK OUT CO\u2082e
- The saving is calculated based on the difference between how an average colleague in your company travels during work hours and a colleague that only uses public transport during work hours. 
- The transport habits of your colleagues are most likely found from a survey prior to this challenge. If not, the data is derived from a company similar to yours.

## Read more
- [https://www.theguardian.com/cities/2018/sep/18/paradise-life-spanish-city-banned-cars-pontevedra](https://www.theguardian.com/cities/2018/sep/18/paradise-life-spanish-city-banned-cars-pontevedra)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_workTransport_03: {
    title: 'All traveling at work today was done by bike or foot',
    description:
      'Use only muscle power for all travel during work hours today.',
    co2_explanation: `MARKDOWN:
## Did you know?
- By cycling or walking you can reduce the number of cars on the road, thus reducing pollution, noise levels, dust, and toxic gases while contributing to a more beautiful urban environment.

## HOW DO WE WORK OUT CO\u2082e
- The saving is calculated based on the difference between how an average colleague in your company travels during work hours and a colleague that only uses muscle power for transport during work hours.
- The transport habits of your colleagues are most likely found from a survey prior to this challenge. If not, the data is derived from a company similar to yours.
    `,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_workTransport_04: {
    title: 'All traveling at work today was done with an electric car',
    description: 'Use an electric car when you go to work events today.',
    co2_explanation: `MARKDOWN:
## Did you know?
- The electricity mix is important for how environmentally friendly driving an electric car is. With a nordic power mix the emissions from driving one km is 81 g CO\u2082e/km while a fossil car has the emission of 257 g CO\u2082e/km. These numbers include the production of the car.

## HOW DO WE WORK OUT CO\u2082e
- The saving is calculated based on the difference between how an average colleague in your company travels during work hours and a colleague that only uses an electric car during work hours.
- The transport habits of your colleagues are most likely found from a survey prior to this challenge. If not, the data is derived from a company similar to yours.
`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
};

import styled, { css } from 'styled-components';
import { Typ30 } from '../Typ';

export const ElementWrapper = styled.div`
  width: 100%;
`;

export const Input = styled.input`
  border-radius: 4px;
  border-style: solid;
  padding: 12px 16px;
  border-width: 1px;
  width: 100%;
  background-color: ${props => (props.whiteBG ? '#ffffff' : '#f4f7f9')};
  border-color: ${props => (props.error ? '#e0487b !important' : '#cfd8dc')};
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  color: #263238;
  transition: border-color 0.25s;
  &:focus {
    border-color: #90a4ae;
    outline: 0;
  }
  &::placeholder {
    color: #90a4ae;
  }
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  padding: 4px 0 6px;
  justify-content: center;
`;

export const ErrorMessage = styled(Typ30)`
  transform: scaleY(0);
  transition: transform 300ms ease;
  transform-origin: top;
  ${props =>
    props.errorMessage &&
    css`
      transform: scaleY(1);
    `}
  line-height: 20px;
  color: #e0487b;
`;

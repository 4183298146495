import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Label, DropdownInput } from '../elements';
import Spa32 from 'dc/Spa32';

import { useText } from 'common/hooks';

export default connect(
  {
    sectionTermDropdownIsOpen: state`admin.campaigns.create.info.sectionTermDropdownIsOpen`,
    selectedSectionTerm: state`admin.campaigns.create.info.selectedSectionTerm`,
    sectionTermOptionClicked: sequences`admin.campaigns.create.info.sectionTermOptionClicked`,
    toggleSectionTermDropdownClicked: sequences`admin.campaigns.create.info.toggleSectionTermDropdownClicked`,
  },
  function SectionTerm({
    sectionTermDropdownIsOpen,
    selectedSectionTerm,
    sectionTermOptionClicked,
    toggleSectionTermDropdownClicked,
    disabled,
  }) {
    const t = useText();

    const options = t.phrases.campaign.section_terms;

    return (
      <>
        <Label>{t('admin.info.section_term')}</Label>
        <DropdownInput
          isJoiningChampionship={disabled}
          onClick={event => {
            disabled
              ? null
              : [event.stopPropagation(), toggleSectionTermDropdownClicked()];
          }}
          onDropdownItemClick={option => sectionTermOptionClicked({ option })}
          dropdownVisible={sectionTermDropdownIsOpen}
          options={options}
          value={selectedSectionTerm || ''}
          placeholder={'Select section term'}
        />
        <Spa32 />
      </>
    );
  }
);

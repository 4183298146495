import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Wrapper,
  Title,
  Description,
  ButtonsWrapper,
  AddButton,
  SkipButton,
} from './elements';
import { useText } from 'common/hooks';

export default connect(
  {
    challengeAdded: state`admin.campaigns.create.challenge.added`,
    removeChallenge: sequences`admin.campaigns.create.challenge.removeChallenge`,
    addChallenge: sequences`admin.campaigns.create.challenge.addChallenge`,
    skipChallenge: sequences`admin.campaigns.create.challenge.skipChallenge`,
  },
  function Challenge({
    challengeAdded,
    removeChallenge,
    addChallenge,
    skipChallenge,
  }) {
    const t = useText();

    return (
      <Wrapper>
        <Title>{t('admin.challenge.set_up.set_up_challenge')}</Title>
        <Description>
          {t('admin.challenge.set_up.team_competition')}
        </Description>
        <ButtonsWrapper>
          {challengeAdded ? (
            <AddButton
              onClick={() => {
                removeChallenge();
              }}
            >
              {t('admin.challenge.set_up.remove')}
            </AddButton>
          ) : (
            <AddButton
              onClick={() => {
                addChallenge();
              }}
            >
              {t('admin.challenge.set_up.add')}
            </AddButton>
          )}

          <SkipButton
            onClick={() => {
              skipChallenge();
            }}
          >
            {t('admin.challenge.set_up.skip')}
          </SkipButton>
        </ButtonsWrapper>
      </Wrapper>
    );
  }
);

export default {
  challenge: {
    co2: {
      title: 'The Climate Challenge',
      description: '',
      image: '',
    },
    climate: {
      title: 'The Climate Challenge',
      description: '',
      image: '',
    },
    workTransport: {
      title: 'The Work Transport Challenge',
      description: '',
      image: '',
    },
    foodWaste: {
      title: 'The Foodwaste Challenge',
      description: '',
      image: '',
    },
    food_waste: {
      title: 'The Foodwaste Challenge',
      description: '',
      image: '',
    },
    plastic: {
      title: 'The Plastic Challenge',
      description: '',
      image: '',
    },
    mindfulCitizen: {
      title: 'The Mindful Citizen Challenge',
      description: '',
      image: '',
    },
    atWork: {
      title: 'The At Work Challenge',
      description: '',
      image: '',
    },
  },
};

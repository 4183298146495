import React, { useState } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';

import {
  Modal,
  CloseIconContainer,
  CloseIcon,
  Container,
  WarningWrapper,
  WarningIcon,
  WarningText,
  Text,
  AdditionalText,
  CheckboxContainer,
  CheckboxWrapper,
  DoNotText,
  Label,
  Input,
  DeleteButton,
} from './elements';
import { ICON } from 'resources/constants';
import { useText } from 'common/hooks';

export default connect(
  {
    showDeleteCampaignModal: state`admin.campaigns.edit.deleteCampaignModal.showDeleteCampaignModal`,
    deleteCampaignForm: state`admin.campaigns.edit.deleteCampaignModal.verifyDeletionForm`,
    isValidDeletionForm: state`admin.campaigns.edit.deleteCampaignModal.isValidDeletionForm`,
    isDeletingCampaign: state`admin.campaigns.edit.deleteCampaignModal.isDeletingCampaign`,
    closeDeleteCampaignModalClicked: sequences`admin.campaigns.edit.closeDeleteCampaignModalClicked`,
    deleteFieldChanged: sequences`admin.campaigns.edit.deleteFieldChanged`,
    verifiedDeleteCampaign: sequences`admin.campaigns.edit.verifiedDeleteCampaign`,
  },
  function DeleteModal({
    showDeleteCampaignModal,
    deleteCampaignForm,
    isValidDeletionForm,
    isDeletingCampaign,
    closeDeleteCampaignModalClicked,
    deleteFieldChanged,
    verifiedDeleteCampaign,
  }) {
    const t = useText();
    const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);
    const [deleteUsers, setDeleteUsers] = useState(false);
    if (!showDeleteCampaignModal) {
      return null;
    }

    return (
      <Modal
        onHide={() => closeDeleteCampaignModalClicked()}
        show={showDeleteCampaignModal}
      >
        <CloseIconContainer>
          <CloseIcon
            onClick={() => closeDeleteCampaignModalClicked()}
            icon={'icon-close'}
          />
        </CloseIconContainer>
        <Container>
          <WarningWrapper>
            <WarningIcon icon={ICON.errorOutline} />
            <WarningText>
              {t('admin.overview.delete.cannot_reverse')}
            </WarningText>
          </WarningWrapper>
          <Text>{t('admin.overview.delete.removes_associated_data')}</Text>
          <AdditionalText
            onClick={() => setShowAdditionalOptions(previous => !previous)}
          >
            {showAdditionalOptions
              ? 'Hide additional options'
              : 'Show additional options'}
          </AdditionalText>
          {showAdditionalOptions ? (
            <CheckboxContainer>
              <DoNotText>
                {
                  'THIS WILL DELETE USERS ACCOUNTS AGAINST THEIR WILL! DO NOT DO THIS EXTRA STEP UNLESS YOU ARE ABSOLUTELY 100% SURE THAT IS WHAT THE ORGANISATION WANTS!!!!'
                }
              </DoNotText>
              <CheckboxWrapper>
                <input
                  type="checkbox"
                  id="deleteUsers"
                  name="deleteUsers"
                  onChange={() => setDeleteUsers(previous => !previous)}
                />
                <Label htmlFor="deleteUsers">{'Delete users as well?'}</Label>
              </CheckboxWrapper>
            </CheckboxContainer>
          ) : null}
          <Input
            onChange={event => {
              deleteFieldChanged({
                value: event.target.value,
              });
            }}
            value={deleteCampaignForm.verifyText.value}
            placeholder={t('admin.overview.delete.type_delete')}
          />
          <DeleteButton
            disabled={!isValidDeletionForm || isDeletingCampaign}
            onClick={() =>
              verifiedDeleteCampaign({ shouldDeleteUsers: deleteUsers })
            }
          >
            {t('admin.overview.delete.delete_button')}
          </DeleteButton>
        </Container>
      </Modal>
    );
  }
);

import { INDICATOR } from './';

export const PARTICIPATION_RATE = {
  0: '0',
  10: '10',
  20: '20',
  30: '30',
  40: '40',
  50: '50',
  60: '60',
  70: '70',
  80: '80',
};

export const BACKLOG_DAY = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
};

export const COMPETITION_INDICATOR = {
  [INDICATOR.co2]: INDICATOR.co2,
  [INDICATOR.points]: INDICATOR.points,
};

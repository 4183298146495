import styled from 'styled-components';
import { Typ30 } from 'dc/Typ';
import TxtField12 from 'dc/TxtField12';
import SwiLab02 from 'dc/SwiLab02';
import IcoW01 from 'dc/IcoW01';
import Lab121 from 'dc/Lab121';
import TxtField06 from 'dc/TxtField06';

const GREY_600 = '#546E7A';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 28px;
`;

export const Line = styled.hr`
  width: 100%;
  color: #cfd8dc;
  margin: 0 0 38px 0;
  border-width: 1px 0 0;
`;

export const InputWrapper = styled.div`
  margin: 18px 0 20px 0;
`;

export const Label = styled(Typ30)`
  color: ${GREY_600};
`;

export const RadioInput = styled(SwiLab02)`
  margin-top: 20px;
  margin-left: 12px;
`;

export const Paragraph = styled(Typ30)`
  color: ${GREY_600};
  margin-bottom: 16px;
`;

export const DropdownInput = styled(TxtField12)`
  background-color: #fff;
  z-index: 3;
  margin-bottom: 12px;
`;

export const SeriesNameInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const DeleteIcon = styled(IcoW01)``;

export const IconFiller = styled.div`
  width: 48px;
  height: 48px;
`;

export const AddSeriesButton = styled(Lab121)`
  cursor: pointer;
  span {
    color: #03739a;
  }
`;

export const TextInput = styled(TxtField06)`
  background-color: #fff;
  width: 100%;
  > div {
    background: #fff;
    > i {
      margin-right: 0;
    }
    > input {
      background: #fff;
    }
  }
`;

export default {
  onboarding: {
    goals: {
      its_2030: "Imagine it's 2030",
      goals_are_met: `MARKDOWN:Climate Goals are met.
Humanity succeeded!

Wanna know how?`,
    },
    you: {
      you_matter: 'Because you matter',
      join_the_story:
        "And you're about to join the legendary story of your impact!",
    },
    compete: {
      compete: 'Compete',
      join_your_team:
        'Join your team and together tackle as many climate activities as you can',
    },
    master: {
      master: 'Master',
      learn_how:
        'Learn how you can reduce global emissions through making tiny lifestyle changes...and more',
    },
    impact: {
      make_impact: 'Make your impact',
      inspire_others:
        'Inspire others with your story and take your impact to a whole new level',
      get_started: 'Get Started',
    },
    skip: 'skip',
  },
  settings: {
    back_to_challenge: 'Back to your challenge',
    back_to_campaigns: 'Back to your campaigns',
    account: 'Account',
    menu: {
      your_campaigns: 'Your campaigns',
      your_profile: 'Your profile',
      reset_password: 'Reset password',
      language: 'Language',
      email_preferences: 'Email preferences',
      ducky_support: 'Ducky Support',
      privacy_terms: 'Privacy and terms',
      log_out: 'Log out',
      close: 'Close menu',
    },
    profile: {
      general_info: {
        active_since: 'Active since %{date}',
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email',
        save: 'Save profile',
        delete_account: 'Delete your account and all associated data',
        delete_everything: 'Delete everything',
        update_picture: 'Update your profile picture',
      },
      delete: {
        delete_account: 'Delete Account',
        sorry_you_leave:
          "We're sorry to see you leave. Please let us know why so we can improve our service for others.",
        no_longer_need: 'I no longer need an account',
        not_what_expected: 'The service was not what I expected',
        not_working: "I couldn't get it to work",
        challenge_ended: 'The challenge has ended',
        other: 'Other...',
        please_specify: 'Please specify',
        cannot_reverse: 'This action cannot be reversed.',
        delete_button: 'Delete Account',
        type_delete: 'Type DELETE to confirm',
      },
    },
    language: 'Language',
    emails: {
      email_preferences: 'Email preferences',
      receive_challenge_emails:
        'I would like to receive emails with my challenge stats and updates',
    },
    password: {
      reset_password: 'Reset password',
      current_password: 'Current password',
      new_password: 'New password',
      confirm_password: 'Confirm password',
      change_password: 'Change password',
    },
    support: {
      ducky_support: 'Ducky Support',
      visit_help_desk:
        'Visit our help desk to find articles and tutorial about using Ducky. It contains all the general information required to set up and run a successful challenge',
      browse_articles: 'BROWSE ARTICLES',
      submit_support_ticket:
        'If you can’t find what you need in any of our help articles you can create and submit a support ticket. A member of the Ducky team will action your request within two working days.',
      create_ticket: 'CREATE A TICKET',
    },
    privacy: {
      privacy_and_terms: 'Privacy and terms',
    },
  },
  ModRegQ6: {
    confirm_your_email: `MARKDOWN:
**Success!**

You must confirm your email address to continue`,
    check_your_inbox: 'Check your inbox',
    sent_an_email:
      'We’ve sent you an email with a link that will log you in to your account.',
    only_use_once:
      'The link can only be used once and expires within one hour.',
  },
  register: {
    device_message: {
      default:
        'No password needed. Use an email you have easy access to on this device so we can send you a magic access link.',
      ios_chrome:
        'We recommend that you use Safari and the Mail app. But you can continue in Chrome if you read emails in the Gmail app on this device.',
      ios_safari:
        'No password needed. Use an email you can read in the Mail app on this device.',
    },
  },
  ActSet01: {
    profileText: 'Your Profile',
    firstName: 'First name',
    lastName: 'Last name',
    bio: 'Bio',
    saveProfile: 'Save Profile',
    active_since: 'Active since',
  },
  ActSet02: {
    yourAccount: 'Your Account',
    email: 'Email',
    password: 'Password',
    changePassword: 'CHANGE PASSWORD',
    changePasswordInfo:
      'We will send an email with instructions to add a new password',
    deleteAccount: 'Delete Account',
    deleteAccountInfo: 'Delete your account and all associated data',
  },
  AcSet03: {
    emailAlert: 'Email Alerts',
    challengeEmailInfo: 'I would like to receive emails regarding challenges',
  },
  TabMen04: {
    profile: 'Profile',
    account: 'Account',
    settings: 'Settings',
  },
  reset_password: {
    create_new_password: 'Create new password',
    invalid_code:
      'The link is not valid, please make sure to include the whole URL from the email and that you have not used this link before. Please request a new email.',
    no_code_recieved:
      'Please make sure to include the whole URL from the email.',
    submit: 'reset password',
    placeholder_password: 'New Password',
    placeholder_repeat: 'Repeat Password',
    password_has_been_reset: 'Your password has been reset.',
    go_to_challenge: 'GO TO CHALLENGE',
  },
  LaAcc01: {
    been_invited: 'Invited to use Ducky tools?',
    select_organisation: 'Please select your organisation to continue:',
    select_section:
      'Please select section and team to register with %{organisation}',
    you_will_be_registered: 'You will be registered with %{organisation}',
    you_are_registered:
      'You are registered to %{organisation}, %{section}, %{team}',
    form: {
      select_organisation: 'Select your organisation',
      select_campaign: 'Select your campaign',
      select_section: 'Select section',
      select_team: 'Select your team',
      continue: 'CONTINUE',
      save_and_continue: 'SAVE AND CONTINUE',
      not_organisation: 'NOT PART OF AN ORGANISATION',
    },
  },
  join_campaign: {
    link: {
      title: 'Welcome to %{campaignName}',
      sub_title: 'Select your %{sectionTerm} and team to participate:',
      sub_title_team_only: 'Select your team to participate',
    },
    code: {
      title: 'Invited to join a campaign?',
      title_with_name: 'Welcome to %{campaignName}',
      sub_title: 'Enter your invitation code to participate:',
      code_placeholder: 'eg. 123456',
      check_code: 'Checking...',
      ok: 'OK',
      no_code:
        'No code? You may have been invited to access via a particular link. Try that instead.',
    },
    form: {
      choose_campaign: 'Select your campaign',
      choose_section: 'Select your section',
      choose_team: 'Select your team',
      choose: 'Select your %{sectionTerm}',
      save_and_continue: 'Save and continue',
    },
    error: {
      title: 'An error occurred',
      messages: {
        invalid_link:
          'The current link is not valid. Please make sure to include the whole link.',
        invalid_domain:
          'You email domain is not allowed to join this campaign. Please make sure to use your email associated with the organisation.',
        joining_error: 'There was an error joining the campaign',
      },
    },
  },
  timeline: {
    your_challenges: 'Your Challenges',
    cant_see:
      "Can't see your challenge? Contact your organiser for a joining link",
    current: 'Current Campaigns',
    future: 'Future Campaigns',
    past: 'Past Campaigns',
    website: 'The %{campaignName} website',
    see_more: 'See more info and statistics on the website',
    joined: 'Joined',
    completed: 'Completed',
    learn_more: 'Learn more',
    go_to: 'Go to %{toolName}',
    challenge: {
      co2: {
        heading: 'The Climate Challenge',
        subheading: 'Learn more',
        text: `MARKDOWN:
Participate in a 2-3 week challenge at your school or workplace, trying out sustainable activities in your daily life together with your classmates or colleagues.
  
Inspire your team by doing activities that are environmentally friendly, good for your health and good for your wallet.`,
      },
      atWork: {
        heading: 'The At Work Challenge',
        subheading: 'Learn more',
        text: `MARKDOWN:
Participate in a 2-3 week challenge at your workplace, trying out sustainable activities in your daily life together with your colleagues.
  
Inspire your team by doing activities that are environmentally friendly, good for your health and good for your wallet.`,
      },
      workTransport: {
        heading: 'The Work Transport Challenge',
        subheading: 'Learn more',
        text: `MARKDOWN:
Participate in a 2-3 week challenge at your school or workplace, trying out sustainable activities in your daily life together with your classmates or colleagues.
  
Inspire your team by doing activities that are environmentally friendly, good for your health and good for your wallet.`,
      },
      foodWaste: {
        heading: 'The Foodwaste Challenge',
        subheading: 'Learn more',
        text: `MARKDOWN:
Participate in a 2-3 week challenge at your school or workplace, trying out sustainable activities in your daily life together with your classmates or colleagues.
  
Inspire your team by doing activities that are environmentally friendly, good for your health and good for your wallet.`,
      },
      plastic: {
        heading: 'The Plastic Challenge',
        subheading: 'Learn more',
        text: `MARKDOWN:
Participate in a 2-3 week challenge at your school or workplace, trying out sustainable activities in your daily life together with your classmates or colleagues.
  
Inspire your team by doing activities that are environmentally friendly, good for your health and good for your wallet.`,
      },
      mindfulCitizen: {
        heading: 'The Mindful Citizen Challenge',
        subheading: 'Learn more',
        text: `MARKDOWN:
Participate in a 2-3 week challenge at home, trying out mindful activities in your daily life together with the citizens around you.
  
Inspire your team by doing activities that are environmentally friendly, good for your health and for your mind!

*The smallest act of kindness is worth more than the grandest intention.*
Oscar Wilde`,
      },
    },
  },
};

import styled, { keyframes, css } from 'styled-components';

const progress = keyframes`
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
`;

const fadeOut = keyframes`
0% {
  opacity: 1;
}
100% {
  opacity: 0;
}
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 4px;
  background-color: transparent;
  z-index: 999999;
  width: 100%;
`;

type BarProps = {
  $isServer?: boolean;
  $isLoading?: boolean;
};

export const Bar = styled.div<BarProps>`
  width: 100%;
  height: 100%;
  opacity: 1;
  animation: ${props => {
    if (props.$isServer) {
      return null;
    }

    return props.$isLoading
      ? css`1s ${progress} ease-out`
      : css`0.5s ${fadeOut} ease-out forwards`;
  }};
  background-color: #e0487b;
`;

import { set } from 'cerebral/factories';
import { state, props } from 'cerebral';
import { setUrlAction } from 'common/appContainer/module/factories';
import { clearUrl } from 'common/appContainer/module/actions';
import { isValidForm, setField } from 'forms/operators';
import * as actions from './actions';
import { redirect } from 'common/factories';

export const getData = [
  actions.getCampaign,
  set(state`admin.campaigns.edit.campaign`, props`campaign`),
  actions.getTeamsExpectedParticipantCounts,
  set(
    state`admin.campaigns.edit.expectedParticipantCounts.teams`,
    props`teamsExpectedParticipantCounts`
  ),
  actions.getTeamLeaderEmails,
  set(state`admin.campaigns.edit.teamLeaderEmails`, props`teamLeaderEmails`),
  actions.setStructureForm,
  actions.getEngagement,
  set(state`admin.campaigns.edit.engagement`, props`engagement`),
  actions.getTotalStats,
  set(state`admin.campaigns.edit.totalStats`, props`totalStats`),
];

export const render = [
  set(state`admin.campaigns.isLoading`, true),
  set(state`admin.campaigns.edit.isEdit`, true),
  set(state`admin.campaigns.edit.campaignKey`, props`campaignKey`),
  set(state`admin.pageSection`, 'publish'),
  getData,
  actions.filterOptions,
  set(state`admin.campaigns.isLoading`, false),
];

export const dropdownSelected = [actions.selectDropdownItem];

export const copyToClipboard = [actions.copyToClipboard];

export const setComparisonOption = [
  set(state`admin.campaigns.edit.${props`type`}`, props`value`),
  actions.filterOptions,
];

export const showDeleteCampaignModalClicked = [
  set(
    state`admin.campaigns.edit.deleteCampaignModal.showDeleteCampaignModal`,
    true
  ),
  set(state`appContainer.shouldResetScroll`, false),
  setUrlAction('deleteCampaign'),
];

export const closeDeleteCampaignModalClicked = [
  set(
    state`admin.campaigns.edit.deleteCampaignModal.showDeleteCampaignModal`,
    false
  ),
  setField(
    state`admin.campaigns.edit.deleteCampaignModal.verifyDeletionForm.verifyText`,
    ''
  ),
  set(state`appContainer.shouldResetScroll`, false),
  clearUrl,
];

export const deleteFieldChanged = [
  setField(
    state`admin.campaigns.edit.deleteCampaignModal.verifyDeletionForm.verifyText`,
    props`value`
  ),
  isValidForm(
    state`admin.campaigns.edit.deleteCampaignModal.verifyDeletionForm`
  ),
  {
    true: set(
      state`admin.campaigns.edit.deleteCampaignModal.isValidDeletionForm`,
      true
    ),
    false: set(
      state`admin.campaigns.edit.deleteCampaignModal.isValidDeletionForm`,
      false
    ),
  },
];

export const verifiedDeleteCampaign = [
  set(state`admin.campaigns.edit.deleteCampaignModal.isDeletingCampaign`, true),
  actions.deleteCampaign,
  set(
    state`admin.campaigns.edit.deleteCampaignModal.isDeletingCampaign`,
    false
  ),
  set(
    state`admin.campaigns.edit.deleteCampaignModal.showDeleteCampaignModal`,
    false
  ),
  redirect('/'),
];

import React from 'react';
import * as PropTypes from 'prop-types';

import { Wrapper, Text } from './elements';

function Subhead01(props) {
  return (
    <Wrapper className={props.className}>
      <Text>{props.text}</Text>
    </Wrapper>
  );
}

Subhead01.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default Subhead01;

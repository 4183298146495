import styled from 'styled-components';

import TxtField07 from 'dc/TxtField07';

export const TextAreaInput = styled(TxtField07)`
  background-color: #fff;
  width: 100%;
  > div {
    height: 144px;
    background: #fff;
    > i {
      margin-right: unset;
    }
    > input {
      background: #fff;
    }
    > textarea {
      padding-top: 0px;
      padding-left: 16px;
      line-height: 20px;
      width: 100%;
      max-height: unset;
    }
  }
`;

import { isAdminEmail } from 'utils/admin';
import { formatProfile } from 'utils/common';

export function isAdmin({ path, props, state }) {
  if (state) {
    const { email, emailVerified } = state.get('appContainer.user') || {};

    return emailVerified && isAdminEmail(email) ? path.true() : path.false();
  }

  const { email, email_verified } = props.firebaseUser;

  return email_verified && isAdminEmail(email) ? path.true() : path.false();
}

export function getProfile({ firebase, props }) {
  const profileKey = props.uid;

  return firebase
    .value(`profiles/list/${profileKey}`)
    .then(response => ({ profile: formatProfile(response.value) }));
}

export function hasProfile({ props, path }) {
  if (props.profile) {
    return path.true();
  }

  return path.false();
}

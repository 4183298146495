import styled from 'styled-components';

export const BackDrop = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  text-align: center;
  z-index: 9999999;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;
export const ModalWrapper = styled.div`
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
`;
export const Modal = styled.div`
  position: relative;
  display: inline-block;
  margin: 48px 0;
  max-width: 520px;
  width: ${props => (props.width ? `${props.width}px` : '95%')};
  min-width: ${props => (props.width ? `${props.width}px` : '95%')};
  max-height: calc(100% - 96px);
  min-height: ${props => (props.minHeight ? `${props.minHeight}px` : 'auto')};
  height: ${props => (props.height ? `${props.height}` : 'auto')};
  padding: 0;
  box-shadow:
    0 19px 60px rgba(0, 0, 0, 0.3),
    0 15px 20px rgba(0, 0, 0, 0.22);
  border-radius: 4px;
  overflow: hidden;
  z-index: 999999;
  background-color: #fff;
  text-align: left;
  box-sizing: border-box;
`;

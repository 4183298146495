import { set } from 'cerebral/factories';
import { state, props } from 'cerebral';
import { value } from 'providers/Firebase/src/operators';

import * as actions from './actions';

export const rendered = [
  value('organisations.list'),
  actions.formatOrganisations,
  set(state`admin.organisations.list`, props`organisationList`),
];

import { useContext } from 'react';
import { getAvatarUrl } from 'utils/avatars';

import ConfigContext from '../ConfigContext';

export default function useGetAvatarUrl() {
  const { staticUrl } = useContext(ConfigContext);

  return function getAvatarUrlWithStaticUrl(profile) {
    return getAvatarUrl(staticUrl, profile);
  };
}

import React from 'react';
import { connect } from '@cerebral/react';
import { languageNames } from 'languages/config';
import { state, sequences } from 'cerebral';
import { useText } from 'common/hooks';

import { Wrapper, Title, Subtitle, Label, DropDown } from './elements';

export default connect(
  {
    appLanguage: state`appContainer.language.locale`,
    languageSelected: state`admin.campaigns.edit.languageSelected`,
    dropdownSelected: sequences`admin.campaigns.edit.dropdownSelected`,
  },
  function Header({ appLanguage, languageSelected, dropdownSelected }) {
    const t = useText();

    return (
      <Wrapper>
        <Title>{t('admin.overview.header.campaign_links')}</Title>
        <Subtitle>{t('admin.overview.header.access_campaign_links')}</Subtitle>
        <Label>{t('admin.overview.header.language_expected')}</Label>
        <DropDown
          options={languageNames}
          placeholder={languageNames[appLanguage]}
          value={languageSelected?.optionValue ?? ''}
          onDropdownItemClick={option =>
            dropdownSelected({
              context: 'language',
              option,
            })
          }
        />
      </Wrapper>
    );
  }
);

import React from 'react';
import { connect } from '@cerebral/react';
import { Form } from 'forms';
import { state, sequences } from 'cerebral';
import {
  Wrapper,
  Title,
  Description,
  OptionDescription,
  RadioButton,
  EmailInput,
  AddLabel,
  EmailWrapper,
  Delete,
} from './elements';
import { useText } from 'common/hooks';
import Spa16 from 'dc/Spa16';
import Spa24 from 'dc/Spa24';
import Spa32 from 'dc/Spa32';
import Spa96 from 'dc/Spa96';
import { CAMPAIGN_ACCESS } from 'resources/constants';

export default connect(
  {
    form: state`admin.campaigns.create.access.form`,
    accessSettingSelected: sequences`admin.campaigns.create.access.accessSettingSelected`,
    emailDomainChanged: sequences`admin.campaigns.create.access.emailDomainChanged`,
    addNewEmailDomain: sequences`admin.campaigns.create.access.addNewEmailDomain`,
    removeEmailDomain: sequences`admin.campaigns.create.access.removeEmailDomain`,
  },
  function Access({
    form,
    accessSettingSelected,
    emailDomainChanged,
    addNewEmailDomain,
    removeEmailDomain,
    get,
  }) {
    const t = useText();
    const accessForm = new Form(form, get);
    const canDelete = Object.values(accessForm.emails || {}).length > 1;

    return (
      <Wrapper>
        <Spa32 />
        <Title>{t('admin.access.title')}</Title>
        <Spa16 />
        <Description>{t('admin.access.description')}</Description>
        <Spa32 />
        <RadioButton
          checked={accessForm.access.value === CAMPAIGN_ACCESS.LINK}
          label={t('admin.access.options.link.label')}
          onClick={() => accessSettingSelected({ value: CAMPAIGN_ACCESS.LINK })}
        />
        <RadioButton
          checked={accessForm.access.value === CAMPAIGN_ACCESS.EMAIL}
          label={t('admin.access.options.email.label')}
          onClick={() =>
            accessSettingSelected({ value: CAMPAIGN_ACCESS.EMAIL })
          }
        />
        <RadioButton
          checked={accessForm.access.value === CAMPAIGN_ACCESS.CODE}
          label={t('admin.access.options.code.label')}
          onClick={() => accessSettingSelected({ value: CAMPAIGN_ACCESS.CODE })}
        />
        <RadioButton
          checked={accessForm.access.value === CAMPAIGN_ACCESS.EMAIL_AND_CODE}
          label={t('admin.access.options.email_and_code.label')}
          onClick={() =>
            accessSettingSelected({ value: CAMPAIGN_ACCESS.EMAIL_AND_CODE })
          }
        />
        <OptionDescription>
          {t(`admin.access.options.${accessForm.access.value}.description`)}
        </OptionDescription>
        <Spa32 />
        {(accessForm.access.value === CAMPAIGN_ACCESS.EMAIL ||
          accessForm.access.value === CAMPAIGN_ACCESS.EMAIL_AND_CODE) && (
          <>
            {Object.values(accessForm.emails || {}).map((field, index) => (
              // eslint-disable-next-line react/jsx-key -- There are no good keys to use here
              <EmailWrapper>
                <EmailInput
                  value={field.value}
                  onChange={e =>
                    emailDomainChanged({
                      value: e.target.value,
                      field: index,
                    })
                  }
                  errorMessage={
                    field.errorMessage && t(field.errorMessage.phrase)
                  }
                  placeholder={t('admin.access.email.enter')}
                  icon={'icon-mail_outline'}
                />
                {canDelete && (
                  <Delete
                    icon={'icon-cancel'}
                    onClick={() => removeEmailDomain({ index })}
                  />
                )}
              </EmailWrapper>
            ))}
            <Spa24 />
            <AddLabel
              icon={'icon-add_circle'}
              color={'#03739A'}
              onClick={() => addNewEmailDomain()}
            >
              {t('admin.access.email.add')}
            </AddLabel>
          </>
        )}
        <Spa96 />
      </Wrapper>
    );
  }
);

import { useEffect } from 'react';

function hideFocusOutlineForNonKeyboardUsers() {
  const styleText =
    '::-moz-focus-inner{border:0 !important;}:focus{outline: none !important;}';
  const unfocus_style = document.createElement('STYLE');

  document.getElementsByTagName('HEAD')[0].appendChild(unfocus_style);

  document.addEventListener('mousedown', () => {
    unfocus_style.innerHTML = styleText;
  });
  document.addEventListener('keydown', () => {
    unfocus_style.innerHTML = '';
  });
}

export default function useOnLoad() {
  useEffect(() => {
    hideFocusOutlineForNonKeyboardUsers();
  }, []);
}

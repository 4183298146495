import { useContext } from 'react';
import { formatNumber } from 'utils/common';

import LanguageContext from '../LanguageContext';

export default function useFormatNumber() {
  const language = useContext(LanguageContext);

  return function format(data, precision = 0) {
    return formatNumber(data, { precision, locale: language.fullLocale });
  };
}

import styled from 'styled-components';

import TxtField06 from 'dc/TxtField06';

export const Input = styled(TxtField06)`
  background-color: #fff;
  width: 100%;
  > div {
    height: 48px;
    background: #fff;
    > i {
      margin-right: unset;
    }
    > input {
      background: #fff;
    }
  }
`;

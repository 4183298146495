import { state } from 'cerebral';
import topicData from 'toolData/topics';

export default get => {
  const allActivities = get(state`appContainer.activities`);
  const audience = get(state`admin.campaigns.create.focus.form.audience.value`);
  const topic = get(state`admin.campaigns.create.focus.form.topic.value`);

  if (!audience || !topic || Object.keys(allActivities).length === 0) return [];

  const { challenge } = topicData[audience][topic];

  if (!challenge) return [];

  const categories = {};

  challenge.forEach(activityKey => {
    const activityCategory = allActivities[activityKey].category;

    if (!(activityCategory in categories)) {
      categories[activityCategory] = [];
    }
    categories[activityCategory].push(activityKey);
  });

  return Object.entries(categories).map(([categoryKey, activityKeys]) => ({
    categoryKey,
    activityKeys,
  }));
};

import styled from 'styled-components';
import Ico24 from 'dc/Ico24';
import { Typ40 } from 'dc/Typ';

type ComponentLinkProps = {
  $selected: boolean;
};

export const ComponentLink = styled.a<ComponentLinkProps>`
  padding: 0 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 48px;
  background-color: ${props =>
    props.$selected ? 'rgba(0,0,0,0.03)' : 'rgba(0,0,0,0)'};
  &:hover {
    background-color: ${props =>
      props.$selected ? 'rgba(0,0,0,0.03)' : 'rgba(0,0,0,0.02)'};
  }
`;

type ComponentButtonProps = {
  $selected: boolean;
};

export const ComponentButton = styled.button<ComponentButtonProps>`
  padding: 0 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 48px;
  background-color: ${props =>
    props.$selected ? 'rgba(0,0,0,0.03)' : 'rgba(0,0,0,0)'};
  &:hover {
    background-color: ${props =>
      props.$selected ? 'rgba(0,0,0,0.03)' : 'rgba(0,0,0,0.02)'};
  }
`;

type IconProps = {
  $iconColor?: string;
};

export const Icon = styled(Ico24)<IconProps>`
  margin-right: 28px;
  color: ${props => (props.$iconColor ? props.$iconColor : '#607d8b')};
`;

type TextProps = {
  $textColor?: string;
};

export const Text = styled(Typ40)<TextProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => (props.$textColor ? props.$textColor : '#607d8b')};
`;

import styled from 'styled-components';
import { Typ30, Typ60 } from 'dc/Typ';

export const Wrapper = styled.div`
  width: 584px;
  margin: 0 auto;
`;

export const Header = styled(Typ60)`
  color: #546e7a;
`;

export const Paragraph = styled(Typ30)`
  margin: 16px 0;
  color: #546e7a;
  display: inline-block;
`;

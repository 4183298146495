import * as sequences from './sequences';
import { renderPage } from '../../../../factories';
import seriesModule from './modules/series';

export default {
  state: {
    isCreatingChampionship: false,
    isValid: false,
  },
  modules: {
    series: seriesModule,
  },
  sequences: {
    routed: renderPage('createChampionship', sequences.render),
    createChampionshipClicked: sequences.createChampionship,
  },
};

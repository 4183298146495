import styled from 'styled-components';

const addProps = props => ({ className: props.icon });
const color = props => props.color || '#607d8b';

type AttributeProps = {
  icon: string;
};

type AdditionalProps = {
  color?: string;
};

type Props = AttributeProps & AdditionalProps;

export const Ico10 = styled.i.attrs<AttributeProps>(addProps)<Props>`
  color: ${color};
  font-size: 10px;
`;

export const Ico12 = styled.i.attrs<AttributeProps>(addProps)<Props>`
  color: ${color};
  font-size: 12px;
`;

export const Ico16 = styled.i.attrs<AttributeProps>(addProps)<Props>`
  color: ${color};
  font-size: 16px;
`;

export const Ico20 = styled.i.attrs<AttributeProps>(addProps)<Props>`
  color: ${color};
  font-size: 20px;
`;

export const Ico24 = styled.i.attrs<AttributeProps>(addProps)<Props>`
  color: ${color};
  font-size: 24px;
`;

export const Ico36 = styled.i.attrs<AttributeProps>(addProps)<Props>`
  color: ${color};
  font-size: 36px;
`;

export const Ico48 = styled.i.attrs<AttributeProps>(addProps)<Props>`
  color: ${color};
  font-size: 48px;
`;

export const Ico72 = styled.i.attrs<AttributeProps>(addProps)<Props>`
  color: ${color};
  font-size: 72px;
`;

export const Ico96 = styled.i.attrs<AttributeProps>(addProps)<Props>`
  color: ${color};
  font-size: 96px;
`;

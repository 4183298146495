import styled from 'styled-components';
import { Typ41, Typ30 } from 'dc/Typ';
import Btn021 from 'dc/Btn021';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 46px;
`;

export const Title = styled(Typ41)`
  color: #546e7a;
  margin-bottom: 8px;
`;

export const Text = styled(Typ30)`
  color: #546e7a;
  margin-bottom: 18px;
`;

export const Button = styled(Btn021)`
  width: 220px;
`;

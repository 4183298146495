import styled from 'styled-components';
import { Typ60, Typ30, Typ31 } from 'dc/Typ';
import Btn021 from 'dc/Btn021';

export const Wrapper = styled.div`
  background-color: #fff;
  min-height: calc(100vh - 80px);
  border-bottom: 8px solid #455a64;
  padding: 80px;
  display: flex;
  justify-content: center;
  width: 100%;
  @media (max-width: 767px) {
    min-height: 500px;
    padding-top: 56px;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  flex: 1;
`;

export const Title = styled(Typ60)`
  margin-bottom: 16px;
`;

export const SubTitle = styled(Typ30)``;

export const TableHeader = styled.th`
  max-width: 150px;
  text-align: center;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
`;
export const TableData = styled.td`
  text-align: center;
`;

export const SmallHeading = styled(Typ31)`
  display: block;
  padding-bottom: 8px;
  padding-top: 16px;
`;

export const SmallBoldText = styled(Typ31)`
  padding-bottom: 4px;
`;

export const NewButton = styled(Btn021)`
  margin-top: 38px;
  margin-bottom: 70px;
  display: block;
`;

import styled from 'styled-components';
import Ico36 from 'dc/Ico36';
import ToolTip01 from 'dc/ToolTip01';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const Icon = styled(Ico36)`
  position: absolute;
  top: 0;
  left: -52px;
`;

export const ToolTip = styled(ToolTip01)`
  div div div {
    top: -48px;
    left: -69px;
  }
`;

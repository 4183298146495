import styled from 'styled-components';
import Act36 from 'dc/Act36';
import { Typ32 } from 'dc/Typ';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  ${props => (props.onClick ? 'cursor: pointer;' : null)};
  min-width: 0;
`;

export const ActivityIcon = styled(Act36)`
  margin-right: 16px;
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
`;

export const Title = styled(Typ32)`
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
`;

import React, { useRef } from 'react';
import { ElementWrapper, Checkbox, Label } from './elements';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  readonly className?: string;
  readonly disabled?: boolean;
  readonly checked: boolean;
  readonly onClick: () => void;
  readonly children: React.ReactNode;
};

export default function CheLab01({
  className,
  disabled = false,
  checked,
  onClick,
  children,
}: Props): React.ReactElement {
  const { current: id } = useRef(`chelab01-${uuidv4()}`);

  return (
    <ElementWrapper
      className={className}
      onClick={disabled ? undefined : onClick}
    >
      <Checkbox checked={checked} labelledby={id} disabled={disabled} />
      <Label id={id}>{children}</Label>
    </ElementWrapper>
  );
}

import * as sequences from './sequences';
import { renderPage } from '../../../../factories';
import structure from './structure';

import computedCampaignLinks from 'admin/onboarding/computed/campaignLinks';

export default {
  state: {
    isEdit: false,
    campaignKey: null,
    campaign: null,
    languageSelected: {
      optionKey: 'no',
      optionValue: 'Norsk',
    },
    checkedComparison: 'teams',
    sectionSelected: {
      optionKey: 'noSectionFilter',
      optionValue: 'All sections (default)',
    },
    totalStats: {},
    expectedParticipantCounts: {
      teams: {},
    },
    deleteCampaignModal: {
      showDeleteCampaignModal: false,
      isDeletingCampaign: false,
      verifyDeletionForm: {
        verifyText: {
          value: '',
          defaultValue: '',
          validationRules: ['isValue', 'equalToWord:DELETE||SLETT'],
          isRequired: true,
        },
      },
      isValidDeletionForm: false,
    },
    computedCampaignLinks,
  },
  modules: {
    structure: structure,
  },
  sequences: {
    routed: renderPage('createCampaign', sequences.render),
    dropdownSelected: sequences.dropdownSelected,
    copyToClipboard: sequences.copyToClipboard,
    comparisonOptionClicked: sequences.setComparisonOption,
    showDeleteCampaignModalClicked: sequences.showDeleteCampaignModalClicked,
    closeDeleteCampaignModalClicked: sequences.closeDeleteCampaignModalClicked,
    deleteFieldChanged: sequences.deleteFieldChanged,
    verifiedDeleteCampaign: sequences.verifiedDeleteCampaign,
  },
};

export default {
  _enum: 'activity_category',
  consumption: 'Consumption',
  energy: 'Energy',
  food: 'Food',
  foodWaste: 'FoodWaste',
  household: 'Household',
  plastic: 'Plastic',
  public: 'Public',
  sustainableFood: 'Sustainable Food',
  transport: 'Transport',
  tutorial: 'Tutorial',
  workTransport: 'Work Transport',
  handprint: 'Handprint',
};

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Wrapper, Title, DropdownInput, JoinButton } from './elements';

import Spa32 from 'dc/Spa32';

export default connect(
  {
    championships: state`admin.organisations.edit.championships`,
    selectedChampionship: state`admin.organisations.edit.selectedChampionship`,
    championshipDropdownIsOpen: state`admin.organisations.edit.championshipDropdownIsOpen`,
    isJoiningChampionship: state`admin.organisations.edit.isJoiningChampionship`,
    championshipOptionClicked: sequences`admin.organisations.edit.championshipOptionClicked`,
    toggleChampionshipDropdownClicked: sequences`admin.organisations.edit.toggleChampionshipDropdownClicked`,
    joinChampionship: sequences`admin.organisations.edit.joinChampionship`,
  },
  function JoinChampionship({
    championships,
    selectedChampionship,
    championshipDropdownIsOpen,
    isJoiningChampionship,
    championshipOptionClicked,
    toggleChampionshipDropdownClicked,
    joinChampionship,
  }) {
    return (
      <Wrapper>
        <Spa32 />
        <Title>{'Join Championship'}</Title>
        <DropdownInput
          placeholder={'Select a championship to join'}
          options={championships}
          value={selectedChampionship ? selectedChampionship.optionValue : ''}
          onClick={event => {
            event.stopPropagation();
            toggleChampionshipDropdownClicked();
          }}
          onDropdownItemClick={option => championshipOptionClicked({ option })}
          dropdownVisible={championshipDropdownIsOpen}
        />
        <JoinButton
          disabled={
            isJoiningChampionship ||
            Object.keys(selectedChampionship).length === 0
          }
          onClick={() => joinChampionship()}
        >
          {'Join this championship'}
        </JoinButton>
      </Wrapper>
    );
  }
);

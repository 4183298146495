import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { useText } from 'common/hooks';

import { Wrapper, Title, Text, SearchDropdown } from './elements';
import { ChampionshipLabel } from 'admin/onboarding/App/Campaigns/CreateCampaign/elements';
import Spa32 from 'dc/Spa32';
import Load04 from 'dc/Load04';

export default connect(
  {
    organisationNames: state`admin.campaigns.computedOrganisationNames`,
    campaignTypeForm: state`admin.campaigns.create.organisation.form`,
    isJoiningChampionship: state`admin.campaigns.create.isJoiningChampionship`,
    showOrgDropdown: state`admin.campaigns.create.organisation.showOrgDropdown`,
    organisationFieldChanged: sequences`admin.campaigns.create.organisation.organisationFieldChanged`,
    organisationSelected: sequences`admin.campaigns.create.organisation.organisationSelected`,
    showDropdown: sequences`admin.campaigns.create.organisation.showDropdown`,
    toggleShowDropdown: sequences`admin.campaigns.create.organisation.toggleShowDropdown`,
  },
  function Organisation({
    organisationNames,
    campaignTypeForm,
    isJoiningChampionship,
    showOrgDropdown,
    organisationSelected,
    organisationFieldChanged,
    showDropdown,
    toggleShowDropdown,
  }) {
    const t = useText();

    if (organisationNames.length > 0) {
      return (
        <Wrapper>
          {isJoiningChampionship ? (
            <ChampionshipLabel icon={'icon-visibility'} color={'#03739A'}>
              {t('championship.view_only')}
            </ChampionshipLabel>
          ) : null}
          <Spa32 />
          <Title>{t('admin.type.choose_organiser')}</Title>
          <Text>{t('admin.type.organised_by')}</Text>
          <SearchDropdown
            isJoiningChampionship={isJoiningChampionship}
            placeholder={t('admin.type.select_org')}
            options={organisationNames}
            value={campaignTypeForm.organisation.value}
            onClick={event => {
              isJoiningChampionship ? null : event.stopPropagation();
            }}
            onFocus={() => {
              isJoiningChampionship ? null : showDropdown();
            }}
            onChange={event => {
              isJoiningChampionship
                ? null
                : organisationFieldChanged({
                    value: event.target.value,
                  });
            }}
            onIconClick={event => {
              isJoiningChampionship
                ? null
                : [event.stopPropagation(), toggleShowDropdown()];
            }}
            onDropdownItemClick={option => {
              organisationSelected({ value: option });
            }}
            dropdownVisible={showOrgDropdown}
          />
        </Wrapper>
      );
    }

    return <Load04 />;
  }
);

import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import {
  OuterWrapper,
  PlaceholderLabel,
  Input,
  NumberCharacters,
  ErrorMessage,
} from './elements';

function TxtField03(props) {
  const [focused, setFocused] = useState(false);

  return (
    <div className={props.className}>
      <OuterWrapper>
        <PlaceholderLabel
          version={props.version}
          focus={!props.placeholder?.length && (focused || props.value?.length)}
        >
          {props.placeholder?.length ? '' : props.label}
        </PlaceholderLabel>
        <Input
          value={props.value}
          placeholder={props.placeholder}
          version={props.version}
          type={'text'}
          maxLength={props.maxLength}
          onChange={props.onChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </OuterWrapper>
      {!props.errorMessage && props.maxLength && (
        <NumberCharacters errorMessage={props.errorMessage}>
          {`${props.value?.length ?? 0}/${props.maxLength}`}
        </NumberCharacters>
      )}
      <ErrorMessage errorMessage={props.errorMessage}>
        {props.errorMessage}
      </ErrorMessage>
    </div>
  );
}

TxtField03.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  version: PropTypes.string,
};

export default TxtField03;

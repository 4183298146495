import styled from 'styled-components';
import Ico24 from 'dc/Ico24';

export const IndInfoWrapper = styled.div`
  display: ${props => (props.hide ? 'none' : 'block')};
  padding: 16px;
  ul {
    padding-left: 31px;
  }
  ul li {
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    color: #37474f;
    overflow-wrap: break-word;
  }
`;

export const IndInfoClose = styled.div`
  width: 100%;
  height: 36px;
  display: ${props => (props.hide ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  background-color: #eceff1;
  cursor: pointer;
`;

export const InfoCloseIcon = styled(Ico24)``;

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Wrapper,
  Title,
  InputWrapper,
  Label,
  DropDown,
  TextArea,
  Paragraph,
} from './elements';
import { ChampionshipLabel } from 'admin/onboarding/App/Campaigns/CreateCampaign/elements';
import { useText } from 'common/hooks';
import {
  ICON,
  CHALLENGE_WINNER_MODE,
  COMPETITION_INDICATOR,
  PARTICIPATION_RATE,
  BACKLOG_DAY,
} from 'resources/constants';

export default connect(
  {
    isJoiningChampionship: state`admin.campaigns.create.isJoiningChampionship`,
    isJoiningSeries: state`admin.campaigns.create.isJoiningSeries`,
    series: state`admin.campaigns.create.series.createdSeries`,
    dropdownSelected: sequences`admin.campaigns.create.dropdownSelected`,
    fieldChanged: sequences`admin.campaigns.create.fieldChanged`,
    indicatorSelected: state`admin.campaigns.create.config.indicatorSelected`,
    seriesSelected: state`admin.campaigns.create.config.seriesSelected`,
    winningParticipationPercentageSelected: state`admin.campaigns.create.challenge.winningParticipationPercentageSelected`,
    winningModeSelected: state`admin.campaigns.create.challenge.winningModeSelected`,
    backlogDaysSelected: state`admin.campaigns.create.challenge.backlogDaysSelected`,
    selectedPrice: state`admin.campaigns.create.challenge.form.prize.value`,
  },
  function Terms({
    isJoiningChampionship,
    isJoiningSeries,
    series,
    dropdownSelected,
    fieldChanged,
    indicatorSelected,
    seriesSelected,
    winningParticipationPercentageSelected,
    winningModeSelected,
    backlogDaysSelected,
    selectedPrice,
  }) {
    const t = useText();

    return (
      <Wrapper>
        {isJoiningChampionship && (
          <ChampionshipLabel icon={'icon-mode_edit'} color={'#03739A'}>
            {t('championship.partly_editable')}
          </ChampionshipLabel>
        )}
        <Title>{t('admin.challenge.terms.set_up_terms')}</Title>

        <InputWrapper>
          <Label>{t('admin.challenge.terms.participants_compete_on')}</Label>
          <DropDown
            disabled={isJoiningChampionship}
            options={Object.fromEntries(
              Object.keys(COMPETITION_INDICATOR).map(type => [
                type,
                t(`admin.challenge.terms.compete_on.${type}`),
              ])
            )}
            onDropdownItemClick={option =>
              dropdownSelected({
                path: 'admin.campaigns.create.config',
                optionType: 'indicator',
                option,
              })
            }
            value={
              indicatorSelected &&
              t(
                `admin.challenge.terms.compete_on.${indicatorSelected.optionKey}`
              )
            }
          />
        </InputWrapper>
        <InputWrapper>
          <Label>{t('admin.challenge.terms.participation_rate')}</Label>
          <DropDown
            disabled={isJoiningChampionship}
            options={Object.fromEntries(
              Object.keys(PARTICIPATION_RATE).map(participationRate => [
                participationRate,
                t(
                  `admin.challenge.terms.participation_rates_choices.${participationRate}`
                ),
              ])
            )}
            onDropdownItemClick={option =>
              dropdownSelected({
                path: 'admin.campaigns.create.challenge',
                optionType: 'winningParticipationPercentage',
                option,
              })
            }
            value={
              winningParticipationPercentageSelected &&
              t(
                `admin.challenge.terms.participation_rates_choices.${winningParticipationPercentageSelected.optionKey}`
              )
            }
          />
        </InputWrapper>
        <InputWrapper>
          <Label>{t('admin.challenge.terms.who_win_challenge')}</Label>
          <DropDown
            disabled={isJoiningChampionship}
            options={Object.fromEntries(
              Object.keys(CHALLENGE_WINNER_MODE).map(winMode => [
                winMode,
                t(`admin.challenge.terms.winner_selection.${winMode}`),
              ])
            )}
            onDropdownItemClick={option =>
              dropdownSelected({
                path: 'admin.campaigns.create.challenge',
                optionType: 'winningMode',
                option,
              })
            }
            value={
              winningModeSelected &&
              t(
                `admin.challenge.terms.winner_selection.${winningModeSelected.optionKey}`
              )
            }
          />
        </InputWrapper>
        <InputWrapper>
          <Label>{t('admin.challenge.terms.number_days_backlog')}</Label>
          <DropDown
            disabled={isJoiningChampionship}
            options={Object.fromEntries(
              Object.keys(BACKLOG_DAY).map(backlogDay => [
                backlogDay,
                t(`admin.challenge.terms.days_backlog.${backlogDay}`),
              ])
            )}
            onDropdownItemClick={option =>
              dropdownSelected({
                path: 'admin.campaigns.create.challenge',
                optionType: 'backlogDays',
                option,
              })
            }
            value={
              backlogDaysSelected &&
              t(
                `admin.challenge.terms.days_backlog.${backlogDaysSelected.optionKey}`
              )
            }
          />
        </InputWrapper>
        <Paragraph as={'p'}>
          {t('admin.challenge.terms.prize_shown_on_challenge')}
        </Paragraph>
        <InputWrapper>
          <Label>{t('admin.challenge.terms.prize_description')}</Label>
          <TextArea
            onChange={event => {
              fieldChanged({
                field: 'admin.campaigns.create.challenge.form.prize',
                value: event.target.value,
              });
            }}
            value={selectedPrice}
            isLocked={false}
            placeholder={t('admin.challenge.terms.prize_recommendations')}
            icon={ICON.modeEdit}
          />
        </InputWrapper>
        {isJoiningSeries && (
          <InputWrapper>
            <Label>{t('admin.challenge.terms.what_series')}</Label>
            <DropDown
              placeholder={'Select series'}
              options={Object.fromEntries(
                Object.keys(series).map(seriesKey => [
                  seriesKey,
                  series[seriesKey].name,
                ])
              )}
              onDropdownItemClick={option =>
                dropdownSelected({
                  path: 'admin.campaigns.create.config',
                  optionType: 'series',
                  option,
                })
              }
              value={seriesSelected && seriesSelected.optionValue}
            />
          </InputWrapper>
        )}
      </Wrapper>
    );
  }
);

import { set, toggle } from 'cerebral/factories';
import { state, props } from 'cerebral';
import { value } from 'providers/Firebase/src/operators';

import * as actions from './actions';

export const render = [
  value('campaigns.list'),
  actions.formatCampaigns,
  actions.getOrganisations,
  actions.setOrganisationNames,
  set(state`admin.campaigns.list`, props`campaignList`),
];

export const toggleShowFinished = [toggle(state`admin.campaigns.showFinished`)];

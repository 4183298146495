import { Compute, state } from 'cerebral';
import { primaryLanguage, languageNames } from 'languages/config';

const getDomainString = (domain, lang) =>
  lang === primaryLanguage ? domain : `${domain}/${lang}`;

const createPublicScreensObject = (domain, sections, challengeKey, language) =>
  Object.entries(sections).map(([sectionKey, section]) => ({
    name: section.name,
    link: `https://widgets.${getDomainString(
      domain,
      language
    )}/public/challenge/${challengeKey}/${sectionKey}`,
  }));

const createChallengeWidgetObject = (domain, challengeKey, language) => ({
  name: 'challenge',
  link: `<iframe scrolling="no" src="https://widgets.${getDomainString(
    domain,
    language
  )}/challenge/${challengeKey}" style="min-height: 1380px;" title="challengeWidget" width="100%"></iframe>`,
});

const widgetsCreation = {
  challengeWidget: createChallengeWidgetObject,
};

export default Compute(get => {
  const campaign = get(state`admin.campaigns.edit.campaign`);
  const appConfig = get(state`appContainer.config`);
  const section = get(state`admin.campaigns.edit.sectionSelected`);
  const checkedComparison = get(state`admin.campaigns.edit.checkedComparison`);

  let links = {};
  if (campaign) {
    links = Object.assign(
      {},
      ...Object.keys(languageNames).map(language => {
        let publicScreens = [],
          statistics = [],
          widgets = [];
        const langDomain = getDomainString(appConfig.domain, language);
        const { integration, config, tools, id } = campaign;
        const reports = {
          report: {
            url: `${
              appConfig.cloudFunctionUrl
            }/requests-admin-createCampaignReport?campaignKey=${id}&aggregationLevel=${checkedComparison}&language=${language}&campaignKeyFilter=${id}${
              section.optionKey !== 'noSectionFilter'
                ? `&sectionKeyFilter=${section.optionKey}`
                : ''
            }`,
          },
          excel: {
            url: `${
              appConfig.cloudFunctionUrl
            }/requests-admin-createCampaignReport?campaignKey=${id}&aggregationLevel=${checkedComparison}&language=${language}&campaignKeyFilter=${id}${
              section.optionKey !== 'noSectionFilter'
                ? `&sectionKeyFilter=${section.optionKey}`
                : ''
            }&fileType=excel`,
          },
        };
        if (integration) {
          publicScreens =
            integration.liveScreen && tools.challengeKey
              ? createPublicScreensObject(
                  appConfig.domain,
                  campaign.structure.sections,
                  tools.challengeKey,
                  language
                )
              : [];

          widgets = Object.keys(integration)
            .filter(key => integration[key] && key.includes('Widget'))
            .map(key => {
              const toolKey = `${key.split('W')[0]}Key`; // DON'T LOOK AT ME!!! I'M HIDEOUS!!! -----------------------> But I turn toolWidget into toolKey, and that's what I wanted

              return widgetsCreation?.[key]?.(
                appConfig.domain,
                tools?.[toolKey],
                language
              );
            })
            .filter(widget => widget);
        }
        if (tools.challengeKey) {
          statistics = [
            {
              name: 'nps',
              url: `${appConfig.cloudFunctionUrl}/requests-admin-createNPS?challengeKey=${tools.challengeKey}`,
            },
            {
              name: 'leaderboard',
              url: `${appConfig.cloudFunctionUrl}/requests-admin-createLeaderboard?challengeKey=${tools.challengeKey}&language=${language}`,
            },
            {
              name: 'client',
              url: `${appConfig.cloudFunctionUrl}/requests-admin-createClientStats?challengeKey=${tools.challengeKey}`,
            },
            {
              name: 'participation',
              url: `${appConfig.cloudFunctionUrl}/requests-admin-createParticipationStats?challengeKey=${tools.challengeKey}`,
            },
          ];
        }

        return {
          [language]: {
            links: {
              join: [
                {
                  link: `https://app.${langDomain}/joinCampaign?link=${campaign.id}`,
                },
              ],
              public: publicScreens,
              widgets: widgets,
            },
            info: { domains: campaign.domains || {} },
            downloads: {
              reports,
              statistics,
              codes: {
                url:
                  (config.access === 'code' ||
                    config.access === 'email_and_code') &&
                  `${appConfig.cloudFunctionUrl}/requests-admin-createCampaignCodesPDF?campaignKey=${campaign.id}&language=${language}`,
              },
            },
          },
        };
      })
    );
  }

  return links;
});

import activityConsumption from './activityConsumption';
import activityEnergy from './activityEnergy';
import activityFood from './activityFood';
import activityHandprint from './activityHandprint';
import activityTransport from './activityTransport';
import activityWorkTransport from './activityWorkTransport';
import activityTutorial from './activityTutorial';

export default Object.assign(
  activityConsumption,
  activityEnergy,
  activityFood,
  activityHandprint,
  activityTransport,
  activityWorkTransport,
  activityTutorial,
  {
    placeholder: {
      title: 'Try something new next week!',
      description: '',
    },
    learn_more: 'learn more',
    no_longer_available: 'Activity no longer available',
  }
);

import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import createApp from './controller';
import App from './App';
import { useOnLoad } from 'common/hooks';

const createdApp = createApp();

function mountApp(app: any): void {
  function AppWithCallbackAfterRender() {
    useOnLoad();
    useEffect(() => {
      document.getElementById('loader').classList.add('loaded');
    });

    return <App app={app} />;
  }

  const container = document.querySelector('#app');
  const root = createRoot(container);

  root.render(<AppWithCallbackAfterRender />);
}

export default mountApp(createdApp);

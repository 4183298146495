import React from 'react';
import * as PropTypes from 'prop-types';
import { ElementWrapper, Icon, TitleText } from './elements';

function Lab400(props) {
  return (
    <ElementWrapper className={props.className} onClick={props.onClick}>
      <TitleText color={props.textColor}>{props.children}</TitleText>
      <Icon icon={props.icon} color={props.iconColor} />
    </ElementWrapper>
  );
}

Lab400.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.node,
};

export default Lab400;

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { useText } from 'common/hooks';

import { Wrapper, SectionWrapper, Title, Text, Button } from './elements';

export default connect(
  {
    linksData: state`admin.campaigns.edit.computedCampaignLinks`,
    languageSelected: state`admin.campaigns.edit.languageSelected.optionKey`,
    openWithToken: sequences`admin.openWithToken`,
  },
  function Info({ linksData, languageSelected, openWithToken }) {
    const t = useText();

    return (
      <Wrapper>
        {Object.keys(linksData?.[languageSelected]?.info?.domains ?? {})
          .length > 0 && (
          <SectionWrapper>
            <Title>{t(`admin.overview.info.authorized_domains`)}</Title>
            <Text>{t(`admin.overview.info.access_via_domain`)}</Text>
            {Object.keys(linksData[languageSelected].info.domains).map(
              emailDomain => (
                <Text key={emailDomain}>{emailDomain.replace(/_/gu, '.')}</Text>
              )
            )}
          </SectionWrapper>
        )}
        {linksData?.[languageSelected]?.downloads?.codes?.url && (
          <SectionWrapper>
            <Title>{t(`admin.overview.info.codes`)}</Title>
            <Text>{t(`admin.overview.info.access_via_codes`)}</Text>
            <Button
              onClick={() =>
                openWithToken({
                  url: linksData[languageSelected].downloads.codes.url,
                })
              }
            >
              {t(`admin.overview.info.download_now`)}
            </Button>
          </SectionWrapper>
        )}
        {Object.values(
          linksData?.[languageSelected]?.downloads?.statistics ?? {}
        ).map(statistics => (
          <SectionWrapper key={statistics.name}>
            <Title>{t(`admin.overview.info.${statistics.name}`)}</Title>
            <Text>
              {t(`admin.overview.info.${statistics.name}_description`)}
            </Text>
            <Button
              onClick={() =>
                openWithToken({
                  url: statistics.url,
                })
              }
            >
              {t(`admin.overview.info.download_now`)}
            </Button>
          </SectionWrapper>
        ))}
      </Wrapper>
    );
  }
);

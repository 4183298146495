import styled from 'styled-components';

function getPadding(props) {
  if (props.$show) {
    if (props.$mobile) {
      return '53px';
    }

    return '16px';
  }

  return '-150px';
}

type WrapperProps = {
  $mobile?: boolean;
  $show?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  max-width: 400px;
  background-color: inherit;
  z-index: 100000;
  color: #fff;
  text-align: center;
  position: fixed;
  margin: 16px;
  font-size: 17px;
  transition:
    bottom 0.25s ease-out,
    opacity,
    0.5s ease-in;
  bottom: ${getPadding};
  opacity: ${props => (props.$show ? 1 : 0)};
  visibility: ${props => (props.$show ? 'visible' : 'hidden')};
`;

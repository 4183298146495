import styled from 'styled-components';
import Lab115B from '../Lab115B';
import Ico24 from '../Ico24';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  height: 78px;
  width: 100%;
  background: #fff;
  &:after {
    position: absolute;
    left: 5%;
    bottom: 0;
    height: 1px;
    width: 90%;
    border-bottom: 1px solid #cfd8dc;
    ${props =>
      !props.showInfoButton ? null : props.last ? null : "content: '';"};
  }
`;

export const Info = styled(Lab115B)`
  h3 {
    color: #455a64;
  }
  span {
    color: #78909c;
  }
`;

export const InfoIcon = styled(Ico24)``;

import React from 'react';
import { ComponentLink, ComponentButton, Text, Icon } from './elements';

type MIt01Props = {
  readonly href?: string;
  readonly className?: string;
  readonly selected?: boolean;
  readonly color: string;
  readonly icon: string;
  readonly textColor?: string;
  readonly caption: string;
  readonly onClick?: () => void;
};

export default function MIt01({
  href,
  className,
  selected,
  color,
  icon,
  textColor,
  caption,
  onClick,
}: MIt01Props) {
  return href ? (
    <ComponentLink className={className} href={href} $selected={selected}>
      <Icon $iconColor={color} icon={icon} />
      <Text $textColor={textColor}>{caption}</Text>
    </ComponentLink>
  ) : (
    <ComponentButton
      className={className}
      onClick={onClick}
      $selected={selected}
    >
      <Icon $iconColor={color} icon={icon} />
      <Text $textColor={textColor}>{caption}</Text>
    </ComponentButton>
  );
}

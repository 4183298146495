import React from 'react';
import * as PropTypes from 'prop-types';
import { Wrapper, Info, InfoIcon } from './elements';

function ActInd01(props) {
  return (
    <Wrapper
      className={props.className}
      showInfoButton={props.showInfoButton}
      last={props.last}
      onClick={props.expandable ? props.onClick : null}
    >
      <Info
        label={props.label}
        value={props.value}
        icon={props.icon}
        color={props.color}
      />
      {props.expandable ? (
        <InfoIcon
          icon={
            props.showInfoButton
              ? 'icon-keyboard_arrow_down'
              : 'icon-keyboard_arrow_up'
          }
          color={'#90A4AE'}
        />
      ) : null}
    </Wrapper>
  );
}

ActInd01.propTypes = {
  className: PropTypes.string,
  showInfoButton: PropTypes.bool,
  last: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.node,
  color: PropTypes.string,
};

export default ActInd01;

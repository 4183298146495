import styled, { css } from 'styled-components';
import { Typ30, Typ41 } from 'dc/Typ';

export const InnerWrapper = styled.div`
  margin: 16px 32px 0 32px;
  ${props =>
    props.theme.isMobile &&
    css`
      flex-grow: 1;
    `}
`;

export const Body = styled(Typ30)``;

export const Title = styled(Typ41)`
  display: block;
  margin-bottom: 16px;
`;

import styled from 'styled-components';
import Lab010 from 'dc/Lab010';

export const Wrapper = styled.div``;

export const Co2Label = styled(Lab010)`
  > i {
    color: #00ab97;
  }
`;

import styled from 'styled-components';
import Ico96 from '../Ico96';
import Ico24 from '../Ico24';

type WrapperProps = {
  $height?: string;
  $width?: string;
  $image?: string;
};

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => (props.$height ? props.$height : '180px')};
  width: ${props => (props.$width ? props.$width : '180px')};
  background: ${props =>
    props.$image ? `url(${props.$image}) no-repeat` : '#f4f7f9'};
  background-size: cover;
  border: 2px dashed #cfd8dc;
  box-sizing: border-box;
  border-radius: 4px;
  &:hover {
    border: 2px dashed #03739a;
  }
  cursor: pointer;
`;

export const BackgroundIcon = styled(Ico96)`
  color: #cfd8dc;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #03739a;
`;

export const Icon = styled(Ico24)`
  color: #fff;
`;

export const InputButton = styled.label`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  border: 0;
  &:disabled {
    cursor: auto;
  }
`;

export const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

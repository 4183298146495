import styled from 'styled-components';
import { Typ30, Typ60 } from 'dc/Typ';
import SwiLab02 from 'dc/SwiLab02';

const grey600 = '#546E7A';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 148px;
`;

export const Title = styled(Typ60)`
  color: ${grey600};
  margin-bottom: 16px;
`;

export const Description = styled(Typ30)`
  color: ${grey600};
`;

export const RadiosLabel = styled(Typ30)`
  color: ${grey600};
  padding-bottom: 8px;
  border-bottom: 1px solid #90a4ae;
  margin-top: 32px;
`;

export const RadioInput = styled(SwiLab02)`
  margin-top: 20px;
  margin-left: 12px;
`;

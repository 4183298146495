export default {
  manage: {
    manage_championships: 'Manage championships',
    set_up_new: 'Set up a new championship or edit an existing one',
    add_new: 'Add new championship',
    show_finished: 'Show finished championships',
    hide_finished: 'Hide finished championships',
  },
  publish: {
    can_not_edit: 'CAN NOT CURRENTLY EDIT CHAMPIONSHIPS.',
    turn_back: "TURN BACK NOW, BEFORE IT'S TOO LATE!",
  },
  series: {
    series_required: 'Additional series level required?',
    no_series: 'No series level required',
    yes_series: 'Yes, add additional series level',
    series_is_added_level:
      'Series is an added level for multiple organisations that may be needed to group campaigns into counties, municipalities or different types of organizations.',
    competition_is_visible:
      'Competition between series is visible in the Championship widget and end report. Any terms defined here will help users connect better to the competition in these places.',
    series_term: 'What term describes the series level?',
    select_term: 'Select series term',
    series_terms: {
      company_size: 'Company size',
      cities: 'Cities',
      counties: 'Counties',
      industries: 'Industries',
    },
    enter_series_name: 'Enter series name',
    add_another: 'Add another',
  },
};

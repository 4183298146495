import styled from 'styled-components';
import IcoW01 from 'dc/IcoW01';
import ActMod13 from 'dc/ActMod13';

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
`;

export const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  width: 100%;
  background: transparent;
`;

export const CloseIcon = styled(IcoW01)`
  cursor: pointer;
  color: #607d8b;
`;

export const AdminMod13 = styled(ActMod13)`
  button {
    display: none;
  }
`;

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Wrapper,
  Title,
  Description,
  Label,
  ImageWrapper,
  ImageText,
  HorizontalRule,
} from './elements';
import TextInput from './TextInput';
import EditableComponent from '../EditableComponent';
import { ChampionshipLabel } from 'admin/onboarding/App/Campaigns/CreateCampaign/elements';
import { useText } from 'common/hooks';
import Spa16 from 'dc/Spa16';
import Spa32 from 'dc/Spa32';
import Spa40 from 'dc/Spa40';
import Spa56 from 'dc/Spa56';

import ImageUpload from '../../../../common/ImageUpload';

import DateInput from './DateInput';
import TextArea from './TextArea';
import RegionAndTimezone from './RegionAndTimezone';
import SectionTerm from './SectionTerm';

export default connect(
  {
    uploadedImages: state`admin.campaigns.create.info.uploadedImages`,
    isJoiningChampionship: state`admin.campaigns.create.isJoiningChampionship`,
    campaignImageSelected: sequences`admin.campaigns.create.info.campaignImageSelected`,
    campaignImageRemoved: sequences`admin.campaigns.create.info.campaignImageRemoved`,
  },
  function Info({
    uploadedImages,
    isJoiningChampionship,
    campaignImageSelected,
    campaignImageRemoved,
  }) {
    const t = useText();

    return (
      <Wrapper>
        {isJoiningChampionship ? (
          <ChampionshipLabel icon={'icon-mode_edit'} color={'#03739A'}>
            {t('championship.partly_editable')}
          </ChampionshipLabel>
        ) : null}
        <Spa32 />
        <Title>{t('admin.info.title')}</Title>
        <Spa16 />
        <Description>{t('admin.info.description')}</Description>
        <TextInput
          width={'320px'}
          disabled={isJoiningChampionship}
          maxLength={30}
          field={'name'}
          placeholder={t('admin.info.enter_name')}
        />
        <Spa40 />
        <Label>{t('admin.info.tagline')}</Label>
        <TextInput
          disabled={isJoiningChampionship}
          maxLength={140}
          field={'tagline'}
          placeholder={t('admin.info.call_to_action')}
        />
        <Spa32 />
        <Label>{t('admin.info.campaign_description')}</Label>
        <TextArea
          disabled={isJoiningChampionship}
          field={'description'}
          placeholder={t('admin.info.introduce_teams')}
        />
        <Spa32 />
        <Label>{t('admin.info.goal_description')}</Label>
        <TextArea
          disabled={isJoiningChampionship}
          field={'goal'}
          placeholder={t('admin.info.main_goals')}
        />
        <Spa56 />
        <EditableComponent isJoiningChampionship={isJoiningChampionship}>
          <ImageWrapper>
            <ImageUpload
              height={'180px'}
              width={'320px'}
              image={uploadedImages.desktop ? uploadedImages.desktop.url : null}
              onAdded={campaignImageSelected}
              onClick={() => campaignImageRemoved()}
            />
            <ImageText>{t('admin.info.upload_photo')}</ImageText>
          </ImageWrapper>
        </EditableComponent>
        <Spa56 />
        <HorizontalRule />
        <Label>{t('admin.info.campaign_url')}</Label>
        <TextInput
          disabled={isJoiningChampionship}
          maxLength={140}
          field={'campaignPageUrl'}
          placeholder={t('admin.info.your_page')}
        />
        <Spa56 />
        <HorizontalRule />
        <Label>{t('admin.info.start_date')}</Label>
        <DateInput disabled={isJoiningChampionship} field={'startDate'} />
        <Spa32 />
        <Label>{t('admin.info.end_date')}</Label>
        <DateInput disabled={isJoiningChampionship} field={'endDate'} />
        <Spa56 />
        <HorizontalRule />
        <RegionAndTimezone isJoiningChampionship={isJoiningChampionship} />
        <Spa40 />
        <Description>{t('admin.info.selecting_timezone')}</Description>
        <HorizontalRule />
        <SectionTerm disabled={isJoiningChampionship} />
        <Spa56 />
      </Wrapper>
    );
  }
);

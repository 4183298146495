import * as sequences from './sequences';
import createModule from './modules/create';
import editModule from './modules/edit';
import { renderPage } from '../../factories';

import computedHasTeamInAllSections from 'admin/onboarding/computed/hasTeamInAllSections';
import computedHasTeamWithTeamLeaderEmail from 'admin/onboarding/computed/hasTeamWithTeamLeaderEmail';
import computedAvailableActivitiesByCategory from 'admin/onboarding/computed/availableActivitiesByCategories';
import computedTimezones from 'admin/onboarding/computed/timezones';
import computedOrganisationNames from 'admin/onboarding/computed/organisationNames';

export default {
  state: {
    list: [],
    showFinished: false,
    isLoading: true,
    computedHasTeamInAllSections,
    computedHasTeamWithTeamLeaderEmail,
    computedAvailableActivitiesByCategory,
    computedTimezones,
    computedOrganisationNames,
  },
  modules: {
    create: createModule,
    edit: editModule,
  },
  sequences: {
    routed: renderPage('campaigns', sequences.render),
    toggleShowFinished: sequences.toggleShowFinished,
  },
};

export function filterOrganisations({ state, props }) {
  const organisations = state.get(
    'admin.campaigns.create.organisation.organisations'
  );
  const organisation = state.get(
    'admin.campaigns.create.organisation.form.organisation.value'
  );
  const formValue = props.value;
  const filteredOrganisations = {};

  Object.keys(organisations).forEach(orgKey => {
    if (organisations[orgKey].name.indexOf(formValue) !== -1) {
      filteredOrganisations[orgKey] = organisations[orgKey];
      if (organisation && organisation === filteredOrganisations[orgKey].name) {
        state.set(
          'admin.campaigns.create.organisation.organisationKey',
          orgKey
        );
      }
    }
  });

  state.set(
    'admin.campaigns.create.organisation.filteredOrganisations',
    filteredOrganisations
  );
}

import React from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';

import { Wrapper } from './elements';
import Create from './Create';
import Header from './Header';
import Links from './Links';
import Info from './Info';
import Delete from './Delete';
import CampaignReport from './CampaignReport';

export default connect(
  {
    linksData: state`admin.campaigns.edit.computedCampaignLinks`,
  },
  function Publish({ linksData }) {
    return Object.keys(linksData).length === 0 ? (
      <Wrapper>
        <Create />
      </Wrapper>
    ) : (
      <Wrapper>
        <Header />
        <Links />
        <CampaignReport />
        <Info />
        <Delete />
      </Wrapper>
    );
  }
);

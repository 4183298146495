export default {
  activity_transport_01: {
    title: 'I walked or biked to work today',
    description:
      "Log on days you mostly use pure muscle power like walking, skateboarding, biking or other forms of transport which don't involve electricity or gas to get to work.",
    co2_explanation: `MARKDOWN:
## The facts
- By cycling or walking you can reduce the number of cars on the road, thus reducing pollution, noise levels, dust and toxic gases while contributing to a more beautiful urban environment. 
- Cycling is a time-efficient mode of transport, as it replaces time spent sitting, driving or using trams, trains or buses with healthy exercise.

## The assumptions
- The saving is calculated based on the carbon emissions produced by an average person commuting to work in your country.
- From national transport surveys we calculate the distance to work and means of transport for the average inhabitant, allowing us to calculate the carbon emissions generated by the commute. The same data is used in our personal footprint calculator.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- Cycling is healthy, fun and a low-impact form of exercise for all ages, and can decrease anxiety, stress and depression.
- Cycling can help to protect you from serious diseases such as stroke, heart attack, some cancers, depression, diabetes, and obesity. 
- With this activity you will most likely have a better start of the day and get valuable exercise.`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- The biggest source of microplastics in Norway is car tire wear. That's because tires consist of approx 60% synthetic material. Car tires emit 5000 tonnes of microplastics per year, and approx half of this ends up in the sea. So reducing car use is a major way of reducing microplastics emissions!`,
  },
  activity_transport_02: {
    title: 'I took public transport to work today',
    description:
      'Log on days you mostly use public transportation such as a bus, tram or train to get to to work.',
    co2_explanation: `MARKDOWN:
## The facts
- Many cities around the world have banned cars in their city centers, leading to reduced emissions, better air quality, more life in the city center and less accidents. 

## The assumptions 
- The saving is calculated based on the difference between the carbon emissions of an average person in your country commuting to work and a person who uses public transport to get to work. 
- From national transport surveys we calculate the distance to work and means of transport for the average inhabitant, allowing us to calculate the carbon emissions generated by the commute. The same data is used in our personal footprint calculator.

## Additional info
- [https://www.theguardian.com/cities/2018/sep/18/paradise-life-spanish-city-banned-cars-pontevedra](https://www.theguardian.com/cities/2018/sep/18/paradise-life-spanish-city-banned-cars-pontevedra)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- Using public transport can help promote a more active lifestyle, improve personal fitness, and remove stress and anxiety associated with driving. 
- Using public transport have great social benifits where you can enjoy a fast, safe, and cheap mode of transport and connect with family, friends, and colleagues. 
- Appropriate public transport is a way to ensure social equality where low-income earners, unemployed, elderly and people with a disability are particularly at risk of social isolation as a result of poor transport options.`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- Tire wear causes microplastics in the ocean. The International Union for the Conservation of Nature and Natural Resources (IUCN) has found that up to 30% of "plastic soup" that pollutes the oceans comes from the wear of textiles and car tires.

## Additional info
- [https://portals.iucn.org/library/node/46622](https://portals.iucn.org/library/node/46622)`,
  },
  activity_transport_03: {
    title: 'I walked or biked to a leisure activity today',
    description:
      "Log on days you mostly use pure muscle power like walking, skateboarding, biking or other forms of transport which don't involve electricity or gas to get to leisure activities.",
    co2_explanation: `MARKDOWN:
## The facts
- Roads and parking lots occupy large natural land areas that many species depend upon. These species have their small, natural habitats split up even more, reducing their population numbers. By walking and cycling we make life easier for the species we live with, as well as reduce our carobon emissions.

## The assumptions
- The saving is calculated based on the difference between the carbon emissions produced by average person in your country commuting to leisure activities.
- From national transport surveys we calculate the distance to leisure activities and means of transport for the average inhabitant, allowing us to calculate the carbon emissions generated by the travel. The same data is used in our personal footprint calculator.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- Walking or cycling more often has been directly linked to a lower rate of obesity and diabetes.

## Additional info
- [https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2937005/](https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2937005/)`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- Studies show that passenger cars are the largest source of microplastics from car tires. If you take public transport, bicycle, walk, or carpool instead of driving a car alone, you can make a big contribution to reducing microplastic emissions from Norwegian roads!`,
  },
  activity_transport_04: {
    title: 'I took public transport to a leisure activity today',
    description:
      'Log on days you mostly use public transportation like bus, tram or train to get to leisure activities.',
    co2_explanation: `MARKDOWN:
## The facts
- Many cities around the world have banned cars in their city centers, leading to reduced emissions, better air quality, more life in the city center and less accidents.

## The assumptions 
- The saving is calculated based on the difference between the carbon emissions of an average person in your country commuting to a leise activity and a person who uses public transport to get to a leisure activity. 
- From national transport surveys we calculate the distance to work and means of transport for the average inhabitant, allowing us to calculate the carbon emissions generated by the commute. The same data is used in our personal footprint calculator.

## Additional info
- [https://www.theguardian.com/cities/2018/sep/18/paradise-life-spanish-city-banned-cars-pontevedra](https://www.theguardian.com/cities/2018/sep/18/paradise-life-spanish-city-banned-cars-pontevedra)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- Using public transport can help promote a more active lifestyle, improve personal fitness, and remove stress and anxiety associated with driving. 
- Using public transport have great social benefits where you can enjoy a fast, safe, and cheap mode of transport and connect with family, friends, and colleagues. 
- Appropriate public transport is a way to ensure social equality where low-income earners, unemployed, elderly and people with a disability are particularly at risk of social isolation as a result of poor transport options.`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- Urban driving leads to more microplastic emissions than driving elsewhere. This is because you accelerate, brake and turn more than you do on highways and long-distance driving.`,
  },
  activity_transport_05: {
    title: 'I walked or biked to school today',
    description:
      "Log on days you mostly use pure muscle power like walking, skateboarding, biking or other forms of transport which don't involve electricity or gas to get to school.",
    co2_explanation: `MARKDOWN:
## The facts
- By cycling or walking you can reduce the number of cars on the road, thus reducing pollution, noise levels, dust and toxic gases while contributing to a more beautiful urban environment.

## The assumptions
- The saving is calculated based on the difference between how an average person in your country gets to school and a person who uses muscle power. 
- From national transport surveys we can find the distance to school and means of transport for the average inhabitant. The same data is used in our personal footprint calculator, meaning that you can find distances, emission factors and exactly how much you can save during a year by calculating your footprint.`,
    health_explanation: `MARKDOWN:
## The facts
- With this activity you will most likely have a better start to your day and get valuable exercise.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_transport_06: {
    title: 'I took public transport to school today',
    description:
      'Log on days you mostly use public transportation such as a bus, tram or train to get to to school.',
    co2_explanation: `MARKDOWN:
## The facts
- Many cities around the world have banned cars in their city centers, leading to reduced emissions, better air quality, more life in the city center and less accidents.

## The assumptions 
- The saving is calculated based on the difference between how an average person in your country gets to school and a person who uses public transport. 
- From national transport surveys we can find distance to school and means of transport for the average inhabitant. The same data is used in our personal footprint calculator, meaning that you can find distances, emission factors and exactly how much you can save during a year by calculating your footprint.

## Additional info
- [https://www.theguardian.com/cities/2018/sep/18/paradise-life-spanish-city-banned-cars-pontevedra](https://www.theguardian.com/cities/2018/sep/18/paradise-life-spanish-city-banned-cars-pontevedra)`,
    health_explanation: `MARKDOWN:
## The facts
- Using public transport can help promote a more active lifestyle, improve personal fitness, and remove stress and anxiety associated with driving. 
- Using public transport have great social benefits where you can enjoy a fast, safe, and cheap mode of transport and connect with family, friends, and colleagues. 
- Appropriate public transport is a way to ensure social equality where low-income earners, unemployed, elderly and people with a disability are particularly at risk of social isolation as a result of poor transport options.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_transport_07: {
    title: 'I took an electric car to work today',
    description:
      'Log on days you mostly use an electric car as opposed to a fossil fuel car to get to work.',
    co2_explanation: `MARKDOWN:
## The facts
- The electricity mix is important for how environmentally friendly an electric car is. As the grid in Europe decarbonizes, the benefits of driving an electric car will increase. Currently, the emissions per kilometer are about 1.7 x more for a fossil fueled car as compared to an electric car. This factor includes the cost of producing both types of cars.

## The assumptions
- The saving is calculated based on the difference between how an average person in your country commutes to work and a person who takes an electric car. 
- From national transport surveys we can find the distance to work and means of transport for the average inhabitant. The same data is used in our personal footprint calculator, meaning that you can find distances, emission factors and exactly how much you can save during a year by calculating your footprint.`,
    health_explanation: `MARKDOWN:
## The facts
- Fossil cars produce emissions of a wide range of health-affecting pollutant. Particles from diesel vehicles can seriously threaten human health because the particles can get deep into the lungs causing diseases such as lung cancer and asthama. 
- Driving an electric car contributes less CO₂e emissions, leading to a cleaner and fresher air, and preventing related diseases associated witth carbon dioxide and other pollutants, compared to driving a fossil car. 
- It's well known that CO₂e emissions are strongly connected to health problems for humans.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_transport_08: {
    title: 'I took an electric car to a leisure activity today',
    description:
      'Log on days you mostly use an electric car as opposed to a fossil fuel car to get to leisure activities.',
    co2_explanation: `MARKDOWN:
## The facts
- The electricity mix is important for how environmentally friendly an electric car is. As the grid in Europe decarbonizes, the benefits of driving an electric car will increase. Currently, the emissions per kilometer are about 1.7 x more for a fossil fueled car as compared to an electric car. This factor includes the cost of producing both types of cars.

## The assumptions
- The saving is calculated based on the difference between how an average person in your country gets to and from leisure activities and a person who uses an electric car. 
- From national transport surveys we can find the distance to leisure activities and means of transport for the average inhabitant. The same data is used in our personal footprint calculator, meaning that you can find distances, emission factors and exactly how much you can save during a year by calculating your footprint.`,
    health_explanation: `MARKDOWN:
## The facts
- Fossil cars produce emissions of a wide range of health-affecting pollutant. Particles from diesel vehicles can seriously threaten human health because the particles can get deep into the lungs causing diseases such as lung cancer and asthama. 
- Driving an electric car contributes less CO₂e emissions, leading to a cleaner and fresher air, and preventing related diseases associated witth carbon dioxide and other pollutants, compared to driving a fossil car. 
- It's well known that CO₂e emissions are strongly connected to health problems for humans.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_transport_09: {
    title: 'I took the stairs today',
    description: 'Take the stairs instead of the elevator/escalator.',
    health_explanation: `MARKDOWN:
## The facts
- Taking the stairs burns more calories per minute than jogging
- Just two minutes extra stair climbing a day is enough to stop average middle age weight gain

## HOW DO WE WORK OUT HEALTH BENEFITS
- Walking the stairs is good for raising your heart rate, building muscles and will give your mind a break which is good for reducing stress.

## Additional info
- [https://www.stepjockey.com/health-benefits-of-stair-climbing](https://www.stepjockey.com/health-benefits-of-stair-climbing)
    `,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_transport_10: {
    title:
      'I gave a compliment to someone for choosing environmentally friendly transport today',
    description:
      'Make others feel proud of their sustainable actions, thereby inspiring them to continue their sustainable journey.',
    co2_explanation: `MARKDOWN:
## The facts
- For a compliment to be powerful it has to be real.

## DOCUMENTED EFFECT
- Most of us compare ourselves to our peers, meaning that a compliment from you will most likely both trigger sustainability awareness and inspire others to continue doing climate actions.

## Additional info
- [https://www.ted.com/talks/per_espen_stoknes_how_to_transform_apocalypse_fatigue_into_action_on_global_warming](https://www.ted.com/talks/per_espen_stoknes_how_to_transform_apocalypse_fatigue_into_action_on_global_warming)
    `,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_transport_11: {
    title:
      'I discussed environmental effects of transport with family, friends, colleagues or on social media today',
    description:
      'Show others that you care and try to find a common understanding of how we can achieve the solutions we need.',
    co2_explanation: `MARKDOWN:
## The facts
- We need to start talking about sustainability efforts to find solutions together and raise the issue to the top of our shared agenda. 
- The read more section below has some great tips for how to talk about sustainability in a way that creates bridges and not barriers.

## DOCUMENTED EFFECT
- You can have a potentially huge impact by sharing your thoughts with others as the sustainability issues can only be solved through collaboration. The person you talk to might be inspired to talk to others, to vote for green politicians, to buy sustainable products, and change the behaviours of their household, to mention just a few potential effects of this action.

## Additional info
- [https://www.weforum.org/agenda/2018/06/how-to-talk-about-climate-change-5-tips-from-the-front-lines/](https://www.weforum.org/agenda/2018/06/how-to-talk-about-climate-change-5-tips-from-the-front-lines/)
- [https://www.ted.com/talks/per_espen_stoknes_how_to_transform_apocalypse_fatigue_into_action_on_global_warming](https://www.ted.com/talks/per_espen_stoknes_how_to_transform_apocalypse_fatigue_into_action_on_global_warming)
    `,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_transport_12: {
    title: 'I worked from home today',
    description:
      'Log on days when instead of travelling to work you work from home.',
    co2_explanation: `MARKDOWN:
## The facts
- By not commuting to work you could save more than 1 Tonne of CO\u2082e annually, compared to an average person who lives 10 km from work. Meaning that if you could work from home one day a week you could save approx. 200Kg CO\u2082e annually.
- Many will experience being more productive, being less stressed and having more time with family when doing this activity.

## The assumptions
- The saving is calculated based on the difference between how an average person in your country commutes to work and a person who works from home.
- From national transport surveys we can find the distance to work and means of transport for average inhabitants. The same data is used in our personal footprint calculator, meaning that you can find distances, emission factors and exactly how much you can save during a year by finding your footprint.

## Additional info
- [https://www.forbes.com/sites/kevinkruse/2012/12/18/benefits-working-from-home/#3b287c961d4c](https://www.forbes.com/sites/kevinkruse/2012/12/18/benefits-working-from-home/#3b287c961d4c)
    `,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_transport_13: {
    title: 'I used digital collaboration tools today',
    description:
      'Use digital collaboration tools as video conferencing, team chats or digital work rooms eg. today.',
    co2_explanation: `MARKDOWN:
## The facts
- If you skip a flight within Europe traveling 1500 km each way, you would save approx. 800 Kg CO\u2082e, which is equivalent to the same saving as if an average Norwegian goes vegan for a year.

## EFFECT
- In cases where the use of digital interaction tools replaces a flight, you will be able to save close to 240kg CO\u2082e on a typical domestic trip / journey in Scandinavia (average of 350km / way). Replacing a trip to Europe you will save 800kg CO\u2082e (average 1500km / way), while replacing a global journey will save you as much as 3350kg CO\u2082e going there and back (average of 7000km / way).
- The most important thing about this competition is to create awareness of activities we can do to travel more environmentally friendly. We have set up activities that most people can action with a daily saving so you can see the effects of what you do every time you do it. Therefore, we collect the savings from this activity to the end result for the whole company, keeping the competition fair for everyone. This activity is added to the competition because it is very important in terms of savings, to creates awareness. 
- In order to collect CO\u2082e related to reducing flights, you can register "If I were to book a long-term work trip today, I would first like to consider the possibilities of minimizing the environmental impact". It can be registered every day!
    `,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_transport_14: {
    title: 'I gave a compliment to someone for choosing a video meeting today',
    description:
      'Make others feel proud of using video for meetings, thereby inspiring them to continue their sustainable journey or make them aware that they have a positive effect on the environment.',
    co2_explanation: `MARKDOWN:
## The facts
- For a compliment to be it has to be real 
- It is evident in research that giving a compliment can improve self-perception and performance for the self and for others too. Compliments can actually revolutionize personal behaviour and increasing environmentally friendly activities.
- Giving a compliment for others is a very powerful way to encourage family, friends, and in society to use more environmentally friendly options, and give them a social recognition for their efforts in saving the environment. 

## EFFECT
- Most of us compare ourselves to our peers, meaning that a compliment from you will most likely both trigger sustainability awareness and inspire others to continue doing climate actions.
    `,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_transport_15: {
    title:
      'I gave a compliment to someone for choosing public transport to a long distance meeting today',
    description:
      'Make others feel proud of their sustainable actions, thereby inspiring them to continue their sustainable journey.',
    co2_explanation: `MARKDOWN:
## The facts
- For a compliment to be it has to be real 
- It is evident in research that giving a compliment can improve self-perception and performance for the self and for others too. Compliment can actually revolutionize personal behaviour and increasing environmentally friendly activities.
- Giving a compliment for others is a very powerful way to encourage family, friends, and in society to use more environmentally friendly options, and give them a social recognition for their efforts in saving the environment. 

## EFFECT
- Most of us compare ourselves to our peers, meaning that a compliment from you will most likely both trigger sustainability awareness and inspire others to continue doing climate actions.
    `,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_transport_16: {
    title: 'I carpooled to work today',
    description: 'Log each day you carpooled to work',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- City driving results in more microplastic emissions than driving elsewhere? This is because you accelerate, brake and turn more than you do on freeway and highway driving.

## Additional info
- [https://www.miljodirektoratet.no/globalassets/publikasjoner/M959/M959.pdf](https://www.miljodirektoratet.no/globalassets/publikasjoner/M959/M959.pdf)`,
  },
  activity_transport_17: {
    title: 'I used no transport today',
    description: 'Log each day you did not use any means of transport',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- The transport sector is one of the largest contributors to CO\u2082 emissions in the world? Transport contributes to around 17 percent of our climate footprint on average. If you avoid driving or flying when you have more environmentally friendly options, it will make a big difference to the climate!`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- City driving results in more microplastic emissions than driving elsewhere? This is because you accelerate, brake and turn more than you do on freeway and highway driving.

## Additional info
- [https://www.miljodirektoratet.no/globalassets/publikasjoner/M959/M959.pdf](https://www.miljodirektoratet.no/globalassets/publikasjoner/M959/M959.pdf)`,
  },
  activity_transport_18: {
    title: 'I carpooled to school today',
    description: 'Log each day you carpooled to school',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    biodiversity_explanation: `MARKDOWN:
## The facts
- City driving results in more microplastic emissions than driving elsewhere? This is because you accelerate, brake and turn more than you do on freeway and highway driving.

## Additional info
- [https://www.miljodirektoratet.no/globalassets/publikasjoner/M959/M959.pdf](https://www.miljodirektoratet.no/globalassets/publikasjoner/M959/M959.pdf)`,
  },
  activity_transport_19: {
    title: 'I conducted eco-driving today',
    description:
      'Log on the days when you conducted eco-driving while driving your car',
    co2_explanation: `MARKDOWN:
## The facts
- Eco-driving is a set of actions that a car driver can take to influence the fuel economy and emissions intensity of a vehicle, reducing its environmental impact. These actions include: strategic decisions (regular vehicle maintenance, optimal tyre pressure), tactical decisions (route selection and vehicle loading),1 and operational decisions (low rpm driving, steady speed, accelerate/decelerate smoothly)
- Reported benefits of eco-driving vary between 5-30%, which makes it an attractive option for drivers wanting to reduce their fuel costs and greenhouse gas emissions.

## The assumptions
- We assume eco-driving can result in fuel efficiency improvements of 4.8%. We estimate the average person's emissions from driving a car based on their driving patterns and reduce the emissions by 4.8% to factor in the efficiency improvements from eco-driving`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_transport_20: {
    title: 'I rode an electric bike to work today',
    description:
      'Log on days when, instead of using a car or public transport, you ride an electric bike to work',
    co2_explanation: `MARKDOWN:
## The facts
- For many people e-bikes can completely replace a car - 28% of buyers stated this was the case.
- China is the world-leader in e-bikes sales, while Germany leads the market in Europe.

## The assumptions
- We calculate the footprint of an electric bicycle per kilometer ridden, based on both electricity usage and production emissions.
- This is subtracted from the footprint of an average commuter, who uses a combination of car and public transport.

## Additional info
- [https://www.ebicycles.com/ebike-facts-statistics/](https://www.ebicycles.com/ebike-facts-statistics/)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_transport_21: {
    title: 'I rode an electric bike to school today',
    description:
      'Can be logged on days when, instead of using a car or public transport, you ride an electric bike to school',
    co2_explanation: `MARKDOWN:
## The facts
- For many people e-bikes can completely replace a car - 28% of buyers stated this was the case.
- China is the world-leader in e-bikes sales, while Germany leads the market in Europe.

## The assumptions
- We calculate the footprint of an electric bicycle per kilometer ridden, based on both electricity usage and production emissions.
- This is subtracted from the footprint of an average commuter, who uses a combination of car and public transport.

## Additional info
- [https://www.ebicycles.com/ebike-facts-statistics/](https://www.ebicycles.com/ebike-facts-statistics/)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_transport_22: {
    title: 'I rode an electric bike to a leisure activity today',
    description:
      'Log on days when, instead of using a car or public transport, you ride an electric bike to a leisure activity',
    co2_explanation: `MARKDOWN:
## The facts
- An electric bike's motor helps you keep 25 km/h, allowing you easy environmentally friendly travel without breaking a sweat!

## The assumptions
- We calculate the footprint of an electric bicycle per kilometer ridden, based on both electricity usage and production emissions.
- This is subtracted from the footprint of the average person, who uses a combination of car and public transport to travel to leisure activities`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
};

import styled from 'styled-components';
import { Typ30 } from 'dc/Typ';

export const Form = styled.form`
  margin-top: 40px;
`;

export const Error = styled(Typ30)`
  color: #ba363d;
`;

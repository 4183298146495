import React from 'react';
import * as PropTypes from 'prop-types';
import {
  ComponentWrapper,
  InputWrapper,
  ErrorMessage,
  Text,
  Icon,
  Input,
  ErrorMessageWrapper,
} from './elements';

function TxtField06(props) {
  return (
    <ComponentWrapper className={props.className}>
      <InputWrapper
        whiteBG={props.whiteBG}
        errorMessage={props.errorMessage}
        value={props.value}
        disabled={props.disabled}
      >
        <Icon errorMessage={props.errorMessage} icon={props.icon} />
        {!props.disabled ? (
          <Input
            id={props.id}
            whiteBG={props.whiteBG}
            type={props.type || 'text'}
            onChange={props.onChange}
            value={props.value}
            maxLength={props.maxLength || 524288}
            max={props.max || Infinity}
            min={props.min || -Infinity}
            placeholder={props.placeholder}
          />
        ) : (
          <Text>{props.value?.length ? props.value : props.placeholder}</Text>
        )}
      </InputWrapper>
      <ErrorMessageWrapper>
        <ErrorMessage errorMessage={props.errorMessage}>
          {props.errorMessage}
        </ErrorMessage>
      </ErrorMessageWrapper>
    </ComponentWrapper>
  );
}

TxtField06.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  whiteBG: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
};

export default TxtField06;

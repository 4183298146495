import styled from 'styled-components';
import Wra6A from '../Wra6A';

export const WrapperSmall = styled(Wra6A)`
  background-color: transparent;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`;

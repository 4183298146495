export default {
  push_notifications: {
    team_has_logged_nudge: {
      title: 'Time to log?',
      description: 'Hi! Most of your team has logged their actions today.',
    },
    morning_message_engager: {
      foodWaste: {
        title: 'Challenge yourself',
        descriptions: {
          0: 'The food waste from Norwegian households decreased 12% between 2010 and 2015. Will you contribute to the positive trend today?',
          1: 'Around ⅓ of all food currently produced for human consumption is wasted.',
          2: 'In Norway we throw away about 385.000 tons of edible food every year.',
          3: 'If food waste were its own country, it would rank as Number 3 of countries with the highest climate gas emissions. Only the US and China would have higher emissions.',
          4: 'Just ¼ of all food thrown away in the US and Europe every year is enough to feed all 821 million starving people in the world.',
          5: 'The food thrown away in Norway every year is enough to feed at least 800.000 more people every year. That is equivalent to the population of Amsterdam.',
          6: 'The emissions from food waste in Norway is equivalent to the emissions from 400.000 petrol cars a year.',
          7: 'The 53 families that have already participated in MatVinn reduced their food waste by an average of 60% in that month. Many reduced by as much as 80%. Can you match that or do better?',
          8: 'The food we throw away in Norway annually has an estimated value of 22 billion Norwegian krone.',
          9: 'Most Norwegians say the reason they throw away food is the “expiration date”. Often visual cues and your sense of smell can be used to provide a more accurate date.',
          10: 'Each Norwegian throws away 42.6 kg of food every year on average.',
          11: 'The food we Norwegians throw away the most is… leftovers! 13 kg of leftovers from pots, plates and take-out boxes per person ends up in the bin every year.',
          12: 'The world uses a land area bigger than China to grow food that is then thrown away.',
          13: 'Food waste accounts for around 10 percent of all man-made climate emissions.',
          14: 'Norway has committed to UN Sustainable Development Goal #12.3, which says we will cut food waste by 50% by 2030. Are you in too?',
          15: 'Norwegian grocery stores throw away 60.000 bananas every day. This is mainly because we don’t like buying single bananas, even if we only need one. Give the single banana a chance today!',
          16: 'Every Norwegian consumes on average about 4400 litres of water daily in their food. More than 3000 litres of water is required to produce a 200 gram steak. Good enough motivation to not waste any?',
          17: 'The food that is thrown away in rich countries (222 billion tons) is about the same as all the food produced in the Southern part of Africa every year (230 billion tons).',
          18: 'According to the UN, the world’s food production has to increase 60% by 2050 to feed what will then be 9 billion people in the world.',
          19: 'The world’s food production accounts for about 30% of global climate emissions.',
          20: 'Consumers account for 58% of all mapped food wastage in Norway. The actions we take at home can have a huge effect!',
        },
      },
      sustainableFood: {
        title: 'Challenge yourself',
        descriptions: {
          0: 'It takes 22 bathtubs of water each day to produce what a single Norwegian eats and drinks.',
          1: 'The food wasted in Norway each year could feed 800.000 people.',
          2: 'The average Norwegian throws away 2667 NOK worth of food every year.',
          3: 'Global food production accounts for about 30% of the world’s total climate emissions.',
          4: 'Norwegian eggs have a 28 day expiry date, but if stored properly they can stay fresh for much longer.',
          5: 'The UN says global food production must increase 60% by 2050 to feed our expected 9 billion population.',
          6: 'Health guidelines recommend 500 grams of red meat a week max. 55 % of men and 33 % of women eat more.',
          7: 'Soy grown in Brazil for Norway’s salmon farming industry,  takes up the land space of 10000 family farms',
          8: '43% of Norwegians want to reduce their meat consumption due to environmental and animal welfare issues.',
          9: 'If everyone ate like Norwegians, we would need 67% more agricultural land than we currently use.',
          10: '15 % of all human induced climate gas emissions comes from meat and dairy farming',
          11: 'The world uses a land area larger than China to grow food which is then thrown away.',
          12: '96% more water is needed to satisfy Norway’s meat demands than is used for its vegetable production',
          13: 'More than 13kg of leftovers per person from pots, plates and boxes ends up in Norwegian bins each year. ',
        },
      },
      mindfulCitizen: {
        title: 'Challenge yourself',
        descriptions: {
          0: "Welcome to the Mindful Citizen Challenge! It's time to make impact in your community! Let's go!",
          1: "Altruism derives from the French word autrui, meaning 'others'. It refers to a quality to focus on someone other than ourselves. Altruism is contagious. Let's spread it all around!",
          2: 'No act of kindness, no matter how small, is ever wasted.',
          3: 'Being thankful for even the smallest thing in times of trouble will make you more resilient.',
          4: "Whales and bats use echo-location. Trees use roots and fungi to connect remotely. We, humans, have online technology. So don't worry, you can always reach out to anyone you care about.",
          5: 'It takes only 4 steps to reduce COVID-19 or any other virus from spreading: less traveling, avoiding crowds, social distancing and regular/proper handwashing!',
          6: 'Gratitude makes our relationships stronger! Thanking a new companion makes them more likely to stick around.',
          7: "Don't forget to log your mindful actions!",
          8: 'When you learn something new, your mind and your perceptions improve, making it easier to handle the inevitable changes life always tends to bring.',
          9: 'World Health Organization recommends to do weekly at least 75 minutes of running or other vigorous intensity activity.',
          10: 'World Health Organization recommends to do weekly at least 2.5 hours of walking or other similar moderate intensity activity.',
          11: "Eighty percent of the world's most successful people have some sort of daily mindfulness practice.",
          12: "Self-reflection is a core of mindfulness! It helps you attune to what's going well in the present moment, so you can practice appreciation and gratitude.",
          13: '"Kindness is a language which the deaf can hear and the blind can see". Mark Twain',
          14: 'Wow! There has been 2 weeks of mindfulness already! Today, try to practise empathic listening with your loved one.',
          15: 'Congratulations! What an achievement! Now, challenge yourself to practice mindfulness every day!',
        },
      },
      workTransport: {
        title: 'Challenge yourself',
        descriptions: {
          0: 'Small actions have huge effects!',
          1: "33% of the world's population will bicycle today.",
          2: "Did you know there are more than 7000 cars available in Norway's car sharing services?",
          3: 'Switching from planes to trains slashes your transport footprint in half. Slow and steady wins the race!',
          4: "It's not about how much you pollute, but how much you can save!",
          5: 'Each of us can reduce our climate emissions every day.',
          6: 'Improving our behavior requires a little effort every day.',
          7: 'Japan’s new "bullet train" is the fastest in the world with a maximum speed of 603 km/h. ',
          8: 'Traveling by rail across long distances is the most climate friendly means of transport by far.',
          9: 'About 100 million bicycles are manufactured worldwide each year.',
          10: 'One of the most effective WIN-WIN-WIN ways to meet up is video conferencing. Save time, money and CO\u2082!',
          11: 'There is no climate action without a green reaction.',
          12: 'Skipping one average European flight has the same climate impact as going vegan for a year!',
          13: 'If you bike today, your heart, lungs and planet will thank you.',
          14: 'It only takes 8 passengers to make a bus more climate friendly than driving a car.',
          15: 'More than half of our muscles are designed for walking. What a waste, eh?',
          16: 'It takes less than 5 passengers to make a tram more climate friendly than driving an electrical car.',
          17: 'Using public transportation instead of driving is one of the most climate effective actions you can do.',
          18: 'Teaming up to try out new habits makes climate action both fun and easy!',
          19: 'Stories are proven to influence behaviour more than mere statistics.',
          20: 'Thanks to you, the world is one step closer reaching global climate goals. Keep it up!',
        },
      },
      co2: {
        title: 'Challenge yourself',
        descriptions: {
          0: 'Welcome to the Climate Challenge. Start testing out new, sustainable habits today!',
          1: 'Skipping one average European flight has the same climate impact as going vegan for a year!',
          2: 'How much do you know about food and climate? Check out the quiz and see if you can get 3/3.',
          3: 'Giving a compliment is a powerful way to encourage family and friends to be more climate-friendly!',
          4: 'Cheese comes to about 8 KgCo\u2082e per kg. Scale back on the size for the sake of the planet!',
          5: 'Did you know that cutting 3 minutes off your shower time can save 30 litres of water?',
          6: 'Wow, what a week! Check your stats!',
          7: 'You can save more than 1 tonne CO\u2082e every year by putting pure muscle power to work!',
          8: 'A beef patty burger has about the same climate impact as 10 vegetarian burgers.',
          9: 'Good morning! Are you one of the happy bikers that will cycle to work today?',
          10: 'What are you going to do today?',
          11: 'Spending a £100 on clothing comes to 51 KgCO\u2082e - equivalent to a 200km drive in an average petrol car.',
          12: "Don't forget to log your impact!",
          13: 'Thanks to you, the world is one step closer to reaching global climate goals. Keep it up!',
          14: 'Small actions have huge effects!',
          15: 'More than half of our muscles are designed for walking. What a waste, eh?',
          16: 'Re-use your favorite mug before you load it in the dishwasher!',
          17: 'Good morning! Are you one of the happy bikers that will cycle to work today?',
          18: "It's not about how much you emit, but how much you can save!",
          19: 'Teaming up to try out new habits makes climate action fun and easy!',
          20: 'Great stories influence behaviour more than mere statistics. Share yours today!',
          21: 'One third of the food produced gets lost or wasted every year. Rescue a meal today!',
        },
      },
      plastic: {
        title: 'Challenge yourself',
        descriptions: {
          0: 'Did you know that one cigarette butt contains as much as 12,000 microplastic units?',
          1: 'The EU requires 50% recycling of plastics in 2025. This an increase of 40.000 tonnes in material recycling annually in Norway.',
          2: 'More than 15 tonnes of plastic go into the ocean every minute.',
          3: 'CO\u2082 emissions from the plastic value chain are increasing. This is largely due to a huge increase in the consumption of plastic articles and especially single use plastics.',
          4: 'Household dust contains plastic items that we breathe in.',
          5: '2/3 of consciously added microplastics stems from agriculture.',
          6: 'In Norway, most of the plastic waste in the sea can be traced to consumers and the fisheries and aquaculture sector.',
          7: 'A number of disposable plastic items will be banned in the EU from 2021 and in Norway as early as 2020!',
          8: 'Remember to bring shopping bags to the store',
          9: 'Some cosmetic products contains as much microplastics as the plastic packaging it comes in.',
          10: 'Around 80% of the plastic that ends up in the sea comes from land.',
          11: 'Want a cup of coffee? Remember to bring a cup',
          12: 'It is produced more than 380 million tons of plastic worldwide every year!',
          13: "All plastics produced annually in the world correspond to the weight of 2/3 of the world's population.",
          14: 'Plastics are mostly made from fossil materials, but can also be made from biomass.',
          15: 'To leave the car is one of the biggest contributions you can make to reducing microplastics emissions!',
          16: 'If plastic production continues to grow, emissions will amount to 1.34 gigatonnes annually in 2030.',
          17: 'In 1999, 65 million boxes and bottles were recycled in Norway, in 2017 it had increased to 1044 million!',
          18: 'Plastic can contain heavy metals and synthetic pollutants that are harmful to health and the environment.',
          19: 'Using reusable products instead of disposable plastic products, you will help reduce plastic astray!',
          20: '12 minutes is the average useful lifetime of plastics.',
          21: 'The plastic problem is so complex that we need system change and individual actions all at once.',
          22: "Today you must remember to log your activities. Tomorrow, it's too late.",
        },
      },
    },
  },
  snackbar: {
    deleted_user: 'Deleted user',
    challenge: {
      participant_joined:
        'MARKDOWN:**%{name}** is now participating on your team.',
      participants_joined:
        'MARKDOWN:**%{name}** and %{smart_count} other is now participating on your team. |||| MARKDOWN:**%{name}** and %{smart_count} others are now participating on your team.',
      participant_logged_activity: '%{name} logged %{activity}.',
      participant_logged_activities:
        'MARKDOWN:**%{name}** logged %{activity} and %{smart_count} other activity. |||| MARKDOWN:**%{name}** logged %{activity} and %{smart_count} other activities.',
    },
    footprint: {
      is_saved:
        'Well done! You just reduced your footprint. Keep up the good work for the world!',
    },
    www: {
      feedback_sent: 'Your message is sent, thanks for your feedback!',
    },
    account: {
      is_deleted: 'Your account is now deleted',
      password_reset_sent:
        'We have sent you an email with instructions to change your password',
    },
    daily_streak: {
      3: "You've just logged 3 days in a row! Climate is happy!",
      5: "You've streaked 5-days in a row! Keep up the pace!",
      7: "You've got a week-streak saving! 7 days in a row. You're a hero!",
      9: "You keep going! 9 days in a row. You're developing new habits now!",
      11: "You're doing amazing! 11 days in a row. Keep it up!",
      13: '13 days in a row with CO\u2082e savings. You inspire your team!',
      15: 'You have saved CO\u2082e 15 days in a row! Great effort!',
      17: 'You have established good habits and have saved CO\u2082e 17 days in a row. Climate is winning!',
      19: 'You are a true climate hero! You have saved CO\u2082e 19 days in a row!',
      21: 'You have saved CO\u2082e every single day in the challenge! You are an inspiration!',
    },
  },
};

export default {
  org_type: {
    company: 'Company',
    government: 'Governmental (e.g. municipality)',
    higher_ed: 'Higher education (e.g. university)',
    lower_ed: 'Lower education (secondary school)',
    ngo: 'NGO',
  },
  add: {
    title: 'Organisation structure',
    add_button: 'Add',
    skip_button: 'Skip and save organisation',
  },
  description:
    'If you have a permanent structure of your organization you can enter it here. For each department you can add a number of groups. These can be used as teams in a campaign and therefore simplify set-up. You don’t need to enter anything here if your organization (like a school) changes regularly.',
  input: {
    title: 'Input your organisation structure',
    enter_department: 'Enter a department',
    enter_group: 'Enter a group',
  },
};

import { v4 } from 'uuid';

export function addSeries({ state }) {
  const seriesKey = v4();
  state.set(
    `admin.championships.create.series.form.createdSeries.${seriesKey}`,
    {
      name: {
        value: '',
        defaultValue: '',
        validationRules: [
          'isValue',
          'isUnique:{"form":"admin.championships.create.series.form.createdSeries","field":"name"}',
        ],
      },
    }
  );
}

export function removeSeries({ props, state }) {
  const { seriesKey } = props;

  state.unset(
    `admin.championships.create.series.form.createdSeries.${seriesKey}`
  );
}

import React from 'react';
import { connect } from '@cerebral/react';
import { sequences } from 'cerebral';
import {
  Wrapper,
  Title,
  Description,
  ButtonContainer,
  AddButton,
  SkipButton,
} from './elements';
import { useText } from 'common/hooks';
import Spa16 from 'dc/Spa16';
import Spa32 from 'dc/Spa32';
import Spa40 from 'dc/Spa40';
import Spa96 from 'dc/Spa96';

export default connect(
  {
    addIntegration: sequences`admin.campaigns.create.integration.addIntegration`,
    skipIntegration: sequences`admin.campaigns.create.integration.skipIntegration`,
  },
  function Integration({ addIntegration, skipIntegration }) {
    const t = useText();

    return (
      <Wrapper>
        <Spa32 />
        <Title>{t('admin.integration.title')}</Title>
        <Spa16 />
        <Description>{t('admin.integration.description')}</Description>
        <Spa40 />
        <ButtonContainer>
          <AddButton onClick={() => addIntegration()}>
            {t('admin.integration.add_button')}
          </AddButton>
          <SkipButton onClick={() => skipIntegration()}>
            {t('admin.integration.skip_button')}
          </SkipButton>
        </ButtonContainer>
        <Spa96 />
      </Wrapper>
    );
  },
);

import * as sequences from './sequences';
import { renderPage } from '../../../../factories';

import computedChampionshipLinks from 'admin/onboarding/computed/championshipLinks';

export default {
  state: {
    isEdit: false,
    championshipKey: null,
    championship: null,
    languageSelected: {
      optionKey: 'no',
      optionValue: 'Norsk',
    },
    checkedComparison: 'teams',
    sectionSelected: {
      optionKey: 'noSectionFilter',
      optionValue: 'All sections (default)',
    },
    organisationSelected: {
      optionKey: 'noOrganisationFilter',
      optionValue: 'All organisations (default)',
    },
    seriesSelected: {
      optionKey: 'noSeriesFilter',
      optionValue: 'All series (default)',
    },
    computedChampionshipLinks,
  },
  sequences: {
    routed: renderPage('createChampionship', sequences.render),
    dropdownSelected: sequences.dropdownSelected,
    comparisonOptionClicked: sequences.setComparisonOption,
  },
};

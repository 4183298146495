import styled, { css } from 'styled-components';
import PoM000 from 'dc/PoM000';
import Wra16A from 'dc/Wra16A';
import Ava72 from 'dc/Ava72';
import { Typ20, Typ40 } from 'dc/Typ';
import MIt01 from 'dc/MIt01';

type PopoverContainerProps = {
  $show: boolean;
};

export const PopoverContainer = styled(PoM000)<PopoverContainerProps>`
  background: #ffffff;
  width: 320px;
  right: 0;
  position: absolute;
  top: 60px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s linear;
  ${props =>
    props.$show &&
    css`
      opacity: 1;
      visibility: visible;
    `};
`;

export const TopWrapper = styled(Wra16A)`
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Avatar = styled(Ava72)``;

export const Username = styled(Typ40)`
  text-align: center;
`;

export const ActiveText = styled(Typ20)`
  color: #90a4ae;
  text-align: center;
`;

export const MiddleWrapper = styled(Wra16A)`
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-top: 1px solid #eceff1;
`;

export const BottomWrapper = styled(Wra16A)`
  background: #f4f7f9;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const EditProfile = styled(MIt01)`
  cursor: pointer;
  &:hover > i {
    color: #00a6b6;
  }
`;

export const Logout = styled(MIt01)`
  cursor: pointer;
  &:hover > i {
    color: #00a6b6;
  }
`;

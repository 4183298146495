import React from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import { Wrapper, ContentWrapper, Loader } from './elements';
import Organisation from './Organisation';
import Info from './Info';
import Focus from './Focus';
import Challenge from './Challenge';
import ChallengeActivities from './Challenge/Activities';
import ChallengeTerms from './Challenge/Terms';
import ChallengeDuration from './Challenge/Duration';
import Integration from './Integration';
import PublicPages from './Integration/PublicPages';
import Widgets from './Integration/Widgets';
import Access from './Access';
import SectionsAndTeams from './SectionsAndTeams';
import Publish from './Publish';

import ErrorBoundary from 'dc/ErrorBoundary';
import { useConfig, useText } from 'common/hooks';

export default connect(
  {
    pageSection: state`admin.pageSection`,
    isLoading: state`admin.campaigns.isLoading`,
  },
  function CreateCampaign({ pageSection, isLoading }) {
    const t = useText();
    const { staticUrl } = useConfig();

    function renderSection() {
      const pages = {
        organisation: Organisation,
        focus: Focus,
        info: Info,
        challenge: Challenge,
        challengeActivities: ChallengeActivities,
        challengeTerms: ChallengeTerms,
        challengeDuration: ChallengeDuration,
        integration: Integration,
        publicPages: PublicPages,
        widgets: Widgets,
        access: Access,
        teams: SectionsAndTeams,
        publish: Publish,
      };

      const Page = pages[pageSection] || Organisation;

      return <Page />;
    }

    if (isLoading) {
      return <Loader />;
    }

    return (
      <Wrapper>
        <ContentWrapper>
          <ErrorBoundary
            title={t('appContainer.error_boundary.title')}
            body={t('appContainer.error_boundary.body')}
            buttonText={t('appContainer.error_boundary.button_text')}
            imageSrc={`${staticUrl}/images/trophy_investigate.svg`}
          >
            {renderSection()}
          </ErrorBoundary>
        </ContentWrapper>
      </Wrapper>
    );
  },
);

import { set, when } from 'cerebral/factories';
import { state, props, string } from 'cerebral';
import { value } from 'providers/Firebase/src/operators';
import { redirect } from 'common/factories';
import * as actions from './actions';
import { validateChampionship } from 'admin/onboarding/module/modules/championships/modules/create/actions';
import { validateCampaign } from 'admin/onboarding/module/modules/campaigns/modules/create/actions';

export const changePage = [
  when(props`page`),
  {
    true: redirect(string`/${props`page`}`),
    false: redirect('/'),
  },
];

export const changePageSection = [
  set(state`admin.pageSection`, props`section`),
  when(state`admin.campaigns.isChampionship`),
  {
    true: [
      validateChampionship,
      {
        true: [
          set(state`admin.campaigns.create.isValid`, true),
          set(state`admin.championships.create.isValid`, true),
        ],
        false: [
          set(state`admin.campaigns.create.isValid`, false),
          set(state`admin.championships.create.isValid`, false),
        ],
      },
    ],
    false: [
      validateCampaign,
      {
        true: set(state`admin.campaigns.create.isValid`, true),
        false: set(state`admin.campaigns.create.isValid`, false),
      },
    ],
  },
];

export const openWithToken = [actions.getToken, actions.openWithToken];

export const getMonthlyStatsClicked = [
  set(state`admin.isLoadingStats`, true),
  value('challenges.dailyStats.ducky'),
  actions.formatChallengeDailyStats,
  value('challenges.dailyStats.profiles'),
  actions.formatProfileDailyStats,
  set(state`admin.monthlyStats`, props`monthlyStats`),
  set(state`admin.isLoadingStats`, false),
];

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Wrapper,
  Title,
  Description,
  ButtonContainer,
  AddButton,
  SkipButton,
} from './elements';
import { useText } from 'common/hooks';
import Spa16 from 'dc/Spa16';
import Spa32 from 'dc/Spa32';
import Spa40 from 'dc/Spa40';
import Spa96 from 'dc/Spa96';
import Form from './Form';

export default connect(
  {
    isCreatingOrganisation: state`admin.organisations.create.isCreatingOrganisation`,
    isValidForm: state`admin.organisations.create.isValidForm`,
    structureAdded: state`admin.organisations.create.structure.added`,
    addStructure: sequences`admin.organisations.create.addStructure`,
    createOrganisationClicked: sequences`admin.organisations.create.createOrganisationClicked`,
  },
  function APOrg04({
    isCreatingOrganisation,
    isValidForm,
    structureAdded,
    addStructure,
    createOrganisationClicked,
  }) {
    const t = useText();

    return (
      <Wrapper>
        <Spa32 />
        <Title>
          {t(`admin.organisation.${structureAdded ? 'input' : 'add'}.title`)}
        </Title>
        <Spa16 />
        <Description>{t('admin.organisation.description')}</Description>
        <Spa40 />
        {structureAdded ? (
          <Form />
        ) : (
          <ButtonContainer>
            <AddButton
              disabled={isCreatingOrganisation}
              onClick={() => addStructure()}
            >
              {t('admin.organisation.add.add_button')}
            </AddButton>
            <SkipButton
              disabled={!isValidForm || isCreatingOrganisation}
              onClick={() => {
                createOrganisationClicked();
              }}
            >
              {t('admin.organisation.add.skip_button')}
            </SkipButton>
          </ButtonContainer>
        )}
        <Spa96 />
      </Wrapper>
    );
  }
);

import { state } from 'cerebral';

export default get => {
  const sections = get(state`admin.structure.structureForm.sections`) || {};

  const teamsArray = Object.values(sections).flatMap(({ teams }) =>
    Object.values(teams || {})
  );

  const hasEnteredAnEmail = teamsArray.some(
    team => team.teamLeaderEmail.value !== ''
  );

  return teamsArray.length > 0 && hasEnteredAnEmail;
};

import styled from 'styled-components';
import Lab121 from 'dc/Lab121';
import Load04 from 'dc/Load04';

export const Wrapper = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 780px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding-top: 72px;
`;

export const ChampionshipLabel = styled(Lab121)`
  position: absolute;
  top: 24px;
  left: 16px;
  span {
    color: #03739a;
  }
`;

export const Loader = styled(Load04)`
  width: 100%;
`;

import * as sequences from './sequences';
import activitiesModule from './modules/activities';
import activityModalModule from './modules/activityModal';
import tagModalModule from './modules/tagModal';
import { CHALLENGE_WINNER_MODE } from 'resources/constants';

export default {
  state: {
    added: false,
    winningModeSelected: { optionKey: CHALLENGE_WINNER_MODE.highest_score },
    winningParticipationPercentageSelected: { optionKey: 60 },
    backlogDaysSelected: { optionKey: 2 },
    form: {
      prize: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      startDate: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      endDate: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      abTestEnabled: {
        value: false,
        defaultValue: '',
        validationRules: ['isValue'],
      },
      backlogDays: {
        value: '2',
        defaultValue: '2',
        validationRules: ['isValue'],
      },
      emailUpdateFrequency: {
        value: '5',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      winningParticipationPercentage: {
        value: '60',
        defaultValue: '60',
        validationRules: ['isValue'],
      },
      winningMode: {
        value: CHALLENGE_WINNER_MODE.highest_score,
        defaultValue: CHALLENGE_WINNER_MODE.highest_score,
        validationRules: ['isValue'],
      },
    },
  },
  modules: {
    activities: activitiesModule,
    activityModal: activityModalModule,
    tagModal: tagModalModule,
  },
  sequences: {
    expandChallenge: sequences.expandChallenge,
    addChallenge: sequences.addChallenge,
    removeChallenge: sequences.removeChallenge,
    skipChallenge: sequences.skipChallenge,
    desktopCoverImageSelected: sequences.uploadDesktopCoverImage,
    mobileCoverImageSelected: sequences.uploadMobileCoverImage,
    adminActivityDetailsClicked: sequences.setAdminActivityModal,
    showAdminTagModalClicked: sequences.showAdminTagModal,
    closeAdminTagModalClicked: sequences.closeAdminTagModal,
  },
};

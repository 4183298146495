import React from 'react';
import { connect } from '@cerebral/react';
import { sequences } from 'cerebral';
import { useText } from 'common/hooks';

import { Wrapper, HorizontalRule, Title, Text, Button } from './elements';
import DeleteModal from './DeleteModal';

export default connect(
  {
    showDeleteCampaignModalClicked: sequences`admin.campaigns.edit.showDeleteCampaignModalClicked`,
  },
  function Header({ showDeleteCampaignModalClicked }) {
    const t = useText();

    return (
      <Wrapper>
        <HorizontalRule />
        <Title>{t('admin.overview.delete.delete_campaign')}</Title>
        <Text>{t('admin.overview.delete.warning')}</Text>
        <Button onClick={() => showDeleteCampaignModalClicked()}>
          {t('admin.overview.delete.delete')}
        </Button>
        <DeleteModal />
      </Wrapper>
    );
  }
);

import styled from 'styled-components';
import TxtField05 from 'dc/TxtField05';
import Ico24 from 'dc/Ico24';

export const Wrapper = styled.div`
  width: 100%;
  padding-left: 96px;
  height: 72px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
`;
export const InputSize = styled(TxtField05)`
  flex-grow: 1;
  width: 100px;
  margin: 0 24px;
  span {
    width: auto;
  }
`;

export const InputName = styled(TxtField05)`
  flex-grow: 1;
  width: 130px;
  span {
    width: auto;
  }
`;

export const InputEmail = styled(TxtField05)`
  flex-grow: 1;
  span {
    width: auto;
  }
`;

export const Delete = styled(Ico24)`
  margin: 12px;
`;

export const Space = styled(Ico24)`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
`;

import styled from 'styled-components';
import { Typ30, Typ60 } from 'dc/Typ';
import TextInput from './TextInput';
import TxtField12 from 'dc/TxtField12';

export const Wrapper = styled.div`
  width: 584px;
  margin: 0 auto;
`;

export const Title = styled(Typ60)`
  color: #546e7a;
`;

export const Description = styled(Typ30)`
  display: block;
  color: #546e7a;
  margin-bottom: 32px;
  span {
    color: #546e7a;
  }

  a {
    color: #03739a;
    text-decoration: underline;
  }
`;

export const ShortTextInput = styled(TextInput)`
  width: 320px !important;
`;

export const Label = styled(Typ30)`
  color: #546e7a;
  margin-bottom: 4px;
`;

export const LongTextInput = styled(TextInput)`
  width: 100%;
`;

export const HorizontalRule = styled.hr`
  margin-bottom: 56px;
  border-top: 1px solid #cfd8dc;
`;

export const DropdownInput = styled(TxtField12)`
  background-color: #fff;
  width: 288px;
`;

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences, props } from 'cerebral';
import { Input } from './elements';
import { ICON } from 'resources/constants';

export default connect(
  {
    value: state`admin.campaigns.create.info.form.${props`field`}.value`,
    fieldChanged: sequences`admin.campaigns.create.fieldChanged`,
  },
  function TextInput({ field, value, fieldChanged, placeholder, maxLength }) {
    return (
      <Input
        onChange={event =>
          fieldChanged({
            field: `admin.campaigns.create.info.form.${field}`,
            value: event.target.value,
          })
        }
        value={value}
        icon={ICON.modeEdit}
        placeholder={placeholder}
        maxLength={maxLength}
      />
    );
  }
);

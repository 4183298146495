export default {
  state: {
    tags: [],
    taggedActivities: [],
    form: {
      activity_tutorial_01: {
        value: true,
        defaultValue: '',
        validationRules: ['isValue'],
      },
    },
  },
  sequences: {},
};

import React from 'react';
import * as PropTypes from 'prop-types';
import {
  ElementWrapper,
  Input,
  ErrorMessageWrapper,
  ErrorMessage,
} from './elements';

function TxtField01(props) {
  return (
    <ElementWrapper className={props.className}>
      <Input
        whiteBG={props.whiteBG}
        className={props.className}
        disabled={props.disabled}
        error={props.errorMessage || props.error}
        name={props.name}
        onChange={props.onChange}
        placeholder={props.placeholder}
        type={props.inputType}
        defaultValue={props.defaultValue}
        value={props.value}
        min={props.min}
        max={props.max}
        step={props.step}
        accept={props.accept}
      />
      <ErrorMessageWrapper>
        <ErrorMessage errorMessage={props.errorMessage || props.error}>
          {props.errorMessage}
        </ErrorMessage>
      </ErrorMessageWrapper>
    </ElementWrapper>
  );
}

TxtField01.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.node,
  inputType: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.node,
  whiteBG: PropTypes.bool,
};

export default TxtField01;

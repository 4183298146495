import React from 'react';
import * as PropTypes from 'prop-types';
import {
  OuterWrapper,
  InputWrapper,
  TextInput,
  Icon,
  DeleteIcon,
  IconPlaceholder,
} from './elements';

function TglTextField1(props) {
  return (
    <OuterWrapper className={props.className}>
      <InputWrapper hasMargin={props.expanded}>
        <Icon
          onClick={props.onExpandClick}
          icon={
            props.expanded
              ? 'icon-keyboard_arrow_down'
              : 'icon-keyboard_arrow_right'
          }
        />
        <TextInput
          version={'default'}
          label={props.placeholder}
          value={props.value}
          onKeyDown={props.onKeyDown}
          onChange={props.onChange}
          maxLength={props.maxLength}
        />
        {props.value || props.error ? (
          <DeleteIcon icon={'icon-cancel'} onClick={props.onDeleteClick} />
        ) : (
          <IconPlaceholder />
        )}
      </InputWrapper>
      {props.expanded ? props.children : null}
    </OuterWrapper>
  );
}

TglTextField1.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  expanded: PropTypes.bool,
  children: PropTypes.object,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  onExpandClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default TglTextField1;

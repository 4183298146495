import { set } from 'cerebral/factories';
import { state } from 'cerebral';
import * as actions from './actions';
import { getData } from '../sequences';

export const saveNewStructureClicked = [
  set(state`admin.campaigns.edit.structure.isSaving`, true),
  actions.extractNewEntities,
  actions.saveNewStructure,
  set(state`admin.campaigns.edit.structure.isSaving`, false),
  getData,
];

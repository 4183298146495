import styled, { css } from 'styled-components';
import Wra16A from '../Wra16A';
import Ico16 from '../Ico16';
import { Typ30 } from '../Typ';

function getBorderColor(props) {
  if (props.focus) {
    return '#00ab97';
  }

  return props.errorMessage ? '#e0487b' : '#cfd8dc';
}

export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InputWrapper = styled(Wra16A)`
  width: 100%;
  background-color: ${props => (props.isLocked ? '#f4f7f9' : '#ffffff')};
  cursor: ${props => (props.isLocked ? 'default' : 'pointer')};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => getBorderColor(props)};
`;

export const Icon = styled(Ico16)`
  display: flex;
  align-items: flex-start;
  color: ${props => getBorderColor(props)};
  margin-right: 8px;
`;

export const Text = styled(Typ30)`
  min-height: 3em;
  max-height: 5em;
  width: 100%;
  color: #90a4ae;
`;
export const ErrorMessage = styled(Typ30)`
  transform: scaleY(0);
  transition: transform 300ms ease;
  transform-origin: top;
  ${props =>
    props.errorMessage &&
    css`
      transform: scaleY(1);
    `};
  line-height: 20px;
  padding-top: 8px;
  color: #e0487b;
`;

export const Input = styled('textarea')`
  vertical-align: top;
  resize: none;
  min-height: 3em;
  max-height: 5em;
  outline: none;
  border: 0px solid;
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
  color: #263238;
  margin: 0;
  width: 100%;
`;

export default {
  ModLogin: {
    email: 'E-mail',
    password: 'Password',
    register: 'Sign Up',
    account: 'Account',
    login: 'Log in',
    send_magic_link: 'Send Magic Link',
    name: 'Name',
    send: 'send',
    super: 'Super',
    or: 'or',
  },
  ModTerms: {
    privacy_policy: 'Privacy Policy',
    last_updated: 'Updated %{date}',
    accept: 'Accept',
    decline: 'Decline',
    delete: 'DELETE ACCOUNT',
    go_back: 'GO BACK',
    are_you_sure: 'Are you sure?',
    are_you_sure_body:
      'By declining the updated terms your account and all your associated data will be deleted.',
    terms_updated: 'We’ve updated our terms',
    terms_of_use:
      'MARKDOWN:[Terms of use and Privacy policy](https://www.ducky.eco/en/terms)',
  },
  ModRegister: {
    i_have_read: 'I have read and understood the',
    terms_of_use: 'Terms of use and Privacy policy',
    or: 'or',
  },
  NavBar: {
    support: 'Support',
    products: 'Products',
    find_tools: 'Find your tools',
    logIn: 'Log in',
    menu: 'Menu',
    register: 'Register account',
    contact_us: 'Contact us',
    your_tools: 'Your tools',
    faq: 'Frequently asked questions',
    ProMen2: {
      active_since: 'Active since',
      log_out: 'Log out',
      profile: 'Edit your profile',
    },
  },
  network_status_notification: 'You’re offline. Please check your internet.',
  error_boundary: {
    title: 'Oops! Something went wrong',
    body: 'Our detective couldn’t solve the case',
    button_text: 'try again',
  },
  not_found: {
    title: 'We cannot find this page',
    body: '',
    button_text: 'Go back to the timeline',
  },
};

export default {
  _enum: 'indicator',
  co2: 'CO\u2082e',
  points: 'points',
  health: 'health',
  biodiversity: 'biodiversity',
  money: 'money',
  time: 'time',
  landuse: 'landuse',
  handprint: 'handprint',
  social: 'social',
};

import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 32px 0;
  padding: 16px 16px 16px 32px;
  background-color: #fdebee;
  border-radius: 3px;
  border-left: 4px solid #ef5451;

  > div > span:first-child {
    padding-top: 0;
  }
`;

import { Provider } from 'cerebral';
import * as uuid from 'uuid';

function UuidProvider() {
  return new Provider({
    nil() {
      return uuid.NIL;
    },
    parse(...args) {
      return uuid.parse(args);
    },
    stringify(...args) {
      return uuid.stringify(args);
    },
    v1(...args) {
      return uuid.v1(args);
    },
    v3(...args) {
      return uuid.v1(args);
    },
    v4(...args) {
      return uuid.v1(args);
    },
    v5(...args) {
      return uuid.v1(args);
    },
    validate(...args) {
      return uuid.validate(args);
    },
    version(...args) {
      return uuid.version(args);
    },
  });
}

export default UuidProvider;

export function getToken({ firebase }) {
  return firebase.getUserIDToken().then(result => ({
    token: result,
  }));
}

export function openWithToken({ props }) {
  const url = new URL(props.url);

  url.searchParams.append('token', props.token);

  window.open(url, '_blank');
}

export function formatChallengeDailyStats({ props }) {
  const monthlyDuckyStats = Object.entries(props.response?.value ?? {}).reduce(
    (total, [dt, dailyStats]) => {
      const datetime = Number(dt);
      const year = new Date(datetime).getFullYear().toString();
      const month = new Date(datetime).getMonth().toString();

      if (!total[year]) {
        total[year] = {
          total: {
            activitiesCount: 0,
            co2: 0,
          },
        };
      }
      if (!total[year][month]) {
        total[year][month] = {};
      }
      Object.entries(dailyStats || {}).forEach(([statsKey, statsValue]) => {
        if (!total[year][month][statsKey]) {
          total[year][month][statsKey] = 0;
        }
        if (statsKey === 'activitiesCount') {
          Object.entries(statsValue || {}).forEach(([, activityValue]) => {
            total[year][month][statsKey] += activityValue;
            total[year].total[statsKey] += activityValue;
          });
        } else {
          total[year][month][statsKey] += dailyStats[statsKey];
          total[year].total[statsKey] += dailyStats[statsKey];
        }
      });

      return total;
    },
    {}
  );

  return { monthlyDuckyStats };
}

export function formatProfileDailyStats({ props }) {
  const { monthlyDuckyStats } = props;
  const monthlyProfileStats = Object.entries(
    props.response?.value ?? {}
  ).reduce((totalProfileStats, [, challengeData]) => {
    Object.entries(challengeData || {}).forEach(([uid, userData]) => {
      Object.entries(userData || {}).forEach(([dt, dailyStats]) => {
        const datetime = Number(dt);
        const year = new Date(datetime).getFullYear().toString();
        const month = new Date(datetime).getMonth().toString();

        if (!totalProfileStats[year]) {
          totalProfileStats[year] = {};
        }
        if (!totalProfileStats[year].total) {
          totalProfileStats[year].total = {
            uniqueUsers: [],
            averageNumLogged: {},
          };
        }
        if (!totalProfileStats[year].total.uniqueUsers) {
          totalProfileStats[year].total.uniqueUsers = [];
        }
        if (!totalProfileStats[year].total.averageNumLogged) {
          totalProfileStats[year].total.averageNumLogged = {};
        }
        if (!totalProfileStats[year].total.uniqueUsers.includes(uid)) {
          totalProfileStats[year].total.uniqueUsers.push(uid);
        }
        if (!totalProfileStats[year][month]) {
          totalProfileStats[year][month] = {};
        }
        if (!totalProfileStats[year][month].uniqueUsers) {
          totalProfileStats[year][month].uniqueUsers = [];
        }
        if (!totalProfileStats[year][month].uniqueUsers.includes(uid)) {
          totalProfileStats[year][month].uniqueUsers.push(uid);
        }
        if (!totalProfileStats[year][month].averageNumLogged) {
          totalProfileStats[year][month].averageNumLogged = {};
        }

        if (!totalProfileStats[year].total.averageNumLogged[datetime]) {
          totalProfileStats[year].total.averageNumLogged[datetime] = {
            uniqueUsers: [],
            activitiesCount: 0,
          };
        }
        if (!totalProfileStats[year][month].averageNumLogged[datetime]) {
          totalProfileStats[year][month].averageNumLogged[datetime] = {
            uniqueUsers: [],
            activitiesCount: 0,
          };
        }
        if (
          !totalProfileStats[year][month].averageNumLogged[
            datetime
          ].uniqueUsers.includes(uid)
        ) {
          totalProfileStats[year][month].averageNumLogged[
            datetime
          ].uniqueUsers.push(uid);
        }
        if (
          !totalProfileStats[year].total.averageNumLogged[
            datetime
          ].uniqueUsers.includes(uid)
        ) {
          totalProfileStats[year].total.averageNumLogged[
            datetime
          ].uniqueUsers.push(uid);
        }

        totalProfileStats[year].total.averageNumLogged[
          datetime
        ].activitiesCount += Object.values(
          dailyStats.activitiesCount ?? {}
        ).reduce((prev, current) => prev + current, 0);

        totalProfileStats[year][month].averageNumLogged[
          datetime
        ].activitiesCount += Object.values(
          dailyStats.activitiesCount ?? {}
        ).reduce((prev, current) => prev + current, 0);
      });
    });

    return totalProfileStats;
  }, monthlyDuckyStats);

  return { monthlyStats: monthlyProfileStats };
}

import styled from 'styled-components';
import Ico20 from '../Ico20';
import { Typ30 } from '../Typ';

export const ElementWrapper = styled.div`
  display: flex;
`;

export const Icon = styled(Ico20)`
  color: ${props => (props.color ? props.color : '#607d8b')};
  margin-right: 8px;
`;

export const TitleText = styled(Typ30)`
  color: #263238;
`;

import React, { useRef } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { useText } from 'common/hooks';
import {
  Wrapper,
  Input,
  Explanation,
  Button,
  Bar,
  OptionWrapper,
} from './elements';
import { ICON } from 'resources/constants';

export default connect(
  {
    isLoadingStructure: state`admin.structure.isLoadingStructure`,
    organisationKey: state`admin.campaigns.create.organisation.organisationKey`,
    organisation: state`admin.campaigns.create.organisation.organisations.${state`admin.campaigns.create.organisation.organisationKey`}`,
    structureForm: state`admin.structure.structureForm`,
    setStructureFromExcelSheet: sequences`admin.structure.setStructureFromExcelSheet`,
    setStructureFromOrganisation: sequences`admin.structure.setStructureFromOrganisation`,
  },
  function ImportStructure({
    isLoadingStructure,
    organisationKey,
    organisation,
    structureForm,
    setStructureFromExcelSheet,
    setStructureFromOrganisation,
  }) {
    const t = useText();
    const ref = useRef();

    const hasData = Object.keys(structureForm.sections || {}).length > 0;

    return (
      <Wrapper>
        <OptionWrapper>
          <Button
            disabled={isLoadingStructure || hasData}
            icon={ICON.fileUpload}
            onClick={() => {
              ref.current?.click();
            }}
            theme={'dark'}
          >
            <Input
              ref={ref}
              type={'file'}
              onChange={e => {
                setStructureFromExcelSheet({ file: e.target.files[0] });

                e.target.value = ''; // Clear the input so the same file can be selected many times after editing it
              }}
              accept={
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              }
            />
            {t('admin.structure.upload_teams')}
          </Button>
          <Explanation>{t('admin.structure.team_from_excel')}</Explanation>
        </OptionWrapper>
        <Bar />
        <OptionWrapper>
          <Button
            disabled={
              !organisation ||
              !organisation.departments ||
              isLoadingStructure ||
              hasData
            }
            icon={ICON.fileUpload}
            onClick={() => setStructureFromOrganisation({ organisationKey })}
            theme={'dark'}
          >
            {t('admin.structure.use_org_structure')}
          </Button>
          <Explanation>{t('admin.structure.org_structure')}</Explanation>
        </OptionWrapper>
      </Wrapper>
    );
  }
);

import React from 'react';
import { connect } from '@cerebral/react';
import { state, props } from 'cerebral';
import { Wrapper, Avatar, Text } from './elements';
import { useText, useImage, useGetAvatarUrl } from 'common/hooks';

export default connect(
  {
    profile: state`appContainer.profiles.${props`profileKey`}`,
  },
  function ChaSnB1({ profile, joinedCount }) {
    const t = useText();
    const getAvatarUrl = useGetAvatarUrl();
    const [url, isLoading] = useImage(getAvatarUrl(profile).small);

    return (
      <Wrapper>
        <Avatar isLoading={isLoading} image={url} />
        <Text>
          {joinedCount === 1
            ? t('messages.snackbar.challenge.participant_joined', {
                name: profile
                  ? profile.name
                  : t('messages.snackbar.deleted_user'),
              })
            : t('messages.snackbar.challenge.participants_joined', {
                name: profile
                  ? profile.name
                  : t('messages.snackbar.deleted_user'),
                smart_count: joinedCount - 1,
              })}
        </Text>
      </Wrapper>
    );
  }
);

import { useContext } from 'react';
import { primaryLanguage } from 'languages/config';
import LanguageContext from '../LanguageContext';
import ConfigContext from '../ConfigContext';

type Options = {
  endpoint?: string;
  domain?: string;
};

export default function useLink(): (path: string, options?: Options) => string {
  const { locale } = useContext(LanguageContext);
  const { domain } = useContext(ConfigContext);

  return function link(path: string, options?: Options): string {
    if (options?.endpoint) {
      return `https://${options.endpoint}.${options?.domain ?? domain}${
        locale === primaryLanguage ? '/' : `/${locale}/`
      }${path}`;
    }

    return `${locale === primaryLanguage ? '/' : `/${locale}/`}${path}`;
  };
}

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';

import { Modal } from './elements';
import AdminActMod02 from './AdminActMod02';

export default connect(
  {
    showAdminActivityModal: state`admin.campaigns.create.challenge.activityModal.showAdminActivityModal`,
    closeAdminActivityModalClicked: sequences`admin.campaigns.create.challenge.activityModal.closeAdminActivityModalClicked`,
  },
  function AdminActMod00({
    showAdminActivityModal,
    closeAdminActivityModalClicked,
  }) {
    if (!showAdminActivityModal) {
      return null;
    }

    return (
      <Modal
        onHide={() => closeAdminActivityModalClicked()}
        show={showAdminActivityModal}
      >
        <AdminActMod02 />
      </Modal>
    );
  }
);

import styled from 'styled-components';

export const Typ10 = styled.span`
  font-size: 10px;
  line-height: 12px;
  font-weight: normal;
  color: #546e7a;
  margin: 0;
`;

export const Typ11 = styled.span`
  font-size: 10px;
  line-height: 12px;
  font-weight: bold;
  color: #263238;
  margin: 0;
`;

export const Typ20 = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  color: #546e7a;
  margin: 0;
`;

export const Typ21 = styled.span`
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  color: #263238;
  margin: 0;
`;

export const Typ30 = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: #263238;
  margin: 0;
`;

export const Typ31 = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #263238;
  margin: 0;
`;

export const Typ32 = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  color: #263238;
  margin: 0;
`;

export const Typ33 = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-transform: uppercase;
  color: #546e7a;
  margin: 0;
`;

export const Typ35 = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: #263238;
`;

export const Typ36 = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #263238;
`;

export const Typ40 = styled.span`
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  color: #263238;
  margin: 0;
`;

export const Typ41 = styled.span`
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: #263238;
  margin: 0;
`;

export const Typ42 = styled.span`
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  color: #263238;
  margin: 0;
`;

export const Typ50 = styled.h4`
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  color: #263238;
  margin: 0;
`;

export const Typ51 = styled.h4`
  font-size: 24px;
  line-height: 28px;
  font-weight: normal;
  color: #263238;
  margin: 0;
`;

export const Typ60 = styled.h3`
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  color: #263238;
  margin: 0;
`;

export const Typ61 = styled.h3`
  font-size: 32px;
  line-height: 40px;
  font-weight: normal;
  color: #263238;
`;

export const Typ70 = styled.h2`
  font-size: 48px;
  line-height: 56px;
  font-weight: bold;
  color: #263238;
  margin: 0;
`;

export const Typ71 = styled.h2`
  font-size: 48px;
  line-height: 56px;
  font-weight: normal;
  color: #263238;
  margin: 0;
`;

export const Typ80 = styled.h1`
  font-size: 56px;
  line-height: 64px;
  font-weight: bold;
  color: #263238;
  margin: 0;
`;

export const Typ81 = styled.h1`
  font-size: 56px;
  line-height: 64px;
  font-weight: normal;
  color: #263238;
  margin: 0;
`;

import React from 'react';
import * as PropTypes from 'prop-types';
import { Wrapper, OuterWrapper, Children } from './elements';
import Load02 from 'dc/Load02';

function Load03(props) {
  return (
    <Wrapper className={props.className}>
      <OuterWrapper>
        <Load02 icon={'icon-photo'} />
      </OuterWrapper>
      {props.children ? <Children>{props.children}</Children> : null}
    </Wrapper>
  );
}

Load03.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Load03;

import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state } from 'app/pwa/app.cerebral';

import { DesktopModal, MobileModal } from './elements';

import Terms from './Terms';
import ConfirmDeletion from './ConfirmDeletion';

type Props = {
  readonly isMobile?: boolean;
};

const deps = {
  showTermsModal: state.appContainer.showTermsModal,
  showConfirmDeletion: state.appContainer.showConfirmDeletion,
};

export function TermsModalComponent({
  isMobile,
  showTermsModal,
  showConfirmDeletion,
}: Props & typeof deps & ConnectedProps): React.ReactElement {
  if (isMobile) {
    return (
      <MobileModal>
        {showConfirmDeletion ? <ConfirmDeletion /> : <Terms />}
      </MobileModal>
    );
  }

  return (
    <DesktopModal show={showTermsModal}>
      {showConfirmDeletion ? <ConfirmDeletion /> : <Terms />}
    </DesktopModal>
  );
}

export default connect<Props, typeof deps>(deps, TermsModalComponent);

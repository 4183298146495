import styled from 'styled-components';
import { Typ60 } from 'dc/Typ';
import TxtField12 from 'dc/TxtField12';
import Btn021 from 'dc/Btn021';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 584px;
`;

export const Title = styled(Typ60)`
  color: #546e7a;
  margin-bottom: 16px;
`;

export const DropdownInput = styled(TxtField12)`
  background-color: #fff;
  width: 288px;
  z-index: 3;
`;

export const JoinButton = styled(Btn021)`
  width: 288px;
  background-color: #03739a;
`;

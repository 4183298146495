import React, { useState } from 'react';
import { connect } from '@cerebral/react';
import { state, props, sequences } from 'cerebral';
import { Field } from 'forms';
import { ICON } from 'resources/constants';
import { useText } from 'common/hooks';

import { Wrapper, Header, Caret, Input, Delete, Space } from './elements';
import Button from 'dc/Button';

export default connect(
  {
    nameField: state`admin.structure.structureForm.sections.${props`sectionKey`}.name`,
    teams: state`admin.structure.structureForm.sections.${props`sectionKey`}.teams`,
    exists: state`admin.structure.structureForm.sections.${props`sectionKey`}.exists`,
    sectionFieldChanged: sequences`admin.structure.sectionFieldChanged`,
    deleteSection: sequences`admin.structure.deleteSection`,
  },
  function Section({
    className,
    children,
    nameField,
    isNew,
    exists,
    sectionFieldChanged,
    deleteSection,
    sectionKey,
    teams,
    get,
  }) {
    const [expanded, setExpanded] = useState(true);
    const t = useText();
    const name = new Field(nameField);

    name._validate(get);

    const teamCount = Object.keys(teams || {}).length;

    return (
      <Wrapper className={className}>
        <Header>
          <Caret
            expanded={expanded}
            icon={ICON.keyboardArrowDown}
            onClick={() => setExpanded(!expanded)}
          />
          <Input
            disabled={name.readOnly}
            label={t('admin.structure.form.section_name')}
            errorMessage={
              (name.errorMessage && t(name.errorMessage.phrase)) ||
              (teamCount === 0 &&
                !isNew &&
                t('admin.structure.at_least_one_team')) ||
              ''
            }
            value={isNew ? '' : name.value}
            onChange={event =>
              sectionFieldChanged({
                isNew,
                sectionKey,
                fieldName: 'name',
                value: event.target.value,
              })
            }
          />
          {!isNew && !exists ? (
            <Button onClick={() => deleteSection({ sectionKey })}>
              <Delete icon={ICON.cancel} />
            </Button>
          ) : (
            <Space />
          )}
        </Header>
        {expanded && children}
      </Wrapper>
    );
  }
);

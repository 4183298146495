import React from 'react';
import * as PropTypes from 'prop-types';

import {
  Wrapper,
  Line,
  Text,
  ButtonsWrapper,
  MainButton,
  CloseButton,
} from './elements';

type Props = {
  className?: string;
  text: string;
  mainButtonText?: string;
  closeButtonOnClick?: () => void;
  mainButtonOnClick?: () => void;
  closeButtonVisible?: boolean;
  mainButtonVisible?: boolean;
};

function StaBar({
  className,
  text,
  mainButtonOnClick,
  mainButtonVisible,
  mainButtonText,
  closeButtonOnClick,
  closeButtonVisible,
}: Props): React.ReactElement {
  return (
    <Wrapper className={className}>
      <Line />
      <Text>{text}</Text>
      <ButtonsWrapper>
        <MainButton onClick={mainButtonOnClick} visible={mainButtonVisible}>
          {mainButtonText}
        </MainButton>
        {closeButtonVisible && (
          <CloseButton
            onClick={closeButtonOnClick}
            icon={'icon-close'}
            color={'#fff'}
          />
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
}

StaBar.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  mainButtonVisible: PropTypes.bool,
  mainButtonText: PropTypes.string,
  mainButtonOnClick: PropTypes.func,
  closeButtonOnClick: PropTypes.func,
  closeButtonVisible: PropTypes.bool,
};

export default StaBar;

export default {
  errors: {
    uid_already_exists:
      'A user with your identifier already exists; please get in contact with us',
    invalid_action_code:
      'The link from your email is invalid. This can happen if the link is malformed, expired, or has already been used.',
    account_exists_with_different_credential:
      'The email you entered already exists. Perhaps you have already created a user with another login method?',
    declined_terms:
      'You need to agree to our terms and conditions before you can use Ducky.',
    email_already_in_use:
      'This email has already been registered. Log in or use another email address.',
    no_code:
      'Could not log in. Please send us the information below and we will help you as soon as possible.',
    popup_blocked: 'Your browser appears to block the pop-up window for login.',
    popup_closed_by_user:
      'The pop-up window for login has been closed before we could log you in.',
    too_many_requests:
      'You attempted to log in too soon after a failed login, wait a few seconds and try again.',
    facebook_creation_failed:
      'Registration with your Facebook user failed. Perhaps you have previously registered with email and password or Google where the email is the same as your Facebook user has?',
    facebook_has_no_email:
      'Your Facebook account does not have a registered email address. Please log in with either Google or an email address.',
    google_login_failed:
      'Registration with your Google account user failed. Perhaps you have previously registered with email or Feide where the email is the same as your Google user has?',
    rate_limit: 'Too many requests. Please wait for a bit and then try again.',
    invalid_login: 'Invalid email or password. Please try again.',
  },
  fieldErrors: {
    invalid_email: 'Invalid E-mail address',
    only_letters: 'Can only contain letters',
    too_short: 'Must contain %{length} or more letters',
    mandatory: 'Must be filled in',
    password_mismatch: 'Passwords do not match',
    email_no_quotes: `You can not have ' in an email`,
    email_valid_TLD: '.%{tld} is not a valid email',
  },
  create_sustainable_society: 'Together we can create a sustainable society',
  verify_email:
    'Please re-enter your name and email to verify it is really you.',
  or_with_email: 'Or with email',
  close: 'Close',
  back_to_login: 'Back to log in',
  back_to_registration: 'Back to registration',
  confirm_account: 'Confirm account',
  error: 'Error:\r\n%{errorMessage}',
  existing_user: 'Already have an account?',
  forgot_password: 'Forgot your password',
  forgot_password_confirmation_description:
    'An email with instructions has been sent to your email address.',
  send_forgot_password_email:
    'No stress! Enter your email address and we’ll send you a link to reset it.',
  send_create_password_email:
    'No stress! Enter your email address and we’ll send you a link to create it.',
  go_back_to: 'Return to',
  login_with_facebook: 'Log in with Facebook',
  login_with_google: 'Log in with Google',
  lost_password: 'Forgot your password?',
  need_password: 'Need a password?',
  min_password_length: 'The password must have more than six characters',
  missing_password: 'Enter password',
  must_log_in: 'You must log in as a user on Ducky to perform this action.',
  new_user: 'New user?',
  not_logged_in: 'You are not logged in',
  or_login_with_email: 'Or login with email',
  or_register_with_email: 'Or register with email and password',
  password_reset_sent:
    'We have sent you an email with instructions for changing your password',
  provider_login_error: 'Error with %{provider} login',
  provider_error_description:
    'You tried to log in to Ducky with %{provider}, but we did not receive a response. Therefore, the login did not work.\r\n\r\nYou can either verify your email at %{provider} and try again or create another account!',
  register_account: 'Sign up',
  register_with_facebook: 'Sign up with Facebook',
  register_with_google: 'Sign up with Google',
  repeat_password: 'Repeat password',
  terms_and_conditions_text: 'By registering account you agree',
  terms_and_conditions_link: 'Terms of Use and Privacy',
  terms_and_conditions_ducky: 'at ducky.eco.',
  valid_email: 'Please enter a valid email address',
  valid_email_misspelled: 'Have you written the e-mail correctly?',
  valid_name: 'Enter a valid name',
  valid_password: 'Enter the correct password',
  use_email: 'Sign up with email',
  use_another: 'Go Back',
  verify_email_address: 'Verify your email address',
  thanks_for_joining:
    'Thank you for joining us! To finish signing up check and verify the email we sent to',
  wrong_address: 'wrong email address?',
  login_again: 'then login again',
  refresh: 'Refresh',
  not_received: 'Not received an email?',
  resend: 'Try again',
  resend_email: 'RESEND EMAIL',
  check_email_address: 'Check your emails',
  follow_instructions_create:
    'Follow instructions to create your password in the email sent to',
  follow_instructions_reset:
    'Follow instructions to reset your password in the email sent to',
  create_a_password: 'Create a password',
  must_be_8_characters: 'Must be at least 8 characters',
  create_password: 'Create password',
  password_created: 'Password created!',
  use_next_time: 'You can use this password the next time you log in',
  continue: 'Continue',
  dont_have_password:
    'Signed up with email but don’t have a password? Don’t panic!',
  follow_steps: 'Follow steps to create a password.',
  email_confirmed: 'Email confirmed!',
};

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { useText } from 'common/hooks';

import {
  Wrapper,
  Title,
  Subtitle,
  DropDownWrapper,
  DropDown,
  RadioButtonsWrapper,
  RadioButton,
  HorizontalRule,
  Button,
  ButtonWrapper,
} from './elements';

export default connect(
  {
    linksData: state`admin.championships.edit.computedChampionshipLinks`,
    languageSelected: state`admin.championships.edit.languageSelected.optionKey`,
    seriesOptions: state`admin.championships.edit.seriesOptions`,
    seriesSelected: state`admin.championships.edit.seriesSelected`,
    organisationOptions: state`admin.championships.edit.organisationOptions`,
    organisationSelected: state`admin.championships.edit.organisationSelected`,
    sectionOptions: state`admin.championships.edit.sectionOptions`,
    sectionSelected: state`admin.championships.edit.sectionSelected`,
    checkedComparison: state`admin.championships.edit.checkedComparison`,
    campaigns: state`admin.championships.edit.campaigns`,
    dropdownSelected: sequences`admin.championships.edit.dropdownSelected`,
    comparisonOptionClicked: sequences`admin.championships.edit.comparisonOptionClicked`,
  },
  function ChampionshipReport({
    linksData,
    languageSelected,
    seriesOptions,
    seriesSelected,
    organisationOptions,
    organisationSelected,
    sectionOptions,
    sectionSelected,
    checkedComparison,
    campaigns,
    dropdownSelected,
    comparisonOptionClicked,
  }) {
    const t = useText();

    return (
      <Wrapper>
        <Title>{t(`admin.overview.reports.championship`)}</Title>
        {Object.keys(campaigns || {}).length > 0 ? (
          <>
            <Subtitle>
              {t(`admin.overview.reports.download_championship_summary`)}
            </Subtitle>
            <RadioButtonsWrapper>
              <RadioButton
                checked={checkedComparison === 'organisations'}
                onClick={() =>
                  comparisonOptionClicked({
                    value: 'organisations',
                    type: 'checkedComparison',
                  })
                }
                label={t(`admin.overview.reports.compare_organisations`)}
              />
              <RadioButton
                checked={checkedComparison === 'sections'}
                onClick={() =>
                  comparisonOptionClicked({
                    value: 'sections',
                    type: 'checkedComparison',
                  })
                }
                label={t(`admin.overview.reports.compare_sections`)}
              />
              <RadioButton
                checked={checkedComparison === 'teams'}
                onClick={() =>
                  comparisonOptionClicked({
                    value: 'teams',
                    type: 'checkedComparison',
                  })
                }
                label={t(`admin.overview.reports.compare_teams`)}
              />
            </RadioButtonsWrapper>
            <HorizontalRule />
            <Subtitle>{t(`admin.overview.reports.select_audience`)}</Subtitle>
            <DropDownWrapper>
              <DropDown
                options={seriesOptions}
                placeholder={t(`admin.overview.reports.filter_series`)}
                value={seriesSelected.optionValue}
                onDropdownItemClick={option =>
                  dropdownSelected({
                    context: 'series',
                    option,
                  })
                }
              />
              <DropDown
                options={organisationOptions}
                disabled={checkedComparison === 'organisations'}
                placeholder={t(`admin.overview.reports.filter_organisations`)}
                value={organisationSelected.optionValue}
                onDropdownItemClick={option =>
                  dropdownSelected({
                    context: 'organisation',
                    option,
                  })
                }
              />
              <DropDown
                options={sectionOptions}
                disabled={checkedComparison !== 'teams'}
                placeholder={t(`admin.overview.reports.filter_sections`)}
                value={sectionSelected.optionValue}
                onDropdownItemClick={option =>
                  dropdownSelected({
                    context: 'section',
                    option,
                  })
                }
              />
            </DropDownWrapper>
            <ButtonWrapper>
              <Button
                href={
                  linksData?.[languageSelected]?.downloads?.reports?.report
                    ?.url ?? ''
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {t(`admin.overview.reports.download_report`)}
              </Button>
            </ButtonWrapper>
            <HorizontalRule />
          </>
        ) : (
          <Subtitle>
            {t(`admin.overview.reports.no_reports_available`)}
          </Subtitle>
        )}
      </Wrapper>
    );
  },
);

import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import {
  ComponentWrapper,
  InputWrapper,
  ErrorMessage,
  Text,
  Icon,
  Input,
} from './elements';

function TxtField07(props) {
  const [focused, setFocused] = useState(false);

  return (
    <ComponentWrapper className={props.className}>
      <InputWrapper
        isLocked={props.isLocked}
        errorMessage={props.errorMessage}
        focus={focused}
      >
        <Icon
          errorMessage={props.errorMessage}
          focus={focused}
          icon={props.icon}
        />
        {!props.isLocked ? (
          <Input
            onChange={props.onChange}
            value={props.value}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            placeholder={focused ? null : props.placeholder}
          />
        ) : (
          <Text>{props.value}</Text>
        )}
      </InputWrapper>
      <ErrorMessage errorMessage={props.errorMessage}>
        {props.errorMessage}
      </ErrorMessage>
    </ComponentWrapper>
  );
}

TxtField07.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  isLocked: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
};

export default TxtField07;

/*  eslint-disable react/no-multi-comp  */
import React from 'react';
import AppContainer from 'common/appContainer/AppContainer';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';

import NavBar from './NavBar';
import NotAuthorized from './NotAuthorized';
import Dashboard from './Dashboard';
import Organisations from './Organisations';
import CreateOrganisation from './Organisations/CreateOrganisation';
import Campaigns from './Campaigns';
import CreateCampaign from './Campaigns/CreateCampaign';
import Championships from './Championships';
import CreateChampionship from './Championships/CreateChampionship';
import topicsData from 'toolData/topics';

import {
  OuterWrapper,
  InnerWrapper,
  SideBar,
  SideBarText,
  LoadingUser,
  OptionWrapper,
  StatusBar,
} from './elements';

const pages = {
  notAuthorized: NotAuthorized,
  dashboard: Dashboard,
  organisations: Organisations,
  createOrganisation: CreateOrganisation,
  campaigns: Campaigns,
  createCampaign: CreateCampaign,
  championships: Championships,
  createChampionship: CreateChampionship,
};

const AppComponent = connect(
  {
    page: state`admin.page`,
    pageSection: state`admin.pageSection`,
    pageSectionExpanded: state`admin.pageSectionExpanded`,
    isServer: state`appContainer.isServer`,
    isEditCampaign: state`admin.campaigns.edit.isEdit`,
    isEditChampionship: state`admin.championships.edit.isEdit`,
    isJoiningChampionship: state`admin.campaigns.create.isJoiningChampionship`,
    isEditOrganisation: state`admin.organisations.edit.isEdit`,
    campaignTopic: state`admin.campaigns.create.focus.form.topic.value`,
    campaignAudience: state`admin.campaigns.create.focus.form.audience.value`,
    create: state`admin.campaigns.create`,
    integrationAdded: state`admin.campaigns.create.integration.added`,
    pageClicked: sequences`admin.pageClicked`,
    pageSectionClicked: sequences`admin.pageSectionClicked`,
    expandChallenge: sequences`admin.campaigns.create.challenge.expandChallenge`,
  },
  function App({
    page,
    pageSection,
    isServer,
    isEditCampaign,
    isEditChampionship,
    isJoiningChampionship,
    isEditOrganisation,
    campaignTopic,
    campaignAudience,
    create,
    integrationAdded,
    pageClicked,
    pageSectionClicked,
    pageSectionExpanded,
    expandChallenge,
  }) {
    const Page = pages[page];
    function displayToolTab(tool) {
      return (
        topicsData[campaignAudience] &&
        topicsData[campaignAudience][campaignTopic] &&
        topicsData[campaignAudience][campaignTopic][tool] &&
        Object.keys(topicsData[campaignAudience][campaignTopic][tool]).length >
          0 &&
        (isJoiningChampionship ? create[tool].added : true)
      );
    }

    if (isServer) {
      return (
        <div>
          <NavBar type={'NavBarA'} showLanguageSelector activePage={'admin'} />
          <LoadingUser>{'Loading user...'}</LoadingUser>
        </div>
      );
    }

    function renderSideBar() {
      if (page === 'createOrganisation') {
        return (
          <SideBar>
            <SideBarText
              disabled={isEditOrganisation}
              selected={pageSection === 'info'}
              onClick={() => {
                pageSectionClicked({ section: 'info' });
              }}
            >
              {'Basic Info'}
            </SideBarText>
            <SideBarText
              disabled={isEditOrganisation}
              selected={pageSection === 'structure'}
              onClick={() => {
                pageSectionClicked({ section: 'structure' });
              }}
            >
              {'Organisation Structure'}
            </SideBarText>
          </SideBar>
        );
      } else if (page === 'createCampaign') {
        return (
          <SideBar>
            <SideBarText
              disabled={isEditCampaign || isJoiningChampionship}
              selected={pageSection === 'organisation'}
              onClick={() => {
                pageSectionClicked({ section: 'organisation' });
              }}
            >
              {'Choose organiser'}
            </SideBarText>
            <SideBarText
              selected={pageSection === 'focus'}
              disabled={isEditCampaign || isJoiningChampionship}
              onClick={() => {
                pageSectionClicked({ section: 'focus' });
              }}
            >
              {'Choose your focus'}
            </SideBarText>
            <SideBarText
              selected={pageSection === 'info'}
              disabled={isEditCampaign}
              onClick={() => {
                pageSectionClicked({ section: 'info' });
              }}
            >
              {'Describe your campaign'}
            </SideBarText>
            {displayToolTab('challenge') ? (
              <SideBarText
                disabled={isEditCampaign}
                selected={pageSection === 'challenge'}
                onClick={() => {
                  create.challenge.added
                    ? expandChallenge()
                    : pageSectionClicked({ section: 'challenge' });
                }}
              >
                {'Set up Challenge'}
              </SideBarText>
            ) : null}
            <OptionWrapper
              show={pageSectionExpanded === 'challenge'}
              maxHeight={'208px'}
            >
              <SideBarText
                indent={1}
                disabled={isJoiningChampionship}
                selected={pageSection === 'challengeActivities'}
                onClick={() => {
                  pageSectionClicked({ section: 'challengeActivities' });
                }}
              >
                {'Choose Activities'}
              </SideBarText>
              <SideBarText
                indent={1}
                selected={pageSection === 'challengeTerms'}
                onClick={() => {
                  pageSectionClicked({ section: 'challengeTerms' });
                }}
              >
                {'Set up terms of competitions'}
              </SideBarText>
              <SideBarText
                indent={1}
                disabled={isJoiningChampionship}
                selected={pageSection === 'challengeDuration'}
                onClick={() => {
                  pageSectionClicked({ section: 'challengeDuration' });
                }}
              >
                {'Choose duration'}
              </SideBarText>
            </OptionWrapper>
            {!(isJoiningChampionship && !integrationAdded) ? (
              <SideBarText
                disabled={isEditCampaign || isJoiningChampionship}
                selected={pageSection === 'integration'}
                onClick={() => {
                  pageSectionClicked({ section: 'integration' });
                }}
              >
                {'Set up integration'}
              </SideBarText>
            ) : null}
            <OptionWrapper
              show={pageSectionExpanded === 'integration'}
              maxHeight={'104px'}
            >
              <SideBarText
                disabled={isJoiningChampionship}
                indent={1}
                selected={pageSection === 'publicPages'}
                onClick={() => {
                  pageSectionClicked({ section: 'publicPages' });
                }}
              >
                {'Activate public pages'}
              </SideBarText>
              <SideBarText
                disabled={isJoiningChampionship}
                indent={1}
                selected={pageSection === 'widgets'}
                onClick={() => {
                  pageSectionClicked({ section: 'widgets' });
                }}
              >
                {'Activate widgets'}
              </SideBarText>
            </OptionWrapper>
            <SideBarText
              disabled={isEditCampaign}
              selected={pageSection === 'access'}
              onClick={() => {
                pageSectionClicked({ section: 'access' });
              }}
            >
              {'Joining the campaign'}
            </SideBarText>
            <SideBarText
              selected={pageSection === 'teams'}
              onClick={() => {
                pageSectionClicked({ section: 'teams' });
              }}
            >
              {'Sections and Teams'}
            </SideBarText>
            <SideBarText
              selected={pageSection === 'publish'}
              onClick={() => {
                pageSectionClicked({ section: 'publish' });
              }}
            >
              {isEditCampaign ? 'Review' : 'Review and Publish'}
            </SideBarText>
          </SideBar>
        );
      } else if (page === 'createChampionship') {
        return (
          <SideBar>
            <SideBarText
              disabled={isEditChampionship}
              selected={pageSection === 'organisation'}
              onClick={() => {
                pageSectionClicked({ section: 'organisation' });
              }}
            >
              {'Choose Organiser'}
            </SideBarText>
            <SideBarText
              disabled={isEditChampionship}
              selected={pageSection === 'focus'}
              onClick={() => {
                pageSectionClicked({ section: 'focus' });
              }}
            >
              {'Choose your focus'}
            </SideBarText>
            <SideBarText
              disabled={isEditChampionship}
              selected={pageSection === 'info'}
              onClick={() => {
                pageSectionClicked({ section: 'info' });
              }}
            >
              {'Describe your championship'}
            </SideBarText>
            {displayToolTab('challenge') ? (
              <SideBarText
                disabled={isEditChampionship}
                selected={pageSection === 'challenge'}
                onClick={() => {
                  create.challenge.added
                    ? expandChallenge()
                    : pageSectionClicked({ section: 'challenge' });
                }}
              >
                {'Set up Challenge'}
              </SideBarText>
            ) : null}
            <OptionWrapper
              show={pageSectionExpanded === 'challenge'}
              maxHeight={'156px'}
            >
              <SideBarText
                disabled={isEditChampionship}
                indent={1}
                selected={pageSection === 'challengeActivities'}
                onClick={() => {
                  pageSectionClicked({ section: 'challengeActivities' });
                }}
              >
                {'Choose Activities'}
              </SideBarText>
              <SideBarText
                indent={1}
                selected={pageSection === 'challengeTerms'}
                onClick={() => {
                  pageSectionClicked({ section: 'challengeTerms' });
                }}
              >
                {'Set up terms of competitions'}
              </SideBarText>
              <SideBarText
                disabled={isEditChampionship}
                indent={1}
                selected={pageSection === 'challengeDuration'}
                onClick={() => {
                  pageSectionClicked({ section: 'challengeDuration' });
                }}
              >
                {'Choose duration'}
              </SideBarText>
            </OptionWrapper>
            <SideBarText
              disabled={isEditChampionship}
              selected={pageSection === 'integration'}
              onClick={() => {
                pageSectionClicked({ section: 'integration' });
              }}
            >
              {'Set up integration'}
            </SideBarText>
            <OptionWrapper
              show={pageSectionExpanded === 'integration'}
              maxHeight={'104px'}
            >
              <SideBarText
                disabled={isEditChampionship}
                indent={1}
                selected={pageSection === 'publicPages'}
                onClick={() => {
                  pageSectionClicked({ section: 'publicPages' });
                }}
              >
                {'Activate public pages'}
              </SideBarText>
              <SideBarText
                disabled={isEditChampionship}
                indent={1}
                selected={pageSection === 'widgets'}
                onClick={() => {
                  pageSectionClicked({ section: 'widgets' });
                }}
              >
                {'Activate widgets'}
              </SideBarText>
            </OptionWrapper>
            <SideBarText
              disabled={isEditChampionship}
              selected={pageSection === 'publish'}
              onClick={() => {
                pageSectionClicked({ section: 'publish' });
              }}
            >
              {'Review and Publish'}
            </SideBarText>
          </SideBar>
        );
      }

      return (
        <SideBar>
          <SideBarText
            selected={page === 'dashboard'}
            onClick={() => {
              pageClicked();
            }}
          >
            {'Dashboard'}
          </SideBarText>
          <SideBarText
            selected={page === 'organisations'}
            onClick={() => {
              pageClicked({ page: 'organisations' });
            }}
          >
            {'Organisations'}
          </SideBarText>
          <SideBarText
            selected={page === 'campaigns'}
            onClick={() => {
              pageClicked({ page: 'campaigns' });
            }}
          >
            {'Campaigns'}
          </SideBarText>
          <SideBarText
            selected={page === 'championships'}
            onClick={() => {
              pageClicked({ page: 'championships' });
            }}
          >
            {'Championships'}
          </SideBarText>
        </SideBar>
      );
    }

    return (
      <OuterWrapper>
        <NavBar type={'NavBarA'} showLanguageSelector activePage={'admin'} />
        <StatusBar
          text={'Creating an organisation'}
          mainButtonVisible={false}
          closeButtonOnClick={() => {}}
        />
        <InnerWrapper>
          {renderSideBar()}
          {Page ? <Page /> : 'Not Found'}
        </InnerWrapper>
      </OuterWrapper>
    );
  },
);

export default function Container({ app }) {
  return (
    <AppContainer app={app}>
      <AppComponent />
    </AppContainer>
  );
}

import styled from 'styled-components';
import { Typ30, Typ60 } from 'dc/Typ';
import Btn021 from 'dc/Btn021';
import Lab401 from 'dc/Lab401';
import Btn020 from 'dc/Btn020';

export const Wrapper = styled.div`
  background-color: #fff;
  min-height: calc(100vh - 80px);
  border-bottom: 8px solid #455a64;
  padding: 80px;
  display: flex;
  justify-content: center;
  width: 100%;
  @media (max-width: 767px) {
    min-height: 500px;
    padding-top: 56px;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  flex: 1;
`;

export const Title = styled(Typ60)`
  padding-bottom: 16px;
`;

export const SubTitle = styled(Typ30)``;

export const NewButton = styled(Btn021)`
  margin-top: 38px;
  margin-bottom: 70px;
  display: block;
`;

export const Label = styled(Lab401)`
  margin-bottom: 32px;
  background-color: inherit;
`;

export const ShowButton = styled(Btn020)`
  margin-top: 76px;
  margin-bottom: 52px;
  width: 100%;
  text-align: center;
  > span {
    color: #03739a;
  }
`;

import React from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import { useText } from 'common/hooks';
import { Wrapper } from './elements';

export default connect(
  {
    hasTeamWithTeamLeaderEmail: state`admin.campaigns.computedHasTeamWithTeamLeaderEmail`,
  },
  function Warning({ hasTeamWithTeamLeaderEmail }) {
    const t = useText();

    return (
      hasTeamWithTeamLeaderEmail && (
        <Wrapper>{t('admin.structure.warning')} </Wrapper>
      )
    );
  }
);

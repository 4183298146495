import React from 'react';
import dayjs from 'dayjs';
import { state, sequences } from 'cerebral';
import { connect } from '@cerebral/react';
import {
  Wrapper,
  ContentWrapper,
  NewButton,
  Title,
  SubTitle,
  Label,
  ShowButton,
} from './elements';

export default connect(
  {
    campaigns: state`admin.campaigns.list`,
    showFinished: state`admin.campaigns.showFinished`,
    toggleShowFinished: sequences`admin.campaigns.toggleShowFinished`,
  },
  function Campaigns({ campaigns, showFinished, toggleShowFinished }) {
    return (
      <Wrapper>
        <ContentWrapper>
          <Title>{'Manage campaigns'}</Title>
          <SubTitle>{'Set up a new campaign or edit an existing one'}</SubTitle>
          <NewButton
            onClick={() => {
              location.href = `/campaigns/create`;
            }}
          >
            {'Add New Campaign'}
          </NewButton>
          {campaigns
            .filter(camp => camp.status !== 'finished')
            .sort((a, b) => b.config.endDatetime - a.config.endDatetime)
            .map(camp => (
              <Label
                key={camp.campKey}
                buttonText={camp.status}
                smallText={`${dayjs(camp.config.startDatetime).format(
                  'DD/MM/YYYY'
                )} - ${dayjs(camp.config.endDatetime).format('DD/MM/YYYY')} (${
                  camp.orgName
                })`}
                href={`/campaigns/edit/${camp.campKey}`}
              >
                {camp.name}
              </Label>
            ))}

          <ShowButton onClick={() => toggleShowFinished()}>
            {showFinished
              ? 'Hide finished campaigns'
              : 'Show finished campaigns'}
          </ShowButton>

          {showFinished
            ? campaigns
                .filter(camp => camp.status === 'finished')
                .sort((a, b) => b.config.endDatetime - a.config.endDatetime)
                .map(camp => (
                  <Label
                    key={camp.campKey}
                    buttonText={camp.status}
                    smallText={`${dayjs(camp.config.startDatetime).format(
                      'DD/MM/YYYY'
                    )} - ${dayjs(camp.config.endDatetime).format(
                      'DD/MM/YYYY'
                    )} (${camp.orgName})`}
                    href={`/campaigns/edit/${camp.campKey}`}
                  >
                    {camp.name}
                  </Label>
                ))
            : null}
        </ContentWrapper>
      </Wrapper>
    );
  }
);

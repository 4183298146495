import { CalculatorActivitiesResponse } from 'providers/Activities/types';

export async function setActivities({ state, activities }) {
  const dataSourceCamp = state.get(
    `admin.campaigns.create.focus.form.dataSource.value`
  );
  const dataSourceChamp = state.get(
    `admin.championship.create.info.dataSource`
  );
  const language = state.get(`appContainer.language.locale`);

  const allActivities: CalculatorActivitiesResponse =
    await activities.getAllActivities({
      dataSource: dataSourceCamp || dataSourceChamp,
      language,
    });

  state.set('appContainer.activities', allActivities);
}

import styled from 'styled-components';
import { Typ40 } from '../Typ';
import Ico24 from '../Ico24';

export const ElementWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 16px 8px;
  width: 100%;
  min-height: 44px;
  cursor: pointer;
  background-color: #fff;
  &:hover {
    background-color: #eceff1;
  }
  &:after {
    position: absolute;
    left: 16px;
    bottom: 0;
    height: 1px;
    width: calc(100% - 16px);
    border-bottom: 1px solid #bcbbc1;
    content: '';
  }
`;

export const TitleText = styled(Typ40)`
  color: ${props => (props.color ? props.color : '#007079')};
  width: calc(100% - 52px);
  word-break: break-word;
`;

export const Icon = styled(Ico24)`
  color: ${props => (props.color ? props.color : '#B0BEC5')};
  margin-right: 8px;
`;

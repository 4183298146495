export function getChampionship({ props, firebase }) {
  return firebase
    .value(`championships.list.${props.championshipKey}`)
    .then(response => ({
      championship: { ...(response.value || {}), key: response.key },
    }));
}

export function getCampaignKeys({ props, firebase }) {
  return firebase
    .value(`championships.campaigns.${props.championshipKey}`)
    .then(response => ({
      campaignKeys: response.value || {},
    }));
}

export function getCampaigns({ props, firebase }) {
  const { campaignKeys } = props;

  const campaignPromises = Object.keys(campaignKeys).map(campaignKey =>
    firebase.value(`campaigns.list.${campaignKey}`).then(response => [
      campaignKey,
      {
        sections: response?.value?.structure?.sections ?? {},
        organisationKey: response?.value?.config?.organisationKey ?? '',
        tools: response?.value?.tools ?? {},
      },
    ])
  );

  return Promise.all(campaignPromises).then(results => ({
    campaigns: Object.fromEntries(results),
  }));
}

export function getSeries({ props, firebase }) {
  const { championshipKey } = props;

  return firebase
    .value(`championships.series.${championshipKey}`)
    .then(response => ({
      series: response.value,
    }));
}

export function getOrganisations({ props, firebase }) {
  const { campaigns } = props;

  const organisationPromises = Object.keys(campaigns).map(campaignKey =>
    firebase
      .value(`organisations.list.${campaigns[campaignKey].organisationKey}`)
      .then(response => [
        campaigns[campaignKey].organisationKey,
        { name: response.value.name, campaignKey },
      ])
  );

  return Promise.all(organisationPromises).then(results => ({
    organisations: Object.fromEntries(results),
  }));
}

export function setSections({ props, state }) {
  const { campaigns } = props;

  const sections = Object.assign(
    {},
    ...Object.keys(campaigns).flatMap(campaignKey =>
      Object.keys(campaigns[campaignKey].sections).flatMap(sectionKey => ({
        [sectionKey]: {
          name: campaigns[campaignKey].sections[sectionKey].name,
          organisationKey: campaigns[campaignKey].organisationKey,
        },
      }))
    )
  );

  state.set(`admin.championships.edit.sections`, sections);
}

export function selectDropdownItem({ props, state }) {
  const { context, option } = props;
  const text = state.get(
    'appContainer.language.phrases.admin.overview.reports'
  );

  state.set(`admin.championships.edit.${context}Selected`, option);
  switch (context) {
    case 'series':
      state.set('admin.championships.edit.sectionSelected', {
        optionKey: 'noSectionFilter',
        optionValue: text.all_sections,
      });
      state.set('admin.championships.edit.organisationSelected', {
        optionKey: 'noOrganisationFilter',
        optionValue: text.all_organisations,
      });
      break;
    case 'organisation': {
      state.set('admin.championships.edit.sectionSelected', {
        optionKey: 'noSectionFilter',
        optionValue: text.all_sections,
      });
      break;
    }
    default:
      break;
  }
}

export function filterOptions({ props, state }) {
  const { value } = props;
  const text = state.get(
    'appContainer.language.phrases.admin.overview.reports'
  );
  const sections = state.get(`admin.championships.edit.sections`);
  const organisations = state.get(`admin.championships.edit.organisations`);
  const series = state.get(`admin.championships.edit.series`);
  const seriesSelected = state.get(
    `admin.championships.edit.seriesSelected.optionKey`
  );
  const organisationSelected = state.get(
    `admin.championships.edit.organisationSelected.optionKey`
  );
  let sectionOptions = {};
  let organisationOptions = {};
  const seriesOptions = Object.assign(
    {},
    ...Object.keys(series || {}).map(seriesKey => ({
      [seriesKey]: series[seriesKey].name,
    }))
  );
  seriesOptions.noSeriesFilter = text.all_series;
  const filteredOrganisations = Object.assign(
    {},
    ...Object.keys(organisations)
      .filter(
        organisationKey =>
          seriesSelected === 'noSeriesFilter' ||
          Object.keys(series?.[seriesSelected]?.campaigns ?? {}).includes(
            organisations[organisationKey].campaignKey
          )
      )
      .map(organisationKey => ({
        [organisationKey]: organisations[organisationKey].name,
      }))
  );
  const filteredSections = Object.assign(
    {},
    ...Object.keys(sections)
      .filter(
        sectionKey =>
          (organisationSelected === 'noOrganisationFilter' &&
            seriesSelected === 'noSeriesFilter') ||
          Object.keys(filteredOrganisations).includes(
            sections[sectionKey].organisationKey
          )
      )
      .map(sectionKey => ({
        [sectionKey]: sections[sectionKey].name,
      }))
  );

  switch (value) {
    case 'teams':
      sectionOptions = filteredSections;
      sectionOptions.noSectionFilter = text.all_sections;
      organisationOptions = filteredOrganisations;
      organisationOptions.noOrganisationFilter = text.all_organisations;
      break;
    case 'sections':
      sectionOptions.noSectionFilter = text.all_sections;
      organisationOptions = filteredOrganisations;
      organisationOptions.noOrganisationFilter = text.all_organisations;
      state.set(`admin.championships.edit.sectionSelected`, {
        optionKey: 'noSectionFilter',
        optionValue: text.all_sections,
      });
      break;
    case 'organisations':
      sectionOptions.noSectionFilter = text.all_sections;
      organisationOptions.noOrganisationFilter = text.all_organisations;
      state.set(`admin.championships.edit.sectionSelected`, {
        optionKey: 'noSectionFilter',
        optionValue: text.all_sections,
      });
      state.set(`admin.championships.edit.organisationSelected`, {
        optionKey: 'noOrganisationFilter',
        optionValue: text.all_organisations,
      });
      break;
    default:
      sectionOptions = filteredSections;
      sectionOptions.noSectionFilter = text.all_sections;
      organisationOptions = filteredOrganisations;
      organisationOptions.noOrganisationFilter = text.all_organisations;
      break;
  }

  state.set(`admin.championships.edit.sectionOptions`, sectionOptions);
  state.set(
    `admin.championships.edit.organisationOptions`,
    organisationOptions
  );
  state.set(`admin.championships.edit.seriesOptions`, seriesOptions);
}

export function getEngagement({ firebase, state }) {
  return firebase
    .task('calculate_engagement', {
      championshipKey: state.get('admin.championships.edit.championshipKey'),
    })
    .then(response => ({ engagement: response }));
}

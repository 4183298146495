import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { useText } from 'common/hooks';

import { Wrapper, Title, Button } from './elements';

export default connect(
  {
    isValid: state`admin.championships.create.isValid`,
    isCreatingChampionship: state`admin.championships.create.isCreatingChampionship`,
    isEditChampionship: state`admin.championships.edit.isEdit`,
    createChampionshipClicked: sequences`admin.championships.create.createChampionshipClicked`,
  },
  function Create({
    isValid,
    isCreatingChampionship,
    isEditChampionship,
    createChampionshipClicked,
  }) {
    const t = useText();

    return (
      <Wrapper>
        <Title>{t('admin.overview.create.publish')}</Title>
        <Button
          disabled={!isValid || isCreatingChampionship || isEditChampionship}
          onClick={() => createChampionshipClicked()}
        >
          {t('admin.overview.create.create_championship')}
        </Button>
      </Wrapper>
    );
  }
);

import { state, props } from 'cerebral';
import { set, toggle, when } from 'cerebral/factories';
import { setField } from 'forms/operators';

import * as actions from './actions';
import { validateCampaign } from '../../actions';
import { validateChampionship } from 'admin/onboarding/module/modules/championships/modules/create/actions';

export const organisationFieldChanged = [
  setField(
    state`admin.campaigns.create.config.form.organisation`,
    props`value`
  ),
  actions.filterOrganisations,
  set(state`admin.campaigns.create.config.organisationChosen`, false),
  set(state`admin.campaigns.create.config.organisationKey`, null),
];

export const organisationSelected = [
  setField(
    state`admin.campaigns.create.config.form.organisation`,
    props`value`
  ),
  set(state`admin.campaigns.create.config.showOrgDropdown`, false),
  set(state`admin.campaigns.create.config.organisationChosen`, true),
  actions.filterOrganisations,
  when(state`admin.campaigns.isChampionship`),
  {
    true: [
      validateChampionship,
      {
        true: set(state`admin.championships.create.isValid`, true),
        false: set(state`admin.championships.create.isValid`, false),
      },
    ],
    false: [
      validateCampaign,
      {
        true: set(state`admin.campaigns.create.isValid`, true),
        false: set(state`admin.campaigns.create.isValid`, false),
      },
    ],
  },
];

export const toggleShowDropdown = [
  toggle(state`admin.campaigns.create.config.showOrgDropdown`),
];

export const showDropdown = [
  set(state`admin.campaigns.create.config.showOrgDropdown`, true),
];

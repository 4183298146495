export default {
  transport_to_work: {
    title: 'Travel to and from work',
  },
  work_transport_local: {
    title: 'Short distance travel during work',
  },
  work_transport_flight: {
    title: 'Long distance travel during work',
  },
  general_info: {
    title: 'General Info',
  },
  travel_to_work: {
    title: 'Travel to and from work',
  },
  short_work_travel: {
    title: 'Short distance travel during work',
  },
  long_work_travel: {
    title: 'Long distance travel during work',
  },
  organisation: {
    title: 'Organisation questions',
  },
  household: {
    title: 'Household',
  },
  transport: {
    title: 'Transport',
  },
  food: {
    title: 'Food',
  },
  food_waste: {
    title: 'Food Waste',
  },
  consumption: {
    title: 'Consumption',
  },
  energy: {
    title: 'Energy',
  },
  sustainability_knowledge: {
    title: 'Sustainability Knowledge',
  },
  plastic: {
    title: 'Plastic',
  },
  other: {
    title: 'Other questions',
  },
};

import React from 'react';
import { connect, ConnectedProps } from '@cerebral/react';
import { state, sequences } from 'app/pwa/app.cerebral';

import {
  Header,
  DuckyIcon,
  PrimaryButton,
  SecondaryButton,
  ButtonWrapper,
} from '../elements';
import { InnerWrapper, Title, Body } from './elements';
import { useText } from 'common/hooks';

const deps = {
  showTermsModal: state.appContainer.showTermsModal,
  goBackClicked: sequences`appContainer.termsGoBackClicked`,
  declineButtonClicked: sequences`appContainer.termsDeclinedClicked`,
};

export function ConfirmDeletionComponent({
  declineButtonClicked,
  goBackClicked,
}: typeof deps & ConnectedProps): React.ReactElement {
  const t = useText();

  return (
    <>
      <Header>
        <DuckyIcon icon={'icon-duckylogo'} />
      </Header>
      <InnerWrapper>
        <Title>{t('appContainer.ModTerms.are_you_sure')}</Title>
        <Body>{t('appContainer.ModTerms.are_you_sure_body')}</Body>
      </InnerWrapper>
      <ButtonWrapper>
        <PrimaryButton color={'#D32E2E'} onClick={() => declineButtonClicked()}>
          {t('appContainer.ModTerms.delete')}
        </PrimaryButton>
        <SecondaryButton onClick={() => goBackClicked()}>
          {t('appContainer.ModTerms.go_back')}
        </SecondaryButton>
      </ButtonWrapper>
    </>
  );
}

export default connect(deps, ConfirmDeletionComponent);

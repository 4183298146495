export default {
  reports: {
    campaign: 'Campaign Reports',
    download_campaign_summary:
      'Download a PDF summary of the entire campaign. What do you want to compare (who are competing)?',
    compare_organisations: 'Compare top organisations',
    compare_sections: 'Compare top sections',
    compare_teams: 'Compare top teams',
    select_audience:
      'Select the audience for which you are generating the report',
    filter_series: 'Filter on series',
    filter_organisations: 'Filter on organisations',
    filter_sections: 'Filter on sections',
    all_series: 'All series (default)',
    all_organisations: 'All organisations (default)',
    all_sections: 'All sections (default)',
    download_report: 'DOWNLOAD REPORT',
    download_excel: 'DOWNLOAD EXCEL SHEET',
    championship: 'Championship Reports',
    download_championship_summary:
      'Download a PDF summary of the entire championship. What do you want to compare (who are competing)?',
    no_reports_available:
      'There are currently no campaigns joined to this championship, so no reports are available.',
  },
  create: {
    publish: 'Publish',
    create_campaign: 'Create Campaign',
    create_championship: 'Create Championship',
  },
  header: {
    campaign_links: 'Campaign Links',
    access_campaign_links:
      'Access and copy any links for joining the campaign or added integrations here.',
    language_expected: 'Language expected:',
    championship_links: 'Championship Links',
    access_championship_links:
      'Access and copy any links for added integrations here.',
  },
  info: {
    authorized_domains: 'Authorized email domains:',
    access_via_domain:
      'For users to access this campaign, they need to create an account using one of the email domains defined during set up. A list of these domains can be found below',
    codes: 'Codes',
    access_via_codes:
      'For users to access this campaign, they will need a code specific to their team. You can download of a PDF of these codes below.',
    leaderboard: 'Leaderboard',
    leaderboard_description:
      'This excel sheet displays the winners of the challenge on an individual level, a team level, and a section level (in separate tabs).',
    client: 'General Statistics',
    client_description:
      'This excel sheet displays various statistics for each day of the challenge, including number of activities logged, number of active users, and winners of each day. the winners of the challenge on an individual level, a team level, and a section level (in separate tabs).',
    participation: 'Participation Statistics',
    participation_description:
      'This excel sheet displays the number of people that have currently joined the challenge on a day by day basis. You can see participation by section, as well as teams within each section.',
    download_now: 'DOWNLOAD NOW',
    engagement: 'Engagement metrics',
    churn: 'Churn rate: %{churn}%',
    logging_percentage: 'Logging percentage: %{percentage}%',
    nps: 'Net Promoter Score',
    nps_description: 'Download the NPS score in an excel format',
    activity_count_title: 'How many times each activity has been logged',
  },
  links: {
    links: 'Links',
    join: 'Join Campaign',
    join_description:
      'All participants can use this link to join the campaign:',
    public: 'Public Screen',
    public_description:
      'Display this web page around your school or workplace to encourage participants to join in the fun whilst they watch the visuals change live! Each section gets their own public screen.',
    widgets: 'Widgets',
    widgets_description:
      "Each of the tools in the campaign has a widget that displays various amazing statistics, updating in real time! Click the 'Click to copy' to copy the iframe to your clipboard.",
    click_to_copy: 'Click to copy',
    challenge: 'Challenge Widget',
    championship: 'Championship Widget',
    copy_iframe: 'Copy iframe',
  },
  delete: {
    delete_campaign: 'Delete Campaign?',
    warning:
      'ALL ASSOCIATED DATA WILL BE DELETED (AGGREGATED DATA WILL REMAIN)',
    delete: 'Delete',
    removes_associated_data:
      'If you choose to delete this campaign, all data associated with this campaign will also be deleted. However, aggregated Ducky data, unable to be traced back to this campaign, will remain.',
    cannot_reverse: 'This action cannot be reversed.',
    delete_button: 'Delete Campaign',
    type_delete: 'Type DELETE to confirm',
  },
};

import styled from 'styled-components';
import { Typ30, Typ60 } from 'dc/Typ';
import TglBox1 from 'dc/TglBox1';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 584px;
  margin: 0 auto;
`;

export const Title = styled(Typ60)`
  margin-bottom: 16px;
`;

export const Description = styled(Typ30)`
  margin-bottom: 32px;
`;

export const TglBox = styled(TglBox1)``;

export const Error = styled(Typ30)`
  color: #ba363d;
  margin-bottom: 32px;
`;

export const Spacer = styled.div`
  height: 20px;
  width: 100%;
  margin-bottom: 32px;
`;

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { useText } from 'common/hooks';

import {
  Wrapper,
  SectionWrapper,
  Title,
  Text,
  EllipsisText,
  Label,
  Link,
  Button,
  HorizontalRule,
} from './elements';

export default connect(
  {
    activities: state`admin.campaigns.edit.campaign.challenge.activities`,
    linksData: state`admin.campaigns.edit.computedCampaignLinks`,
    engagement: state`admin.campaigns.edit.engagement`,
    totalStats: state`admin.campaigns.edit.totalStats`,
    languageSelected: state`admin.campaigns.edit.languageSelected.optionKey`,
    copyToClipboard: sequences`admin.campaigns.edit.copyToClipboard`,
  },
  function Links({
    activities,
    linksData,
    engagement,
    totalStats,
    languageSelected,
    copyToClipboard,
  }) {
    const t = useText();

    return (
      <Wrapper>
        <HorizontalRule />
        <SectionWrapper>
          <Title>{t(`admin.overview.info.engagement`)}</Title>
          <Text>
            {t(`admin.overview.info.churn`, {
              churn: engagement?.churnRate?.toFixed(2) ?? '-',
            })}
          </Text>
          <Text>
            {t(`admin.overview.info.logging_percentage`, {
              percentage: engagement?.percentageLogged?.toFixed(2) ?? '-',
            })}
          </Text>
        </SectionWrapper>
        <SectionWrapper>
          <Title>{t(`admin.overview.info.activity_count_title`)}</Title>
          <table>
            <tbody>
              {Object.keys(activities ?? {})
                .filter(activityKey => !activityKey.includes('tutorial'))
                .filter(activityKey => activities[activityKey])
                .map(activityKey => ({
                  activityKey,
                  count: totalStats?.activitiesCount?.[activityKey] ?? 0,
                }))
                .sort((a, b) => b.count - a.count)
                .map(({ activityKey, count }) => {
                  const text = t(`challenge.activities.${activityKey}.title`);

                  return (
                    <tr key={activityKey}>
                      <td>
                        <Text>{count}</Text>
                      </td>
                      <td>
                        <EllipsisText title={text}>{text}</EllipsisText>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </SectionWrapper>
        <HorizontalRule />
        {languageSelected &&
          Object.entries(linksData[languageSelected].links).map(
            ([linkType, detailsArray]) => {
              if (detailsArray.length > 0) {
                return (
                  <SectionWrapper key={linkType}>
                    <Title>{t(`admin.overview.links.${linkType}`)}</Title>
                    <Text>
                      {t(`admin.overview.links.${linkType}_description`)}
                    </Text>
                    {detailsArray.map(details => {
                      if (linkType === 'widgets') {
                        return (
                          <React.Fragment key={details.link}>
                            <Label>
                              {t(`admin.overview.links.${details.name}`)}
                            </Label>
                            <Button
                              onClick={() =>
                                copyToClipboard({ str: details.link })
                              }
                            >
                              {t(`admin.overview.links.copy_iframe`)}
                            </Button>
                          </React.Fragment>
                        );
                      }
                      if (details.name) {
                        return (
                          <React.Fragment key={details.link}>
                            <Label>{details.name}</Label>
                            <Link as={'a'} href={details.link} target="_blank">
                              {details.link}
                            </Link>
                          </React.Fragment>
                        );
                      }

                      return (
                        <Link
                          as={'a'}
                          key={details.link}
                          href={details.link}
                          target="_blank"
                        >
                          {details.link}
                        </Link>
                      );
                    })}
                  </SectionWrapper>
                );
              }

              return null;
            }
          )}
        <HorizontalRule />
      </Wrapper>
    );
  }
);

import styled from 'styled-components';
import { Typ20 } from '../Typ';
import Wra12A from '../Wra12A';

export const ElementWrapper = styled(Wra12A)`
  background-color: #263238;
  border-radius: 4px;
  position: relative;
`;
export const Content = styled(Typ20)`
  color: #fff;
  text-align: center;
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const Wrapper = styled.div`
  &:hover > div:first-child {
    visibility: visible;
    opacity: 1;
  }
  display: inline-block;
  position: relative;
`;

type TooltipProps = {
  $marginTop?: string;
  $marginLeft?: string;
  $maxWidth?: number;
};

export const Tooltip = styled.div<TooltipProps>`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 9999;
  margin-right: -${props => props.$maxWidth}px;
  margin-left: ${props => props.$marginLeft};
  margin-top: ${props => props.$marginTop};
  max-width: ${props => props.$maxWidth}px;
`;

import styled from 'styled-components';
import Ico12 from '../Ico12';
import { Typ20, Typ21 } from '../Typ';

export const ElementWrapper = styled.span`
  line-height: 14px;
  display: inline-flex;
  align-items: center;
`;

export const Icon = styled(Ico12)`
  padding-right: 4px;
  color: ${props => (props.icon === 'icon-leaf' ? '#00AB97' : '#8BC34A')};
  color: ${props => props.color};
`;

export const Text20 = styled(Typ20)`
  color: ${props => props.color};
`;

export const Text21 = styled(Typ21)`
  color: ${props => props.color};
`;

import styled from 'styled-components';
import Act96 from '../Act96';
import { Typ30, Typ41 } from '../Typ';
import Btn021 from '../Btn021';
import Subhead01 from '../Subhead01';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 49px 16px 0;
  background-color: #f4f7f9;
  text-align: center;
`;

export const ActivityImage = styled(Act96)`
  margin-bottom: 12px;
`;

export const ActivityName = styled(Typ41)`
  color: #37474f;
  margin-bottom: 12px;
`;

export const ActivityDescription = styled(Typ30)`
  color: #546e7a;
  margin-bottom: 22px;
  max-width: 100%;
`;

export const HabitButton = styled(Btn021)`
  margin-bottom: 6px;
`;

export const SubHeader = styled(Subhead01)``;

import styled from 'styled-components';
import Wra16D from 'dc/Wra16D';
import { Typ30 } from 'dc/Typ';

export const Wrapper = styled(Wra16D)`
  background-color: #263238;
`;

export const Text = styled(Typ30)`
  color: #fff;
`;

export function newTeam(path, field) {
  return {
    name: {
      value: '',
      defaultValue: '',
      validationRules: [
        'isValue',
        `isUnique:{"form":"${path}","field":"${field}"}`,
      ],
    },
    expectedParticipantCount: {
      value: '',
      defaultValue: '',
      validationRules: ['isValue'],
    },
    teamLeaderEmail: {
      value: '',
      defaultValue: '',
      validationRules: ['isEmail'],
    },
  };
}

export function newSection() {
  return {
    name: {
      value: '',
      defaultValue: '',
      validationRules: [
        'isValue',
        `isUnique:{"form":"admin.structure.structureForm.sections","field":"name"}`,
      ],
    },
    teams: {},
  };
}

export function deleteSection({ state, props }) {
  state.unset(`admin.structure.structureForm.sections.${props.sectionKey}`);
}

export function deleteTeam({ state, props }) {
  state.unset(
    `admin.structure.structureForm.sections.${props.sectionKey}.teams.${props.teamKey}`
  );
}

export function addNewSection({ props, state, uuid }) {
  const newSectionKey = Date.now();

  state.set(`admin.structure.newIds.section`, newSectionKey);
  state.set(`admin.structure.newIds.teams.${newSectionKey}`, uuid.v4());

  state.set(
    `admin.structure.structureForm.sections.${props.sectionKey}`,
    newSection()
  );
}

export function addNewTeam({ props, state, uuid }) {
  state.set(`admin.structure.newIds.teams.${props.sectionKey}`, uuid.v4());

  state.set(
    `admin.structure.structureForm.sections.${props.sectionKey}.teams.${props.teamKey}`,
    newTeam(
      `admin.structure.structureForm.sections.${props.sectionKey}.teams`,
      'name'
    )
  );
}

export function getStructureFromOrganisation({ state, props }) {
  const departments = state.get(
    `admin.campaigns.create.organisation.organisations.${props.organisationKey}.departments`
  );

  return {
    sections: Object.values(departments || {}).map(section => ({
      name: section.name,
      teams: Object.values(section.teams),
    })),
  };
}

export function parseExcel({ props, http, config }) {
  return http
    .fetch(
      `${config.get(
        'cloudFunctionUrl'
      )}/requests-admin-campaignStructureFromExcel`,
      {
        body: props.file,
        method: 'POST',
      }
    )
    .then(res => res.json())
    .then(data => {
      if (data.status === 200) {
        return { sections: data.sections };
      }

      return { sections: [] };
    });
}

export function formatStructureForm({ props, uuid }) {
  const sections = Object.fromEntries(
    props.sections.map(section => {
      const sectionKey = uuid.v4();

      return [
        sectionKey,
        {
          name: {
            defaultValue: '',
            validationRules: [
              'isValue',
              `isUnique:{"form":"admin.structure.structureForm.sections","field":"name"}`,
            ],
            value: section.name,
          },
          teams: Object.fromEntries(
            section.teams.map(team => {
              const teamKey = uuid.v4();

              return [
                teamKey,
                {
                  name: {
                    defaultValue: '',
                    validationRules: [
                      'isValue',
                      `isUnique:{"form":"admin.structure.structureForm.sections.${sectionKey}.teams","field":"name"}`,
                    ],
                    value: team.name,
                  },
                  expectedParticipantCount: {
                    defaultValue: '',
                    validationRules: ['isValue'],
                    value: String(
                      team.expectedParticipantCount === undefined
                        ? ''
                        : team.expectedParticipantCount
                    ),
                  },
                  teamLeaderEmail: {
                    defaultValue: '',
                    validationRules: ['isEmail'],
                    value: team.teamLeaderEmail || '',
                  },
                },
              ];
            })
          ),
        },
      ];
    })
  );

  const newTeamIds = Object.fromEntries([
    ...Object.keys(sections).map(sectionKey => [sectionKey, uuid.v4()]),
  ]);

  return { createdSections: sections, newTeamIds };
}

import styled from 'styled-components';
import { Typ60, Typ30 } from 'dc/Typ';
import TxtField12 from 'dc/TxtField12';
import SwiLab02 from 'dc/SwiLab02';
import Btn021 from 'dc/Btn021';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto 24px;
`;

export const Title = styled(Typ60)`
  color: #546e7a;
  margin-bottom: 16px;
`;

export const Subtitle = styled(Typ30)`
  color: #546e7a;
  margin-bottom: 32px;
`;

export const Label = styled(Typ30)`
  color: #546e7a;
  margin-bottom: 8px;
`;

export const DropDownWrapper = styled.div`
  margin-bottom: 62px;
`;

export const DropDown = styled(TxtField12)`
  width: 288px;
  margin-bottom: 24px;
`;

export const RadioButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 34px;
`;

export const RadioButton = styled(SwiLab02)`
  margin-bottom: 16px;
  > span {
    color: #546e7a !important;
  }

  > div > i {
    color: ${props => (props.checked ? '#0099A9' : '#546E7A')};
  }
`;

export const HorizontalRule = styled.hr`
  margin-bottom: 32px;
  width: 100%;
  border-top: 1px solid #90a4ae;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Button = styled(Btn021)`
  width: 320px;
  margin-bottom: 58px;
  align-self: center;
`;

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Wrapper,
  Line,
  InputWrapper,
  Label,
  RadioInput,
  Paragraph,
  DropdownInput,
  DeleteIcon,
  SeriesNameInputWrapper,
  IconFiller,
  AddSeriesButton,
  TextInput,
} from './elements';

import { useText } from 'common/hooks';
import { ICON, SERIES_TYPE } from 'resources/constants';

export default connect(
  {
    seriesForm: state`admin.championships.create.series.form`,
    seriesTypeSelected: state`admin.championships.create.series.seriesTypeSelected`,
    seriesFieldChanged: sequences`admin.championships.create.series.seriesFieldChanged`,
    seriesTypeOptionClicked: sequences`admin.championships.create.series.seriesTypeOptionClicked`,
    seriesOptionClicked: sequences`admin.championships.create.series.seriesOptionClicked`,
    addSeriesClicked: sequences`admin.championships.create.series.addSeriesClicked`,
    removeSeriesClicked: sequences`admin.championships.create.series.removeSeriesClicked`,
  },
  function Series({
    seriesForm,
    seriesTypeSelected,
    seriesFieldChanged,
    seriesTypeOptionClicked,
    seriesOptionClicked,
    addSeriesClicked,
    removeSeriesClicked,
  }) {
    const t = useText();
    const seriesNeeded = seriesForm.seriesNeeded.value;
    const { createdSeries } = seriesForm;

    return (
      <Wrapper>
        <Line />
        <InputWrapper>
          <Label>{t('admin.championship.series.series_required')}</Label>
          <RadioInput
            onClick={() => seriesOptionClicked()}
            checked={!seriesNeeded}
            label={t('admin.championship.series.no_series')}
            key={'noSeries'}
          />
          <RadioInput
            onClick={() => seriesOptionClicked()}
            checked={seriesNeeded}
            label={t('admin.championship.series.yes_series')}
            key={'yesSeries'}
          />
        </InputWrapper>
        <Paragraph as={'p'}>
          {t('admin.championship.series.series_is_added_level')}
        </Paragraph>
        <Paragraph as={'p'}>
          {t('admin.championship.series.competition_is_visible')}
        </Paragraph>
        {seriesNeeded ? (
          <>
            <InputWrapper>
              <Label>{t('admin.championship.series.series_term')}</Label>
              <DropdownInput
                placeholder={t('admin.championship.series.select_term')}
                options={Object.fromEntries(
                  Object.values(SERIES_TYPE).map(seriesType => [
                    seriesType,
                    t(`admin.championship.series.series_terms.${seriesType}`),
                  ])
                )}
                value={
                  seriesTypeSelected
                    ? t(
                        `admin.championship.series.series_terms.${seriesTypeSelected.optionKey}`
                      )
                    : ''
                }
                onDropdownItemClick={option =>
                  seriesTypeOptionClicked({
                    path: 'admin.championships.create.series',
                    optionType: 'seriesType',
                    option,
                  })
                }
              />
            </InputWrapper>
            {Object.entries(createdSeries).map(([seriesKey, series], index) => (
              <SeriesNameInputWrapper key={seriesKey}>
                <TextInput
                  maxLength={140}
                  placeholder={t('admin.championship.series.enter_series_name')}
                  onChange={event =>
                    seriesFieldChanged({
                      field: `admin.championships.create.series.form.createdSeries.${seriesKey}.name`,
                      value: event.target.value,
                      seriesKey,
                    })
                  }
                  value={createdSeries?.[seriesKey]?.name?.value || ''}
                  icon={ICON.modeEdit}
                  errorMessage={
                    series?.name?.errorMessage?.phrase
                      ? t(series.name.errorMessage.phrase)
                      : ''
                  }
                />
                {index > 0 ? (
                  <DeleteIcon
                    icon={ICON.cancel}
                    color={'#607D8B'}
                    onClick={() => removeSeriesClicked({ seriesKey })}
                  />
                ) : (
                  <IconFiller />
                )}
              </SeriesNameInputWrapper>
            ))}
            <AddSeriesButton
              icon={ICON.addCircle}
              color={'#03739A'}
              onClick={() => addSeriesClicked()}
            >
              {t('admin.championship.series.add_another')}
            </AddSeriesButton>
          </>
        ) : null}
      </Wrapper>
    );
  }
);

import React from 'react';
import {
  OuterWrapper,
  TitleWrapper,
  Icon,
  Title,
  ContentWrapper,
  Content,
} from './elements';

type TglBox1Props = {
  readonly startExpanded: boolean;
  readonly title: string;
  readonly children: React.ReactElement;
  readonly className?: string;
};

export default function TglBox1({
  startExpanded,
  title,
  children,
  className,
}: TglBox1Props) {
  const [expanded, setExpanded] = React.useState(startExpanded);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <OuterWrapper className={className}>
      <TitleWrapper onClick={() => handleClick()}>
        <Icon
          icon={
            expanded ? 'icon-keyboard_arrow_down' : 'icon-keyboard_arrow_right'
          }
        />
        <Title>{title}</Title>
      </TitleWrapper>
      <ContentWrapper $expanded={expanded}>
        <Content>{children}</Content>
      </ContentWrapper>
    </OuterWrapper>
  );
}

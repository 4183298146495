export function addNewEmailDomainField({ state }) {
  state.push('admin.campaigns.create.access.form.emails', {
    value: '',
    defaultValue: '',
    validationRules: ['isValue'],
  });
}

export function removeEmailDomainField({ state, props }) {
  const fields = state.get('admin.campaigns.create.access.form.emails');

  fields.splice(props.index, 1);
  state.set('admin.campaigns.create.access.form.emails', fields);
}

import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  min-height: calc(100vh - 80px);
  border-bottom: 8px solid #455a64;
  padding: 80px;
  text-align: center;
  width: 100%;

  @media (max-width: 767px) {
    min-height: 500px;
    padding-top: 56px;
  }
`;

export default {
  menu: {
    your_team: 'Your Team',
    your_section: {
      _enum: 'SECTION_TERM',
      building: 'Your Building',
      department: 'Your Department',
      discipline: 'Your Discipline',
      faculty: 'Your Faculty',
      school: 'Your School',
      area: 'Your Area',
      city: 'Your City',
      municipality: 'Your Municipality',
      county_municipality: 'Your County Municipality',
      country: 'Your Country',
      section: 'Your Section',
    },
    your_organisation: 'Your Organisation',
    all_organisations: 'All Organisations',
  },
  footer: {
    expand: 'Expand list',
    hide: 'Hide full list',
    page: 'Page %{currentPage} of %{pageCount}',
  },
  lists: {
    team: {
      you: 'Your position',
      active: 'Active team members',
      info: 'Only team members who have logged at least one activity appear in the leaderboard.',
    },
    section: {
      you: "Your team's position",
      all: {
        _enum: 'SECTION_TERM',
        section: 'All teams in your section',
        building: 'All teams in your building',
        department: 'All teams in your department',
        discipline: 'All teams in your discipline',
        faculty: 'All teams in your faculty',
        school: 'All teams in your school',
        area: 'All teams in your area',
        city: 'All teams in your city',
        municipality: 'All teams in your municipality',
        county_municipality: 'All teams in your county municipality',
        country: 'All teams in your country',
        other: 'All teams',
      },
    },
    challenge: {
      you: {
        _enum: 'SECTION_TERM',
        section: "Your section's position",
        building: "Your building's position",
        department: "Your department's position",
        discipline: "Your discipline's position",
        faculty: "Your faculty's position",
        school: "Your school's position",
        area: "Your area's position",
        city: "Your city's position",
        municipality: "Your municipality's position",
        county_municipality: "Your county municipality's position",
        country: "Your country's position",
        other: 'Your position',
      },
      all: {
        _enum: 'SECTION_TERM',
        section: 'All sections in %{organisationName}',
        building: 'All buildings in %{organisationName}',
        department: 'All departments in %{organisationName}',
        discipline: 'All disciplines in %{organisationName}',
        faculty: 'All faculties in %{organisationName}',
        school: 'All schools in %{organisationName}',
        area: 'All areas in %{organisationName}',
        city: 'All cities in %{organisationName}',
        municipality: 'All municipalities in %{organisationName}',
        county_municipality: 'All county municipalities in %{organisationName}',
        country: 'All countries in %{organisationName}',
        other: 'All in %{organisationName}',
      },
    },
    championship: {
      you: "Your organisation's position",
      all: 'All organisations',
      last_updated: 'Last updated: %{lastUpdated}',
    },
  },
};

import { createContext } from 'react';

import { ClientConfig } from 'configs/types';

export default createContext<ClientConfig>({
  firebase: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
  ccm: {
    URL: '',
  },
  duckyFeedbackSurvey: {
    no: '',
    en: '',
    ja: '',
  },
  cloudFunctionUrl: '',
  cloudFunctionRegion: '',
  widgetUrl: '',
  appUrl: '',
  domain: '',
  staticUrl: '',
  translateImagePath: '',
  matomoTrackingUrl: '',
  matomoSiteId: -1,
  DUCKY_ENV: '',
  duckyApi: {
    url: '',
    firebaseUrl: '',
  },
});

import styled, { css } from 'styled-components';
import { Typ20, Typ30 } from 'dc/Typ';

const versionSizes = {
  default: '0.875em',
  large: '1.125em',
  huge: '1.500em',
};

export const OuterWrapper = styled('div')`
  display: flex;
  min-height: 48px;
`;

export const PlaceholderLabel = styled('span')`
  font-size: ${props =>
    props.focus ? '0.875em' : versionSizes[props.version]};
  line-height: 20px;
  font-weight: normal;
  color: ${props => (props.focus ? '#00ab97' : '#b0bec5')};
  pointer-events: none;
  position: absolute;
  transform: ${props =>
    props.focus ? 'translate3d(0, 0, 0)' : 'translate3d(0,23px,0) scale(1)'};
  transform-origin: left top;
  transition: all 200ms ease-in-out;
`;

export const Input = styled('input')`
  align-self: flex-end;
  font-family: Montserrat;
  font-size: ${props =>
    props.version ? versionSizes[props.version] : 'inherit'};
  line-height: 20px;
  height: 30px;
  width: 100%;
  padding: 0;
  border: 0;
  border-bottom: 1px solid rgba(38, 50, 56, 0.12);
  border-radius: 0;
  box-sizing: border-box;
  transition: all 100ms ease-in-out;
  &:focus {
    outline: 0;
    border-bottom: 1px solid #0099a9;
  }
`;

export const NumberCharacters = styled(Typ20)`
  text-align: right;
  padding-right: 24px;
  width: 100%;
  color: #b0bec5;
  margin-top: 8px;
  position: absolute;
`;

export const ErrorMessage = styled(Typ30)`
  width: 100%;
  text-align: center;
  transform: scaleY(0);
  transition: transform 300ms ease;
  transform-origin: top;
  ${props =>
    props.errorMessage &&
    css`
      transform: scaleY(1);
    `};
  line-height: 20px;
  padding-top: 8px;
  color: #e0487b;
  position: absolute;
`;

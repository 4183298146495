export default {
  activity_food_01: {
    title: 'I wasted no food today',
    description:
      'Log on days you eat or save left over food instead of throwing it away. You might store your leftovers, make a great meal from it or buy the right amount of food in the first place.',
    co2_explanation: `MARKDOWN:
## The facts
- Food production causes about 26% of greenhouse gas emissions globally, yet around 17% of this food is wasted!
- [60% of this waste happens at home](https://www.unep.org/resources/report/unep-food-waste-index-report-2021), so you can be a [part of the solution](https://www.fao.org/3/mb060e/mb060e03.pdf) by eating up leftovers and planning ahead. 
- If less food is thrown away, less will have to be produced.

## The assumptions
- The savings are calculated by subtracting the footprint of  an average person in your country from the footprint of a person who wastes no food at home. The emission reduction is equivalent to the emissions used to produce the amount of food that was wasted. We find the amount of average food waste from country-specific statistics. Please refer to the climate calculator documentation for more country-specific documentation!

## Additional info
- [Learn strategies to waste less food](https://www.healthline.com/nutrition/reduce-food-waste)
- [Video on food waste](https://youtu.be/KZf9_GdDff0)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## The facts
- By not wasting food, you help fight malnutrition and hunger in other countries.

## Additional info
- [Facts about food waste and hunger](https://www.wfp.org/stories/5-facts-about-food-waste-and-hunger)`,
  },
  activity_food_02: {
    title: 'I ate vegetarian today',
    description: 'Log on days you have not eaten any meat or fish all day.',
    co2_explanation: `MARKDOWN:
## The facts
- [Animal husbandry is a major source of greenhouse gas emissions](https://ourworldindata.org/environmental-impacts-of-food#), mainly due to methane emitted by ruminants like cows and sheep, and land use change (cutting down forests to create space to graze animals)
- The climate impact of meat depends on the type of meat and how it's produced. Eating game is much better for the climate than industrial meat, while white meat like chicken typically produces less emissions than red meat like beef or lamb.
- Eating more plants and less animal products is one of the most effective ways you can reduce your climate footprint.

## The assumptions
- Calculations are made by removing your calorie intake from meat products and transferring these calories to vegetables, fruits and cereal products.
- Check out the climate calculator and its documentation for more information on exactly how much you can save by changing your eating habits. 

## Additional info
- [How to go vegetarian](https://www.bbc.co.uk/food/articles/how_to_go_vegetarian)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## Additional info
- [Check out this summary from the Harvard Medical School](https://www.health.harvard.edu/staying-healthy/becoming-a-vegetarian#:~:text=Compared%20with%20meat%20eaters%2C%20vegetarians,such%20as%20carotenoids%20and%20flavonoids.) on the health outcomes associated with cutting down on meat, and how you can maintain a healthy diet while reducing meat consumption.`,
  },
  activity_food_03: {
    title: 'I ate less dairy today',
    description:
      'Log on days you eat less than 200 grams of dairy (equivalent to a glass of milk).',
    co2_explanation: `MARKDOWN:
## The facts
- Animal husbandry is a major source of greenhouse gas emissions, mainly due to methane emitted by ruminants like cows and sheep, and land use change (cutting down forests to create space to graze animals).
- Milk, yoghurt and cheese have high emissions per calorie content. There are many good alternatives to dairy products made from oats, rice, soy and nuts.

## The assumptions
- Calculations are made by removing your calorie intake from dairy products and transferring these calories to vegetables, fruits and cereal products.

## Additional info
- [https://www.theguardian.com/environment/2018/may/31/avoiding-meat-and-dairy-is-single-biggest-way-to-reduce-your-impact-on-earth](https://www.theguardian.com/environment/2018/may/31/avoiding-meat-and-dairy-is-single-biggest-way-to-reduce-your-impact-on-earth)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## Additional info
- [Check out this study](https://journals.sagepub.com/doi/abs/10.1177/1559827611425762?casa_token=VnTg4qL_kRYAAAAA%3AX7cEykrwr6E8I-QO5O1Cuu_Bx_arT0A9123PE5Av-Fljayu4Ft6yHxcO1T0gViTF80EVUa_ULEyXkA&journalCode=ajla) on the healthy outcomes associated with plant-based diets.
- [Check out the UK's NHS recommendations](https://www.nhs.uk/live-well/eat-well/the-vegan-diet/) for maintaining a healthy diet while cutting down on dairy.`,
  },
  activity_food_04: {
    title: 'I ate fish instead of meat today',
    description:
      'Log on days you replace meat with sustainable fish for all meals.',
    co2_explanation: `MARKDOWN:
## The facts
- [Seafood aquaculture has a significantly lower carbon footprint compared to other meat](https://www.sintef.no/contentassets/25338e561f1a4270a59ce25bcbc926a2/report-carbon-footprint-norwegian-seafood-products-2017_final_040620.pdf/). Choose eco-certified seafood whenever possible.
- Many species in the ocean are endangered because of overfishing, and many fishing methods destroy the sea floor and kill unwanted or unsellable species, known as bycatch.

## The assumptions
- The calculation removes the calorie intake from meat products of an average citizen in your country, and transfers these calories to fish products.

## Additional info
- [WWF sustainable seafood guide](https://wwf.panda.org/act/live_green/out_shopping/seafood_guides/?)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## Additional info
- [Read more from the Harvard School of Public Health](https://www.hsph.harvard.edu/nutritionsource/fish/) on the health benefits of eating fish](https://www.hsph.harvard.edu/nutritionsource/fish/)`,
  },
  activity_food_05: {
    title: 'I wasted no food today',
    description: 'Registered if you did not throw away any food.',
    co2_explanation: `MARKDOWN:
## Did you know?
- We throw away 46,6 kg food per person a year.

## Find out more
- [https://www.ostfoldforskning.no/no/publikasjoner/Publication/?id=2479](https://www.ostfoldforskning.no/no/publikasjoner/Publication/?id=2479)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_food_06: {
    title: 'I checked what I already have',
    description:
      'Registered if you checked your fridge, freezer, and pantry before you went food shopping.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    time_explanation: `MARKDOWN:
## Did you know?
- Danish consumers spend on average between 4 and 5 hours a month on grocery shopping that ends up in the bin

## Find out more
- [https://ourworld.unu.edu/en/if-everybody-hates-wasting-food-why-do-we-and-how-can-we-stop](https://ourworld.unu.edu/en/if-everybody-hates-wasting-food-why-do-we-and-how-can-we-stop)`,
    money_explanation: `MARKDOWN:
## Did you know?
- Every Norwegian on average throws away every 8th shopping bag of food
- On average each and everyone of us throws away food for 2 677 kr each year

## Find out more
- [https://www.miljodirektoratet.no/Documents/publikasjoner/M1016/M1016.pdf](https://www.miljodirektoratet.no/Documents/publikasjoner/M1016/M1016.pdf)`,
  },
  activity_food_07: {
    title: 'I cooled food quickly and properly today',
    description:
      'Registered if you quickly and properly cooled leftovers or food from the grocery store.',
    co2_explanation: `MARKDOWN:
## Did you know?
- By ensuring preheat the food to a high enough temperature and being mindful of cooling it down quickly, you prolong the shelf life of the foodwaste, you prolong the durability to the food waste
- Several refrigerated cargoes have a Keep-it indicator that register if cold chain is taken care of and shows the actual durability of the item. 
- You should cool down food in a water bath before you put it in a fridge. If not the temperature could increase in the entire fridge and reduce the quality of the rest of the food, and make it bad.

## Find out more
- [https://www.matvett.no/brukopp-leksikon/middagsrester](https://www.matvett.no/brukopp-leksikon/middagsrester)
- [https://www.keep-it.no](https://www.keep-it.no)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_food_08: {
    title: 'I used a shopping list today',
    description:
      'Registered if you followed your list when you went food shopping today.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    money_explanation: `MARKDOWN:
## Did you know?
- To shop without a plan kan lead to more food in the shopping cart then you will manage to eat up in time, and is one of the reasons to why we spend more money on food. 
- There are several ways to create a shopping list that helps you to use money and shop what you need. For example you can take a picture of your fridge before you leave, so that you see what you don’t need to buy, a kind of “opposite shopping list” Or use one of many shopping apps, that you can update and share with others in your household for better planning.

## Find out more
- [https://www.dnbnyheter.no/privatokonomi/spar-penger-med-en-matplan/](https://www.dnbnyheter.no/privatokonomi/spar-penger-med-en-matplan/)
- [https://www.dinside.no/mobil/gjor-det-mye-enklere-a-fa-med-seg-alt-pa-handlelista/67093692](https://www.dinside.no/mobil/gjor-det-mye-enklere-a-fa-med-seg-alt-pa-handlelista/67093692)`,
  },
  activity_food_09: {
    title: 'I made a meal plan for the week today',
    description: `Registered if you made, shopped for and/or followed the week's meal plan.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know? 
- 1 out of 12 say that they buy more items than they intended to, everytime they buy food.
- To plan the meals for the week, with commodities that fits several dinners, could save you time, money, energy and food waste.
- Plans changes and it can be smart to add one or two “open days” where you eat leftovers, go out and eat, order takeaway or visit someone for dinner etc.
- Make a flexible plan that makes it possible to move around on the dinner plans, for example depending on how much time you have each day.

## Find out more
- [https://fil.forbrukerradet.no/wp-content/uploads/2016/09/rapport-handlevaner-og-holdbarhetsdato-2016.pdf](https://fil.forbrukerradet.no/wp-content/uploads/2016/09/rapport-handlevaner-og-holdbarhetsdato-2016.pdf)

## Inspiration
- [https://spar.no/Nyheter/middag-uten-matsvinn/](https://spar.no/Nyheter/middag-uten-matsvinn/)`,
  },
  activity_food_10: {
    title: 'I ate leftover food today',
    description: `Registered if you used leftovers in one or more of today's meals.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Leftover are the one thing we throw the most
- Every Norwegian throws on average 13 kg leftovers from pots, dishes and leftover boxes?

## Find out more
- [https://restemat.blogspot.com](https://restemat.blogspot.com)`,
  },
  activity_food_11: {
    title: 'I trusted my senses today',
    description: `Registered if you saw, smelled, or tasted your food to consider if it was still edible.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- The “Best before” date on groceries are what most Norwegians give as the reason for throwing food?
- There is a difference between “Best before” and “last Consumption day”. 
- How the food has been stored is much more important than the expiry date. For example, would you drink a milk that has not expired, but has been left open on the workbench for a week, rather than an “expired” milk that has been stored in the fridge?

## Effect
- This activity helps you with the activity "I wasted no food today", which has a CO\u2082-calculation.

## Find out more
- [https://www.framtiden.no/201609097040/aktuelt/mat/sa-lenge-holder-best-for-maten.html](https://www.framtiden.no/201609097040/aktuelt/mat/sa-lenge-holder-best-for-maten.html)
- [https://www.matvett.no/uploads/documents/Matsvinn-i-Norge-Rapportering-av-nokkeltall-2015-2017.pdf](https://www.matvett.no/uploads/documents/Matsvinn-i-Norge-Rapportering-av-nokkeltall-2015-2017.pdf)`,
  },
  activity_food_12: {
    title: 'I talked to someone about food waste today',
    description: `Registered if you talked to someone about MatVinn or shared a food waste tip.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    handprint_explanation: `MARKDOWN:
## Did you know?
- 8 out of 10 Norwegians say they feel guilty when throwing food? Still many hesitate to tell others and take care of food that others would like throw away after a cabin trip or a dinner party for example, out of fear of being seen as cheap or old fashioned?
- To throw less food is one of our most important and often “invisible climate actions? Ho more people talks about the advantages of throwing less food and how little effort it takes, you could contribute to breaking down prejudice

## Find out more
- [https://www.duo.uio.no/bitstream/handle/10852/50412/Master-thesis-Elisabeth-Riise-Jenssen.pdf?sequence=1](https://www.duo.uio.no/bitstream/handle/10852/50412/Master-thesis-Elisabeth-Riise-Jenssen.pdf?sequence=1)`,
  },
  activity_food_13: {
    title: 'I talked with our kids about food waste today',
    description: `Registered if you talked to your kids about why it's important to throw away less food.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    handprint_explanation: `MARKDOWN:
## Did you know?
- It is extra important to avoid pointing fingers at children and make them feel guilt when it comes to food.
- To talk about “the journey of the food” (for example a banana) from ground to plate could be a good starting point for showing respect to our food. 
- It is smart to have the food waste talk before you argue over the last broccoli bite at the dinner table. Bring it up in “times of peace”. 
- Allowing the children to serve themselves makes it easier to talk about why it is smart to take a little bit at a time, and know how much they can eat.`,
  },
  activity_food_14: {
    title: 'I used the "Eat Me First" box today',
    description: `Registered if you used the "Eat Me First" box when you made yourself something to eat.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- With basic commodities such as flour, butter, pasta, egg, canned and spices available, it is is easier to make a new and tasty meal out of leftovers that need to be eaten
- Matvett has made an easy “use up encyclopedia” for inspiration to how leftovers can be turned into tasty meals?

## Find out more
- [https://www.matvett.no/brukopp-leksikon](https://www.matvett.no/brukopp-leksikon)`,
  },
  activity_food_15: {
    title: 'I stored my food properly today',
    description: `Registered if you placed your food where it's best stored to keep it longer.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- The fridge should keep an average temperature of 2-4 celsius degrees and has different from top to bottom? Place the food based on what it withstands best
- E.g. Bananas, avocado, tomatoes is best kept outside the fridge. 
- Apples, plums, and tropical fruit contains ethylene gas that affects the food around. Thus, they should not be kept alongside sensitive fruits such as bananas and avocados. Potatoes has a similar effect on onions, so they should also be kept separate
- If you have a wine cabinet, it could have the optimal temperature to store fruit that is happiest in “middle temperature” like bananas and cucumbers 

## Find out more
- [https://www.matprat.no/artikler/oppbevaring-og-holdbarhet/hvor-i-kjoleskapet-bor-maten-sta/](https://www.matprat.no/artikler/oppbevaring-og-holdbarhet/hvor-i-kjoleskapet-bor-maten-sta/)
- [https://www.dagbladet.no/mat/forskningssjef---pass-deg-for-bananfella/70013611](https://www.dagbladet.no/mat/forskningssjef---pass-deg-for-bananfella/70013611)
- [https://www.frukt.no](https://www.frukt.no)`,
  },
  activity_food_16: {
    title: 'I saved leftovers today',
    description: `Registered if you saved everything big or small after today's meals.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    time_explanation: `MARKDOWN:
## Did you know?
- There is much time, food and money to save by having the food that needs to be eaten first gathered on an easily accessible place. 
- Leftovers does not need to be eaten in the exact same form the day after, it can easily be transformed to something new and delicious.`,
  },
  activity_food_17: {
    title: 'I ate all the food in my lunch box today',
    description: `Registered if you didn't throw away any food in your lunch box.`,
    health_explanation: `MARKDOWN:
## Did you know?
- Norwegians throws away 100 000 breads on average each day.
- To grow grain for one bread, you need two square meters of topsoil.

## Find out more
- [https://helsenorge.no/kosthold-og-ernaring/mat-for-barn/skolemat](https://helsenorge.no/kosthold-og-ernaring/mat-for-barn/skolemat)
- [https://www.dagbladet.no/mat/tusenvis-av-barn-kaster-matpakka---alle-gjor-den-samme-tabben-ved-skolestart/68597135](https://www.dagbladet.no/mat/tusenvis-av-barn-kaster-matpakka---alle-gjor-den-samme-tabben-ved-skolestart/68597135)
- [https://www.melk.no/Kosthold-og-helse/Skole/Slik-kan-du-variere-en-sunn-matpakke](https://www.melk.no/Kosthold-og-helse/Skole/Slik-kan-du-variere-en-sunn-matpakke)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    landuse_explanation: `MARKDOWN:
## Did you know?
- Norwegians throws away 100 000 breads on average each day.
- To grow grain for one bread, you need two square meters of topsoil.

## Find out more
- [https://kiwi.no/tema/kast-mindre-mat/slik-unngar-du-a-kaste-brod/](https://kiwi.no/tema/kast-mindre-mat/slik-unngar-du-a-kaste-brod/)`,
  },
  activity_food_18: {
    title: 'I chose smaller food packaging sizes today',
    description: `Registered if you bought smaller food packaging sizes that fits you better.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    landuse_explanation: `MARKDOWN:
## Did you know?
- Norwegians throws away 100 000 breads on average each day.
- To grow grain for one bread, you need two square meters of topsoil.

## Find out more
- [https://kiwi.no/tema/kast-mindre-mat/slik-unngar-du-a-kaste-brod/](https://kiwi.no/tema/kast-mindre-mat/slik-unngar-du-a-kaste-brod/)`,
  },
  activity_food_19: {
    title: 'I marked leftover foods today',
    description: `Registered if you marked expiration date and content on leftover foods.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- The freezer could be a great assistant to avoid food waste, but untagged food in the fridge and freezer can lead to many UFO’s (unidentifiable frozen objects) and a lot of food waste.
- It is also smart to have a fixed place for leftovers in your freezer so that you avoid looking for the food
- Having a masking tape and pen lying on the workbench, makes it quick and easy to tag food and remove it when it is eaten

## Find out more
- [https://www.youtube.com/watch?v=B6mi-ZFCprs](https://www.youtube.com/watch?v=B6mi-ZFCprs)`,
  },
  activity_food_20: {
    title: 'I adjusted the amount of food today',
    description: `Registered if you adjusted the amount of food after noticing how much food was left last time.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Many of us estimate based on habits and doesn't notice that we often have the same amount of leftovers?
- Simple tools like tools for measuring pasta, water and adjustable internet recipes can assist the measurement of portions
- It is smart to have fruit lying around if you get extra hungry

## Find out more
- [https://www.lovefoodhatewaste.com/portion-planner#portion_calculator](https://www.lovefoodhatewaste.com/portion-planner#portion_calculator)`,
  },
  activity_food_21: {
    title: "I didn't shop on an empty stomach today",
    description: `Registered if you made sure no one was starving while food shopping.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    money_explanation: `MARKDOWN:
## Did you know?
- We are more likely to buy more food than we need when we are hungry.
- Having a small bag of nuts or snack carrots available, kan make the shopping cheaper, more climate friendly and nicer for big and small.

## Find out more
- https://www.lovefoodhatewaste.com/portion-planner#portion_calculator`,
  },
  activity_food_22: {
    title: 'I included the kids in the cooking today',
    description: `Registered if the kids took part in making one or more of today's meals.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    handprint_explanation: `MARKDOWN:
## Did you know?
- Including kids in the dinner making and what it takes to get the food on our plate can increase the motivation for eating up the food.

## Inspiration 
- [https://www.rema.no/nyheter/derfor-bor-barna-vaere-med-pa-matlagingen/](https://www.rema.no/nyheter/derfor-bor-barna-vaere-med-pa-matlagingen/)
`,
  },
  activity_food_23: {
    title: 'I helped the store avoid food waste today',
    description: `Registered if you contributed to food not being thrown away from the store.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Norwegian grocery stores throws away more that 60 000 tons of food per year.
- The stores have to throw away refrigerated items that are left around the store when going to the checkout, if they don’t know for how long it has been lying there.
- Norwegian stores throw away 60 000 bananas everyday. A lot of the reason for this is that nobody chooses the single bananas, even when we are only buying one. 
- When you buy food that you know that you will be using soon, you contribute to less food waste when you buy the one with the shortest expiry date.
- If you squeeze too hard on the avocado, they can easily get damaged so that they have to be thrown away.
- If you mix and match candy or nuts and change your mind and leave it, the store have to throw it away.

## Find out more
- [https://www.framtiden.no/201609077035/aktuelt/mat/butikkene-kaster-60.000-bananer-hver-dag.html](https://www.framtiden.no/201609077035/aktuelt/mat/butikkene-kaster-60.000-bananer-hver-dag.html)
- [https://www.framtiden.no/201706237165/aktuelt/mat/butikker-kaster-mat-de-kunne-gitt-til-trengende.html](https://www.framtiden.no/201706237165/aktuelt/mat/butikker-kaster-mat-de-kunne-gitt-til-trengende.html)`,
  },
  activity_food_24: {
    title: 'I saved meat or fish from being thrown away today',
    description: `Registered if you didn't throw away any meat or fish.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Meat production is responsible for approximately 15% of the global climate emissions.
- Fresh meat, fish and seafood is often tagged with the “last consumption day”. If it is stored right, it is often safe to eat also after the date. Use your senses to consider if it it bad or still edible.
- The bacterias attack the meat from the outside, so there is a big difference between minced meat products and whole cuts? You should be more careful with minced meat products like ground beef and liver paste after the expiry date because they more easily get germ formations. Whole cuts, rather gets more tender the older it is. Professional chefs often prefer meat that are close to has passed the expiry data. 
- Meat products often have intense smell immediately after you have opened it, that you should not get fooled by? Wait a couple of minutes and see if it still smells bad, then you should be careful and not eat it.

## Find out more
- [https://www.framtiden.no/gronne-tips/mat/sjekk-hvilken-mat-som-er-best-for-miljoet.html](https://www.framtiden.no/gronne-tips/mat/sjekk-hvilken-mat-som-er-best-for-miljoet.html)`,
  },
  activity_food_25: {
    title: 'I saved vegetables from being thrown away today',
    description: `Registered if you didn't throw away vegetables.`,
    co2_explanation: `MARKDOWN:
## Did you know?
- We throw away 11,3 kg fruit and vegetables per person a year.
- Soggy salad and carrots can get fresh and crisp again if they are placed in ice cold water in a bowl for a few minutes
- Some lemon or lime on a salad or other vegetables can contribute to them being fresh over longer time
- Soggy vegetables are super nice in soups, sauces, stews or baked in the oven.

## Inspiration
- [https://www.kk.no/livstil/fa-liv-i-slappe-gronnsaker/67917470](https://www.kk.no/livstil/fa-liv-i-slappe-gronnsaker/67917470)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## Did you know?
- Hand blending your vegetables makes it easy to mix it into pizza sauces and soups. A great way of sneaking in more vegetables in your diet.

## Find out more
- [https://www.ostfoldforskning.no/no/publikasjoner/Publication/?id=2479](https://www.ostfoldforskning.no/no/publikasjoner/Publication/?id=2479)`,
  },
  activity_food_26: {
    title: 'I saved fruit from being thrown away today',
    description: `Registered if you didn't throw away fruits.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- We throw away 11,3 kg fruit and vegetables per person a year.
- Overripe and brown fruit can be used in baking, smoothies or be frozen down and be used for something good later.

## Find out more
- [https://www.ostfoldforskning.no/no/publikasjoner/Publication/?id=2479](https://www.ostfoldforskning.no/no/publikasjoner/Publication/?id=2479)
- [https://www.nrk.no/viten/disse-fruktene-og-gronnsakene-kastes-det-mest-av-1.13852697](https://www.nrk.no/viten/disse-fruktene-og-gronnsakene-kastes-det-mest-av-1.13852697)

## Inspiration
- [https://www.melk.no/Inspirasjon/Restemat/Gode-tips-til-hva-du-kan-lage-med-overmoden-frukt-og-baer](https://www.melk.no/Inspirasjon/Restemat/Gode-tips-til-hva-du-kan-lage-med-overmoden-frukt-og-baer)`,
  },
  activity_food_27: {
    title: 'I saved dairy products from being thrown away today',
    description: `Registered if you didn't throw away dairy products.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- We throw away 2,7 kg dairy products per person a year.
- Most if the dairy products are tagged best before and are often good long after the expiry date.
- Many dairy products are “sour” or matured in the first place, like for example yoghurt, sour cream and cheese. 
- Milk, sour cream, and yoghurt that has expired can easily be used in waffles, cakes or smoothies. 
- Leftovers from cheese are perfect for other leftovers to gratinate them in the oven. 
- Eggs are often usable long after the expiry date, but it can be smart to crack it in a separate glass and consider it with your own senses.

## Find out more
- [https://www.ostfoldforskning.no/no/publikasjoner/Publication/?id=2479](https://www.ostfoldforskning.no/no/publikasjoner/Publication/?id=2479)

## Inspiration
- [https://www.melk.no/Inspirasjon/Restemat](https://www.melk.no/Inspirasjon/Restemat)`,
  },
  activity_food_28: {
    title: 'I saved bread and baked goods today',
    description: `Registered if you didn't throw away bread and baked goods.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- We throw away 8 kg bread and other baked goods per person a year.
- Dry bread can be as good as new as toast, croutons, breading, gratin, for desserts or as cake base.
- Several stores sell less bread to contribute to less food waste.
- By cutting up and freeze bread, you can quickly get as good as fresh slices of bread each day.

## Find out more
- [https://www.ostfoldforskning.no/no/publikasjoner/Publication/?id=2479](https://www.ostfoldforskning.no/no/publikasjoner/Publication/?id=2479)

## Inspiration
- [https://kiwi.no/tema/kast-mindre-mat/slik-unngar-du-a-kaste-brod/](https://kiwi.no/tema/kast-mindre-mat/slik-unngar-du-a-kaste-brod/)`,
  },
  activity_food_29: {
    title: 'I invented a frugal recipe today',
    description: `Registered if you made up a new way to use leftover foods.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- There is a lot of inspiration online and that you don’t need chef skills to make something good out of what you have.
- Having a fixed leftover Thursday is a good way of getting rid of the leftovers before the weekend.
- Clean-your-fridge-soup, leftover wraps, or leftover pancakes is often a hit for big and small.

## Inspiration
- [https://spar.no/Nyheter/middag-uten-matsvinn/](https://spar.no/Nyheter/middag-uten-matsvinn/)`,
  },
  activity_food_30: {
    title: 'I invented a new MatVinn-hack today',
    description: `Registered if you invented a new hack to throw away less food.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    handprint_explanation: `MARKDOWN:
## Did you know?
- Ask people you know to share their best food waste trip! This is a great opportunity to start a conversation about food waste. - Maybe you’ll learn a new tip or two - and at the same time make those around you aware that you care about reducing food waste.`,
  },
  activity_food_31: {
    title: 'I portioned food properly today',
    description: `Registered if you portioned food properly so there were no food left on the plate.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Meny portion after what looks nice on the plate and not what we can eat.
- To serve less and more often kan increase the appetite to nibblers.
- To use a slightly smaller plate can contribute to less food waste.`,
  },
  activity_food_32: {
    title: 'I ate pesticide-free food today',
    description: `Log each day when you take care to eat pesticide-free food, as ecological.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Pesticides affect habitats and contribute to biodiversity loss, including large reductions of insect populations.
- Pesticides also contribute to numerous forms of environmental degradation, including air and water pollution, soil depletion and diminishing biodiversity.
- Brazil, which supplies most of the soy used in Norwegian salmon farming, has the highest use of pesticides in the world, and the soy industry represents 50 % of this. Pesticides that are illegal in Europe are used in Brazil, often being released from aircraft 100 meters from houses, schools and water sources.

## Read more
- [https://documents-dds-ny.un.org/doc/UNDOC/GEN/G17/017/85/PDF/G1701785.pdf?OpenElement](https://documents-dds-ny.un.org/doc/UNDOC/GEN/G17/017/85/PDF/G1701785.pdf?OpenElement)
- [https://www.theguardian.com/environment/2019/feb/10/plummeting-insect-numbers-threaten-collapse-of-nature](https://www.theguardian.com/environment/2019/feb/10/plummeting-insect-numbers-threaten-collapse-of-nature)
- [https://www.framtiden.no/201703157119/aktuelt/mat/det-norske-lakseeventyret-blir-fort-et-mareritt-for-brasil.html](https://www.framtiden.no/201703157119/aktuelt/mat/det-norske-lakseeventyret-blir-fort-et-mareritt-for-brasil.html)`,
    health_explanation: `MARKDOWN:
## Did you know?
- Pesticides are potentially toxic to humans and can have both acute and chronic health effects, depending on the quantity and ways in which a person is exposed.
- People who face the greatest health risks from exposure to pesticides are those who come into contact with them at work, in their home or garden.

## Read more
- [https://www.who.int/news-room/fact-sheets/detail/pesticide-residues-in-food](https://www.who.int/news-room/fact-sheets/detail/pesticide-residues-in-food)`,
  },
  activity_food_33: {
    title: 'I ate vegetarian for breakfast today',
    description: `Log each day you skip meat or fish for breakfast.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Meat production is the source of major CO\u2082 emissions due to animal feed (which largely consists of soy and corn), methane emissions through ruminant discharge, and cutting down the rain forest.
- There are big differences in climate impact coming from the type of meat and how it is produced. For example it is much better for climate emissions if you eat game or quarry rather than industrial meat, and chicken rather than beef and lamb.
- By testing out plant-based dishes in your diet, you contribute to significant reductions in climate emissions.

## Documented effects
- Calculations are made by removing your calorie intake from meat products and transferring these calories to vegetables, fruits and cereal products.
- Check out the climate calculator and its documentation for more information on exactly how much you can save by changing your habits. 
- We've estimated that 25% of your calorie intake comes from breakfast.

## Read more
- [https://static.ducky.eco/calculator_documentation.pdf](https://static.ducky.eco/calculator_documentation.pdf)

## Tips to get started
- [https://vegetarmat.org/type/frokost/](https://vegetarmat.org/type/frokost/)`,
    health_explanation: `MARKDOWN:
## Did you know?
- By reducing your meat consumption, you can potentially reduce the risk of serious diseases such as cardiovascular disease and cancer.
- Your body will be super happy with all the fibre, vitamins and nutrients you get from a more plant-based diet.

## Read more
- [https://www.health.harvard.edu/staying-healthy/becoming-a-vegetarian](https://www.health.harvard.edu/staying-healthy/becoming-a-vegetarian)`,
  },
  activity_food_34: {
    title: 'I ate vegetarian lunch today',
    description: `Log each day you have a fish and meat free lunch, but eat dairy.`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Meat production is the source of major CO\u2082 emissions due to animal feed (which largely consists of soy and corn), methane emissions through ruminant discharge, and cutting down the rain forest.
- There are big differences in climate impact coming from the type of meat and how it is produced. For example it is much better for climate emissions if you eat game or quarry rather than industrial meat, and chicken rather than beef and lamb.
- By testing out plant-based dishes in your diet, you contribute to significant reductions in climate emissions.

## Documented effects
- Calculations are made by removing your calorie intake from meat products and transferring these calories to vegetables, fruits and cereal products.
- Check out the climate calculator and its documentation for more information on exactly how much you can save by changing your habits.

## Read more
- [https://static.ducky.eco/calculator_documentation.pdf](https://static.ducky.eco/calculator_documentation.pdf)

## Tips to get started
- [https://vegetarmat.org/type/lunsj/](https://vegetarmat.org/type/lunsj/)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## Did you know?
- By reducing your meat consumption, you can potentially reduce the risk of serious diseases such as cardiovascular disease and cancer.
- Your body will be super happy with all the fibre, vitamins and nutrients you get from a more plant-based diet.

## Read more
- [https://www.health.harvard.edu/staying-healthy/becoming-a-vegetarian](https://www.health.harvard.edu/staying-healthy/becoming-a-vegetarian)`,
  },
  activity_food_35: {
    title: 'I ate vegetarian for dinner today',
    description: `Log each day you have a fish and meat-free dinner.`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Meat production is the source of major CO\u2082 emissions due to animal feed (which largely consists of soy and corn), methane emissions through ruminant discharge, and cutting down the rain forest.
- There are big differences in climate impact coming from the type of meat and how it is produced. For example it is much better for climate emissions if you eat game or quarry rather than industrial meat, and chicken rather than beef and lamb.
- By testing out plant-based dishes in your diet, you contribute to significant reductions in climate emissions.

## Documented effects
- Calculations are made by removing your calorie intake from meat products and transferring these calories to vegetables, fruits and cereal products.
- We've estimated that 45% of your calorie intake comes from dinner.

## Read more
- [https://static.ducky.eco/calculator_documentation.pdf](https://static.ducky.eco/calculator_documentation.pdf)

## Tips to get started
- [https://trinesmatblogg.no/tag/vegetar/](https://trinesmatblogg.no/tag/vegetar/)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## Did you know?
- By reducing your meat consumption, you can potentially reduce the risk of serious diseases such as cardiovascular disease and cancer.
- Your body will be super happy with all the fibre, vitamins and nutrients you get from a more plant-based diet.

## Read more
- [https://www.health.harvard.edu/staying-healthy/becoming-a-vegetarian](https://www.health.harvard.edu/staying-healthy/becoming-a-vegetarian)`,
    handprint_explanation: `MARKDOWN:
## Did you know?
- Inviting friends, family and acquaintances to a delicious vegetarian dinner can help encourage a reduction in their own meat consumption.

## Effect
- According to psychologist Dr Per Espen Stoknes we can use our social networks to influence the people around us.

## Read more
- [https://www.bi.no/forskning/business-review/articles/2015/12/fem-mater-a-redde-kloden-pa/](https://www.bi.no/forskning/business-review/articles/2015/12/fem-mater-a-redde-kloden-pa/)`,
  },
  activity_food_36: {
    title: 'I ate locally grown food today',
    description: `Log each day when you take care to buy and eat locally grown food.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Locally grown food is beneficial for the environment because it limits the transport miles of the food, and reduces pollution associated with transport (climate emissions, road load, local pollution such as particles and road salt etc.).
- Transport emissions, on the other hand, constitute a very small climate difference if the climate emissions associated with local production, compared to imported foods, is higher. From a CO\u2082 perspective, for example, greenhouse grown vegetables heated with fossil fuel energy is worse for the environment than if we had imported the same food from abroad.

## Read more
- [https://www.framtiden.no/myteknusing/er-kortreist-mat-mer-miljovennlig.html](https://www.framtiden.no/myteknusing/er-kortreist-mat-mer-miljovennlig.html)`,
  },
  activity_food_37: {
    title: 'I talked to friends and family about sustainable food today',
    description: `Log each day you talk to someone about sustainable food.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Talking about sustainability to a wider audience can help us find better solutions together. A heightened focus among people can also help us accelerate the transition to a greener everyday lifestyle.
- Talking to others can also have positive repercussions that inspire them to buy sustainable foods themselves and talk positively about it to others.

## Read more
- [https://www.weforum.org/agenda/2018/06/how-to-talk-about-climate-change-5-tips-from-the-front-lines/](https://www.weforum.org/agenda/2018/06/how-to-talk-about-climate-change-5-tips-from-the-front-lines/)
- [https://www.ted.com/talks/per_espen_stoknes_how_to_transform_apocalypse_fatigue_into_action_on_global_warming](https://www.ted.com/talks/per_espen_stoknes_how_to_transform_apocalypse_fatigue_into_action_on_global_warming)`,
  },
  activity_food_38: {
    title: 'I ate fish instead of meat for lunch today',
    description: `Log each day you replace meat with fish or veggies for lunch.`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Seafood has a significantly lower carbon footprint compared to meat. Be mindful about choices you make by using eco-certified seafood production that meets environmental standards. For instance, herring, mackerel, cod or pollock has a lower footprint than farmed salmon. Look for these alternatives when shopping or choosing from a menu.

## Documented effect.
- The calculation is done by removing the calorie intake from meat products of an average citizen in your country, and transferring these calories to fish products.
- We estimate that 35% of your calorie intake comes from lunch.

## Les mer
- [https://foodprint.org/issues/sustainable-seafood/](https://foodprint.org/issues/sustainable-seafood/) 
- [https://wwf.panda.org/get_involved/live_green/out_shopping/seafood_guides](https://wwf.panda.org/get_involved/live_green/out_shopping/seafood_guides)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## Did you know? 
- Sustainable consumption of fish can significantly improve your health and well-being.
- Eating fish can help you to decrease the risk of heart disease, strokes, and diabetes. 
- Fat fish contains omega-3 fatty acids proved to help in human development, and can even prevent depression. Fatty fish is also a good source for vitamin D, important for promoting calcium absorption and bone growth.

## Read more
- [https://www.healthline.com/nutrition/11-health-benefits-of-fish#section7](https://www.healthline.com/nutrition/11-health-benefits-of-fish#section7)
- [https://ods.od.nih.gov/factsheets/VitaminD-HealthProfessional/](https://ods.od.nih.gov/factsheets/VitaminD-HealthProfessional/)`,
  },
  activity_food_39: {
    title: 'I ate fish instead of meat for dinner today',
    description: `Log each day you eat sustainable fish instead of meat for dinner.`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Seafood has a significantly lower carbon footprint compared to meat. Be mindful about choices you make by using eco-certified seafood production that meets environmental standards. For instance, herring, mackerel, cod or pollock has a lower footprint than farmed salmon. Look for these alternatives when shopping or choosing from a menu.

## Documented effect.
- The calculation is done by removing the calorie intake from meat products of an average citizen in your country, and transferring these calories to fish products.
- We estimate that 35% of your calorie intake comes from lunch.

## Les mer
- [https://foodprint.org/issues/sustainable-seafood/](https://foodprint.org/issues/sustainable-seafood/) 
- [https://wwf.panda.org/get_involved/live_green/out_shopping/seafood_guides](https://wwf.panda.org/get_involved/live_green/out_shopping/seafood_guides)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## Did you know? 
- Sustainable consumption of fish can significantly improve your health and well-being.
- Eating fish can help you to decrease the risk of heart disease, strokes, and diabetes. 
- Fat fish contains omega-3 fatty acids proved to help in human development, and can even prevent depression. Fatty fish is also a good source for vitamin D, important for promoting calcium absorption and bone growth.

## Read more
- [https://www.healthline.com/nutrition/11-health-benefits-of-fish#section7](https://www.healthline.com/nutrition/11-health-benefits-of-fish#section7)
- [https://ods.od.nih.gov/factsheets/VitaminD-HealthProfessional/](https://ods.od.nih.gov/factsheets/VitaminD-HealthProfessional/)`,
  },
  activity_food_40: {
    title: 'I ate out today',
    description: `Log each day you eat out, spending money on services instead of things.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- You can eat at a restaurant with good conscience? Investing our money in services instead of buying things can be both climate friendly and enjoyable. Remember to choose climate friendly restaurants!

## Read more
- [https://www.visitoslo.com/no/oslo-for-deg/gront-oslo/spis-gront/](https://www.visitoslo.com/no/oslo-for-deg/gront-oslo/spis-gront/)`,
  },
  activity_food_41: {
    title: 'I drank tap water instead of bottled water today',
    description: `Log each day you drink tap water instead of bottled water.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Drinking tap water instead of bottled water saves the CO\u2082 emissions related to the capture, transport and treatment of the water at the bottling plant. As well as the emissions related to producing the bottles, cleaning, filling, sealing, labelling and refrigerating them, and transporting them to the retailer and then to the consumer.

## Read more
- [https://www.countyofkane.org/Recycling/Documents/TakeBackTheTap_web.pdf](https://www.countyofkane.org/Recycling/Documents/TakeBackTheTap_web.pdf)`,
  },
  activity_food_42: {
    title: 'Today I asked my store to stock more sustainable food',
    description: `Log each day you ask a store to stock more sustainable food products.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    handprint_explanation: `MARKDOWN:
## Did you know?
- By requesting sustainable foods you tell producers that you want your food to come from sustainable resources. 

## Read more
- [https://www.weforum.org/agenda/2018/06/how-to-talk-about-climate-change-5-tips-from-the-front-lines/](https://www.weforum.org/agenda/2018/06/how-to-talk-about-climate-change-5-tips-from-the-front-lines/)
- [https://www.ted.com/talks/per_espen_stoknes_how_to_transform_apocalypse_fatigue_into_action_on_global_warming](https://www.ted.com/talks/per_espen_stoknes_how_to_transform_apocalypse_fatigue_into_action_on_global_warming)`,
  },
  activity_food_43: {
    title: 'I ate vegan for breakfast today',
    description: `Log each day you have a fish, meat and dairy-free breakfast.`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Meat and dairy production is the source of major CO\u2082 emissions due to animal feed (which largely consists of soy and corn), methane emissions through ruminant discharge, and cutting down the rain forest.
- For example, milk, yoghurt and cheese have high emissions per calorie content. There are many good alternatives to dairy products made from oats, rice, soy and nuts.

## Documented effects
- Calculations are made by removing your calorie intake from meat products and transferring these calories to vegetables, fruits and cereal products.
- We've estimated that 20% of a daily calorie intake for an average person comes from breakfast.

## Read more
- [https://www.framtiden.no/gronne-tips/spor-oss/bor-jeg-unnga-a-spise-soya.html](https://www.framtiden.no/gronne-tips/spor-oss/bor-jeg-unnga-a-spise-soya.html)

## Tips to get started
- [https://www.vegansamfunnet.no/mattips/](https://www.vegansamfunnet.no/mattips/)
- [https://www.vegetarbloggen.no/category/veganske-oppskrifter/frokost/](https://www.vegetarbloggen.no/category/veganske-oppskrifter/frokost/)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## Did you know? 
- Reducing your dairy consumption especially the fat-saturated products can improve your health and decrease the risks for heart disease, cancer, and obesity. 
- If you are going for dairy-free diet, then you need to ensure supplementary vitamins (especially, vitamin B) and minerals that is needed for your body through wide range of options from vegetables fruits, grains, and dairy-free milk alternatives. 

## Read more
- [https://www.hollandandbarrett.com/the-health-hub/food-drink/diets/dairy-free/keeping-healthy-dairy-free-diet/](https://www.hollandandbarrett.com/the-health-hub/food-drink/diets/dairy-free/keeping-healthy-dairy-free-diet/)`,
  },
  activity_food_44: {
    title: 'I ate vegan for lunch today',
    description: `Log each day you have a fish, meat and dairy-free lunch.`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Meat and dairy production is the source of major CO\u2082 emissions due to animal feed (which largely consists of soy and corn), methane emissions through ruminant discharge, and cutting down the rain forest.
- For example, milk, yoghurt and cheese have high emissions per calorie content. There are many good alternatives to dairy products made from oats, rice, soy and nuts.

## Documented effects
- Calculations are made by removing your calorie intake from meat products and transferring these calories to vegetables, fruits and cereal products.
- We've estimated that 35% of a daily calorie intake for an average person comes from lunch.

## Read more
- [https://www.framtiden.no/gronne-tips/spor-oss/bor-jeg-unnga-a-spise-soya.html](https://www.framtiden.no/gronne-tips/spor-oss/bor-jeg-unnga-a-spise-soya.html)

## Tips to get started
- [https://www.vegansamfunnet.no/mattips/](https://www.vegansamfunnet.no/mattips/)
- [https://www.vegetarbloggen.no/](https://www.vegetarbloggen.no/)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## Did you know? 
- Reducing your dairy consumption especially the fat-saturated products can improve your health and decrease the risks for heart disease, cancer, and obesity. 
- If you are going for dairy-free diet, then you need to ensure supplementary vitamins (especially, vitamin B) and minerals that is needed for your body through wide range of options from vegetables fruits, grains, and dairy-free milk alternatives. 

## Read more
- [https://www.hollandandbarrett.com/the-health-hub/food-drink/diets/dairy-free/keeping-healthy-dairy-free-diet/](https://www.hollandandbarrett.com/the-health-hub/food-drink/diets/dairy-free/keeping-healthy-dairy-free-diet/)`,
  },
  activity_food_45: {
    title: 'I ate vegan for dinner today',
    description: `Log each day you have a fish, meat and dairy-free dinner.`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Meat and dairy production is the source of major CO\u2082 emissions due to animal feed (which largely consists of soy and corn), methane emissions through ruminant discharge, and cutting down the rain forest.
- For example, milk, yoghurt and cheese have high emissions per calorie content. There are many good alternatives to dairy products made from oats, rice, soy and nuts.

## Documented effects
- Calculations are made by removing your calorie intake from meat products and transferring these calories to vegetables, fruits and cereal products.
- We've estimated that 45% of a daily calorie intake for an average person comes from dinner.

## Read more
- [https://www.framtiden.no/gronne-tips/spor-oss/bor-jeg-unnga-a-spise-soya.html](https://www.framtiden.no/gronne-tips/spor-oss/bor-jeg-unnga-a-spise-soya.html)

## Tips to get started
- [https://vegetarmat.org/](https://vegetarmat.org/)
- [https://www.vegetarbloggen.no/](https://www.vegetarbloggen.no/)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## Did you know? 
- Reducing your dairy consumption especially the fat-saturated products can improve your health and decrease the risks for heart disease, cancer, and obesity. 
- If you are going for dairy-free diet, then you need to ensure supplementary vitamins (especially, vitamin B) and minerals that is needed for your body through wide range of options from vegetables fruits, grains, and dairy-free milk alternatives. 

## Read more
- [https://www.hollandandbarrett.com/the-health-hub/food-drink/diets/dairy-free/keeping-healthy-dairy-free-diet/](https://www.hollandandbarrett.com/the-health-hub/food-drink/diets/dairy-free/keeping-healthy-dairy-free-diet/)`,
  },
  activity_food_46: {
    title: 'I ate barley instead of rice today',
    description: `Log each day you have barley instead of rice for every meal.`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Barley is probably the most climate efficient production of food energy there is. Producing rice contributes 5 times more climate gas emissions than barley. Barley can also be grown far north in Norway, while rice needs to be imported from Asia, contributing to emissions both from production and transportation.

## Read more
- [https://www.framtiden.no/gronne-tips/mat/slik-tar-du-miljovennlig-mat-pa-kornet.html](https://www.framtiden.no/gronne-tips/mat/slik-tar-du-miljovennlig-mat-pa-kornet.html)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## Did you know? 
- Barley has naturally high levels of the dietary fiber beta-glucan, which has a documented positive health effect on cholesterol and blood sugar. The size of its molecule also works as a cleaning broom for the intestines.

## Read more
- [https://www.framtiden.no/gronne-tips/mat/slik-tar-du-miljovennlig-mat-pa-kornet.html](https://www.framtiden.no/gronne-tips/mat/slik-tar-du-miljovennlig-mat-pa-kornet.html)`,
  },
  activity_food_47: {
    title: 'I ate herring or mackerel instead of salmon today',
    description: `Log each day you have herring or mackerel instead of salmon for all meals.`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Every 6th Norwegian salmon is eaten in Asia or the US, and a large part of the salmon's feed consists of Brazilian soy. This leads to increased climate gas emissions both in production and transport.
- Norway is the worlds biggest salmon exporter, but we also have a lot of other more sustainable fish species to choose from.

## Read more
- [https://www.framtiden.no/201805097290/aktuelt/mat/norsk-lakseoppdrett-er-en-klimabombe.html](https://www.framtiden.no/201805097290/aktuelt/mat/norsk-lakseoppdrett-er-en-klimabombe.html)
- [https://www.framtiden.no/gronne-tips/mat/dette-er-verdens-mest-barekraftige-fiskemat.html](https://www.framtiden.no/gronne-tips/mat/dette-er-verdens-mest-barekraftige-fiskemat.html)
- [https://www.framtiden.no/gronne-tips/mat/klimagassutslipp-fra-matvarer.html](https://www.framtiden.no/gronne-tips/mat/klimagassutslipp-fra-matvarer.html)
- [https://www.framtiden.no/201703157119/aktuelt/mat/det-norske-lakseeventyret-blir-fort-et-mareritt-for-brasil.html](https://www.framtiden.no/201703157119/aktuelt/mat/det-norske-lakseeventyret-blir-fort-et-mareritt-for-brasil.html)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    health_explanation: `MARKDOWN:
## Did you know? 
- Herring is one of the most healthy foods we can eat.

## Read more
- [https://www.framtiden.no/gronne-tips/mat/dette-er-verdens-mest-barekraftige-fiskemat.html](https://www.framtiden.no/gronne-tips/mat/dette-er-verdens-mest-barekraftige-fiskemat.html)`,
  },
  activity_food_48: {
    title: 'I requested soy-free salmon today',
    description: `Log each day you request soy-free salmon at the grocery store or eating out.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Every 6th Norwegian salmon is eaten in Asia or the US, and a large part of the salmon's feed consists of Brazilian soy. This leads to increased climate gas emissions both in production and transport.
- By requesting soy-free salmon, you tell producers that you want our food to come from sustainable sources.

## Read more
- [https://www.framtiden.no/201703157119/aktuelt/mat/det-norske-lakseeventyret-blir-fort-et-mareritt-for-brasil.html](https://www.framtiden.no/201703157119/aktuelt/mat/det-norske-lakseeventyret-blir-fort-et-mareritt-for-brasil.html)
- [https://www.framtiden.no/201812207383/aktuelt/mat/pa-vei-mot-mer-barekraftig-laksefor.html](https://www.framtiden.no/201812207383/aktuelt/mat/pa-vei-mot-mer-barekraftig-laksefor.html)
- [https://www.framtiden.no/201812037376/aktuelt/mat/kun-salma-har-en-plan-for-a-redusere-soyaforbruket.html](https://www.framtiden.no/201812037376/aktuelt/mat/kun-salma-har-en-plan-for-a-redusere-soyaforbruket.html)`,
  },
  activity_food_49: {
    title: 'I ate seasonal food today',
    description: `Log each day you choose to eat seasonal food.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- A good reason for eating more seasonal food is that the produce is harvested at the perfect ripeness. That means we eat our food when it tastes the best!
- At the same time, it limits the production and consumption of food, and reduces the growing of food in energy intensive greenhouses and the long-distance transportation of fresh foods.

## Read more
- [https://www.bama.no/storkjokken/sesongkalender/](https://www.bama.no/storkjokken/sesongkalender/)`,
  },
  activity_food_50: {
    title: 'I replaced cows milk with a non dairy option today',
    description: `Log each day you replace cow's milk with a non dairy option.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Even though the soy used in animal and fish feed is highly environmentally problematic, soy itself has a very low carbon footprint when consumed directly by humans.
- Most of the soy used in vegetarian and vegan products is grown in the EU or the US.

## Effect
- This activity helps you test out vegan options. If you replace all dairy products in a meal, you can then register any of the vegan-meal activities, which has a CO\u2082-calculation.

## Read more
- [https://www.framtiden.no/gronne-tips/spor-oss/bor-jeg-unnga-a-spise-soya.html](https://www.framtiden.no/gronne-tips/spor-oss/bor-jeg-unnga-a-spise-soya.html)`,
  },
  activity_food_51: {
    title: 'I tried a vegetarian meat substitute today',
    description: `Log each day you replace meat with a vegetarian meat substitute.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Even though the soy used in animal and fish feed is highly environmentally problematic, soy itself has a very low carbon footprint when consumed directly by humans.
- 43% of Norwegians say they want to reduce their meat consumption due to environmental and animal welfare reasons.

## Effect
- This activity helps you test out vegetarian options. If you replace a meal with vegetarian options, you can register the vegetarian meal activities, which has a CO\u2082-calculation. 

## Read more
- [https://www.framtiden.no/gronne-tips/mat/klimagassutslipp-fra-matvarer.html](https://www.framtiden.no/gronne-tips/mat/klimagassutslipp-fra-matvarer.html)`,
  },
  activity_food_52: {
    title: 'I ate more sustainable meat instead of beef today',
    description: `Log each day you replace beef with more sustainable meat.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## Did you know?
- Game meat is the most sustainable meat alternative to beef. In most cases, this type of meat ensures better animal welfare  than other types of meat production. The stock of moose, deer and venison in Norway has never been larger. So while we do need to reduce our overall meat consumption, game meat can be a climate-smart alternative for those occasions when we choose to eat meat.
- We eat large quantities of minced meat in Norway, but there are large differences in the environmental impact of the different types of meat we use. Instead of beef, choose minced meat made of organic pork or chicken.

## Read more
- [https://www.framtiden.no/gronne-tips/mat/tips-til-mer-barekraftig-kjott.html](https://www.framtiden.no/gronne-tips/mat/tips-til-mer-barekraftig-kjott.html)`,
  },
  activity_food_53: {
    title: 'I ate local fruits and vegetables today',
    description:
      'Log on days when most of the produce you eat is from a regional producer. Note that the savings are highly dependent on how YOU travel to buy your food, so opt for eco-friendly transport when possible.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Whether or not local food is an efficient way to reduce emissions is a contentious topic. The savings of shorter transport distances are often negated by the more inefficient logistics of local supply chains. However, there are still a lot of social advantages to supporting local production.
- Transport is a relatively small part of the total food footprint; focusing on what you eat can create more savings than focusing on where it is from

## The assumptions
- Ducky's food calculations include a transport factor, which is an additional 10% on top of production emissions. In this activity, we assume that the additional transport emissions for fruits and vegetables you consume in a day are zero.
- If you travel long distances by car to buy local food, this might erase the whole savings. Opt for food baskets or eco-friendly modes of transport when possible.

## Additional info
- [https://ourworldindata.org/environmental-impacts-of-food#you-want-to-reduce-the-carbon-footprint-of-your-food-focus-on-what-you-eat-not-whether-your-food-is-local](https://ourworldindata.org/environmental-impacts-of-food#you-want-to-reduce-the-carbon-footprint-of-your-food-focus-on-what-you-eat-not-whether-your-food-is-local)`,
  },
  activity_food_54: {
    title: 'I ate local beef instead of imported today',
    description:
      'Log on days when you buy beef which is locally produced, instead of beef imported from outside your country',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Net GHG emissions from beef production vary globally, depending upon the management strategy chosen (intensive vs extensive rotational grazing, more sustainable vs non-sustainable feed) and local environmental factors such as climate, soil type as well as local beef production standards, and cultural influences, like breeds and common feeds
- Important to keep in mind that local beef still has higher emissions than other sources of protein (i.e plant protein like soy, fish, chicken etc.). Hence, switching from beef to more sustainable protein sources will have greater environmental benefits than buying local beef instead of imported.

## The assumptions
- Norwegian beef production is less CO\u2082e intensive as compared to global beef production as a result of using low-carbon management strategies for beef production, favorable environmental factors, and lower emissions from transport as a result of absence of import

## Additional info
- [https://onlinelibrary.wiley.com/doi/full/10.1111/gcb.15509?af=R](https://onlinelibrary.wiley.com/doi/full/10.1111/gcb.15509?af=R)`,
  },
  activity_food_55: {
    title: 'I ate vegan today',
    description:
      'Log on the days when you refrain from eating any animal products, such as meat, fish and dairy, for all meals',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Animal husbandry is a major source of greenhouse gas emissions, mainly due to methane emitted by ruminants like cows and sheep, and land use change (cutting down forests to create space to graze animals) 
- Eating more plants and fewer animal products is one of the most effective ways you can reduce your climate footprint.
- It's easier than ever to eat a vegan diet since there are a plethora of vegan replacements for foods like meat and dairy.

## The assumptions
- Calculations are made by removing your calorie intake from meat, seafood, and dairy products and transferring these calories to vegetables, fruits, and cereal products.
- Check out the climate calculator and its documentation for more information on exactly how much you can save by changing your eating habits.

## Additional info
- [How to go vegetarian](https://www.greenpeace.org.uk/news/10-tips-plant-based-food-go-vegan/?gclid=CjwKCAjw5_GmBhBIEiwA5QSMxFdZpVqdHro0Rd5Zfy23O4jK9qIGp4ooO95M6N6mFLJhAxT4I8l2URoC5iYQAvD_BwE)
- [Giving up dairy and meat has one of the greatest impacts on reducing your footprint](https://ourworldindata.org/environmental-impacts-of-food#)`,
  },
  activity_food_56: {
    title: 'I ate white meat instead of red meat today',
    description:
      'Log on the days you substitute red meat for white meat for all meals',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Based on the findings of this [research paper](https://www.science.org/doi/10.1126/science.aaq0216), the environmental impacts of red meats (beef and lamb) dwarfs that of white meats (poultry, pork). This is partly down to the biology of how ruminant animals digest food; the digestion of ruminants produces a lot of methane, which is a much more potent greenhouse gas than CO\u2082, although it is short lived. The resource-intensive nature of livestock rearing also explains the higher climate cost.

## The assumptions
- Compared to the diet of an average citizen of your country, the calculation removes the calorie intake from red meat, and transfers these calories to white meat products.

## Additional info
- [The climate impact of different meats](https://interactive.carbonbrief.org/what-is-the-climate-impact-of-eating-meat-and-dairy/)`,
  },
};

import React from 'react';
import { BaseControllerClass, state, sequences } from 'cerebral';
import { Container, connect } from '@cerebral/react';
import { ThemeProvider } from 'styled-components';
import Load06 from './Load06';
import TermsModal from './TermsModal';
import Snackbar from 'common/components/Snackbar';
import { extend, locale } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import nb from 'dayjs/locale/nb';
import en from 'dayjs/locale/en-gb';
import ja from 'dayjs/locale/ja';

import LanguageContext from '../../LanguageContext';
import ConfigContext from '../../ConfigContext';

extend(relativeTime);
extend(localizedFormat);

const locales = {
  nb: nb,
  en: en,
  ja: ja,
};

const App = connect(
  {
    isMobile: state`app.isMobile`,
    languageObj: state`appContainer.language.format`,
    showTermsModal: state`appContainer.showTermsModal`,
    clicked: sequences`appContainer.clicked`,
  },
  function App({ clicked, children, languageObj, isMobile, showTermsModal }) {
    locale({ ...locales[languageObj?.locale || 'nb'], ...languageObj });

    if (showTermsModal && isMobile) {
      return <TermsModal isMobile />;
    }

    return (
      <div onClick={() => clicked()}>
        <Load06 />
        <TermsModal />
        <div>{children}</div>
        <Snackbar />
      </div>
    );
  }
);

type Props = {
  readonly app: BaseControllerClass;
  readonly children: React.ReactNode;
};

export default function AppContainer(props: Props): React.ReactElement {
  return (
    <Container app={props.app}>
      <ConfigContext.Provider value={props.app.getState('appContainer.config')}>
        <LanguageContext.Provider
          value={props.app.getState('appContainer.language')}
        >
          <ThemeProvider
            theme={{ isMobile: props.app.getState('app.isMobile') }}
          >
            <App>{props.children}</App>
          </ThemeProvider>
        </LanguageContext.Provider>
      </ConfigContext.Provider>
    </Container>
  );
}

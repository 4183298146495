import React from 'react';
import * as PropTypes from 'prop-types';
import { Wrapper, ActivityIcon, RightColumn, Title } from './elements';
import Lab021 from 'dc/Lab021';

function LIC003(props) {
  return (
    <Wrapper className={props.className} onClick={props.onClick}>
      <ActivityIcon icon={props.activityIcon} />
      <RightColumn>
        <Title>{props.title}</Title>
        <Lab021 co2={props.co2} />
      </RightColumn>
    </Wrapper>
  );
}

LIC003.propTypes = {
  activityIcon: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  co2: PropTypes.node,
};

export default LIC003;

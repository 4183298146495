/* eslint-disable max-statements */

function validateChallenge({ forms }) {
  const challengeForm = forms.toJSON('admin.campaigns.create.challenge.form');
  const backlogDays = challengeForm.backlogDays > 0;
  const emailUpdateFrequency = challengeForm.emailUpdateFrequency > 0;
  const challengeStartDatetime = challengeForm.startDate;
  const challengePrize = challengeForm.prize;
  const challengeEndDatetime = challengeForm.endDate;
  const activitiesForm = forms.toJSON(
    'admin.campaigns.create.challenge.activities.form'
  );
  const activities = Object.keys(activitiesForm).filter(
    activityKey => activitiesForm[activityKey]
  );
  const isValidChallenge =
    backlogDays &&
    emailUpdateFrequency &&
    challengeStartDatetime &&
    challengeEndDatetime &&
    challengePrize &&
    activities.length > 1; // Tutorial activity and one chosen activity are needed

  return isValidChallenge;
}

function validateTools({ state, forms }) {
  const challengeAdded = state.get('admin.campaigns.create.challenge.added');
  let isValidTools = challengeAdded;

  if (challengeAdded) {
    isValidTools &&= validateChallenge({ forms });
  }

  return isValidTools;
}

export function validateChampionship({ state, path, forms }) {
  const organisationForm = forms.toJSON(
    'admin.campaigns.create.organisation.form'
  );
  const infoForm = forms.toJSON('admin.campaigns.create.info.form');
  const organisation = organisationForm.organisation.length > 0;
  const focusForm = forms.toJSON('admin.campaigns.create.focus.form');
  const startDate = infoForm.startDate.length > 0;
  const endDate = infoForm.endDate.length > 0;
  const description = infoForm.description.length > 0;
  const tagline = infoForm.tagline.length > 0;
  const audience = focusForm.audience.length > 0;
  const topic = focusForm.topic.length > 0;
  const name = infoForm.name.length > 0;
  const seriesValid = state.get('admin.championships.create.series.isValid');

  const isValid =
    organisation &&
    startDate &&
    endDate &&
    topic &&
    audience &&
    name &&
    description &&
    tagline &&
    seriesValid;
  if (isValid && validateTools({ state, forms })) {
    return path.true();
  }

  return path.false();
}

export function createChampionship({ firebase, state, forms }) {
  const create = state.get('admin.campaigns.create');
  const series = forms.toJSON('admin.championships.create.series.form');

  delete create.organisation.organisations;
  delete create.organisation.showOrgDropdown;
  delete create.info.sectionTermDropdownIsOpen;
  delete create.challenge.activities.taggedActivities;
  delete create.challenge.activities.tags;
  delete create.challenge.activityModal;
  delete create.challenge.tagModal;

  const template = {
    challenge: create.challenge,
    focus: create.focus,
    info: create.info,
    integration: create.integration,
    organisation: create.organisation,
    config: create.config,
    series,
  };

  return firebase.task('create_championship', template).catch(error => {
    console.log('CREATE CHAMPIONSHIP TASK ERROR', error); // eslint-disable-line no-console
  });
}

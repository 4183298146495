import * as sequences from './sequences';
import { v4 } from 'uuid';

const seriesKey = v4();

export default {
  state: {
    isValid: true,
    form: {
      seriesNeeded: {
        value: false,
        defaultValue: '',
        validationRules: ['isExisty'],
      },
      seriesType: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      createdSeries: {
        [seriesKey]: {
          name: {
            value: '',
            defaultValue: '',
            validationRules: [
              'isValue',
              'isUnique:{"form":"admin.championships.create.series.form.createdSeries","field":"name"}',
            ],
          },
        },
      },
    },
  },
  sequences: {
    seriesOptionClicked: sequences.setSeriesOption,
    seriesTypeOptionClicked: sequences.setSelectedSeriesTypeOption,
    addSeriesClicked: sequences.addSeries,
    removeSeriesClicked: sequences.removeSeries,
    seriesFieldChanged: sequences.seriesFieldChanged,
  },
};

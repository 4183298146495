import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Wrapper,
  RadioInput,
  Title,
  Description,
  RadiosLabel,
} from './elements';
import { ChampionshipLabel } from 'admin/onboarding/App/Campaigns/CreateCampaign/elements';
import { useText } from 'common/hooks';
import { AUDIENCE, TOPIC, DATA_SOURCE } from 'resources/constants';
import topicsData from 'toolData/topics';

export default connect(
  {
    campaignFocusForm: state`admin.campaigns.create.focus.form`,
    isJoiningChampionship: state`admin.campaigns.create.isJoiningChampionship`,
    topicChanged: sequences`admin.campaigns.create.focus.topicChanged`,
    audienceChanged: sequences`admin.campaigns.create.focus.audienceChanged`,
    dataSourceChanged: sequences`admin.campaigns.create.focus.dataSourceChanged`,
  },
  function Focus({
    campaignFocusForm,
    isJoiningChampionship,
    topicChanged,
    audienceChanged,
    dataSourceChanged,
  }) {
    const t = useText();

    return (
      <Wrapper>
        {isJoiningChampionship ? (
          <ChampionshipLabel icon={'icon-visibility'} color={'#03739A'}>
            {t('championship.view_only')}
          </ChampionshipLabel>
        ) : null}
        <Title>{t('admin.focus.choose_focus')}</Title>
        <Description>{t('admin.focus.select_topic')}</Description>
        <RadiosLabel>{t('admin.focus.target_audience')}</RadiosLabel>
        {Object.values(AUDIENCE).map(audience => (
          <RadioInput
            disabled={
              isJoiningChampionship &&
              audience !== campaignFocusForm.audience.value
            }
            onClick={() => {
              if (!isJoiningChampionship)
                audienceChanged({
                  audience,
                });
            }}
            checked={campaignFocusForm.audience.value === audience}
            label={t(`admin.focus.audience_${audience}`)}
            key={audience}
          />
        ))}
        <RadiosLabel>{t('admin.focus.topics_included')}</RadiosLabel>
        {Object.values(TOPIC).map(topic => (
          <RadioInput
            onClick={() => {
              if (!isJoiningChampionship)
                topicChanged({
                  topic,
                });
            }}
            checked={campaignFocusForm.topic.value === topic}
            label={t(`admin.focus.topic_${topic}`)}
            disabled={
              topic === TOPIC.workTransport ||
              !topicsData[campaignFocusForm.audience.value][topic] ||
              (isJoiningChampionship && topic !== campaignFocusForm.topic.value)
            }
            key={topic}
          />
        ))}
        {campaignFocusForm.topic.value === TOPIC.co2 && (
          <>
            <RadiosLabel>{t('admin.focus.choose_data_source')}</RadiosLabel>
            {Object.values(DATA_SOURCE).map(dataSource => (
              <RadioInput
                onClick={() => {
                  if (!isJoiningChampionship)
                    dataSourceChanged({
                      dataSource,
                    });
                }}
                checked={campaignFocusForm.dataSource.value === dataSource}
                label={t(`admin.focus.data_source.${dataSource}`)}
                disabled={isJoiningChampionship}
                key={dataSource}
              />
            ))}
          </>
        )}
      </Wrapper>
    );
  }
);

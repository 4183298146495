import dayjs from 'dayjs';

export function getOrganisation({ props, firebase }) {
  return firebase
    .value(`organisations.list.${props.organisationKey}`)
    .then(response => ({
      organisation: { ...(response.value || {}), key: response.key },
    }));
}

export function setCurrentChampionships({ props, state }) {
  const championships = props.response.value;
  const currentChampionships = Object.assign(
    {},
    ...Object.keys(championships).flatMap(championshipKey => {
      if (
        dayjs(championships[championshipKey].config.endDate)
          .add(23, 'h')
          .add(59, 'm')
          .valueOf() > Date.now() ||
        championships[championshipKey].config.endDatetime > Date.now() // TODO: Remove once datetimes are no longer used on championship
      ) {
        return {
          [championshipKey]: championships[championshipKey].config.name,
        };
      }

      return {};
    })
  );

  state.set('admin.organisations.edit.championships', currentChampionships);
}

export function setKeysInStorage({ state, storage }) {
  const championshipKey = state.get(
    'admin.organisations.edit.form.championship.value'
  );
  const organisationKey = state.get('admin.organisations.edit.organisationKey');
  storage.set('championshipKey', championshipKey);
  storage.set('organisationKey', organisationKey);
}

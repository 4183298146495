import createModule from './modules/create';
import editModule from './modules/edit';

import { renderPage } from '../../factories';
import * as sequences from './sequences';

export default {
  state: {
    list: [],
  },
  modules: {
    create: createModule,
    edit: editModule,
  },
  sequences: {
    routed: renderPage('organisations', sequences.rendered),
  },
};

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';

import { Wrapper, CheLab, Item, Lic, ModalButton } from './elements';
import { useText, useFormatNumber, useGetActivityIconUrl } from 'common/hooks';
import { INDICATOR } from 'resources/constants';
import { getIndicator } from 'utils/common';

/**
 * List activities inside a category, allowing to (un)check them.
 */
export default connect(
  {
    allActivities: state`appContainer.activities`,
    activitiesForm: state`admin.campaigns.create.challenge.activities.form`,
    fieldChanged: sequences`admin.campaigns.create.fieldChanged`,
    adminActivityDetailsClicked: sequences`admin.campaigns.create.challenge.adminActivityDetailsClicked`,
  },
  function ListActivitiesByCategory({
    activityKeys,
    allActivities,
    activitiesForm,
    fieldChanged,
    adminActivityDetailsClicked,
    disabled,
  }) {
    const t = useText();
    const formatNumber = useFormatNumber();
    const getActivityIconUrl = useGetActivityIconUrl();

    const allSelected = activityKeys.reduce(
      (allSelect, activityKey) =>
        allSelect &&
        activitiesForm[activityKey] &&
        activitiesForm[activityKey].value,
      true,
    );

    /**
     * (Un)select all activities
     */
    function selectAll() {
      const newValue = !allSelected;
      activityKeys.forEach(activityKey => {
        fieldChanged({
          field: `admin.campaigns.create.challenge.activities.form.${activityKey}`,
          value: newValue,
        });
      });
    }

    function isChecked(activityKey) {
      return activitiesForm[activityKey] && activitiesForm[activityKey].value;
    }

    function toggle(activityKey) {
      fieldChanged({
        field: `admin.campaigns.create.challenge.activities.form.${activityKey}`,
        value: !isChecked(activityKey),
      });
    }

    return (
      <Wrapper>
        <CheLab
          disabled={disabled}
          checked={allSelected}
          onClick={() => selectAll()}
        >
          {t('admin.challenge.activities.select_all')}
        </CheLab>
        {activityKeys.map(activityKey => {
          // Render each activity
          const activity = allActivities[activityKey];
          const icon = getActivityIconUrl(activity?.icon);
          const co2 = formatNumber(
            getIndicator(allActivities[activityKey].indicators, INDICATOR.co2),
            2,
          );

          return (
            <Item key={activityKey}>
              <CheLab
                checked={isChecked(activityKey)}
                disabled={disabled}
                onClick={() => toggle(activityKey)}
              >
                <Lic
                  activityIcon={icon}
                  title={t(`challenge.activities.${activityKey}.title`)}
                  co2={co2}
                />
              </CheLab>
              <ModalButton
                onClick={() =>
                  adminActivityDetailsClicked({ activityKey: activityKey })
                }
              >
                {t('admin.challenge.activities.see_details')}
              </ModalButton>
            </Item>
          );
        })}
      </Wrapper>
    );
  },
);

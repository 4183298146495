import React from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import { Wrapper, ContentWrapper } from './elements';

import APOrg01 from './APOrg01';
import APOrg04 from './APOrg04';
import JoinChampionship from './JoinChampionship';

export default connect(
  {
    pageSection: state`admin.pageSection`,
  },
  function CreateOrganisation({ pageSection }) {
    function renderSection() {
      switch (pageSection) {
        case 'structure':
          return <APOrg04 />;
        case 'joinChampionship':
          return <JoinChampionship />;
        case 'info':
        default:
          return <APOrg01 />;
      }
    }

    return (
      <Wrapper>
        <ContentWrapper>{renderSection()}</ContentWrapper>
      </Wrapper>
    );
  }
);

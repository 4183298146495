import { set, toggle } from 'cerebral/factories';
import { state, props, string } from 'cerebral';
import { value } from 'providers/Firebase/src/operators';
import { redirect } from 'common/factories';

import * as actions from './actions';

export const rendered = [
  set(state`admin.organisations.edit.isEdit`, true),
  set(state`admin.organisations.edit.organisationKey`, props`organisationKey`),
  set(state`admin.pageSection`, 'joinChampionship'),
  actions.getOrganisation,
  set(state`admin.organisations.edit.organisation`, props`organisation`),
  value('championships.list'),
  actions.setCurrentChampionships,
];

export const setSelectedChampionshipOption = [
  set(state`admin.organisations.edit.selectedChampionship`, props`option`),
  set(
    state`admin.organisations.edit.form.championship.value`,
    props`option.optionKey`
  ),
  set(state`admin.organisations.edit.championshipDropdownIsOpen`, false),
];

export const toggleChampionshipDropdown = [
  toggle(state`admin.organisations.edit.championshipDropdownIsOpen`),
];

export const joinChampionship = [
  set(state`admin.organisations.edit.isJoiningChampionship`, true),
  actions.setKeysInStorage,
  redirect(string`/campaigns/create`),
  set(state`admin.organisations.edit.isJoiningChampionship`, false),
];

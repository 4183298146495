import React from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import { useText } from 'common/hooks';
import { Form, Error } from './elements';

import Section from './Section';
import Team from './Team';

export default connect(
  {
    structureForm: state`admin.structure.structureForm`,
    newIds: state`admin.structure.newIds`,
    hasTeamsInAllSections: state`admin.campaigns.hasTeamsInAllSections`,
  },
  function SectionsAndTeams({ structureForm, newIds }) {
    const t = useText();

    const existingSections = Object.entries(structureForm.sections || {});
    const sections = [...existingSections, [newIds.section, { isNew: true }]];

    return (
      <Form>
        {sections.map(([sectionKey, section]) => {
          const existingTeams = Object.entries(section.teams || {});
          const teams = [
            ...existingTeams,
            [newIds.teams[sectionKey], { isNew: true }],
          ];

          return (
            <Section
              key={sectionKey}
              isNew={section.isNew || false}
              sectionKey={sectionKey}
            >
              {teams.map(([teamKey, team]) => (
                <Team
                  key={teamKey}
                  isNew={team.isNew || false}
                  sectionKey={sectionKey}
                  teamKey={teamKey}
                />
              ))}
            </Section>
          );
        })}
        {existingSections.length === 0 && (
          <Error>{t('admin.structure.at_least_one_section')}</Error>
        )}
      </Form>
    );
  }
);

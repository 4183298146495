import styled from 'styled-components';
import Btn021 from 'dc/Btn021';

export const Wrapper = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  min-width: 780px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding-top: 72px;
`;

export const Button = styled(Btn021)``;

import * as sequences from './sequences';

export default {
  state: {
    uploadedImages: {
      desktop: {
        url: null,
        width: 320,
        height: 180,
      },
    },
    form: {
      name: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      tagline: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      description: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      goal: {
        value: '',
        defaultValue: '',
      },
      timezone: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      campaignPageUrl: {
        value: '',
        defaultValue: '',
      },
      startDate: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      endDate: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      sectionTerm: {
        value: 'section',
        defaultValue: 'section',
        validationRules: ['isValue'],
      },
    },
  },
  sequences: {
    campaignImageSelected: sequences.uploadCampaignImage,
    campaignImageRemoved: sequences.removeCampaignImage,
    regionOptionClicked: sequences.setSelectedRegionDropdownOption,
    toggleRegionDropdownClicked: sequences.toggleRegionDropdown,
    timezoneOptionClicked: sequences.setSelectedTimezoneDropdownOption,
    toggleTimezoneDropdownClicked: sequences.toggleTimezoneDropdown,
    sectionTermOptionClicked: sequences.setSelectedSectionTermDropdownOption,
    toggleSectionTermDropdownClicked: sequences.toggleSectionTermDropdown,
  },
};

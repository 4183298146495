/**
 * Look at the structure and search for new entities.
 * Return an object shaped like this:
 * {
 *   newEntities: {
 *     newSection_name: {
 *       isNew: true,
 *       name: "example section",
 *       teams: {
 *         newTeamInNewSection_name: {
 *           name: "example team1",
 *           expectedParticipants: 5,
 *         }
 *       }
 *     },
 *     existingSection_id: {
 *       teams: {
 *         newTeamInExistingSection_name: {
 *           name: "example team2",
 *           expectedParticipants: 5,
 *         }
 *       }
 *     }
 *   }
 * }
 *
 * All teams are new.
 * Section might be new or might be existing ones. Therefore, there is a `isNew` attribute.
 *
 */
export function extractNewEntities({ forms }) {
  const structureForm = forms.get('admin.structure.structureForm');
  const newEntities = { sections: {} };

  Object.entries(structureForm.sections)
    .filter(([, section]) => section.name.value) // Remove empty last row of sections
    .forEach(([sectionKey, section]) => {
      if (!section.exists) {
        const newTeams = Object.fromEntries(
          Object.entries(section.teams)
            .filter(([, team]) => team.name.value) // Remove empty last row of teams
            .map(([teamKey, team]) => [
              teamKey,
              {
                name: team.name.value,
                expectedParticipants: Number(
                  team.expectedParticipantCount.value
                ),
                teamLeaderEmail: team.teamLeaderEmail.value,
              },
            ])
        );
        newEntities.sections[sectionKey] = {
          isNew: true,
          name: section.name.value,
          teams: newTeams,
        };
      } else {
        const newTeams = Object.fromEntries(
          Object.entries(section.teams)
            .filter(([, team]) => !team.exists && team.name.value) // If new or remove empty last row of teams
            .map(([teamKey, team]) => [
              teamKey,
              {
                name: team.name.value,
                expectedParticipants: Number(
                  team.expectedParticipantCount.value
                ),
                teamLeaderEmail: team.teamLeaderEmail.value,
              },
            ])
        );

        if (Object.keys(newTeams).length > 0) {
          newEntities.sections[sectionKey] = {
            teams: newTeams,
          };
        }
      }
    });

  return { newEntities };
}

export function saveNewStructure({ firebase, props, state }) {
  const { newEntities } = props;

  if (Object.keys(newEntities.sections).length === 0) return Promise.resolve();

  return firebase.task('structure_edition_add_new_entities', {
    campaignKey: state.get(`admin.campaigns.edit.campaign.id`),
    newEntities,
  });
}

export function getNewStructureFromDb({ state, firebase }) {
  const campaignKey = state.get(`admin.campaigns.edit.campaign.id`);

  return firebase
    .value(`campaigns.list.${campaignKey}.structure.sections`)
    .then(res => ({ newStructure: res.value }));
}

export function refreshStructure({ state, props }) {
  const { newStructure } = props;
  state.set(`admin.campaigns.edit.campaign.structure.sections`, newStructure);
}

import * as sequences from './sequences';

export default {
  state: {
    activityKey: null,
    showAdminActivityModal: false,
    showMoreInfo: {},
  },
  sequences: {
    closeAdminActivityModalClicked: sequences.closeAdminActivityModalClicked,
    toggleIndicatorExpanded: sequences.toggleIndicatorExpanded,
  },
};

export default {
  title: 'Joining the campaign',
  description: 'How would you like users to join your campaign?',
  options: {
    link: {
      label: 'Open to all who follow a link',
      description:
        'Users must follow a unique link and select team to get access to your campaign. You will get this link when you finish setting up the campaign.',
    },
    email: {
      label: 'Closed with email restrictions ',
      description:
        'Users must register with a certain email address (e.g. work email) and select team to get access to your campaign. Add several domains if needed. Example: @ducky.eco',
    },
    code: {
      label: 'Closed with team codes',
      description:
        'Users must enter a unique team code to get access to the campaign. You will get a list of codes when you finish setting up the campaign, which you should distribute to team leaders.',
    },
    email_and_code: {
      label: 'Closed with email restrictions AND team codes',
      description:
        'Users must register with a certain email address (e.g. work email) and then enter a unique team code to get access to the campaign. You will get a list of codes when you finish setting up the campaign, which you should distribute to team leaders.',
    },
  },
  email: {
    enter: 'Enter email domain',
    add: 'Add another',
  },
};

import React from 'react';
import * as PropTypes from 'prop-types';
import { Wrapper, SectionHeader, Image } from './elements';

type TagModHeProps = {
  colors: {
    primary: string;
    secondary: string;
  };
  backButtonClick: () => void;
  onClose: () => void;
  showBackButton: boolean;
  backButtonText: string;
  image: {
    small: string;
    large: string;
    hasBottomPadding?: boolean;
  };
};

export default function TagModHe({
  colors,
  backButtonClick,
  onClose,
  showBackButton,
  backButtonText,
  image,
}: TagModHeProps): React.ReactElement {
  return (
    <Wrapper colors={colors}>
      <SectionHeader
        onButtonClick={backButtonClick}
        showButton={showBackButton}
        buttonText={backButtonText}
        onClose={onClose}
      />
      <Image src={image.large} hasBottomPadding={image.hasBottomPadding} />
    </Wrapper>
  );
}

TagModHe.propTypes = {
  colors: PropTypes.object,
  onClose: PropTypes.func,
  image: PropTypes.object,
  showBackButton: PropTypes.bool,
  backButtonClick: PropTypes.func,
  backButtonText: PropTypes.string,
};

import styled, { css } from 'styled-components';
import Ico16 from '../Ico16';
import { Typ30 } from '../Typ';

export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  background-color: ${props => (props.whiteBG ? '#ffffff' : '#f4f7f9')};
  border-radius: 4px;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: ${props =>
    props.focus ? '#00a6b6' : props.errorMessage ? '#e0487b' : '#cfd8dc'};
`;

export const Icon = styled(Ico16)`
  display: flex;
  padding-left: 16px;
  align-items: center;
  color: ${props =>
    props.focus ? '#00a6b6' : props.errorMessage ? '#e0487b' : '#cfd8dc'};
  margin-right: 8px;
`;

export const Text = styled(Typ30)`
  width: 100%;
  height: 100%;
  color: #546e7a;
  height: 100%;
  padding: 16px;
  font-size: 14px;
  line-height: 14px;
  cursor: default;
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  padding: 4px 0 6px;
  justify-content: center;
`;

export const ErrorMessage = styled(Typ30)`
  transform: scaleY(0);
  transition: transform 300ms ease;
  transform-origin: top;
  ${props =>
    props.errorMessage &&
    css`
      transform: scaleY(1);
    `};
  line-height: 20px;
  color: #e0487b;
`;

export const Input = styled('input')`
  outline: none;
  width: 100%;
  height: 100%;
  padding: 16px;
  border: 0px solid;
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
  color: #263238;
  background-color: ${props => (!props.value ? '#f4f7f9' : '#ffffff')};
  background-color: ${props => (props.whiteBG ? '#ffffff' : '#f4f7f9')};
  ::placeholder {
    color: #546e7a;
  }
`;

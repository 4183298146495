export default {
  title: 'Describe your campaign',
  description: `MARKDOWN:Add some more information about your campaign, some of which will be used in Ducky tools you choose to set up later. Text entered will only be shown in the language you provide it in. 

  Everything can be changed up until the campaign starts and your campaign will only be published after you have reviewed the timeline and price summary.  
    [Read more about campaigns.](#)`,
  enter_name: 'Enter your campaign name',
  tagline: 'Tagline',
  call_to_action: 'This is a short call to action for the campaign',
  campaign_description: 'Campaign description',
  introduce_teams: 'Introduce your teams to the campaign and its topic.',
  goal_description: 'Goal description',
  main_goals:
    'Write your main sustainability goals. What metrics do you aim to improve? E.g. higher employee engagement, CO\u2082 emission reduction, lower sick rates. This helps both Ducky and your users!',
  upload_photo: `MARKDOWN:Upload a photo or logo that identifies your campaign in Ducky. This will be used across tools.

Format - JPG or PNG

Ratio - 16:9

For best results, upload 1920 x 1080 px image.`,
  campaign_url: 'Campaign page URL',
  your_page: 'eg. https://www.yourcampaignpage.com',
  start_date: 'Campaign start date',
  end_date: 'Campaign end date',
  region: 'Region',
  select_region: 'Select your region',
  timezone: 'Timezone',
  select_timezone: 'Select your timezone',
  section_term: 'Section term',
  selecting_timezone:
    'Selecting a region and timezone will give participants a better experience of the tools they use. If participants in your campaign span more than one timezone, select one that represents the majority.',
};

import { state, props } from 'cerebral';
import { set, toggle, when } from 'cerebral/factories';

import { validateCampaign } from '../../actions';
import { validateChampionship } from 'admin/onboarding/module/modules/championships/modules/create/actions';

export const addIntegration = [
  set(state`admin.campaigns.create.integration.added`, true),
  set(state`admin.campaigns.create.isValid`, false),
  set(state`admin.pageSectionExpanded`, 'integration'),
  set(state`admin.pageSection`, 'publicPages'),
  when(state`admin.campaigns.isChampionship`),
  {
    true: [
      validateChampionship,
      {
        true: set(state`admin.championships.create.isValid`, true),
        false: set(state`admin.championships.create.isValid`, false),
      },
    ],
    false: [
      validateCampaign,
      {
        true: set(state`admin.campaigns.create.isValid`, true),
        false: set(state`admin.campaigns.create.isValid`, false),
      },
    ],
  },
];

export const skipIntegration = [
  set(state`admin.campaigns.create.integration.added`, false),
  set(state`admin.pageSectionExpanded`, null),
  set(state`admin.pageSection`, 'access'),
];

export const toggleIntegration = [
  toggle(state`admin.campaigns.create.integration.form.${props`field`}.value`),
];

import styled from 'styled-components';
import { Typ30, Typ60 } from 'dc/Typ';
import Btn021 from 'dc/Btn021';
import Btn020 from 'dc/Btn020';

const azur500 = '#03739a';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 584px;
  margin: 0 auto;
`;

export const Title = styled(Typ60)`
  color: #546e7a;
  margin-bottom: 16px;
`;

export const Description = styled(Typ30)`
  color: #546e7a;
  margin-bottom: 40px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const AddButton = styled(Btn021)`
  background-color: ${azur500};
  width: 148px;
`;

export const SkipButton = styled(Btn020)`
  span {
    color: ${azur500};
  }
  width: 212px;
`;

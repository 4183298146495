import tags from './tags';
import toplist from './toplist';
import quiz from './quiz';
import info from './info';
import activities from './activities';

export default {
  tags,
  toplist,
  quiz,
  info,
  meta: {
    title: 'Ducky | Climate Challenge',
    short_name: 'Challenge',
    description:
      'Compete to save carbon emissions at your school or workplace, and change habits with simple everyday activities.',
  },
  AddToHomeScreen: {
    title: 'Add to home screen',
    text: 'MARKDOWN:Want quick access to this challenge? Tap the **share button** from the middle of your bottom browser menu, and select **Add to Home**.',
  },
  activities,
  kgCO2e: 'kgCO\u2082e',
  points: 'Points',
  log_all: 'Log all',
  pageTitles: {
    overview: 'Challenge Overview',
    leaderboard: 'Leaderboards',
    log: 'Log Activities',
    feed: 'Latest Events',
    info: 'Challenge Info',
  },
  GMod08: {
    co2: {
      heading: 'Saving kgCO\u2082e',
      content1:
        'Your footprint is the sum of all the actions you do every day, often expressed in the number of kilos of CO\u2082-equivalents (kgCO\u2082e).',
      content2:
        'On Ducky, the leaf icon represents the amount of kgCO\u2082e you save by carrying out sustainable measures compared to the habits of an average Norwegian person.',
    },
    points: {
      heading: 'Points earned',
      content1:
        'In Ducky, the star icon represents the amount of points you earn by carrying out sustainable actions.',
      content2: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    },
    close: 'CLOSE',
  },
  GMod09: {
    heading_atWork: 'Hi %{userName}, You Hero!',
    heading_co2: 'Hi %{userName}, You CO₂ Hero!',
    heading_plastic: 'Hi %{userName}, You plastic Hero!',
    heading_workTransport: 'Hi %{userName}, You transport Hero!',
    heading_foodWaste: 'Hi %{userName}, You food waste Hero!',
    heading_mindfulCitizen: 'Hi %{userName}, You mindful Hero!',
    heading_general: 'Hi %{userName}, You Hero!',
    intro_atWork: 'You are now part of a %{duration} challenge.',
    intro_co2: 'You are now part of a %{duration} CO₂ challenge.',
    intro_plastic: 'You are now part of a %{duration} plastic challenge.',
    intro_workTransport:
      'You are now part of a %{duration} work transport challenge.',
    intro_foodWaste: 'You are now part of a %{duration} food waste challenge.',
    intro_mindfulCitizen:
      'You are now part of a %{duration} mindful citizen challenge.',
    intro_general: 'You are now part of a %{duration} challenge.',
    tagline_atWork_co2:
      'Try out as many at work actions as you can and save CO₂ with your team.',
    tagline_co2_co2:
      'Try out as many CO₂ actions as you can and save CO₂ with your team.',
    tagline_plastic_co2:
      'Try out as many plastic actions as you can and save CO₂ with your team.',
    tagline_workTransport_co2:
      'Try out as many work transport actions as you can and save CO₂ with your team.',
    tagline_foodWaste_co2:
      'Try out as many food waste actions as you can and save CO₂ with your team.',
    tagline_mindfulCitizen_co2:
      'Try out as many mindful actions as you can and save CO₂ with your team',
    tagline_general_co2:
      'Try out as many climate actions as you can and save CO₂ with your team.',
    tagline_atWork_points:
      'Try out as many at work actions as you can and save points with your team.',
    tagline_co2_points:
      'Try out as many CO₂ actions as you can and earn points with your team.',
    tagline_plastic_points:
      'Try out as many plastic actions as you can and earn points with your team.',
    tagline_workTransport_points:
      'Try out as many work transport actions as you can and earn points with your team.',
    tagline_foodWaste_points:
      'Try out as many food waste actions as you can and earn points with your team.',
    tagline_mindfulCitizen_points:
      'Try out as many mindful actions as you can and earn points with your team.',
    tagline_general_points:
      'Try out as many climate actions as you can and earn points with your team.',
    start_button: 'GET STARTED!',
    more_button: 'I WANT TO KNOW MORE',
    details_title1: 'Easy-to-do actions',
    details_text1:
      'Simple and highly effective actions for you to take on in your everyday life.',
    details_title2: 'Insights for each activity',
    details_text2:
      'Learn why each activity is important and how it affects our environment.',
    details_title3: 'Compete in teams',
    details_text3:
      'Work together against other teams and see who can create the most impact.',
    details_title4: 'Simple visualisations',
    details_text4:
      'Gain knowledge with simple visualisations everyone can understand.',
    details_title5: 'See the bigger picture',
    details_text5:
      'Gain insights into how numerous small initiatives can combine to make larger environmental impacts.',
    details_title6: 'Immediate feedback',
    details_text6:
      'Follow the progress of the competition LIVE with real-time visualisations.',
    footer: 'GOT IT',
  },
  GMod10: {
    heading: "You've got this %{userName}!",
    content: "We already know you're a hero, now show everyone else!",
    start_button: 'GO TO CHALLENGE',
    revisit_button: 'REVISIT TUTORIAL',
  },
  GMod16: {
    leaderboard_info: 'Leaderboard info',
    co2_per_team: 'CO\u2082 per team',
    shows_average_co2:
      'Shows the average KgCO\u2082e saved per participant in each team.',
    points_per_team: 'Points per team',
    shows_average_points:
      'Shows the average points per participant in each team.',
    participation_per_team: 'Participation per team',
    shows_percentage_participating:
      'Shows the percentage of those participating in each team out of those that were invited.',
    close: 'Close',
  },
  StreakModal: {
    day_streaks: 'Day streaks',
    log_activities: 'Log activities every day to keep it going!',
    days_logged: 'Days you logged',
    days_missed: 'Days you missed',
  },
  GoalModal: {
    set_goal: 'Set your personal goal',
    1: {
      title: 'Eco Warrior',
      text: 'You stand for what you believe in and are willing to learn. You plan to save what most participants can save in %{length} days: %{goal} %{unit}',
    },
    2: {
      title: 'Planet Saver',
      text: 'Already making an impact. You plan to push yourself a bit more and save %{goal} %{unit} in %{length} days',
    },
    3: {
      title: 'Climate Master',
      text: 'An experienced planet saver and also influencing others. Challenge yourself to save %{goal} %{unit} in %{length} days',
    },
    amend_this: 'You can amend this at any time',
    cancel: 'CANCEL',
    save_goal: 'SAVE GOAL',
  },
  NpsModal: {
    feedback_matters: 'Your feedback matters to us! 😇',
    likely_to_recommend: 'How likely are you to recommend Ducky to others?',
    not_likely: 'not likely',
    very_likely: 'very likely',
    important_reason: "What's the most important reason for your score?",
    share_thoughts: 'Share your thoughts here',
    cancel: 'CANCEL',
    back: 'BACK',
    send_feedback: 'SEND FEEDBACK',
    thanks: 'Thanks for sharing feedback',
    party: '🎉',
    close: 'CLOSE',
  },
  goal_achieved: {
    1: {
      title: 'Nice work Planet Saver!',
      text: 'You have reached your %{goal} %{unit} personal goal',
    },
    2: {
      title: 'Nice work Eco Warrior!',
      text: 'You have reached your %{goal} %{unit} personal goal',
    },
    3: {
      title: 'Wow. You mastered that!!',
      text: 'But your team still needs you! Keep logging',
    },
  },
  participant_goal_achieved: 'reached a personal goal of %{goal} %{unit}!',
  participant_goal_set: 'has set a personal goal of %{goal} %{unit}!',
  user_quiz_completed: {
    consumption: 'You scored %{score}/3 on the goods & services quiz',
    energy: 'You scored %{score}/3 on the energy quiz',
    food: 'You scored %{score}/3 on the food quiz',
    transport: 'You scored %{score}/3 on the transport quiz',
    microplastics: 'You scored %{score}/3 on the microplastics quiz',
  },
  participant_quiz_completed: {
    consumption: 'completed a goods & services quiz',
    energy: 'completed an energy quiz',
    food: 'completed a food quiz',
    transport: 'completed a transport quiz',
    microplastics: 'completed a microplastics quiz',
  },
  participant_quiz_completed_prompt: {
    consumption: 'Take the goods and services quiz',
    energy: 'Take the energy quiz',
    food: 'Take the food quiz',
    transport: 'Take the transport quiz',
    microplastics: 'Take the plastic quiz',
  },
  user_quiz_completed_nice_work: 'Nice work!',
  TabMen03: {
    log: 'Activities',
    topList: 'Leaderboard',
    insights: 'Insights',
    latestEvents: 'Latest events',
  },
  ActMod02: {
    documented_benefits: 'Documented benefits:',
    topic_tags: 'Topic tags:',
    co2_text: 'kgCO\u2082e',
    health_text: 'Health benefits',
    points_text: 'Points',
    money_text: 'Money benefits',
    time_text: 'Time benefits',
    landuse_text: 'Landuse benefits',
    social_text: 'Social benefits',
    handprint_text: 'Handprint benefits',
    biodiversity_text: 'Biodiversity',
  },
  COLab01: {
    your_status: 'Your status',
    points: 'Points',
    activities: 'Activities',
    participation: 'participation',
  },
  SeH002: {
    add_favorite: 'Add as favorite',
    remove_favorite: 'Remove favorite',
    add_habit: 'Add as habit',
    remove_habit: 'Remove Habit',
  },
  ChaDSc1: {
    day: 'Day %{day}',
    your_co2_savings: 'Your savings',
    your_points_savings: 'Your points',
    your_co2_savings_today: 'Your savings - Today',
    your_points_savings_today: 'Your points - Today',
    your_co2_savings_today_tooltip: 'Your savings (kgCO\u2082e) today',
    your_points_savings_today_tooltip: 'Your points - Today',
    your_co2_savings_tooltip: 'Your savings (kgCO\u2082e)',
    your_points_savings_tooltip: 'Your points',
  },
  ChaMeta01: {
    time_til_end: 'until end',
    time_til_start: 'until start',
    days_til_end: 'Days until end',
    participants: 'Participants',
    total_co2: 'Total Savings',
    ended: 'Ended',
    total_co2_tooltip:
      'The total kgCO\u2082e\rsaved by all participants in this challenge',
    total_points: 'Points earned',
    total_points_tooltip: 'Total number of points earned during this challenge',
    total_activities: 'Activities Logged',
    total_activities_tooltip:
      'Total number of activities logged during this challenge',
    total_default: '-',
    total_default_tooltip: '-',
  },
  ChaMeta09: {
    participation: '% Participating',
    actions: 'Activities',
    co2: 'kgCO\u2082e',
    points: 'Points',
  },
  ChStPrt1: {
    main_text:
      'Your saving is equivalent to traveling %{convertedDistance}km by %{vehicle}. This is roughly the same as %{transportMethod} from %{from} to %{to}.',
    sub_text:
      ' Actual distance between %{from} and %{to} is %{actualDistance}km',
    total_co2_saved: 'In total you have saved %{co2} kgCO\u2082e',
  },
  ChStPrt3: {
    average_co2_saved:
      'On average, each participant has saved %{co2} kgCO\u2082e',
    main_text:
      'Your average saving is equivalent to traveling %{convertedDistance}km by %{vehicle}. This is roughly the same as %{transportMethod} from %{from} to %{to}.',
    sub_text:
      ' Actual distance between %{from} and %{to} is %{actualDistance}km',
  },
  ChStPrt4: {
    you: 'You',
    average_in_team: 'Average - Your team',
    average_in_section_term: {
      building: 'Average - Your building',
      department: 'Average - Your department',
      discipline: 'Average - Your discipline',
      faculty: 'Average - Your faculty',
      school: 'Average - Your school',
      area: 'Average - Your area',
      city: 'Average - Your city',
      municipality: 'Average - Your municipality',
      county_municipality: 'Average - Your county municipality',
      country: 'Average - Your country',
      section: 'Average - Your section',
    },
  },
  ChStPrt7: {
    most_popular_activity: 'Most popular activity:',
    activities: 'activities',
  },
  ChStPrt9: {
    challenge_participation_amount:
      '%{participantCounts} of %{expectedParticipantCounts} invited participants are included in this challenge.',
  },
  ChaPaM25B: {
    ChaStat1M: {
      loading_translation: 'loading translation',
      saving_total: 'Total savings in %{organisationName}',
    },
    ChaStat5M: {
      number_of_participants: 'Number of participants in %{organisationName}',
      participants: 'participants',
    },
    personal_insights: 'Personal Insights',
    challenge_insights: 'Challenge Insights',
    no_one_logged: 'Please log an activity to see the challenge insights.',
    statistics_and_insights: 'Statistics and insights',
    for_this_challenge: 'for this challenge',
  },
  ChaPaD10A: {
    ChaSec02: {
      details_co2: 'Climate challenge details',
      details_plastic: 'Plastic challenge details',
      details_foodWaste: 'Foodwaste challenge details',
      details_atWork: 'At work challenge details',
      prize: 'Prize',
      revisit_tutorial: 'Revisit tutorial',
      challenge_faq: 'Challenge FAQ',
      share_feedback: 'Tell us what you think',
      presented_by: 'Presented by',
      presents: 'presents',
    },
  },
  ChaPaM24A: {
    details_co2: 'Climate challenge details',
    details_plastic: 'Plastic challenge details',
    details_foodWaste: 'Foodwaste challenge details',
    details_atWork: 'At work challenge details',
    prize: 'Prize',
    revisit_tutorial: 'Revisit tutorial',
    challenge_faq: 'Challenge FAQ',
  },
  ChaPaD21A: {
    ActItem2: {
      Btn33: 'Log all',
    },
    ActItem7: {
      back: 'BACK TO ALL',
    },
    Fil01: {
      close_filter: 'CLOSE FILTER',
    },
    your_habits: 'Your habits',
    press_to_add_habit_activity:
      'Press “ADD AS HABIT” when you “learn more” about each activity. Then you can log them all here in one click.',
    available_activities: 'Available activities',
    other_activities: 'Other activities',
    placeholderText:
      'Sort activities you log a lot, or wish to focus on, by adding them as habits.',
    all_activities: 'All activities',
    filter: 'FILTER',
  },
  ChaPaM21A: {
    ActItem2: {
      Btn33: 'Log all',
    },
    your_habits: 'Your habits',
    press_to_add_habit_activity:
      'Press “ADD AS HABIT” when you “learn more” about each activity. Then you can log them all here in one click.',
    popular: 'Most Popular in %{organisationName}',
    energy: 'Energy',
    food: 'Food',
    consumption: 'Goods & Services',
    transport: 'Transport',
    tutorial: 'Tutorial',
    available_activities: 'Available activities',
    other_activities: 'Other activities',
  },
  ChaPaD22B: {
    LB01: {
      average: 'Average',
      name: 'Name',
      activities: 'Activities',
      deleted_user: 'Deleted user',
      toplist_your_team: 'Leaderboard - your team',
    },
  },
  ChaPaM22B: {
    toplist_your_team: 'Your Team',
    toplist_view_more: 'View All',
    toplist_view_less: 'View Less',
  },
  latest_events: {
    new: 'New',
    earlier: 'Earlier',
    FeC002: {
      deleted_user: 'Deleted user',
    },
    FeC003: {
      deleted_user: 'Deleted user',
      participating_in_your_team: 'is now participating on your team.',
    },
    FeC004: {
      your_team: 'Your team',
      deleted_user: 'Deleted user',
      you_are_participating: 'You are now participating in this challenge',
      challenge_participation_subtext:
        'along with %{smart_count} other person! |||| along with %{smart_count} other people!',
    },
    FeC005A: {
      now_started: 'The challenge has started!',
    },
    FeC005B: {
      now_complete: 'The challenge is now complete',
    },
    FeC006: {
      new_position_on_toplist: {
        co2: 'New position on the CO\u2082 leaderboard.',
        points: 'New position on the points leaderboard.',
      },
      new_team_rank: 'Your team is now number %{position}',
      position_decreased_from_yesterday:
        'Down %{smart_count} place from yesterday |||| Down %{smart_count} places from yesterday',
      position_increased_from_yesterday:
        'Up %{smart_count} place from yesterday |||| Up %{smart_count} places from yesterday',
      position_similar_to_yesterday: 'Same place as yesterday',
    },

    FeC007: {
      new_level_for_your_team: 'New level for your team',
      loading_translation: 'loading translation',
      your_team_milestone_saving:
        'Your team has on average saved more than %{co2} kgCO\u2082 per participant',
    },
    FeC008: { notification: 'Notification' },
    UserGoalCompleted: {
      reached_your_goal: 'You have reached your %{goal} %{unit} personal goal!',
    },
  },
  ChaPaM23B: {
    recent_activity: 'Recent activity',
    FeC002: {
      deleted_user: 'Deleted user',
    },
    FeC003: {
      deleted_user: 'Deleted user',
      participating_in_your_team: 'is now participating on your team.',
    },
    FeC004: {
      your_team: 'Your team',
      deleted_user: 'Deleted user',
      you_are_participating: 'You are now participating in this challenge',
      challenge_participation_subtext:
        'along with %{smart_count} other person! |||| along with %{smart_count} other people!',
    },
    FeC005A: {
      now_started: 'The challenge has started!',
    },
    FeC005B: {
      now_complete: 'The challenge is now complete',
    },
    FeC007: {
      new_level_for_your_team: 'New level for your team',
      loading_translation: 'loading translation',
      your_team_milestone_saving:
        'Your team has on average saved more than %{co2} kgCO\u2082 per participant',
    },
  },
  ChaPaD24B: {
    PlaHo01: {
      no_one_logged: 'Please log an activity to see the challenge insights.',
    },
    ChaStat1D: {
      saving_total: 'Total savings in %{organisationName}',
      loading_translation: 'loading translation',
      participant_savings_in_section: 'Savings per participant in your section',
    },
    ChaStat2BD: {
      loading_translation: 'loading translation',
      PlaHo01: 'Are you able to achieve average savings of 10 kgCO\u2082e?',
      participant_savings:
        'Average savings per participant in %{organisationName}',
    },
    ChaStat4D: {
      registered_activities: 'Registered activities in %{organisationName}',
      activities: 'Activities',
    },
    ChaStat5D: {
      number_of_participants: 'Number of participants in %{organisationName}',
      participants: 'participants',
    },
    PersonalInsights: {
      your_daily_savings: {
        co2: 'Your daily savings',
        points: 'Your daily points',
        default: '-',
      },
      your_total_savings: {
        co2: 'Your total savings',
        points: 'Your total points',
        default: '-',
      },
      translate: {
        are_you_able: 'Are you able to save 10 kgCO\u2082e or more?',
      },
      day: 'Day %{day}',
      tooltip: {
        co2: 'Your total savings (kgCO\u2082e)',
        points: 'Your total points',
        default: '-',
      },
      streaks: {
        title: {
          track_days: 'Track your active days',
          x_day_streak: 'You are on a %{streak} day streak!',
          missed_a_day: 'Looks like you missed a day of logging',
          logged_x: 'You logged %{totalLogged} out of %{totalDays} days',
          logged_every: 'You logged activities every day!',
        },
        text: {
          get_a_streak: 'Will you get a %{totalDays} day streak?',
          keep_it_going: 'Log activities every day to keep it going',
          lost_streak: 'You’ve lost your streak',
          blank: '',
          nice_work: 'Nice work',
        },
      },
      goals: {
        goal: 'Goal',
        set_goal: 'SET A PERSONAL GOAL',
        edit_goal: 'Edit goal',
        view_impact: 'VIEW YOUR IMPACT',
        set_a_new_goal: 'Set a new goal',
      },
    },
  },
  ChaPaD24M: {
    ChaStat2BM: {
      PlaHo01: 'Are you able to achieve an average savings of 10 kgCO\u2082e?',
      participant_savings:
        'Average savings per participant in %{organisationName}',
    },
    ChaStat4M: {
      registered_activities: 'Registered activities in %{organisationName}',
      activities: 'Activities',
    },
    loading_translation: 'loading translation',
    participant_savings_in_section: 'Savings per participant in your section',
  },
  ChaPaDCB: {
    ChaCo03: {
      your_team: 'Your team',
      saving_kgCO2e: 'Average participant kgCO\u2082e saved in this team',
      points_earned: 'Average participant points earned in this team',
      percentage_participating:
        'Percentage of those participating in this team',
      logged_actions: 'Number of logged actions',
    },
    ChaCo05: {
      saving_per_team: 'Savings per team',
    },
  },
  ChaPaMCB: {
    title: {
      co2: 'CO\u2082 per team',
      points: 'Points per team',
      participation: 'Participation per team',
      actions: 'Activities',
    },
    buttons: {
      co2: 'CO\u2082 per team',
      points: 'Points per team',
      participation: 'Participation per team',
      learn_more: 'Learn more',
      see_section_teams: 'See teams in my section',
      see_challenge_teams: 'Closest teams in the challenge',
      actions: 'Activities',
    },
    ChaMeta10: {
      your_team: 'Your team',
    },
  },
  FeC1: {
    attending: 'Joined',
    deleted_user: 'Deleted user',
    members_in_your_team:
      '%{smart_count} member (your team) |||| %{smart_count} members (your team)',
  },
  LBHead8: {
    saving_co2: 'kgCO\u2082e saved',
    saving_points: 'points earned',
  },
  tutorial: {
    continue: 'CONTINUE TUTORIAL',
    back: 'Back',
    next: 'Next',
    finish: 'Finish',
    PopUp00: {
      title: 'Access all activities by',
      text: 'MARKDOWN:pressing the **+** button',
    },
    PopUp01: {
      title: 'Log an activity you tried',
      text: 'out today by pressing the tick',
    },
    PopUp02: {
      title: "Wow! That's how easy",
      text_co2: 'it is to save CO\u2082!',
      text_points: 'it is to earn points!',
    },
  },
  tips: {
    find_activities_easier: 'Make finding activities easier',
    log_missed_activities: 'Log activities for days you missed',
  },
  status_bar: {
    reminders: 'Enable reminders?',
    read_more: 'READ MORE',
  },
};

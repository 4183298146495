import React from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';

import { Wrapper, Title, Description, TglBox, Spacer, Error } from './elements';
import { ChampionshipLabel } from 'admin/onboarding/App/Campaigns/CreateCampaign/elements';
import { useText } from 'common/hooks';
import ListActivitiesByCategory from './ListActivitiesByCategory';
import AdminActMod00 from './AdminActMod00';
import AdminTagMod10 from './AdminTagMod10';

export default connect(
  {
    availableActivitiesByCategories: state`admin.campaigns.computedAvailableActivitiesByCategory`,
    isJoiningChampionship: state`admin.campaigns.create.isJoiningChampionship`,
    activitiesForm: state`admin.campaigns.create.challenge.activities.form`,
  },
  function Activities({
    availableActivitiesByCategories,
    isJoiningChampionship,
    activitiesForm,
  }) {
    const t = useText();

    const noActivitiesAdded =
      Object.values(activitiesForm).filter(activity => activity.value)
        .length === 1; // The tutorial activity is always present

    return (
      <Wrapper>
        {isJoiningChampionship && (
          <ChampionshipLabel icon={'icon-visibility'} color={'#03739A'}>
            {t('championship.view_only')}
          </ChampionshipLabel>
        )}
        <Title>{t('admin.challenge.activities.choose_activities')}</Title>
        <Description>
          {t('admin.challenge.activities.activities_recommendations')}
        </Description>

        {noActivitiesAdded ? (
          <Error>{t('admin.challenge.activities.no_activities_added')}</Error>
        ) : (
          <Spacer />
        )}

        {availableActivitiesByCategories.map(
          ({ categoryKey, activityKeys }) => (
            <TglBox
              title={t(`activity_category.${categoryKey}`)}
              key={categoryKey}
              startExpanded
            >
              <ListActivitiesByCategory
                category={categoryKey}
                activityKeys={activityKeys}
                disabled={isJoiningChampionship}
              />
            </TglBox>
          ),
        )}

        <AdminTagMod10 />
        <AdminActMod00 />
      </Wrapper>
    );
  },
);

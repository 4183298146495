import styled from 'styled-components';
import Ico24 from '../Ico24';
import { Typ30, Typ60 } from '../Typ';

export const ElementWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const Icon = styled(Ico24)`
  margin-right: 8px;
  color: ${props => (props.color ? props.color : '#00ab97')};
`;

type ValueTextProps = {
  $themeName?: 'light' | 'dark';
  $show?: string;
};

export const ValueText = styled(Typ60)<ValueTextProps>`
  color: ${props => (props.$themeName === 'dark' ? '#fff' : '#263238')};
  margin-right: 8px;
  display: ${props => (props.$show ? 'inline' : 'none')};
`;

type LabelTextProps = {
  $themeName?: 'light' | 'dark';
};

export const LabelText = styled(Typ30)<LabelTextProps>`
  align-self: flex-end;
  line-height: 28px;
  color: ${props =>
    props.$themeName === 'dark' ? 'rgba(255, 255, 255, 0.4)' : '#90a4ae'};
`;

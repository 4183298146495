import { sequence, state, props, string } from 'cerebral';
import { set, when } from 'cerebral/factories';
import { value } from 'providers/Firebase/src/operators';
import { redirect } from 'common/factories';

import * as actions from './actions';

const getOrganisations = [
  value(string`organisations.list`),
  when(props`response.value`),
  {
    true: [
      set(
        state`admin.campaigns.create.organisation.organisations`,
        props`response.value`
      ),
      set(
        state`admin.campaigns.create.organisation.filteredOrganisations`,
        props`response.value`
      ),
    ],
    false: [
      set(state`admin.campaigns.create.organisation.organisations`, {}),
      set(state`admin.campaigns.create.organisation.filteredOrganisations`, {}),
    ],
  },
];

const urlActions = [sequence('campaignCreateUrlActions', [])];

export const render = [
  set(state`admin.championships.isLoading`, true),
  set(state`admin.campaigns.isChampionship`, true),
  getOrganisations,
  urlActions,
  set(state`admin.championships.isLoading`, false),
];

export const createChampionship = [
  set(state`admin.championships.create.isCreatingChampionship`, true),
  actions.createChampionship,
  redirect('/'),
  set(state`admin.championships.create.isCreatingChampionship`, false),
];

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences, props } from 'cerebral';
import { DateInput } from './elements';

export default connect(
  {
    value: state`admin.campaigns.create.info.form.${props`field`}.value`,
    fieldChanged: sequences`admin.campaigns.create.fieldChanged`,
  },
  function Info({ field, value, fieldChanged, disabled }) {
    return (
      <DateInput
        disabled={disabled}
        inputType={'date'}
        onChange={event =>
          fieldChanged({
            field: `admin.campaigns.create.info.form.${field}`,
            value: event.target.value,
          })
        }
        value={value}
        min={'2010-01-01'}
        max={'2050-01-01'}
      />
    );
  }
);

import styled from 'styled-components';
import { Typ30 } from '../Typ';
import Btn021 from '../Btn021';
import IcoW01 from '../IcoW01';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #03739a;
  padding-left: 32px;
`;

export const Line = styled.div`
  position: absolute;
  top: 8px;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: #fff;
`;

export const Text = styled(Typ30)`
  color: #fff;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MainButton = styled(Btn021)`
  display: ${props => (props.visible ? 'block' : 'none')};
  background-color: #c8e7f2;
  span {
    color: #004a66;
  }
  &:hover {
    background-color: #004a66;
    span {
      color: #f4f7f9;
    }
  }
`;

export const CloseButton = styled(IcoW01)`
  margin-left: 32px;
  cursor: pointer;
`;

import styled from 'styled-components';
import Swi03 from '../Swi03';

export const ElementWrapper = styled.div`
  display: flex;
`;

export const Checkbox = styled(Swi03)`
  margin-right: 8px;
`;

export const Label = styled.label`
  align-self: center;
  color: #37474f;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  margin: 0;
  cursor: pointer;
`;

import React from 'react';
import dayjs from 'dayjs';
import { state, sequences } from 'cerebral';
import { connect } from '@cerebral/react';
import { useText } from 'common/hooks';
import {
  Wrapper,
  ContentWrapper,
  NewButton,
  Title,
  SubTitle,
  Label,
  ShowButton,
} from './elements';

export default connect(
  {
    championships: state`admin.championships.list`,
    language: state`appContainer.language.locale`,
    primaryLanguage: state`appContainer.primaryLanguage`,
    showFinished: state`admin.championships.showFinished`,
    toggleShowFinished: sequences`admin.championships.toggleShowFinished`,
  },
  function Championships({
    championships,
    language,
    primaryLanguage,
    showFinished,
    toggleShowFinished,
  }) {
    const t = useText();

    return (
      <Wrapper>
        <ContentWrapper>
          <Title>{t('admin.championship.manage.manage_championships')}</Title>
          <SubTitle>{t('admin.championship.manage.set_up_new')}</SubTitle>
          <NewButton
            onClick={() => {
              location.href = `${
                language === primaryLanguage ? '/' : `/${language}/`
              }championships/create`;
            }}
          >
            {t('admin.championship.manage.add_new')}
          </NewButton>
          {championships
            .filter(champ => champ.status !== 'finished')
            .sort((a, b) => b.config.endDatetime - a.config.endDatetime)
            .map(champ => (
              <Label
                key={champ.champKey}
                buttonText={champ.status}
                smallText={`${dayjs(
                  champ.config.startDatetime || champ.config.startDate // TODO: Remove once datetimes are no longer used on championship
                ).format('DD/MM/YYYY')} - ${dayjs(
                  champ.config.endDatetime || champ.config.endDate // TODO: Remove once datetimes are no longer used on championship
                ).format('DD/MM/YYYY')} (${champ.orgName})`}
                href={`/championships/edit/${champ.champKey}`}
              >
                {champ.config.name}
              </Label>
            ))}

          <ShowButton onClick={() => toggleShowFinished()}>
            {showFinished
              ? t('admin.championship.manage.hide_finished')
              : t('admin.championship.manage.show_finished')}
          </ShowButton>

          {showFinished
            ? championships
                .filter(champ => champ.status === 'finished')
                .sort(
                  (a, b) =>
                    (b.config.endDatetime || // TODO: Remove once datetimes are no longer used on championship
                      dayjs(b.config.startDate).add(1, 'm').valueOf()) -
                    (a.config.endDatetime || // TODO: Remove once datetimes are no longer used on championship
                      dayjs(a.config.endDate)
                        .add(23, 'h')
                        .add(59, 'm')
                        .valueOf())
                )
                .map(champ => (
                  <Label
                    key={champ.champKey}
                    buttonText={champ.status}
                    smallText={`${dayjs(
                      champ.config.startDatetime || champ.config.startDate // TODO: Remove once datetimes are no longer used on championship
                    ).format('DD/MM/YYYY')} - ${dayjs(
                      champ.config.endDatetime || champ.config.endDate // TODO: Remove once datetimes are no longer used on championship
                    ).format('DD/MM/YYYY')} (${champ.orgName})`}
                    href={`/championships/edit/${champ.champKey}`}
                  >
                    {champ.config.name}
                  </Label>
                ))
            : null}
        </ContentWrapper>
      </Wrapper>
    );
  }
);

import React from 'react';
import * as PropTypes from 'prop-types';
import { RadioButton } from './elements';
import Ico24 from '../Ico24';

function Swi02(props) {
  return (
    <RadioButton
      className={props.className}
      checked={props.checked}
      onClick={props.onClick}
    >
      <Ico24
        icon={
          props.checked
            ? 'icon-radio_button_checked'
            : 'icon-radio_button_unchecked'
        }
      />
    </RadioButton>
  );
}

Swi02.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Swi02;

import styled from 'styled-components';
import { Typ30 } from '../Typ';

export const Wrapper = styled.div`
  width: 100%;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f7f9;
  border-bottom: 1px solid #cfd8dc;
`;

export const Text = styled(Typ30)`
  color: #78909c;
`;

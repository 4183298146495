import styled from 'styled-components';
import TxtField06 from 'dc/TxtField06';
import TxtField12 from 'dc/TxtField12';
import { Typ30, Typ60 } from 'dc/Typ';
import Upload01 from 'dc/Upload01';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 584px;
`;

export const EnterInput = styled(TxtField06)`
  background-color: #fff;
  width: 288px;
  align-self: flex-start;
  > div {
    height: 48px;
    background: #fff;
    > input {
      background: #fff;
    }
  }
`;

export const DropdownInput = styled(TxtField12)`
  background-color: #fff;
  width: 288px;
  z-index: 3;
`;

export const Title = styled(Typ60)`
  color: #546e7a;
  margin-bottom: 16px;
`;

export const Text = styled(Typ30)`
  color: #546e7a;
  margin-bottom: 32px;
`;

export const Uploader = styled(Upload01)`
  margin: 38px 0 56px;
`;

export const Label = styled(Typ30)`
  color: #546e7a;
  margin-top: 36px;
`;

export const NumberInput = styled(EnterInput)`
  width: 154px;
`;

import React from 'react';
import { connect } from '@cerebral/react';
import { sequences, state } from 'cerebral';
import { Wrapper, Title, Description, PageDescription } from './elements';
import { ChampionshipLabel } from 'admin/onboarding/App/Campaigns/CreateCampaign/elements';
import { useText } from 'common/hooks';
import Spa16 from 'dc/Spa16';
import Spa32 from 'dc/Spa32';
import Spa40 from 'dc/Spa40';
import Spa96 from 'dc/Spa96';

import CheLab01 from 'dc/CheLab01';

export default connect(
  {
    pages: state`admin.campaigns.create.integration.form.pages`,
    isJoiningChampionship: state`admin.campaigns.create.isJoiningChampionship`,
    optionClicked: sequences`admin.campaigns.create.integration.optionClicked`,
  },
  function PublicPages({ pages, isJoiningChampionship, optionClicked }) {
    const t = useText();

    return (
      <Wrapper>
        {isJoiningChampionship ? (
          <ChampionshipLabel icon={'icon-visibility'} color={'#03739A'}>
            {t('championship.view_only')}
          </ChampionshipLabel>
        ) : null}
        <Spa32 />
        <Title>{t('admin.integration.public_pages.title')}</Title>
        <Spa32 />
        <Description>
          {t('admin.integration.public_pages.description')}
        </Description>
        <Spa40 />
        {Object.keys(pages).map(page => (
          <React.Fragment key={page}>
            <CheLab01
              disabled={isJoiningChampionship}
              checked={pages[page].value}
              onClick={event => {
                event.stopPropagation();
                optionClicked({ field: `pages.${page}` });
              }}
            >
              {t(`admin.integration.public_pages.pages.${page}.title`)}
            </CheLab01>
            <PageDescription>
              {t(`admin.integration.public_pages.pages.${page}.description`)}
            </PageDescription>
            <Spa16 />
          </React.Fragment>
        ))}
        <Spa96 />
      </Wrapper>
    );
  }
);

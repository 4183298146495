/* eslint-disable @typescript-eslint/ban-ts-comment */
import App, { BaseControllerClass, state } from 'cerebral';
import * as Sentry from '@sentry/browser';
import page from 'page';
import qs from 'query-string';
import cloneDeep from 'lodash.clonedeep';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { notifyError } from 'common/appContainer/module/factories';
import { setAuthenticationError } from 'common/appContainer/module/modules/auth/actions';
import FormsProvider from 'providers/Forms';
import {
  FirebaseProviderAuthenticationError,
  FirebaseProviderError,
} from 'providers/Firebase/src/errors';
import UseragentProvider from 'providers/Useragent';
import HttpProvider, { HttpProviderError } from 'providers/Http/client';
import { activitiesProvider } from 'providers/Activities';
import TranslateProvider from '@ducky/provider-translate';
import StorageProvider from 'providers/Storage';
import ConfigProvider from 'providers/Config';
import RouterProvider from 'providers/Router';
import SentryProvider from 'providers/Sentry/client';
import UrlActionProvider from 'providers/UrlAction';
import UuidProvider from 'providers/Uuid';
import { databaseProvider } from 'providers/Database';
import FirebaseProvider from 'providers/Firebase';
import appContainer from 'common/appContainer/module';
import tlds from 'resources/tlds.json';

import admin from './module';
import routes from './routes';

import en from 'resources/languages/en';
import { ClientConfig } from 'configs/types';

const firebaseApp = initializeApp(
  (process.env.CONFIG as unknown as ClientConfig).firebase as FirebaseOptions
);

export default function createController(): BaseControllerClass {
  const rootModule = ({ app }) => {
    app.on('initialized', () => {
      Sentry.init({
        dsn: 'https://2c3ac7bb77ab470a928eb7f1fa93e52b@sentry.ducky.eco/3',
        environment: process.env.DUCKY_ENV,
        release: process.env.SOURCE_VERSION,
      });

      Sentry.addGlobalEventProcessor(event => {
        try {
          if (event.extra) {
            event.extra.state = cloneDeep(app.getState());
          } else {
            event.extra = { state: cloneDeep(app.getState()) };
          }

          // @ts-ignore
          delete event.extra.state.appContainer.language;
          // @ts-ignore
          delete event.extra.state.useragent;
        } catch {
          // Continue regardless of error
        }

        return event;
      });

      app.runSequence('Set Language', [
        ({ store }) => {
          store.set(state`appContainer.language`, en);
          store.set(state`appContainer.primaryLanguage`, 'en');
          store.set(state`appContainer.config`, process.env.CONFIG);
        },
      ]);

      page.start();
    });

    routes.forEach(route => {
      page(route.path, ({ params, querystring, path }) => {
        app.getSequence(route.signal)({
          ...params,
          ...qs.parse(querystring),
          path,
        });
      });
    });

    return {
      modules: {
        appContainer,
        admin,
        useragent: UseragentProvider({
          media: {
            small: '(max-width: 767px)',
            medium: '(min-width: 768px) and (max-width: 1024px)',
            large: '(min-width: 1025px)',
            portrait: '(orientation: portrait)',
          },
          window: true,
        }),
      },
      catch: [
        [HttpProviderError, notifyError('errors.http')],
        [FirebaseProviderAuthenticationError, setAuthenticationError],
        [FirebaseProviderError, notifyError('errors.firebase')],
      ],
      providers: {
        activities: activitiesProvider({
          firebaseUrl: (process.env.CONFIG as unknown as ClientConfig).duckyApi
            .firebaseUrl,
          duckyApiUrl: (process.env.CONFIG as unknown as ClientConfig).duckyApi
            .url,
        }),
        config: ConfigProvider(),
        database: databaseProvider({
          fetch: fetch,
          baseUrl: (process.env.CONFIG as unknown as ClientConfig).firebase
            .databaseURL,
          ccmUrl: (process.env.CONFIG as unknown as ClientConfig).ccm.URL,
          language: 'no',
        }),
        router: RouterProvider(),
        forms: FormsProvider({
          rules: {
            isUnique(value, args, get) {
              const form = get(state`${args.form}`);

              return (
                Object.values(form).filter(
                  formValues =>
                    formValues[args.field].value.trim() === value.trim()
                ).length === 1
              );
            },
            isGreaterThan(value, arg) {
              return value > arg;
            },
            equalToWord(value, arg) {
              return arg.split('||').indexOf(value) > -1;
            },
            hasValidTLD(value) {
              const parts = value.split('.');

              if (parts.length === 0) return true;
              const tld = parts[parts.length - 1];

              return tlds.indexOf(tld.toUpperCase()) >= 0;
            },
          },

          errorMessages: {
            isUnique() {
              return {
                phrase: 'admin.field_errors.must_be_unique',
                options: {},
              };
            },
            isGreaterThan() {
              return {
                phrase: 'authentication.fieldErrors.invalid_email',
                options: {},
              };
            },
            isEmail() {
              return {
                phrase: 'authentication.fieldErrors.invalid_email',
                options: {},
              };
            },
            isAlpha() {
              return {
                phrase: 'authentication.fieldErrors.only_letters',
                options: {},
              };
            },
            minLength(value, length) {
              return {
                phrase: 'authentication.fieldErrors.too_short',
                options: { length },
              };
            },
            isValue() {
              return {
                phrase: 'authentication.fieldErrors.mandatory',
                options: {},
              };
            },
            equalsField() {
              return {
                phrase: 'authentication.fieldErrors.password_mismatch',
                options: {},
              };
            },
            hasValidTLD(value) {
              const parts = value.split('.');

              if (parts.length === 0) return true;
              const tld = parts[parts.length - 1];

              return {
                phrase: 'authentication.fieldErrors.email_valid_TLD',
                options: { tld },
              };
            },
          },
        }),
        storage: StorageProvider(),
        translate: TranslateProvider(),
        http: HttpProvider(),
        sentry: SentryProvider(),
        urlAction: UrlActionProvider(),
        uuid: UuidProvider(),
        firebase: FirebaseProvider({
          app: firebaseApp,
        }),
      },
    };
  };

  const app = App(rootModule);

  window.onbeforeunload = () => app.getSequence('appContainer.unloading')();

  return app;
}

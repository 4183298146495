import styled from 'styled-components';
import TxtField05 from '../TxtField05';
import IcoW01 from '../IcoW01';

export const OuterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${props => (props.hasMargin ? '30px' : '0')};
`;

export const TextInput = styled(TxtField05)`
  position: relative;
  width: calc(100% - 96px);
  > div {
    min-height: 30px;
    > span {
      bottom: 28px;
    }
  }
`;

export const Icon = styled(IcoW01)`
  cursor: pointer;
`;

export const DeleteIcon = styled(IcoW01)`
  cursor: pointer;
  > i:hover {
    color: #03739a;
  }
`;

export const IconPlaceholder = styled.div`
  width: 48px;
  height: 48px;
`;

import { state, props } from 'cerebral';
import { set, toggle } from 'cerebral/factories';
import * as createActions from '../../actions';

export const uploadCampaignImage = [
  set(
    state`admin.campaigns.create.info.uploadedImages.desktop.url`,
    props`url`
  ),
  set(
    state`admin.campaigns.create.info.uploadedImages.desktop.width`,
    props`width`
  ),
  set(
    state`admin.campaigns.create.info.uploadedImages.desktop.height`,
    props`height`
  ),
  set(
    state`admin.campaigns.create.info.uploadedImages.desktop.file`,
    props`file`
  ),
  createActions.validateCampaign,
  {
    true: set(state`admin.campaigns.create.isValid`, true),
    false: set(state`admin.campaigns.create.isValid`, false),
  },
];

export const removeCampaignImage = [
  set(state`admin.campaigns.create.info.uploadedImages.desktop`, null),
  createActions.validateCampaign,
  {
    true: set(state`admin.campaigns.create.isValid`, true),
    false: set(state`admin.campaigns.create.isValid`, false),
  },
];

export const setSelectedRegionDropdownOption = [
  set(
    state`admin.campaigns.create.info.selectedRegion`,
    props`option.optionKey`
  ),
  set(state`admin.campaigns.create.info.regionDropdownIsOpen`, false),
];

export const toggleRegionDropdown = [
  toggle(state`admin.campaigns.create.info.regionDropdownIsOpen`),
];

export const setSelectedTimezoneDropdownOption = [
  set(
    state`admin.campaigns.create.info.selectedTimezone`,
    props`option.optionKey`
  ),
  set(
    state`admin.campaigns.create.info.form.timezone.value`,
    props`option.optionKey`
  ),
  set(state`admin.campaigns.create.info.timezoneDropdownIsOpen`, false),
];

export const toggleTimezoneDropdown = [
  toggle(state`admin.campaigns.create.info.timezoneDropdownIsOpen`),
];

export const toggleSectionTermDropdown = [
  toggle(state`admin.campaigns.create.info.sectionTermDropdownIsOpen`),
];

export const setSelectedSectionTermDropdownOption = [
  set(
    state`admin.campaigns.create.info.selectedSectionTerm`,
    props`option.optionValue`
  ),
  set(
    state`admin.campaigns.create.info.form.sectionTerm.value`,
    props`option.optionKey`
  ),
  set(state`admin.campaigns.create.info.sectionTermDropdownIsOpen`, false),
];

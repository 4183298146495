import React from 'react';
import * as PropTypes from 'prop-types';

import { Wrapper } from './elements';

function Wra16A(props) {
  return (
    <Wrapper
      className={props.className}
      onClick={props.onClick}
      style={props.style}
      id={props.id}
      as={props.as}
    >
      {props.children}
    </Wrapper>
  );
}

Wra16A.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  id: PropTypes.string,
};

export default Wra16A;

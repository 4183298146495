import { sequence, state, props, string } from 'cerebral';
import { set, when, debounce } from 'cerebral/factories';
import { setField } from 'forms/operators';
import { value } from 'providers/Firebase/src/operators';
import { redirect } from 'common/factories';
import { validateChampionship } from 'admin/onboarding/module/modules/championships/modules/create/actions';

import * as actions from './actions';

const getOrganisations = [
  value(string`organisations.list`),
  when(props`response.value`),
  {
    true: [
      set(
        state`admin.campaigns.create.organisation.organisations`,
        props`response.value`
      ),
      set(
        state`admin.campaigns.create.organisation.filteredOrganisations`,
        props`response.value`
      ),
    ],
    false: [
      set(state`admin.campaigns.create.organisation.organisations`, {}),
      set(state`admin.campaigns.create.organisation.filteredOrganisations`, {}),
    ],
  },
];

const urlActions = [sequence('campaignCreateUrlActions', [])];

export const render = [
  set(state`admin.campaigns.isLoading`, true),
  getOrganisations,
  actions.isJoiningChampionship,
  {
    true: [
      actions.getTemplate,
      actions.setTemplate,
      when(state`admin.campaigns.create.series.seriesNeeded`),
      {
        true: [actions.getSeries, actions.setSeries],
        false: [],
      },
    ],
    false: [],
  },
  urlActions,
  set(state`admin.campaigns.isLoading`, false),
];

export const fieldChanged = [
  setField(state`${props`field`}`, props`value`),
  debounce(1000),
  {
    continue: [
      when(state`admin.campaigns.isChampionship`),
      {
        true: [
          validateChampionship,
          {
            true: set(state`admin.championships.create.isValid`, true),
            false: set(state`admin.championships.create.isValid`, false),
          },
        ],
        false: [
          actions.validateCampaign,
          {
            true: set(state`admin.campaigns.create.isValid`, true),
            false: set(state`admin.campaigns.create.isValid`, false),
          },
        ],
      },
    ],
    discard: [],
  },
];

export const dropdownSelected = [
  actions.dropdownSelected,
  when(state`admin.campaigns.isChampionship`),
  {
    true: [
      validateChampionship,
      {
        true: set(state`admin.championships.create.isValid`, true),
        false: set(state`admin.championships.create.isValid`, false),
      },
    ],
    false: [
      actions.validateCampaign,
      {
        true: set(state`admin.campaigns.create.isValid`, true),
        false: set(state`admin.campaigns.create.isValid`, false),
      },
    ],
  },
];

export const createCampaign = [
  set(state`admin.campaigns.create.isCreatingCampaign`, true),
  actions.uploadCampaignImage,
  {
    error: [set(state`admin.campaigns.create.error`, props`error`)],
    success: [
      set(state`admin.campaigns.create.error`, null),
      actions.createCampaignObject,
      when(state`admin.campaigns.create.challenge.added`),
      {
        true: actions.createChallengeObject,
        false: [],
      },
      when(state`admin.campaigns.create.integration.added`),
      {
        true: actions.createIntegrationObject,
        false: [],
      },
      actions.createCampaign,
      actions.resetCreateCampaignData,
      redirect('/'),
    ],
  },
  set(state`admin.campaigns.create.isCreatingCampaign`, false),
];

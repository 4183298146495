function getStatus({ endDatetime, startDatetime }) {
  if (Date.now() > endDatetime) return 'finished';
  if (Date.now() > startDatetime) return 'live';

  return 'published';
}

export function formatCampaigns({ props }) {
  const campaignList = Object.entries(props.response.value || {}).map(
    ([campKey, data]) => ({
      ...data,
      campKey,
      status: getStatus(data.config),
    })
  );

  return { campaignList };
}

export function getOrganisations({ firebase }) {
  return firebase
    .value(`organisations.list`)
    .then(res => ({ organisations: res.value }));
}

export function setOrganisationNames({ props }) {
  const { organisations } = props;
  const campaignList = props.campaignList.map(camp => ({
    ...camp,
    orgName:
      organisations?.[camp.config.organisationKey]?.name || 'orgNotFound',
  }));

  return { campaignList };
}

export default {
  activity_energy_01: {
    title: 'I reduced the indoor heating today',
    description:
      "Log on days you have lowered the indoor temperature 2 degrees less than normal, or if you only heat parts of your home. To log the activity every day, it is enough that you reduce the temperature once. You don't have to lower the temperature an additional 2 degrees every day.",
    co2_explanation: `MARKDOWN:
## The facts
- Reducing indoor temperature is an effective way of lowering your heating bill, and becoming more sustainable.

## The assumptions
- It's assumed that you live in an average house with an average family, and with average heating options. The savings are calculated based on the saving potential of reducing the indoor temperature with 2 degrees. 
- The amount of emissions per kWh of energy is calculated from electricity, district heating, wood and oil. 
- The energy mix of your country/region is part of the calculations, and the effect of the action can differ significantly between regions.
`,
    health_explanation: `MARKDOWN:
## The facts
- Reducing indoor heating is good for your health, well-being, and helps you to achieve thermal comfort. This is because high indoor temperatures can make you feel tired and less active, low temperatures makes you feel cold and restless.

## Additional info
- [https://www.greeneducationfoundation.org/green-building-program-sub/learn-about-green-building/1239-thermal-comfort.html](https://www.greeneducationfoundation.org/green-building-program-sub/learn-about-green-building/1239-thermal-comfort.html)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_energy_02: {
    title: 'I reduced the indoor temperature over night',
    description:
      'Log on nights your indoor temperature is at least 2 degrees less than the daytime temperature.',
    co2_explanation: `MARKDOWN:
## The facts
- Reducing indoor temperature during the night saves a lot of energy compared to keeping a constant temperature.

## The assumptions
- It's assumed that you live in an average house with an average family, and average heating options. The savings are calculated based on the saving potential for reducing the indoor temperature with 2 degrees for 8 hours every night. 
- The amount of emissions per kWh of energy is calculated from electricity, district heating, wood and oil. 
- The energy mix of your country/region is part of the calculations, and the effect of the action can differ significantly between regions.
`,
    health_explanation: `MARKDOWN:
## The facts
- Reducing indoor temperature is good for your health and well-being.
`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_energy_03: {
    title: 'I switched off unnecessary lights today',
    description:
      'Log on days you switch off lights at home when they are not in use.',
    co2_explanation: `MARKDOWN:
## The facts
- Encouraging a habit of switching off unnecessary light doesn't just save energy, but also money.
- It makes you aware of the resources you consume and can affect your attitude positively in more areas.

## The assumptions
- It's assumed that we on average use about 500 kWh on lights per year, and that 70% can be saved by turning it off when it's not in use.
`,
    health_explanation: `MARKDOWN:
## The facts
- Exposure to light during the night is believed to affect your sleeping, and may put you at risk for health problems such as diabetes.

## Additional info
- [https://www.netdoctor.co.uk/healthy-living/wellbeing/a10639/how-light-affects-your-health/](https://www.netdoctor.co.uk/healthy-living/wellbeing/a10639/how-light-affects-your-health/)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_energy_04: {
    title: 'I reduced the amount of dishes today',
    description:
      'Log on days you are reducing the number of times you need to run the dishwasher by using less dishes or making sure the dishwasher is full when you do run it.',
    co2_explanation: `MARKDOWN:
## The facts
- Using an energy efficient dishwasher saves energy, water and CO₂e emissions.

## The assumptions
- It's assumed that you can save around 50% of the average energy consumption if you reduce the amount of dishes and fill up the dishwasher when you run it. 

## Additional info
- [https://www.greenchoices.org/green-living/at-home/is-it-greener-to-hand-wash-or-use-a-dishwasher](https://www.greenchoices.org/green-living/at-home/is-it-greener-to-hand-wash-or-use-a-dishwasher)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_energy_05: {
    title: 'I air dried clothes today',
    description:
      'Log on laundry day when instead of throwing clothes in the tumble dryer you air dry them instead.',
    co2_explanation: `MARKDOWN:
## The facts
- The second-most energy-consuming appliance after the refrigerator is the tumble dryer. Air drying your clothes significantly reduce your carbon footprint.

## The assumptions
- It's assumed that you spend the same energy tumble-drying as the average household. When you air dry your clothes emissions related to tumble-drying are removed.

## Additional info
- [https://www.cleancult.com/blog/8-reasons-why-you-should-air-dry-your-clothes/](https://www.cleancult.com/blog/8-reasons-why-you-should-air-dry-your-clothes/)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_energy_06: {
    title: "I unplugged all electrical devices that weren't in use today",
    description:
      "Log if during a day you unplug any electronic devices that aren't being used (could be TVs, chargers, playstations etc.)",
    co2_explanation: `MARKDOWN:
## The facts 
- Unplugging electrical appliances like TVs, chargers, cables, play stations, hair dryers and so on saves you a lot of energy because it prevents drawing power. All appliances and devices, if plugged in, will consume energy, even if they're switched off. Consider if it's something you rarely use, and unplug it when you're finished using it.

## The assumptions
- We assume that by unplugging unused electrical appliances, the consumption of these gadgets can be reduced by 30%.

## Additional info
- [https://www.aol.com/2011/06/20/12-household-appliances-you-should-unplug-to-save-money/?guccounter=1](https://www.aol.com/2011/06/20/12-household-appliances-you-should-unplug-to-save-money/?guccounter=1)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_energy_07: {
    title: 'I avoided the use of electronic devices tonight',
    description:
      'Log on days you plan to have an evening free of electronic devices (could be TVs, chargers, playstations etc.) between 17:00 and 23:00.',
    co2_explanation: `MARKDOWN:
## The facts
- Not using electronic devices at night saves energy, money, and CO₂e-emissions. 

## The assumptions
- It's assumed that you use the same amount of electronic devices as an average person in your country, and that 15% of this use is for entertainment purposes. 
- The calculations assume that your power consumption is reduced with 80%.`,
    health_explanation: `MARKDOWN:
## The facts
- Using electronic devices before bedtime can affect your sleep, and make it more difficult to fall asleep. A good night's sleep helps you strengthen your memory, learning, and becoming more organized and relaxed.

## Additional info
- [https://www.psychologytoday.com/us/blog/mental-wealth/201103/wired-and-tired-electronics-and-sleep-disturbance-in-children](https://www.psychologytoday.com/us/blog/mental-wealth/201103/wired-and-tired-electronics-and-sleep-disturbance-in-children)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_energy_08: {
    title: 'I showered for 5 minutes or less today',
    description:
      'Log on days you shower for 5 minutes or less. You could time it using your favourite song.',
    co2_explanation: `MARKDOWN:
## The facts 
- By showering less often and cutting down on shower time you will save energy, water and climate emissions. You'll also get a smaller power bill and can sit a little longer at the breakfast table in the morning!

## The assumptions
- It's assumed that an average showerhead uses 9.5 liters of hot water per minute and that an average shower last 8 minutes.
- The calculation is based on the energy required to heat up the around 30 liters of hot water you save. 

## Additional info
- [https://www.theguardian.com/commentisfree/2016/mar/08/shower-once-a-week-polluting-environment](https://www.theguardian.com/commentisfree/2016/mar/08/shower-once-a-week-polluting-environment)`,
    health_explanation: `MARKDOWN:
## The facts
- Showering every day can be bad for your skin, but many hit the showers at least once a day. Overuse of soap can potentially strip your skin of essential oils, and showering less often can help improve your skin health.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_energy_09: {
    title: 'I had a body wash instead of a shower today',
    description:
      'Log on days you replace your bath or shower with a body wash at the sink.',
    co2_explanation: `MARKDOWN:
## The facts 
- A body wash saves energy, pollutes less, is more economic, and can help protect your skin.

## The assumptions
- It's assumed that an average showerhead uses 9.5 liters of hot water per minute and that an average shower last 8 minutes.
- A body wash is estimated to be the same as a one minute shower. 
- The calculation is based on the energy required to heat up the around 65 liters of hot water you save on this activity. 

## Additional info
- [https://www.theguardian.com/commentisfree/2016/mar/08/shower-once-a-week-polluting-environment](https://www.theguardian.com/commentisfree/2016/mar/08/shower-once-a-week-polluting-environment)`,
    health_explanation: `MARKDOWN:
## The facts
- Showering every day can be bad for your skin, but many hit the showers at least once a day. Overuse of soap can potentially strip your skin of essential oils, and showering less often can help improve your skin health.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_energy_10: {
    title: 'I used less hot water from the tap today',
    description:
      'Log on days you use cold water or reduce the amount of hot water for brushing your teeth, washing your hands, shaving, washing up etc.',
    co2_explanation: `MARKDOWN:
## The facts 
- Using less hot water from the tap is a great step towards sustainability because it conserves water, saves energy, and reduces CO₂e emissions.

## The assumptions
- It's assumed that around 15% of the average hot water consumption is spend on something else than showering and that 70% of that can be saved by doing this activity. 

## Additional info
- [https://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.828.2626&rep=rep1&type=pdf](https://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.828.2626&rep=rep1&type=pdf)`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_energy_11: {
    title: 'I reused my clothes OR fully loaded the washing machine today',
    description:
      'Log on days you reduce the need to wash clothes. You could re-use clothes or make sure that when you do load the washing machine, it is full.',
    co2_explanation: `MARKDOWN:
## The facts
- Washing and drying 5 kg of clothes every other day creates 440 kg of CO₂e-emissions a year. If you use your clothes a little longer, and only run full loads, you contribute to reduced emissions. 

## The assumptions
- It's assumed that you can save around 50% of the average energy consumption if you wash your clothes less often.

## Additional info 
- [https://theswatchbook.offsetwarehouse.com/2015/07/16/environmental-impact-of-the-washing-machine/](https://theswatchbook.offsetwarehouse.com/2015/07/16/environmental-impact-of-the-washing-machine/)`,
    health_explanation: `MARKDOWN:
## The facts 
- Laundry soaps and fabric softners are not environmentally friendly and cause chemical pollution that affect our health.`,
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
  },
  activity_energy_12: {
    title: 'I reduced the need for laundry today',
    description:
      'Log each day you washed away a stain or aired a garment instead of washing it in the machine.',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    biodiversity_explanation: `MARKDOWN:
## The facts 
- Washing clothes in a washing machine is a large source of microplastics emissions. A synthetic garment can release up to 1,900 microfibers when washed in a washing machine! We wash our clothes too often, so consider airing or wiping individual stains. It also contributes to less wear and tear on your favorite garments, so you can wear them longer!`,
    handprint_explanation: `MARKDOWN:
## The facts
- We wash our clothes too often, which leads to large emissions of microplastics. But if you lead by example and show that one can easily reduce laundry by wiping away a stain, you might get others to do it too!

## Additional info
- [https://www.framtiden.no/gronne-tips/fritid/kutting-av-mikroplast-fra-klesvask.html](https://www.framtiden.no/gronne-tips/fritid/kutting-av-mikroplast-fra-klesvask.html)
- [https://www.framtiden.no/gronne-tips/klar/miljobevisst-klesvask.html](https://www.framtiden.no/gronne-tips/klar/miljobevisst-klesvask.html)`,
  },
  activity_energy_13: {
    title: 'I ran the washing machine at a lower temperature today',
    description:
      'Log on days you would normally run the washing machine but you reduced the temperature to 30C or lower',
    points_explanation: `MARKDOWN:
Points reflect a general level of impact per activity.

**5 points - Individual Impact**<br/>
For example: activities you can do yourself without any communication with or influencing of others. 

**10 points - Social Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging friends, family, colleagues, local store etc. 

**15 points - Community Impact**<br/>
For example: complimenting, talking to, inspiring, encouraging high level bodies such as organizations, politicians, municipalities etc`,
    co2_explanation: `MARKDOWN:
## The facts
- Washing clothes at 30C uses around 40% less electricity compared to higher temperatures. For 20C, the reduction in energy use is 66%.

## The assumptions
- It's assumed that you can save around 40% of the average energy consumption of washing machines if you wash your clothes at lower temperatures.

## Additional info 
- [https://acecleanuk.co.uk/30-vs-40-washing-lower-temperature-make-difference/](https://acecleanuk.co.uk/30-vs-40-washing-lower-temperature-make-difference/)`,
  },
};

import Btn020 from '../Btn020';
import styled from 'styled-components';

export const Link = styled.a`
  height: 36px;
  min-width: 80px;
  padding: 0 16px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  background-color: #007079;
  box-shadow:
    0 1px 6px rgba(0, 0, 0, 0.12),
    0 1px 4px rgba(0, 0, 0, 0.12);
  &:disabled {
    background-color: ${props =>
      props.theme === 'dark'
        ? 'rgba(144 ,164 ,174 , 0.12)'
        : 'rgba(96 ,125 ,139 , 0.12)'};
    box-shadow: none;
  }
`;

export const Button = styled(Btn020)`
  background-color: #007079;
  box-shadow:
    0 1px 6px rgba(0, 0, 0, 0.12),
    0 1px 4px rgba(0, 0, 0, 0.12);
  &:hover {
    span {
      transform: scale(1.03);
    }
  }
  span {
    transition: all 0.15s ease-in-out;
    color: #fff;
  }
  &:disabled {
    background-color: ${props =>
      props.theme === 'dark'
        ? 'rgba(144 ,164 ,174 , 0.12)'
        : 'rgba(96 ,125 ,139 , 0.12)'};
    box-shadow: none;
  }
  &:disabled span {
    color: ${props =>
      props.theme === 'dark'
        ? 'rgba(255, 255, 255, 0.4)'
        : 'rgba(96 ,125 ,139 , 0.4)'};
  }
`;

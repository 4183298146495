import React from 'react';
import * as PropTypes from 'prop-types';
import { Wrapper } from './elements';

function PoM000(props) {
  function handleWrapperClick(event) {
    event.stopPropagation();
  }

  return (
    <Wrapper
      id={props.id}
      className={props.className}
      onClick={handleWrapperClick}
    >
      {props.children}
    </Wrapper>
  );
}

PoM000.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
};
export default PoM000;

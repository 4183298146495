import React from 'react';
import * as PropTypes from 'prop-types';
import { ElementWrapper, LabelText, RadioButton } from './elements';

function SwiLab02(props) {
  if (props.disabled) {
    return (
      <ElementWrapper className={props.className} disabled>
        <RadioButton color={'#90A4AE'} disabled />
        {props.label ? <LabelText disabled>{props.label}</LabelText> : null}
      </ElementWrapper>
    );
  }

  return (
    <ElementWrapper
      checked={props.checked}
      className={props.className}
      onClick={props.onClick}
    >
      <RadioButton color={'#00ab97'} checked={props.checked} />
      {props.label ? <LabelText>{props.label}</LabelText> : null}
    </ElementWrapper>
  );
}

SwiLab02.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default SwiLab02;

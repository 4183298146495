import * as sequences from './sequences';
import { renderPage } from '../../../../factories';

export default {
  state: {
    isCreatingOrganisation: false,
    isValidForm: false,
    sectionNameErrors: {},
    teamNameErrors: {},
    organisationTypes: [
      'company',
      'government',
      'higher_ed',
      'lower_ed',
      'ngo',
    ],
    uploadedImage: {
      desktop: {
        url: null,
        width: 180,
        height: 180,
      },
    },
    form: {
      name: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      orgType: {
        value: '',
        defaultValue: '',
      },
      country: {
        value: '',
        defaultValue: '',
      },
      numPeople: {
        value: '',
        defaultValue: '',
      },
      sections: {
        s0: {
          value: '',
          defaultValue: '',
          validationRules: ['isValue'],
        },
      },
    },
  },
  sequences: {
    routed: renderPage('createOrganisation', sequences.render),
    fieldChanged: sequences.fieldChanged,
    newSectionNameChanged: sequences.newSectionNameChanged,
    addNewEmptySection: sequences.addNewEmptySection,
    deleteSectionClicked: sequences.deleteSectionClicked,
    newTeamNameChanged: sequences.newTeamNameChanged,
    addNewEmptyTeam: sequences.addNewEmptyTeam,
    deleteTeamClicked: sequences.deleteTeamClicked,
    addStructure: sequences.addStructure,
    createOrganisationClicked: sequences.createOrganisation,
    orgTypeOptionClicked: sequences.setSelectedOrgTypeOption,
    toggleOrgTypeDropdownClicked: sequences.toggleOrgTypeDropdown,
    countryOptionClicked: sequences.setSelectedCountryOption,
    toggleCountryDropdownClicked: sequences.toggleCountryDropdown,
    organisationImageSelected: sequences.uploadOrganisationImage,
    organisationImageRemoved: sequences.removeOrganisationImage,
  },
};

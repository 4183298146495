import React from 'react';
import { connect } from '@cerebral/react';
import { sequences, state } from 'cerebral';

import { AdminMod13, CloseIcon, CloseIconContainer, Wrapper } from './elements';
import Subhead01 from 'dc/Subhead01';
import Lab400 from 'dc/Lab400';
import IndicatorModItem from './IndicatorModItem';

import { useText, useGetActivityIconUrl } from 'common/hooks';

export default connect(
  {
    activity: state`appContainer.activities.${state`admin.campaigns.create.challenge.activityModal.activityKey`}`,
    activityKey: state`admin.campaigns.create.challenge.activityModal.activityKey`,
    closeAdminActivityModalClicked: sequences`admin.campaigns.create.challenge.activityModal.closeAdminActivityModalClicked`,
    showAdminTagModal: sequences`admin.campaigns.create.challenge.showAdminTagModalClicked`,
  },
  function AdminActMod02({
    activity,
    activityKey,
    closeAdminActivityModalClicked,
    showAdminTagModal,
  }) {
    const t = useText();
    const getActivityIconUrl = useGetActivityIconUrl();

    return (
      <Wrapper>
        <CloseIconContainer>
          <CloseIcon
            onClick={() => closeAdminActivityModalClicked()}
            icon={'icon-close'}
          />
        </CloseIconContainer>

        <AdminMod13
          activityIcon={getActivityIconUrl(activity?.icon)}
          name={t(`challenge.activities.${activityKey}.title`)}
          description={t(`challenge.activities.${activityKey}.description`)}
          buttonIcon={'icon-cached'}
          headerText={t(`challenge.ActMod02.documented_benefits`)}
        />

        {Object.keys(activity.indicators).map(indicator => (
          <IndicatorModItem
            key={`AdminActMod02-${indicator}`}
            activity={activity}
            activityKey={activityKey}
            indicator={indicator}
          />
        ))}
        <Subhead01 text={t(`challenge.ActMod02.topic_tags`)} />
        {Object.values(activity.tags).map(tagArray =>
          tagArray.map(tag => (
            <Lab400
              key={activityKey + tag}
              icon={'icon-keyboard_arrow_right'}
              onClick={() =>
                showAdminTagModal({
                  tag: tag,
                  activityModalKey: activityKey,
                })
              }
            >
              {t(`challenge.tags.${tag}.name`)}
            </Lab400>
          )),
        )}
      </Wrapper>
    );
  },
);

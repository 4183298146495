import styled from 'styled-components';
import { Typ30, Typ60 } from 'dc/Typ';
import TxtField12 from 'dc/TxtField12';
import Upload01 from 'dc/Upload01';

export const Wrapper = styled.div`
  width: 584px;
  margin: 0 auto;
`;

export const Title = styled(Typ60)`
  color: #546e7a;
`;

export const Description = styled(Typ30)`
  display: block;
  color: #546e7a;
  margin-bottom: 32px;
  span {
    color: #546e7a;
  }

  a {
    color: #03739a;
    text-decoration: underline;
  }
`;

export const Label = styled(Typ30)`
  color: #546e7a;
  margin-bottom: 4px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Uploader = styled(Upload01)``;

export const ImageText = styled(Typ30)`
  width: calc(100% - 360px);
  div > span {
    color: #546e7a;
  }
  div > span:not(:nth-child(2)) {
    padding-top: 0;
  }
`;

export const HorizontalRule = styled.hr`
  margin-bottom: 56px;
  border-top: 1px solid #cfd8dc;
`;

export const DropdownInput = styled(TxtField12)`
  background-color: #fff;
  width: 288px;
  cursor: ${props => (props.isJoiningChampionship ? 'not-allowed' : 'default')};
  input {
    cursor: ${props => (props.isJoiningChampionship ? 'not-allowed' : 'text')};
  }
  div {
    cursor: ${props =>
      props.isJoiningChampionship ? 'not-allowed' : 'pointer'};
  }
`;

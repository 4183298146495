import { useState, useEffect } from 'react';
import { getApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { isUrl } from 'utils/avatars';
const cache = {};

type Return = [string, boolean, Error?];

/**
 * Returns a download url for any given url or storage path
 */
export default function useImage(path: string): Return {
  const [url, setUrl] = useState<string>(isUrl(path) ? path : cache[path]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    let isActive = true;

    if (cache[path]) {
      setIsLoaded(true);
      setUrl(cache[path]);
    } else if (isUrl(path)) {
      setIsLoaded(true);
      setUrl(path);
    } else {
      try {
        getDownloadURL(ref(getStorage(getApp()), path))
          .then(newUrl => {
            const image = new Image();

            image.addEventListener('load', () => {
              cache[path] = newUrl;

              if (isActive) {
                setIsLoaded(true);
                setUrl(newUrl);
              }
            });

            image.src = newUrl;
          })
          .catch(err => {
            setError(err);
          });
      } catch (err) {
        setError(err);
      }
    }

    return () => {
      isActive = false;
      setError(null);
      setIsLoaded(false);
    };
  }, [path, url]);

  return [url, !isLoaded, error];
}

import styled from 'styled-components';
import IcoW01 from '../IcoW01';
import Btn022 from '../Btn022';

export const ElementWrapper = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const Button = styled(Btn022)`
  cursor: pointer;
  color: #fff;
  padding: 12px;
  > span {
    color: #fff;
  }
`;

export const Icon = styled(IcoW01)`
  cursor: pointer;
`;

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { useText } from 'common/hooks';

import { Wrapper, Title, Button } from './elements';

export default connect(
  {
    isValid: state`admin.campaigns.create.isValid`,
    isCreatingCampaign: state`admin.campaigns.create.isCreatingCampaign`,
    isEdit: state`admin.campaigns.edit.isEdit`,
    error: state`admin.campaigns.create.error`,
    createCampaignClicked: sequences`admin.campaigns.create.createCampaignClicked`,
  },
  function Create({
    isValid,
    isCreatingCampaign,
    isEdit,
    error,
    createCampaignClicked,
  }) {
    const t = useText();

    return (
      <Wrapper>
        <Title>{t('admin.overview.create.publish')}</Title>
        <Button
          disabled={!isValid || isCreatingCampaign || isEdit}
          onClick={() => createCampaignClicked()}
        >
          {t('admin.overview.create.create_campaign')}
        </Button>
        {error}
      </Wrapper>
    );
  }
);

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  list-style-type: none;
  box-shadow:
    0 3px 10px rgba(0, 0, 0, 0.16),
    0 3px 10px rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 999;
  background-color: #fff;
`;

import styled from 'styled-components';
import { Typ60 } from 'dc/Typ';
import Btn021 from 'dc/Btn021';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto 56px;
`;

export const Title = styled(Typ60)`
  color: #546e7a;
  margin-bottom: 16px;
`;

export const Button = styled(Btn021)``;

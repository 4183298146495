import dayjs from 'dayjs';

function getStatus({ endDatetime, endDate, startDatetime, startDate }) {
  const start = startDatetime || dayjs(startDate).add(1, 'm').valueOf(); // TODO: Remove once datetimes are no longer used on championship
  const end = endDatetime || dayjs(endDate).add(23, 'h').add(59, 'm').valueOf(); // TODO: Remove once datetimes are no longer used on championship

  if (Date.now() > end) return 'finished';
  if (Date.now() > start) return 'live';

  return 'published';
}

export function formatChampionships({ props }) {
  const championshipList = Object.entries(props.response.value || {}).map(
    ([champKey, data]) => ({
      ...data,
      champKey,
      status: getStatus(data.config),
    })
  );

  return { championshipList };
}

export function getOrganisations({ firebase }) {
  return firebase
    .value(`organisations.list`)
    .then(res => ({ organisations: res.value }));
}

export function setOrganisationNames({ props }) {
  const { organisations } = props;
  const championshipList = props.championshipList.map(champ => ({
    ...champ,
    orgName:
      organisations?.[champ.config.organisationKey]?.name || 'orgNotFound',
  }));

  return { championshipList };
}

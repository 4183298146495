import * as sequences from './sequences';

export default {
  state: {
    isSaving: false,
  },
  sequences: {
    saveNewStructureClicked: sequences.saveNewStructureClicked,
  },
};

import React from 'react';
import {
  Wrapper,
  Title,
  Description,
  ShortTextInput,
  Label,
  LongTextInput,
  HorizontalRule,
} from './elements';
import Spa16 from 'dc/Spa16';
import Spa32 from 'dc/Spa32';
import Spa40 from 'dc/Spa40';
import Spa56 from 'dc/Spa56';
import { useText } from 'common/hooks';

import DateInput from 'admin/onboarding/App/Campaigns/CreateCampaign/Info/DateInput';
import TextArea from 'admin/onboarding/App/Campaigns/CreateCampaign/Info/TextArea';
import SectionTerm from 'admin/onboarding/App/Campaigns/CreateCampaign/Info/SectionTerm';

export default function Info() {
  const t = useText();

  return (
    <Wrapper>
      <Spa32 />
      <Title>{t('admin.info.title')}</Title>
      <Spa16 />
      <Description>{t('admin.info.description')}</Description>
      <ShortTextInput
        maxLength={30}
        field={'name'}
        placeholder={t('admin.info.enter_name')}
      />
      <Spa40 />
      <Label>{t('admin.info.tagline')}</Label>
      <LongTextInput
        maxLength={140}
        field={'tagline'}
        placeholder={t('admin.info.call_to_action')}
      />
      <Spa32 />
      <Label>{t('admin.info.campaign_description')}</Label>
      <TextArea
        field={'description'}
        placeholder={t('admin.info.introduce_teams')}
      />
      <Spa32 />
      <Label>{t('admin.info.goal_description')}</Label>
      <TextArea field={'goal'} placeholder={t('admin.info.main_goals')} />
      <Spa56 />
      <Spa56 />
      <HorizontalRule />
      <Label>{t('admin.info.campaign_url')}</Label>
      <LongTextInput
        maxLength={140}
        field={'campaignPageUrl'}
        placeholder={t('admin.info.your_page')}
      />
      <Spa56 />
      <HorizontalRule />
      <Label>{t('admin.info.start_date')}</Label>
      <DateInput field={'startDate'} />
      <Spa32 />
      <Label>{t('admin.info.end_date')}</Label>
      <DateInput field={'endDate'} />
      <Spa56 />
      <HorizontalRule />
      <SectionTerm />
      <Spa56 />
    </Wrapper>
  );
}

import React from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import { Wrapper } from './elements';
import Create from './Create';
import Header from './Header';
import Links from './Links';
import ChampionshipReport from './ChampionshipReport';

export default connect(
  {
    linksData: state`admin.championships.edit.computedChampionshipLinks`,
  },
  function Publish({ linksData }) {
    return Object.keys(linksData).length === 0 ? (
      <Wrapper>
        <Create />
      </Wrapper>
    ) : (
      <Wrapper>
        <Header />
        <Links />
        <ChampionshipReport />
      </Wrapper>
    );
  }
);

import * as sequences from './sequences';
import { AUDIENCE, DATA_SOURCE } from 'resources/constants';

export default {
  state: {
    form: {
      audience: {
        value: AUDIENCE.personal,
        defaultValue: AUDIENCE.personal,
        validationRules: ['isValue'],
      },
      topic: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      dataSource: {
        value: DATA_SOURCE.NORWAY,
        defaultValue: DATA_SOURCE.NORWAY,
        validationRules: ['isValue'],
      },
    },
  },
  sequences: {
    topicChanged: sequences.changeTopic,
    audienceChanged: sequences.changeAudience,
    dataSourceChanged: sequences.changeDataSource,
  },
};

export default {
  campaign_code_pdf: {
    start: 'Starting {{START-DATE}}',
    share: 'Share your impact with us!',
  },
  section_terms: {
    building: 'building |||| buildings',
    department: 'department |||| departments',
    discipline: 'discipline |||| disciplines',
    faculty: 'faculty |||| faculties',
    school: 'school |||| schools',
    area: 'area |||| areas',
    city: 'city |||| cities',
    municipality: 'municipality |||| municipalities',
    county: 'county municipality |||| county municipalities', //CCM uses 'county' not 'county_municipality'
    county_municipality: 'county municipality |||| county municipalities',
    country: 'country |||| countries',
    section: 'section |||| sections',
  },
};

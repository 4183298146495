import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Wrapper,
  Title,
  Text,
  EnterInput,
  DropdownInput,
  Uploader,
  Label,
  NumberInput,
} from './elements';
import Spa32 from 'dc/Spa32';
import Spa16 from 'dc/Spa16';

import { useText } from 'common/hooks';

function handleOnFileSelected(event, fileSelectedSignal) {
  if (event.target.files[0]) {
    const image = new Image();
    const { files } = event.target;
    const url = window.URL.createObjectURL(files[0]);

    image.addEventListener('load', () => {
      fileSelectedSignal({
        file: files[0],
        width: image.width,
        height: image.height,
        url: url,
      });
    });

    image.src = url;
    window.URL.revokeObjectURL(files[0]);
  }
}

export default connect(
  {
    createOrganisationForm: state`admin.organisations.create.form`,
    orgTypes: state`admin.organisations.create.organisationTypes`,
    uploadedImage: state`admin.organisations.create.uploadedImage`,
    selectedOrgType: state`admin.organisations.create.selectedOrgType`,
    orgTypeDropdownIsOpen: state`admin.organisations.create.orgTypeDropdownIsOpen`,
    selectedCountry: state`admin.organisations.create.selectedCountry`,
    countryDropdownIsOpen: state`admin.organisations.create.countryDropdownIsOpen`,
    countryOptionClicked: sequences`admin.organisations.create.countryOptionClicked`,
    orgTypeOptionClicked: sequences`admin.organisations.create.orgTypeOptionClicked`,
    toggleOrgTypeDropdownClicked: sequences`admin.organisations.create.toggleOrgTypeDropdownClicked`,
    toggleCountryDropdownClicked: sequences`admin.organisations.create.toggleCountryDropdownClicked`,
    fieldChanged: sequences`admin.organisations.create.fieldChanged`,
    organisationImageSelected: sequences`admin.organisations.create.organisationImageSelected`,
    organisationImageRemoved: sequences`admin.organisations.create.organisationImageRemoved`,
  },
  function APOrg01({
    createOrganisationForm,
    orgTypes,
    uploadedImage,
    selectedOrgType,
    orgTypeDropdownIsOpen,
    selectedCountry,
    countryDropdownIsOpen,
    countryOptionClicked,
    orgTypeOptionClicked,
    toggleOrgTypeDropdownClicked,
    toggleCountryDropdownClicked,
    fieldChanged,
    organisationImageSelected,
    organisationImageRemoved,
  }) {
    const t = useText();

    return (
      <Wrapper>
        <Spa32 />
        <Title>{'Basic Info'}</Title>
        <Text>
          {
            'In order to set up a campaign or join an existing one please provide some information about your organization.'
          }
        </Text>
        <EnterInput
          onChange={event =>
            fieldChanged({
              field: 'admin.organisations.create.form.name',
              value: event.target.value,
            })
          }
          maxLength={55}
          icon={'icon-location_city'}
          value={createOrganisationForm.name.value}
          placeholder={'Enter organisation name'}
        />
        <Spa16 />
        <DropdownInput
          placeholder={'Select organisation type'}
          options={orgTypes.reduce(
            (obj, orgType) =>
              Object.assign(obj, {
                [orgType]: t(`admin.organisation.org_type.${orgType}`),
              }),
            {}
          )}
          value={
            selectedOrgType
              ? t(`admin.organisation.org_type.${selectedOrgType.optionKey}`)
              : ''
          }
          onClick={event => {
            event.stopPropagation();
            toggleOrgTypeDropdownClicked();
          }}
          onDropdownItemClick={option => orgTypeOptionClicked({ option })}
          dropdownVisible={orgTypeDropdownIsOpen}
        />
        <Uploader
          height={'180px'}
          width={'180px'}
          image={
            uploadedImage && uploadedImage.desktop
              ? uploadedImage.desktop.url
              : null
          }
          onAddClick={event =>
            handleOnFileSelected(event, organisationImageSelected)
          }
          onClick={() => organisationImageRemoved()}
          accept={'image/jpeg, image/png'}
        />
        <DropdownInput
          placeholder={'Select country of operation'}
          options={t.countries}
          value={selectedCountry ? `${selectedCountry.optionValue}` : ''}
          onClick={event => {
            event.stopPropagation();
            toggleCountryDropdownClicked();
          }}
          onDropdownItemClick={option => countryOptionClicked({ option })}
          dropdownVisible={countryDropdownIsOpen}
        />
        <Label>{'Number of employees/students'}</Label>
        <NumberInput
          onChange={event =>
            fieldChanged({
              field: 'admin.organisations.create.form.numPeople',
              value: event.target.value,
            })
          }
          max={9999999}
          min={1}
          icon={'icon-people'}
          type={'number'}
          value={createOrganisationForm.numPeople.value}
        />
      </Wrapper>
    );
  }
);

import { TOPIC, AUDIENCE } from '../constants';

export default {
  // CMM refactor workTransport keys to use names to match calculator
  // e.g. people_in_household -> members

  [AUDIENCE.work]: {
    [TOPIC.workTransport]: {
      challenge: [
        'activity_transport_01',
        'activity_transport_02',
        'activity_transport_03',
        'activity_transport_04',
        'activity_transport_07',
        'activity_transport_08',
        'activity_transport_09',
        'activity_transport_10',
        'activity_transport_11',
        'activity_transport_13',
        'activity_transport_14',
        'activity_transport_15',
        'activity_workTransport_01',
        'activity_workTransport_02',
        'activity_workTransport_03',
        'activity_workTransport_04',
      ],
    },
    [TOPIC.co2]: {
      challenge: [
        'activity_consumption_01',
        'activity_consumption_02',
        'activity_consumption_03',
        'activity_consumption_04',
        'activity_consumption_06',
        'activity_consumption_26',
        'activity_consumption_27',
        'activity_consumption_28',
        'activity_consumption_29',
        'activity_consumption_31',
        'activity_consumption_33',
        'activity_energy_01',
        'activity_energy_02',
        'activity_energy_03',
        'activity_energy_04',
        'activity_energy_05',
        'activity_energy_06',
        'activity_energy_07',
        'activity_energy_08',
        'activity_energy_09',
        'activity_energy_10',
        'activity_energy_11',
        'activity_food_01',
        'activity_food_02',
        'activity_food_03',
        'activity_food_04',
        'activity_food_53',
        'activity_transport_01',
        'activity_transport_02',
        'activity_transport_03',
        'activity_transport_04',
        'activity_transport_07',
        'activity_transport_08',
        'activity_transport_12',
        'activity_transport_19',
        'activity_transport_20',
        'activity_transport_22',
        'activity_handprint_28',
      ],
    },
  },
  [AUDIENCE.school]: {
    [TOPIC.co2]: {
      challenge: [
        'activity_consumption_01',
        'activity_consumption_02',
        'activity_consumption_03',
        'activity_consumption_04',
        'activity_consumption_06',
        'activity_consumption_29',
        'activity_consumption_31',
        'activity_consumption_33',
        'activity_energy_01',
        'activity_energy_02',
        'activity_energy_03',
        'activity_energy_04',
        'activity_energy_05',
        'activity_energy_06',
        'activity_energy_07',
        'activity_energy_08',
        'activity_energy_09',
        'activity_energy_10',
        'activity_energy_11',
        'activity_food_01',
        'activity_food_02',
        'activity_food_03',
        'activity_food_04',
        'activity_food_53',
        'activity_transport_03',
        'activity_transport_04',
        'activity_transport_05',
        'activity_transport_06',
        'activity_transport_08',
        'activity_transport_12',
        'activity_transport_21',
        'activity_transport_22',
      ],
    },
    [TOPIC.plastic]: {
      challenge: [
        'activity_energy_12',
        'activity_consumption_08',
        'activity_consumption_09',
        'activity_consumption_10',
        'activity_consumption_11',
        'activity_consumption_12',
        'activity_consumption_13',
        'activity_consumption_14',
        'activity_consumption_15',
        'activity_consumption_16',
        'activity_consumption_17',
        'activity_consumption_18',
        'activity_consumption_19',
        'activity_consumption_20',
        'activity_consumption_21',
        'activity_consumption_23',
        'activity_consumption_24',
        'activity_consumption_25',
        'activity_handprint_01',
        'activity_handprint_02',
        'activity_handprint_03',
        'activity_handprint_04',
        'activity_handprint_06',
        'activity_handprint_07',
        'activity_handprint_08',
        'activity_handprint_09',
        'activity_handprint_19',
        'activity_transport_03',
        'activity_transport_04',
        'activity_transport_05',
        'activity_transport_06',
        'activity_transport_18',
      ],
    },
  },
  [AUDIENCE.personal]: {
    [TOPIC.foodWaste]: {
      challenge: [
        'activity_food_05',
        'activity_food_06',
        'activity_food_07',
        'activity_food_08',
        'activity_food_09',
        'activity_food_10',
        'activity_food_11',
        'activity_food_12',
        'activity_food_13',
        'activity_food_14',
        'activity_food_15',
        'activity_food_16',
        'activity_food_17',
        'activity_food_18',
        'activity_food_19',
        'activity_food_20',
        'activity_food_21',
        'activity_food_22',
        'activity_food_23',
        'activity_food_24',
        'activity_food_25',
        'activity_food_26',
        'activity_food_27',
        'activity_food_28',
        'activity_food_29',
        'activity_food_30',
        'activity_food_31',
      ],
    },
    [TOPIC.plastic]: {
      challenge: [
        'activity_energy_12',
        'activity_consumption_08',
        'activity_consumption_09',
        'activity_consumption_10',
        'activity_consumption_11',
        'activity_consumption_12',
        'activity_consumption_13',
        'activity_consumption_14',
        'activity_consumption_15',
        'activity_consumption_16',
        'activity_consumption_17',
        'activity_consumption_18',
        'activity_consumption_19',
        'activity_consumption_20',
        'activity_consumption_21',
        'activity_consumption_22',
        'activity_consumption_23',
        'activity_consumption_24',
        'activity_consumption_25',
        'activity_handprint_01',
        'activity_handprint_02',
        'activity_handprint_03',
        'activity_handprint_04',
        'activity_handprint_05',
        'activity_handprint_06',
        'activity_handprint_07',
        'activity_handprint_08',
        'activity_handprint_09',
        'activity_handprint_19',
        'activity_transport_01',
        'activity_transport_02',
        'activity_transport_03',
        'activity_transport_04',
        'activity_transport_05',
        'activity_transport_06',
        'activity_transport_16',
      ],
    },
    [TOPIC.mindfulCitizen]: {
      challenge: [
        'activity_handprint_12',
        'activity_handprint_13',
        'activity_handprint_14',
        'activity_handprint_15',
        'activity_handprint_16',
        'activity_handprint_17',
        'activity_handprint_18',
        'activity_handprint_19',
        'activity_handprint_20',
        'activity_handprint_21',
        'activity_handprint_22',
        'activity_handprint_23',
        'activity_handprint_24',
        'activity_handprint_25',
        'activity_handprint_26',
        'activity_handprint_27',
        'activity_food_29',
      ],
    },
  },
};

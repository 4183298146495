import Load04 from '../Load04';
import styled from 'styled-components';

type AvatarElementProps = {
  $image?: string;
};

export const AvatarElement = styled.div<AvatarElementProps>`
  border: none;
  border-radius: 50%;
  background-image: ${props =>
    props.$image ? `url(${props.$image})` : 'none'};
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
`;

export const Loader = styled(Load04)`
  vertical-align: middle;
  display: block;
  background-color: inherit;
`;

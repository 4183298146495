import styled from 'styled-components';
import TxtField01 from 'dc/TxtField01';
import { Typ30, Typ60 } from 'dc/Typ';

const grey600 = '#546E7A';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 584px;
  margin: 0 auto;
`;

export const Title = styled(Typ60)`
  margin-bottom: 16px;
  color: ${grey600};
`;

export const Description = styled(Typ30)`
  margin-bottom: 12px;
  color: ${grey600};
`;

export const LabelDate = styled(Typ30)`
  color: ${grey600};
  margin-top: 25px;
  margin-bottom: 4px;
`;

export const Input = styled(TxtField01)`
  background-color: #fff;
  height: 46px;
  input {
    width: auto;
  }
`;

import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { KEY_CODE } from 'resources/constants';
import {
  ElementWrapper,
  SelectionWrapper,
  TextField,
  PlaceholderField,
  DropdownArrow,
  DropdownWrapper,
  Option,
} from './elements';

function TxtField12(props) {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <ElementWrapper
      className={props.className}
      disabled={props.disabled}
      onBlur={() => setShowDropdown(false)}
      tabIndex={0}
      onKeyDown={({ keyCode }) => {
        if (keyCode === KEY_CODE.enter) {
          setShowDropdown(!props.disabled);
        }
        if (keyCode === KEY_CODE.esc) setShowDropdown(false);
      }}
    >
      <SelectionWrapper
        onClick={() => !props.disabled && setShowDropdown(!showDropdown)}
      >
        {props.value ? (
          <TextField>{props.value}</TextField>
        ) : (
          <PlaceholderField>{props.placeholder}</PlaceholderField>
        )}
        <DropdownArrow icon={'icon-arrow_drop_down'} />
      </SelectionWrapper>
      <DropdownWrapper visible={showDropdown && !props.disabled}>
        {Object.keys(props.options || {}).map(optionKey => (
          <Option
            key={optionKey}
            onClick={() => {
              props.onDropdownItemClick({
                optionKey: optionKey,
                optionValue: props.options[optionKey],
              });
              setShowDropdown(false);
            }}
          >
            {props.options[optionKey]}
          </Option>
        ))}
      </DropdownWrapper>
    </ElementWrapper>
  );
}

TxtField12.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.object,
  onDropdownItemClick: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.node,
};

export default TxtField12;

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import {
  Wrapper,
  Error,
  SectionInput,
  TeamInput,
  TeamsWrapper,
  Button,
} from './elements';
import { useText } from 'common/hooks';

export default connect(
  {
    isCreatingOrganisation: state`admin.organisations.create.isCreatingOrganisation`,
    isValidForm: state`admin.organisations.create.isValidForm`,
    createOrganisationForm: state`admin.organisations.create.form`,
    sectionNameErrors: state`admin.organisations.create.sectionNameErrors`,
    teamNameErrors: state`admin.organisations.create.teamNameErrors`,
    newSectionNameChanged: sequences`admin.organisations.create.newSectionNameChanged`,
    newTeamNameChanged: sequences`admin.organisations.create.newTeamNameChanged`,
    addNewEmptySection: sequences`admin.organisations.create.addNewEmptySection`,
    addNewEmptyTeam: sequences`admin.organisations.create.addNewEmptyTeam`,
    deleteTeamClicked: sequences`admin.organisations.create.deleteTeamClicked`,
    deleteSectionClicked: sequences`admin.organisations.create.deleteSectionClicked`,
    createOrganisationClicked: sequences`admin.organisations.create.createOrganisationClicked`,
  },
  function Form({
    isCreatingOrganisation,
    isValidForm,
    createOrganisationForm,
    sectionNameErrors,
    teamNameErrors,
    newSectionNameChanged,
    newTeamNameChanged,
    addNewEmptySection,
    addNewEmptyTeam,
    deleteTeamClicked,
    deleteSectionClicked,
    createOrganisationClicked,
  }) {
    const t = useText();

    return (
      <Wrapper>
        {Object.keys(createOrganisationForm.sections).map(sectionKey => (
          <div key={sectionKey}>
            <Error>{sectionNameErrors[sectionKey]}</Error>
            <SectionInput
              error={sectionNameErrors[sectionKey]}
              onChange={event => {
                newSectionNameChanged({
                  field: `admin.organisations.create.form.sections.${sectionKey}.value`,
                  value: event.target.value,
                  sectionKey: sectionKey,
                });
              }}
              onKeyDown={event => {
                addNewEmptySection({
                  keyCode: event.keyCode || event.which,
                  sectionKey: sectionKey,
                });
              }}
              onDeleteClick={() => {
                deleteSectionClicked({
                  sectionKey: sectionKey,
                  value: '',
                });
              }}
              value={createOrganisationForm.sections[sectionKey].value}
              placeholder={t('admin.organisation.input.enter_department')}
              maxLength={24}
              expanded={
                createOrganisationForm.sections[sectionKey].value.length > 0 ||
                (createOrganisationForm.sections[sectionKey].teams &&
                  Object.keys(createOrganisationForm.sections[sectionKey].teams)
                    .length > 1)
              }
            >
              <TeamsWrapper>
                {createOrganisationForm.sections[sectionKey].teams
                  ? Object.keys(
                      createOrganisationForm.sections[sectionKey].teams
                    ).map(teamKey => (
                      <div key={teamKey}>
                        <Error>{teamNameErrors[teamKey]}</Error>
                        <TeamInput
                          placeholder={t(
                            'admin.organisation.input.enter_group'
                          )}
                          onChange={event => {
                            newTeamNameChanged({
                              field: `admin.organisations.create.form.sections.${sectionKey}.teams.${teamKey}.value`,
                              value: event.target.value,
                              sectionKey: sectionKey,
                              teamKey: teamKey,
                            });
                          }}
                          onKeyDown={event => {
                            addNewEmptyTeam({
                              keyCode: event.keyCode || event.which,
                              sectionKey: sectionKey,
                              teamKey: teamKey,
                            });
                          }}
                          onDeleteClick={() => {
                            deleteTeamClicked({
                              sectionKey: sectionKey,
                              teamKey: teamKey,
                              value: '',
                            });
                          }}
                          value={
                            createOrganisationForm.sections[sectionKey].teams[
                              teamKey
                            ].value
                          }
                          maxLength={24}
                        />
                      </div>
                    ))
                  : null}
              </TeamsWrapper>
            </SectionInput>
          </div>
        ))}
        <Button
          disabled={!isValidForm || isCreatingOrganisation}
          onClick={() => createOrganisationClicked()}
        >
          {'Finish and save new organisation'}
        </Button>
      </Wrapper>
    );
  }
);

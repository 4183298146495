import { v4 } from 'uuid';
import * as sequences from './sequences';

const newSectionId = v4();

export default {
  state: {
    isLoadingStructure: false,
    structureForm: {
      sections: {},
    },
    newIds: {
      section: newSectionId,
      teams: {
        [newSectionId]: v4(),
      },
    },
  },
  sequences: {
    teamFieldChanged: sequences.teamFieldChanged,
    sectionFieldChanged: sequences.sectionFieldChanged,
    deleteSection: sequences.deleteSection,
    deleteTeam: sequences.deleteTeam,
    setStructureFromExcelSheet: sequences.setStructureFromExcelSheet,
    setStructureFromOrganisation: sequences.setStructureFromOrganisation,
  },
};

import React from 'react';
import * as PropTypes from 'prop-types';
import { BackDrop, ModalWrapper, Modal } from './elements';

function Mod001(props) {
  if (!props.show) {
    return null;
  }

  return (
    <BackDrop onClick={props.onHide} ref={props.modalRef}>
      <ModalWrapper
        className={props.className}
        height={props.outerHeight}
        minHeight={props.minHeight}
      >
        <Modal
          id={props.id}
          width={props.width}
          height={props.modalHeight}
          onClick={e => {
            e.stopPropagation();
          }}
          minHeight={props.minHeight}
        >
          {props.children}
        </Modal>
      </ModalWrapper>
    </BackDrop>
  );
}

Mod001.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  modalRef: PropTypes.func,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  width: PropTypes.string,
};
export default Mod001;

import { set, toggle } from 'cerebral/factories';
import { state, props } from 'cerebral';
import { clearUrl } from 'common/appContainer/module/actions.js';
import * as actions from './actions';

export const closeAdminActivityModalClicked = [
  set(state`appContainer.shouldResetScroll`, false),
  actions.closeMoreInfo,
  clearUrl,
];

export const toggleIndicatorExpanded = toggle(
  state`admin.campaigns.create.challenge.activityModal.showMoreInfo.${props`indicator`}`
);

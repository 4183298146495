import styled from 'styled-components';
import Wra16A from 'dc/Wra16A';
import Ava36 from 'dc/Ava36';
import { Typ30 } from 'dc/Typ';

export const Wrapper = styled(Wra16A)`
  min-width: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  > span > span {
    color: #fff;
  }
`;

export const Avatar = styled(Ava36)`
  margin-right: 16px;
`;

export const Text = styled(Typ30)`
  color: #fff;
  text-align: left;
  flex: 1;
  -webkit-font-smoothing: antialiased;
`;

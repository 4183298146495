import React from 'react';
import { Link, Button, Icon, Text } from './elements';

type Btn022Props = {
  readonly href?: string;
  readonly disabled?: boolean;
  readonly target?: string;
  readonly className?: string;
  readonly children: React.ReactElement | string;
  readonly ariaLabel?: string;
  readonly themeName?: 'light' | 'dark';
  readonly icon?: string;
  readonly onClick?: () => void;
};

export default function Btn022({
  href,
  disabled,
  target,
  className,
  children,
  ariaLabel,
  themeName,
  icon,
  onClick,
}: Btn022Props) {
  if (href) {
    return (
      <Link
        className={className}
        $disabled={Boolean(disabled)}
        href={href}
        aria-label={ariaLabel}
        target={target}
      >
        <Icon $themeName={themeName} icon={icon} />
        <Text $themeName={themeName}>{children}</Text>
      </Link>
    );
  }

  return (
    <Button
      className={className}
      disabled={Boolean(disabled)}
      onClick={onClick}
      $themeName={themeName}
      aria-label={ariaLabel}
    >
      <Icon $themeName={themeName} icon={icon} />
      <Text $themeName={themeName}>{children}</Text>
    </Button>
  );
}

import React from 'react';
import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import { Container, Bar } from './elements';

export default connect(
  {
    isServer: state`appContainer.isServer`,
    isUnloading: state`appContainer.isUnloading`,
  },
  function Load06({ isServer, isUnloading }) {
    return (
      <Container>
        <Bar $isServer={isServer} $isLoading={isUnloading} />
      </Container>
    );
  },
);

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Label, DropdownInput } from '../elements';
import EditableComponent from '../../EditableComponent';
import { useText } from 'common/hooks';
import Spa32 from 'dc/Spa32';

export default connect(
  {
    timezone: state`admin.campaigns.computedTimezones`,
    regionDropdownIsOpen: state`admin.campaigns.create.info.regionDropdownIsOpen`,
    selectedRegion: state`admin.campaigns.create.info.selectedRegion`,
    timezoneDropdownIsOpen: state`admin.campaigns.create.info.timezoneDropdownIsOpen`,
    selectedTimezone: state`admin.campaigns.create.info.selectedTimezone`,
    regionOptionClicked: sequences`admin.campaigns.create.info.regionOptionClicked`,
    toggleRegionDropdownClicked: sequences`admin.campaigns.create.info.toggleRegionDropdownClicked`,
    timezoneOptionClicked: sequences`admin.campaigns.create.info.timezoneOptionClicked`,
    toggleTimezoneDropdownClicked: sequences`admin.campaigns.create.info.toggleTimezoneDropdownClicked`,
  },
  function Info({
    timezone,
    regionDropdownIsOpen,
    selectedRegion,
    regionOptionClicked,
    toggleRegionDropdownClicked,
    timezoneDropdownIsOpen,
    selectedTimezone,
    timezoneOptionClicked,
    toggleTimezoneDropdownClicked,
    isJoiningChampionship,
  }) {
    const t = useText();

    return (
      <>
        <Label>{t('admin.info.region')}</Label>
        <EditableComponent isJoiningChampionship={isJoiningChampionship}>
          <DropdownInput
            onClick={event => {
              event.stopPropagation();
              toggleRegionDropdownClicked();
            }}
            onDropdownItemClick={option => regionOptionClicked({ option })}
            dropdownVisible={regionDropdownIsOpen}
            options={timezone.regionObj}
            value={selectedRegion || ''}
            placeholder={t('admin.info.select_region')}
          />
        </EditableComponent>
        <Spa32 />
        <Label>{t('admin.info.timezone')}</Label>
        <EditableComponent isJoiningChampionship={isJoiningChampionship}>
          <DropdownInput
            onClick={event => {
              event.stopPropagation();
              toggleTimezoneDropdownClicked();
            }}
            onDropdownItemClick={option => timezoneOptionClicked({ option })}
            dropdownVisible={timezoneDropdownIsOpen}
            options={timezone.timezoneObj}
            value={selectedTimezone || ''}
            placeholder={t('admin.info.select_timezone')}
          />
        </EditableComponent>
      </>
    );
  }
);

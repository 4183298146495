export default {
  choose_focus: 'Choose your focus',
  select_topic:
    'Select a topic to narrow down the focus of your campaign. Selecting for instance transport will add transport questions to a survey, as well as transport activities in a challenge.',
  target_audience: 'Who is your target audience?',
  topics_included: 'What topic would you like to include?',
  audience_personal: 'Personal',
  audience_work: 'Work',
  audience_school: 'School',
  topic_co2: 'CO\u2082',
  topic_workTransport: 'Transport (outdated)',
  topic_foodWaste: 'Food Waste',
  topic_plastic: 'Plastic Waste',
  topic_mindfulCitizen: 'Mindful Citizen',
  choose_data_source: 'Choose which data source to use in the campaign',
  data_source: {
    _enum: 'DATA_SOURCE',
    norway: 'Norwegian',
    japan: 'Japanese',
    united_kingdom: 'United Kingdom',
  },
};

import { state, props } from 'cerebral';
import { set, when } from 'cerebral/factories';
import * as actions from './actions';
import { validateCampaign } from '../../actions';
import { validateChampionship } from 'admin/onboarding/module/modules/championships/modules/create/actions';
import { setUrlAction } from 'common/appContainer/module/factories';

import { clearUrl } from 'common/appContainer/module/actions';

export const expandChallenge = [
  set(state`admin.pageSectionExpanded`, 'challenge'),
  set(state`admin.pageSection`, 'challenge'),
];

export const addChallenge = [
  set(state`admin.campaigns.create.challenge.added`, true),
  set(state`admin.campaigns.create.isValid`, false),
  set(state`admin.pageSectionExpanded`, 'challenge'),
  set(state`admin.pageSection`, 'challengeActivities'),
  actions.setActivities,
  when(state`admin.campaigns.isChampionship`),
  {
    true: [
      validateChampionship,
      {
        true: set(state`admin.championships.create.isValid`, true),
        false: set(state`admin.championships.create.isValid`, false),
      },
    ],
    false: [
      validateCampaign,
      {
        true: set(state`admin.campaigns.create.isValid`, true),
        false: set(state`admin.campaigns.create.isValid`, false),
      },
    ],
  },
];

export const removeChallenge = [
  set(state`admin.campaigns.create.challenge.added`, false),
  set(state`admin.pageSectionExpanded`, ''),
  set(state`admin.campaigns.create.isValid`, false),
  when(state`admin.campaigns.isChampionship`),
  {
    true: [
      validateChampionship,
      {
        true: set(state`admin.championships.create.isValid`, true),
        false: set(state`admin.championships.create.isValid`, false),
      },
    ],
    false: [
      validateCampaign,
      {
        true: set(state`admin.campaigns.create.isValid`, true),
        false: set(state`admin.campaigns.create.isValid`, false),
      },
    ],
  },
];

export const skipChallenge = [
  set(state`admin.pageSection`, 'integration'),
  set(state`admin.pageSectionExpanded`, 'integration'),
];

export const uploadDesktopCoverImage = [
  set(
    state`admin.campaigns.create.challenge.uploadedImages.desktop.url`,
    props`url`
  ),
  set(
    state`admin.campaigns.create.challenge.uploadedImages.desktop.width`,
    props`width`
  ),
  set(
    state`admin.campaigns.create.challenge.uploadedImages.desktop.height`,
    props`height`
  ),
  set(
    state`admin.campaigns.create.challenge.uploadedImages.desktop.file`,
    props`file`
  ),
];

export const uploadMobileCoverImage = [
  set(
    state`admin.campaigns.create.challenge.uploadedImages.mobile.url`,
    props`url`
  ),
  set(
    state`admin.campaigns.create.challenge.uploadedImages.mobile.width`,
    props`width`
  ),
  set(
    state`admin.campaigns.create.challenge.uploadedImages.mobile.height`,
    props`height`
  ),
  set(
    state`admin.campaigns.create.challenge.uploadedImages.mobile.file`,
    props`file`
  ),
];

export const setAdminActivityModal = [
  set(
    state`admin.campaigns.create.challenge.activityModal.activityKey`,
    props`activityKey`
  ),
  set(state`appContainer.shouldResetScroll`, false),
  setUrlAction('adminActivityModal'),
];

export const showAdminTagModal = [
  set(state`admin.campaigns.create.challenge.tagModal.key`, props`tag`),
  when(props`activityModalKey`),
  {
    true: set(
      state`admin.campaigns.create.challenge.tagModal.fromActivityModal`,
      props`activityModalKey`
    ),
    false: [],
  },
  set(state`appContainer.shouldResetScroll`, false),
  setUrlAction('adminTagModal'),
];

export const closeAdminTagModal = [
  set(state`admin.campaigns.create.challenge.tagModal.show`, false),
  set(state`admin.campaigns.create.challenge.tagModal.key`, null),
  set(
    state`admin.campaigns.create.challenge.tagModal.fromActivityModal`,
    false
  ),
  set(state`appContainer.shouldResetScroll`, false),
  clearUrl,
];

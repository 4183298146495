export default {
  set_up_teams: 'Set up teams',
  info: 'Add section and team details, including the approximate size you expect each team to be.  Add an email address for each team leader and once the campaign is published, they will receive relevant information via email.',
  import:
    'You can either import team details or or manually input them below (you can make manual updates after importing too): ',
  save_structure: 'Save teams and sections',
  at_least_one_team: 'All sections need at least one team',
  at_least_one_section: 'You need to enter at least one section',
  team_name: 'Team name',
  approx_size: 'Approx size',
  team_leader_email: "Team leader's email",
  team_from_excel: 'Team details from a spreadsheet',
  org_structure: 'The structure input when setting up the organization.',
  use_org_structure: 'USE ORG STRUCTURE',
  upload_teams: 'UPLOAD TEAMS',
  form: {
    team_name: 'Team name',
    section_name: 'Section name',
    participation_count: 'Approx size',
    team_leader_email: "Team leader's email",
  },
  warning: `MARKDOWN:**Important**
Make sure emails have been received in a GDPR compliant way. We need a [data processing agreement](https://gdpr.eu/what-is-data-processing-agreement/) in order to use emails that are not given directly from users.  
  `,
};

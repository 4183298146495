import * as sequences from './sequences';
import { INDICATOR } from 'resources/constants';

export default {
  state: {
    organisations: {},
    filteredOrganisations: {},
    showOrgDropdown: false,
    newSectionNameError: null,
    createdSections: [],
    editingSectionIndex: null,
    tempSectionName: null,
    sectionNameErrors: {},
    newTeamNameErrors: {},
    teamNameErrors: {},
    indicatorSelected: { optionKey: INDICATOR.co2 },
    form: {
      organisation: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      newSectionName: {
        value: '',
        defaultValue: '',
        validationRules: ['isValue'],
      },
      indicator: {
        value: INDICATOR.co2,
        defaultValue: INDICATOR.co2,
        validationRules: ['isValue'],
      },
    },
    uploadingStructureExcel: false,
  },
  sequences: {
    organisationFieldChanged: sequences.organisationFieldChanged,
    organisationSelected: sequences.organisationSelected,
    toggleShowDropdown: sequences.toggleShowDropdown,
    showDropdown: sequences.showDropdown,
  },
};

import React from 'react';
import * as PropTypes from 'prop-types';

import { Wrapper } from './elements';

function SnB01(props) {
  return <Wrapper className={props.className}>{props.children}</Wrapper>;
}

SnB01.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default SnB01;

import styled from 'styled-components';
import { Typ60, Typ30 } from 'dc/Typ';
import TxtField12 from 'dc/TxtField12';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto 56px;
`;

export const Title = styled(Typ60)`
  color: #546e7a;
  margin-bottom: 16px;
`;

export const Subtitle = styled(Typ30)`
  color: #546e7a;
  margin-bottom: 32px;
`;

export const Label = styled(Typ30)`
  color: #546e7a;
  margin-bottom: 8px;
`;

export const DropDown = styled(TxtField12)`
  width: 288px;
`;

import React from 'react';
import * as PropTypes from 'prop-types';
import { Link, Button } from './elements';

function Btn021(props) {
  if (props.href) {
    return (
      <Link
        className={props.className}
        disabled={props.disabled}
        href={props.href}
        type={props.type}
        id={props.id}
        theme={props.theme}
        target={props.target}
        rel={props.rel}
      >
        {props.children}
      </Link>
    );
  }

  return (
    <Button
      className={props.className}
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.type}
      id={props.id}
      theme={props.theme}
    >
      {props.children}
    </Button>
  );
}

Btn021.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  theme: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
};

export default Btn021;

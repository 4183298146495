/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import * as PropTypes from 'prop-types';
import {
  ElementWrapper,
  MainContent,
  ButtonWrapper,
  ButtonText,
  Icon,
  TitleText,
  SmallText,
} from './elements';

function Lab401(props) {
  function renderContent() {
    if (props.disabled) {
      return (
        <div>
          <SmallText>{props.smallText}</SmallText>
          <MainContent>
            <TitleText color={'#CFD8DC'}>{props.children}</TitleText>
          </MainContent>
        </div>
      );
    } else if (props.completed) {
      return (
        <a href={props.href}>
          <SmallText>{props.smallText}</SmallText>
          <MainContent>
            <TitleText color={'#455A64'}>{props.children}</TitleText>
            <ButtonWrapper>
              <Icon icon={'icon-check_circle'} color={'#004750'} />
            </ButtonWrapper>
          </MainContent>
        </a>
      );
    }

    return (
      <a href={props.href}>
        <SmallText>{props.smallText}</SmallText>
        <MainContent>
          <TitleText color={'#455A64'}>{props.children}</TitleText>
          <ButtonWrapper>
            <ButtonText>{props.buttonText}</ButtonText>
            <Icon icon={'icon-keyboard_arrow_right'} />
          </ButtonWrapper>
        </MainContent>
      </a>
    );
  }

  return (
    <ElementWrapper
      className={props.className}
      onClick={props.disabled ? null : props.onClick}
      disabled={props.disabled}
    >
      {renderContent()}
    </ElementWrapper>
  );
}

Lab401.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  completed: PropTypes.bool,
  smallText: PropTypes.string,
  buttonText: PropTypes.string,
  children: PropTypes.node,
};

export default Lab401;

import React from 'react';
import * as PropTypes from 'prop-types';
import { Wrapper, OuterWrapper, Loader, Icon } from './elements';

function Load02(props) {
  return (
    <Wrapper className={props.className}>
      <OuterWrapper className={props.className}>
        <Loader />
        <Icon icon={props.icon} />
      </OuterWrapper>
    </Wrapper>
  );
}

Load02.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
};

export default Load02;

import styled from 'styled-components';
import NavIt01 from 'dc/NavIt01';
import StaBar from 'dc/StaBar';

export const OuterWrapper = styled.div`
  background-color: #f4f7f9;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
`;

export const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  @media (min-width: 1380px) {
    padding-right: 280px;
  }
`;

export const LoadingUser = styled.div`
  background-color: #f4f7f9;
  min-height: calc(100vh - 80px);
  border-bottom: 8px solid #455a64;
  background-image: linear-gradient(#f4f7f9, #ffffff);
  padding: 80px;
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    min-height: 500px;
    padding-top: 56px;
  }
`;

export const SideBar = styled.div`
  position: relative;
  width: 280px;
  display: flex;
  flex-direction: column;
  background-color: #eceff1;
  padding-top: 16px;
`;

export const SideBarText = styled(NavIt01)``;

export const OptionWrapper = styled.div`
  position: relative;
  height: ${props => (props.show ? props.maxHeight : 0)};
  visibility: ${props => (props.show ? 'visible' : 'collapse')};
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &:after {
    content: '';
    background: #78909c;
    position: absolute;
    bottom: 0;
    left: 16px;
    width: 248px;
    height: ${props => (props.show ? '1px' : 0)};
  }
  &:before {
    content: '';
    background: #78909c;
    position: absolute;
    top: 0;
    left: 16px;
    width: 248px;
    height: ${props => (props.show ? '1px' : 0)};
    z-index: 1;
  }
`;

export const StatusBar = styled(StaBar)`
  margin-top: 80px;
`;

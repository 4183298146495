import styled from 'styled-components';
import { Typ60, Typ30 } from 'dc/Typ';
import TxtField12 from 'dc/TxtField12';
import TxtField07 from 'dc/TxtField07';

const GREY_600 = '#546E7A';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 148px;
`;

export const Title = styled(Typ60)`
  color: ${GREY_600};
`;

export const InputWrapper = styled.div`
  margin: 18px 0 20px 0;
`;

export const Label = styled(Typ30)`
  color: ${GREY_600};
`;

export const DropDown = styled(TxtField12)`
  margin-top: 8px;
`;

export const Paragraph = styled(Typ30)`
  margin-top: 48px;
  margin-bottom: 16px;
  color: ${GREY_600};
`;

export const TextArea = styled(TxtField07)`
  margin-top: 8px;
`;

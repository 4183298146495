import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 584px;
  margin: 0 auto;
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 16px 0;
  width: 100%;
  border-top: 1px solid #cfd8dc;
`;

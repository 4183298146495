import React from 'react';
import { connect } from '@cerebral/react';
import { state, props, sequences } from 'cerebral';
import { Form } from 'forms';
import { ICON } from 'resources/constants';
import { useText } from 'common/hooks';

import {
  Wrapper,
  Header,
  InputName,
  InputSize,
  InputEmail,
  Delete,
  Space,
} from './elements';
import Button from 'dc/Button';

export default connect(
  {
    form: state`admin.structure.structureForm.sections.${props`sectionKey`}.teams.${props`teamKey`}`,
    exists: state`admin.structure.structureForm.sections.${props`sectionKey`}.teams.${props`teamKey`}.exists`,
    teamFieldChanged: sequences`admin.structure.teamFieldChanged`,
    deleteTeam: sequences`admin.structure.deleteTeam`,
  },
  function Team({
    className,
    form,
    isNew,
    teamFieldChanged,
    deleteTeam,
    exists,
    sectionKey,
    teamKey,
    get,
  }) {
    const t = useText();
    const sectionForm = new Form(form, get);

    const handleOnChange = fieldName => e => {
      teamFieldChanged({
        isNew,
        sectionKey,
        teamKey,
        fieldName,
        value: e.target.value,
      });
    };

    return (
      <Wrapper className={className}>
        <Header>
          <InputName
            disabled={sectionForm.name?.readOnly}
            label={t('admin.structure.form.team_name')}
            errorMessage={
              (sectionForm.name?.errorMessage &&
                t(sectionForm.name.errorMessage.phrase)) ||
              ''
            }
            value={isNew ? '' : sectionForm.name?.value}
            onChange={handleOnChange('name')}
          />
          <InputSize
            disabled={sectionForm.expectedParticipantCount?.readOnly}
            label={t('admin.structure.form.participation_count')}
            errorMessage={
              (sectionForm.expectedParticipantCount?.errorMessage &&
                t(sectionForm.expectedParticipantCount.errorMessage.phrase)) ||
              ''
            }
            type={'number'}
            min={0}
            value={isNew ? '' : sectionForm.expectedParticipantCount?.value}
            onChange={handleOnChange('expectedParticipantCount')}
          />
          <InputEmail
            disabled={sectionForm.teamLeaderEmail?.readOnly}
            label={t('admin.structure.form.team_leader_email')}
            errorMessage={
              (sectionForm.teamLeaderEmail?.errorMessage &&
                t(sectionForm.teamLeaderEmail?.errorMessage.phrase)) ||
              ''
            }
            type={'email'}
            value={isNew ? '' : sectionForm.teamLeaderEmail?.value}
            onChange={handleOnChange('teamLeaderEmail')}
          />
          {!isNew && !exists ? (
            <Button onClick={() => deleteTeam({ teamKey, sectionKey })}>
              <Delete icon={ICON.cancel} />
            </Button>
          ) : (
            <Space />
          )}
        </Header>
      </Wrapper>
    );
  }
);

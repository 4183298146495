import styled, { css, keyframes } from 'styled-components';
import Wra16A from 'dc/Wra16A';
import Wra6A from 'dc/Wra6A';
import IcoW02 from 'dc/IcoW02';
import Btn020 from 'dc/Btn020';
import Ava36 from 'dc/Ava36';

const buttonAnimations = {
  in: keyframes`
    0% {
      top: -100px;
    }
    100% {
      top: 0px;
    }
  `,
  out: keyframes`
    0% {
      top: 0px;
    }
    100% {
      top: -100px;
    }
  `,
};

export const WrapperDesktop = styled(Wra16A)`
  background: transparent;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 100;
  justify-content: space-between;
`;

export const ButtonWrapper = styled(Wra6A)`
  display: flex;
  position: relative;
  top: -100px;
  animation: ${props =>
    props.animation
      ? css`0.3s ${buttonAnimations[props.animation]} ease-in-out forwards`
      : null};
`;

export const DuckyIcon = styled(IcoW02)`
  &:hover {
    transform: scale(1.03);
  }
  transition: all 0.3s ease-in-out;
  -webkit-font-smoothing: antialiased;

  cursor: pointer;
  & > i {
    color: #455a64;
    font-size: 36px;
    line-height: 36px;
  }
`;

export const DuckyIconLink = styled.a`
  @media (max-width: 766px) {
    display: none;
  }
`;

export const Button = styled(Btn020)`
  & > span {
    color: ${props => (props.active ? '#263238' : '#607d8b')};
    text-transform: none;
  }

  border: 2px solid ${props => (props.active ? '#263238' : '#607d8b')};

  &:hover {
    border-color: #263238;
    & > span {
      color: #263238;
    }
  }
`;

export const Avatar = styled(Ava36)`
  cursor: pointer;
  margin-left: 12px;
`;

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { useText, useConfig } from 'common/hooks';

import {
  Wrapper,
  SectionWrapper,
  Title,
  Text,
  Label,
  Link,
  Button,
  HorizontalRule,
} from './elements';

export default connect(
  {
    linksData: state`admin.championships.edit.computedChampionshipLinks`,
    engagement: state`admin.championships.edit.engagement`,
    championshipKey: state`admin.championships.edit.championshipKey`,
    languageSelected: state`admin.championships.edit.languageSelected.optionKey`,
    copyToClipboard: sequences`admin.campaigns.edit.copyToClipboard`,
    openWithToken: sequences`admin.openWithToken`,
  },
  function Links({
    linksData,
    engagement,
    championshipKey,
    languageSelected,
    copyToClipboard,
    openWithToken,
  }) {
    const t = useText();
    const config = useConfig();

    return (
      <Wrapper>
        <HorizontalRule />
        <SectionWrapper>
          <Title>{t(`admin.overview.info.engagement`)}</Title>
          <Text>
            {t(`admin.overview.info.churn`, {
              churn: engagement?.churnRate?.toFixed(2) ?? '-',
            })}
          </Text>
          <Text>
            {t(`admin.overview.info.logging_percentage`, {
              percentage: engagement?.percentageLogged?.toFixed(2) ?? '-',
            })}
          </Text>
        </SectionWrapper>
        <HorizontalRule />
        {Object.entries(linksData?.[languageSelected]?.links || {}).map(
          ([linkType, detailsArray]) => {
            if (detailsArray.length > 0) {
              return (
                <SectionWrapper key={linkType}>
                  <Title>{t(`admin.overview.links.${linkType}`)}</Title>
                  <Text>
                    {t(`admin.overview.links.${linkType}_description`)}
                  </Text>
                  {detailsArray.map(details => {
                    if (linkType === 'widgets') {
                      return (
                        <React.Fragment key={details.link}>
                          <Label>
                            {t(`admin.overview.links.${details.name}`)}
                          </Label>
                          <Button
                            onClick={() =>
                              copyToClipboard({ str: details.link })
                            }
                          >
                            {t(`admin.overview.links.copy_iframe`)}
                          </Button>
                        </React.Fragment>
                      );
                    }
                    if (details.name) {
                      return (
                        <React.Fragment key={details.link}>
                          <Label>{details.name}</Label>
                          <Link
                            as={'a'}
                            href={details.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {details.link}
                          </Link>
                        </React.Fragment>
                      );
                    }

                    return (
                      <Link
                        as={'a'}
                        key={details.link}
                        href={details.link}
                        target="_blank"
                      >
                        {details.link}
                      </Link>
                    );
                  })}
                </SectionWrapper>
              );
            }

            return null;
          }
        )}
        <HorizontalRule />
        <SectionWrapper>
          <Title>{t(`admin.overview.info.nps`)}</Title>
          <Text>{t(`admin.overview.info.nps_description`)}</Text>
          <Button
            onClick={() =>
              openWithToken({
                url: `${config.cloudFunctionUrl}/requests-admin-createNPS?championshipKey=${championshipKey}`,
              })
            }
          >
            {t(`admin.overview.info.download_now`)}
          </Button>
        </SectionWrapper>
        <HorizontalRule />
      </Wrapper>
    );
  }
);

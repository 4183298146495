import styled from 'styled-components';
import { Typ30, Typ60 } from 'dc/Typ';
import SwiLab02 from 'dc/SwiLab02';
import TextField from 'dc/TxtField06';
import Lab121 from 'dc/Lab121';
import Ico36 from 'dc/Ico36';

export const Wrapper = styled.div`
  width: 584px;
  margin: 0 auto;
`;

export const Title = styled(Typ60)`
  color: #546e7a;
`;

export const Description = styled(Typ30)`
  color: #546e7a;

  span {
    color: #546e7a;
  }

  a {
    color: #03739a;
    text-decoration: underline;
  }
`;

export const RadioButton = styled(SwiLab02)`
  align-items: flex-start;
  margin-bottom: 20px;
  div {
    color: #546e7a;
  }
  span {
    color: #546e7a !important;
  }
`;

export const OptionDescription = styled(Typ30)`
  margin-top: 20px;
`;

export const EmailInput = styled(TextField)``;

export const AddLabel = styled(Lab121)`
  > span {
    color: #03739a;
  }

  cursor: pointer;
`;

export const EmailWrapper = styled.div`
  display: flex;
`;

export const Delete = styled(Ico36)`
  padding: 7px;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

import styled, { keyframes } from 'styled-components';
import Ico12 from '../Ico12';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const OuterWrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: transparent;
  position: relative;
`;

export const Loader = styled.div`
  background-color: #f4f7f9;
  box-sizing: border-box;
  top: 0;
  left: 0;
  border-top: 2px solid rgba(38, 50, 56, 0.1);
  border-right: 2px solid rgba(38, 50, 56, 0.1);
  border-bottom: 2px solid rgba(38, 50, 56, 0.1);
  border-left: 2px solid #b0bec5;
  transform: translateZ(0);
  animation: ${rotate360} 1.1s infinite linear;
  border-radius: 50%;
  width: 100%;
  height: 100%;
`;

export const Icon = styled(Ico12)`
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -6px;
  margin-top: -6px;
  color: #90a4ae;
`;

import React from 'react';
import * as PropTypes from 'prop-types';
import { Image } from './elements';

function Act36(props) {
  return (
    <Image
      className={props.className}
      src={props.icon}
      alt={props.alt}
      onClick={props.onClick}
      height={36}
      width={36}
    />
  );
}

Act36.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
};

export default Act36;

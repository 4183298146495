export default {
  title: 'Set up your integrations',
  description: `MARKDOWN:Would you like to add integrations to your campaign such as public pages and web widgets?  These are used for visualizing in real-time the results of a survey, comparing teams in a challenge or allowing users to experiment with a climate calculator on your own website. Integrations pull live data from the campaign.  
  `,
  add_button: 'Add',
  skip_button: 'Skip',
  public_pages: {
    title: 'Activate public pages',
    description:
      'What public pages would you like to activate for your campaign? Public pages makes it much easier for you to engage participants!',
    pages: {
      liveScreen: {
        title: 'Public live screen',
        description: `MARKDOWN:Visualizes the status of the Challenge, live, on a computer or TV screen, in a communal place. Great for keeping team members engaged throughout a challenge.
        `,
      },
    },
  },
  widgets: {
    title: 'Activate widgets',
    description: `MARKDOWN:What widgets would you like to activate for your campaign? Widgets are interactive iFrame-integrations you can put on any website, visualizing live data from the campaign.
    `,
    widgets: {
      challengeWidget: {
        title: 'Challenge Widget',
        description: `MARKDOWN:Visualizes your organization's total CO\u2082e savings from the challenge, in real time. Explore the ranking and savings of different teams, and be inspired by the most popular activities.
        `,
      },
    },
  },
};

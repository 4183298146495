import * as sequences from './sequences';
import createModule from './modules/create';
import editModule from './modules/edit';
import { renderPage } from '../../factories';

export default {
  state: {
    list: [],
    showFinished: false,
    isLoading: true,
  },
  modules: {
    create: createModule,
    edit: editModule,
  },
  sequences: {
    routed: renderPage('championships', sequences.render),
    toggleShowFinished: sequences.toggleShowFinished,
  },
};

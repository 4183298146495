import { sequence, props, state } from 'cerebral';
import { adminRendered } from 'common/appContainer/module/factories';
import { isAdmin } from 'common/actions';
import { equals, set } from 'cerebral/factories';

const urlActions = [
  sequence('adminUrlActions', [
    // Hide all modals
    set(
      state`admin.campaigns.create.challenge.activityModal.showAdminActivityModal`,
      false
    ),
    set(state`admin.campaigns.create.challenge.tagModal.show`, false),
    // Show proper modal if needed
    equals(props`action`),
    {
      adminActivityModal: set(
        state`admin.campaigns.create.challenge.activityModal.showAdminActivityModal`,
        true
      ),
      adminTagModal: set(
        state`admin.campaigns.create.challenge.tagModal.show`,
        true
      ),
      otherwise: [],
    },
  ]),
];

export function renderPage(page, continueChain = []) {
  return adminRendered([
    isAdmin,
    {
      true: set(state`admin.page`, page),
      false: set(state`admin.page`, 'notAuthorized'),
    },
    urlActions,
    continueChain,
  ]);
}

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';

import { Modal, Header, Info } from './elements';
import { getTagInfoColors, getTagImage } from 'utils/challenge';
import { useText, useConfig } from 'common/hooks';

export default connect(
  {
    tag: state`admin.campaigns.create.challenge.tagModal.key`,
    showTagModal: state`admin.campaigns.create.challenge.tagModal.show`,
    tagModalFromActivityModal: state`admin.campaigns.create.challenge.tagModal.fromActivityModal`,
    closeAdminTagModal: sequences`admin.campaigns.create.challenge.closeAdminTagModalClicked`,
    showActivityDetails: sequences`admin.campaigns.create.challenge.adminActivityDetailsClicked`,
  },
  function AdminTagMod01({
    tag,
    showTagModal,
    tagModalFromActivityModal,
    closeAdminTagModal,
    showActivityDetails,
  }) {
    if (!showTagModal) {
      return null;
    }

    const t = useText();
    const { staticUrl } = useConfig();
    const image = getTagImage(staticUrl, tag);

    return (
      <Modal onHide={() => closeAdminTagModal()} show={tag && showTagModal}>
        <Header
          onClose={() => closeAdminTagModal()}
          colors={getTagInfoColors(tag)}
          image={image}
          showBackButton={Boolean(tagModalFromActivityModal)}
          backButtonClick={() =>
            showActivityDetails({
              activityKey: tagModalFromActivityModal,
            })
          }
          backButtonText={t(`challenge.tags.back`)}
        />
        <Info>{tag && t(`challenge.tags.${tag}.description`)}</Info>
      </Modal>
    );
  }
);

import styled from 'styled-components';
import SeH002 from 'dc/SeH002';

type WrapperProps = {
  colors: { primary: string; secondary: string };
};

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: 100%;
  height: 178px;
  background: ${props =>
    props.colors
      ? `linear-gradient(98.57deg,${props.colors.primary} 0%,${props.colors.secondary} 100%)`
      : 'linear-gradient(98.57deg,#007079 0%,#35B8C4 100%)'};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionHeader = styled(SeH002)`
  i {
    color: #fff;
  }
`;

type ImageProps = {
  hasBottomPadding?: boolean;
};

export const Image = styled.img<ImageProps>`
  position: absolute;
  bottom: ${props => (props.hasBottomPadding ? '13px' : 0)};
  max-width: 100%;
  height: auto;
`;

import styled from 'styled-components';
import Wra12A from '../Wra12A';

export const WrapperNarrow = styled(Wra12A)`
  max-width: 100%;
  padding: 12px;
  background-color: transparent;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`;

export const WrapperLink = styled.a`
  background-color: transparent;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  cursor: pointer;
`;

import React, { useState } from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import dayjs from 'dayjs';

import {
  WrapperDesktop,
  ButtonWrapper,
  DuckyIcon,
  Button,
  DuckyIconLink,
  Avatar,
} from './elements';

import ProMen2 from 'dc/ProMen2';
import { useLink, useText, useImage, useGetAvatarUrl } from 'common/hooks';

export default connect(
  {
    isAuthenticating: state`appContainer.auth.isAuthenticating`,
    profile: state`appContainer.profiles.${state`appContainer.user.uid`}`,
    isUnloading: state`appContainer.isUnloading`,
    isServer: state`appContainer.isServer`,
    logoutClicked: sequences`appContainer.logoutClicked`,
    googleRegisterClicked: sequences`appContainer.auth.googleRegisterClicked`,
  },
  function NavBar1A({
    isAuthenticating,
    profile,
    isServer,
    isUnloading,
    googleRegisterClicked,
    logoutClicked,
  }) {
    let buttonWrapperAnimation = null;

    if (!isServer && isUnloading) {
      buttonWrapperAnimation = 'out';
    } else if (!isServer && !isAuthenticating) {
      buttonWrapperAnimation = 'in';
    }

    const [showMenu, setShowMenu] = useState(false);
    const t = useText();
    const link = useLink();
    const getAvatarUrl = useGetAvatarUrl();
    const [url, loading] = useImage(getAvatarUrl(profile).small);

    return (
      <nav>
        <WrapperDesktop>
          <DuckyIconLink href={'/'}>
            <DuckyIcon icon="icon-duckylogo" />
          </DuckyIconLink>
          <ButtonWrapper animation={buttonWrapperAnimation}>
            {!loading && profile ? (
              <>
                <Avatar
                  image={url}
                  isLoading={loading}
                  onClick={() => setShowMenu(!showMenu)}
                />
                <ProMen2
                  isLoading={loading}
                  active_text={t('appContainer.NavBar.ProMen2.active_since')}
                  edit_profile_text={t('appContainer.NavBar.ProMen2.profile')}
                  log_out_text={t('appContainer.NavBar.ProMen2.log_out')}
                  avatarURL={url}
                  username={profile.name}
                  activeSince={dayjs(profile.joinedDatetime).format(
                    'MMMM YYYY'
                  )}
                  editClicked={() =>
                    (location.href = link('profile', { endpoint: 'app' }))
                  }
                  logoutClicked={() => logoutClicked()}
                  show={showMenu}
                />
              </>
            ) : null}
            {!profile && (
              <Button
                onClick={() => googleRegisterClicked({ redirectUrl: '/' })}
              >
                {t('appContainer.NavBar.logIn')}
              </Button>
            )}
          </ButtonWrapper>
        </WrapperDesktop>
      </nav>
    );
  }
);

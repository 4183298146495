import styled from 'styled-components';
import Wra16A from '../Wra16A';
import { Typ30 } from '../Typ';

export const Text = styled(Typ30)`
  color: ${props => (props.disabled ? '#CFD8DC' : '#546e7a')};
  font-weight: ${props => (props.selected ? 'bold' : 'normal')};
  padding-left: ${props => (props.indent ? `${props.indent * 24}px` : 0)};
`;

export const Wrapper = styled(Wra16A)`
  position: relative;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  background-color: ${props => (props.selected ? '#f4f7f9' : '#eceff1')};
  height: 52px;
  width: 280px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  &:hover {
    background-color: ${props => (props.disabled ? 'auto' : '#f4f7f9')};
  }
  &:hover ${Text} {
    font-weight: ${props => (props.disabled ? 'auto' : 'bold')};
  }
  &:after {
    content: '';
    background: #78909c;
    position: absolute;
    bottom: 0;
    left: 16px;
    width: 248px;
    height: ${props => (props.underlined ? '1px' : '0')};
  }
`;

import styled from 'styled-components';
import { Typ31 } from 'dc/Typ';
import TglTextField1 from 'dc/TglTextField1';
import TxtField13 from 'dc/TxtField13';
import Btn021 from 'dc/Btn021';

export const Wrapper = styled.div`
  padding-top: 14px;
  display: flex;
  flex-direction: column;
`;

export const SectionInput = styled(TglTextField1)`
  margin-bottom: 24px;
  padding-left: 48px;
`;

export const TeamsWrapper = styled.div`
  padding-left: 130px;
`;

export const TeamInput = styled(TxtField13)`
  margin-bottom: 24px;
`;

export const Error = styled(Typ31)`
  padding-left: 96px;
  color: red;
`;

export const Button = styled(Btn021)`
  margin-top: 86px;
  align-self: center;
`;

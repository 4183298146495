import React from 'react';
import * as PropTypes from 'prop-types';
import { ElementWrapper, Icon, Text20, Text21 } from './elements';

function Lab010(props) {
  return (
    <ElementWrapper className={props.className} onClick={props.onClick}>
      <Icon color={props.color} icon={props.icon} />
      {props.bold ? (
        <Text21 color={props.color}>{props.children}</Text21>
      ) : (
        <Text20 color={props.color}>{props.children}</Text20>
      )}
    </ElementWrapper>
  );
}

Lab010.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  bold: PropTypes.bool,
};
export default Lab010;

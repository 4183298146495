import { state } from 'cerebral';

export default get => {
  const filteredOrganisations = get(
    state`admin.campaigns.create.organisation.filteredOrganisations`
  );

  return Object.keys(filteredOrganisations).map(
    organisationKey => filteredOrganisations[organisationKey].name
  );
};

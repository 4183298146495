import styled from 'styled-components';
import { Typ30, Typ60 } from 'dc/Typ';
import TxtField11 from 'dc/TxtField11';

export const Wrapper = styled.div`
  width: 584px;
  margin: 0 auto;
`;

export const Title = styled(Typ60)`
  color: #546e7a;
  margin-bottom: 16px;
`;

export const Text = styled(Typ30)`
  color: #546e7a;
`;

export const SearchDropdown = styled(TxtField11)`
  margin: 4px 0 44px;
  width: 288px;
  cursor: ${props => (props.isJoiningChampionship ? 'not-allowed' : 'default')};
  input {
    cursor: ${props => (props.isJoiningChampionship ? 'not-allowed' : 'text')};
  }
  div {
    cursor: ${props =>
      props.isJoiningChampionship ? 'not-allowed' : 'pointer'};
  }
`;

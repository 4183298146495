import { state } from 'cerebral';
import { primaryLanguage, languageNames } from 'languages/config';

const getDomainString = (domain, lang) =>
  lang === primaryLanguage ? domain : `${domain}/${lang}`;

export default get => {
  const championship = get(state`admin.championships.edit.championship`);
  const organisationOptions = get(
    state`admin.championships.edit.organisationOptions`
  );
  const appConfig = get(state`appContainer.config`);
  const series = get(state`admin.championships.edit.seriesSelected`);
  const organisation = get(
    state`admin.championships.edit.organisationSelected`
  );
  const section = get(state`admin.championships.edit.sectionSelected`);
  const checkedComparison = get(
    state`admin.championships.edit.checkedComparison`
  );
  const organisations = get(state`admin.championships.edit.organisations`);

  let links = {};

  if (championship) {
    links = Object.assign(
      {},
      ...Object.keys(languageNames).map(language => {
        const defaultCampaignKey =
          organisations?.[
            Object.keys(organisationOptions ?? {}).filter(
              key => key !== 'noOrganisationFilter'
            )[0]
          ]?.campaignKey;
        const langDomain = getDomainString(appConfig.widgetUrl, language);

        const championshipWidgetIframe = `<iframe src="${langDomain}/championship/${championship.key}" scrolling="no" style="min-height: 924px;" title="championshipWidget" width="100%"></iframe>`;

        const reports = defaultCampaignKey
          ? {
              report: {
                url: `${
                  appConfig.cloudFunctionUrl
                }/requests-admin-createCampaignReport?campaignKey=${defaultCampaignKey}&aggregationLevel=${checkedComparison}&language=${language}${
                  series.optionKey !== 'noSeriesFilter'
                    ? `&seriesKeyFilter=${series.optionKey}`
                    : ''
                }${
                  organisation.optionKey !== 'noOrganisationFilter'
                    ? `&campaignKeyFilter=${
                        organisations[organisation.optionKey].campaignKey
                      }`
                    : ''
                }${
                  section.optionKey !== 'noSectionFilter'
                    ? `&sectionKeyFilter=${section.optionKey}`
                    : ''
                }`,
              },
              excel: {
                url: `${
                  appConfig.cloudFunctionUrl
                }/requests-admin-createCampaignReport?campaignKey=${defaultCampaignKey}&aggregationLevel=${checkedComparison}&language=${language}${
                  series.optionKey !== 'noSeriesFilter'
                    ? `&seriesKeyFilter=${series.optionKey}`
                    : ''
                }${
                  organisation.optionKey !== 'noOrganisationFilter'
                    ? `&campaignKeyFilter=${
                        organisations[organisation.optionKey].campaignKey
                      }`
                    : ''
                }${
                  section.optionKey !== 'noSectionFilter'
                    ? `&sectionKeyFilter=${section.optionKey}`
                    : ''
                }&fileType=excel`,
              },
            }
          : {};

        return {
          [language]: {
            links: {
              widgets: [
                {
                  name: 'championship',
                  link: championshipWidgetIframe,
                },
              ],
            },
            downloads: {
              reports,
            },
          },
        };
      })
    );
  }

  return links;
};

import React from 'react';
import {
  Wrapper,
  BackgroundIcon,
  IconWrapper,
  Icon,
  Input,
  InputButton,
} from './elements';

type Upload01Props = {
  readonly height: string;
  readonly width: string;
  readonly onClick: () => void;
  readonly className?: string;
  readonly image: string;
  readonly onAddClick: () => void;
  readonly accept: string;
  readonly backgroundIcon: string;
};

export default function Upload01({
  height,
  width,
  onClick,
  className,
  image,
  onAddClick,
  accept,
  backgroundIcon,
}: Upload01Props) {
  return (
    <Wrapper
      $height={height}
      $width={width}
      onClick={image ? onClick : null}
      className={className}
      $image={image}
    >
      {image ? (
        <IconWrapper>
          <Icon icon={image ? 'icon-delete' : 'icon-photo_camera'} />
        </IconWrapper>
      ) : (
        <InputButton htmlFor={'file'}>
          <Input
            onChange={onAddClick}
            type={'file'}
            name={'file'}
            id={'file'}
            accept={accept}
          />
          <BackgroundIcon icon={backgroundIcon || 'icon-add'} />
          <IconWrapper>
            <Icon icon={image ? 'icon-delete' : 'icon-photo_camera'} />
          </IconWrapper>
        </InputButton>
      )}
    </Wrapper>
  );
}

import styled, { css } from 'styled-components';
import TxtField05 from 'dc/TxtField05';
import Ico24 from 'dc/Ico24';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 72px;
`;

export const Input = styled(TxtField05)`
  flex-grow: 1;
  span {
    width: auto;
  }
`;

export const Delete = styled(Ico24)`
  margin: 12px;
`;

export const Caret = styled(Ico24)`
  margin: 12px;
  transform: rotate(0);
  transition: transform 0.2s ease-in-out;
  ${props =>
    !props.expanded &&
    css`
      transform: rotate(-90deg);
    `}
`;

export const Space = styled(Ico24)`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
`;

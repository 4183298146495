import styled from 'styled-components';
import Mod001 from 'dc/Mod001';
import TagModHe from 'dc/TagModHe';

export const Modal = styled(Mod001)`
  pointer-events: auto;
  max-width: 520px;

  > div {
    background-color: #ffffff;
    margin: 8px;
  }
`;

export const Header = styled(TagModHe)``;

export const Info = styled.div`
  padding: 17px;
`;

import React from 'react';
import { ElementWrapper, LabelText, ValueText, Icon } from './elements';

type Lab115BProps = {
  readonly className?: string;
  readonly icon: string;
  readonly color?: string;
  readonly theme?: 'light' | 'dark';
  readonly value: string;
  readonly label: string;
};

export default function Lab115B({
  className,
  icon,
  color,
  theme,
  value,
  label,
}: Lab115BProps) {
  return (
    <ElementWrapper className={className}>
      <Icon icon={icon || 'icon-leaf'} color={color} />
      <ValueText $themeName={theme} $show={value}>
        {value}
      </ValueText>
      <LabelText $themeName={theme}>{label}</LabelText>
    </ElementWrapper>
  );
}

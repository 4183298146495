import moment from 'moment-timezone';

export function uploadCampaignImage({ state, firebase, path }) {
  const { file } = state.get(
    'admin.campaigns.create.info.uploadedImages.desktop'
  );

  return firebase
    .uploadAdminImage(file, { size: `640x360>`, path: 'campaignCovers' })
    .then(({ response }) => path.success({ campaignImage: response.path }))
    .catch(() =>
      path.error({
        error:
          'Error uploading image, please use a supported image format and a file less than 10MB',
      })
    );
}

function validateChallenge({ forms }) {
  const challengeForm = forms.toJSON('admin.campaigns.create.challenge.form');
  const backlogDays = challengeForm.backlogDays > 0;
  const { startDate, endDate, prize } = challengeForm;
  const emailUpdateFrequency = challengeForm.emailUpdateFrequency > 0;
  const activitiesForm = forms.toJSON(
    'admin.campaigns.create.challenge.activities.form'
  );
  const activities = Object.keys(activitiesForm).filter(
    activityKey => activitiesForm[activityKey]
  );
  const isValidChallenge =
    backlogDays &&
    emailUpdateFrequency &&
    startDate &&
    endDate &&
    prize &&
    activities.length > 1; // Tutorial activity and one chosen activity are needed

  return isValidChallenge;
}

function validateTools({ state, forms }) {
  const challengeAdded = state.get('admin.campaigns.create.challenge.added');
  let isValidTools = challengeAdded;
  if (challengeAdded) {
    isValidTools &&= validateChallenge({ forms });
  }

  return isValidTools;
}

export function validateCampaign({ state, path, forms }) {
  const organisationForm = forms.toJSON(
    'admin.campaigns.create.organisation.form'
  );
  const infoForm = forms.toJSON('admin.campaigns.create.info.form');
  const campaignImage = state.get(
    'admin.campaigns.create.info.uploadedImages.desktop.url'
  );
  const focusForm = forms.toJSON('admin.campaigns.create.focus.form');
  const configForm = forms.toJSON('admin.campaigns.create.config.form');
  const structureForm = forms.get('admin.structure.structureForm.sections');
  const structure = forms.toJSON('admin.structure.structureForm.sections');
  const isJoiningSeries = state.get('admin.campaigns.create.isJoiningSeries');

  const { organisation } = organisationForm;
  const {
    startDate,
    endDate,
    description,
    tagline,
    timezone,
    name,
    sectionTerm,
  } = infoForm;
  const { audience, topic } = focusForm;
  const { series } = configForm;

  const isValid =
    organisation &&
    startDate &&
    endDate &&
    topic &&
    audience &&
    name &&
    description &&
    tagline &&
    timezone &&
    sectionTerm &&
    campaignImage &&
    structureForm.isValid &&
    Object.keys(structure).length > 0 &&
    Boolean(!isJoiningSeries || series);

  if (isValid && validateTools({ state, forms })) {
    return path.true();
  }

  return path.false();
}

export function createCampaignObject({ state, props, forms }) {
  const isJoiningChampionshipValue = state.get(
    `admin.campaigns.create.isJoiningChampionship`
  );
  const championshipKey = state.get(`admin.campaigns.create.championshipKey`);
  const infoForm = forms.get('admin.campaigns.create.info.form');
  const focusForm = forms.get('admin.campaigns.create.focus.form');
  const accessForm = forms.get('admin.campaigns.create.access.form');
  const configForm = forms.get('admin.campaigns.create.config.form');
  const structureForm = forms.toJSON('admin.structure.structureForm');
  const organisationKey = isJoiningChampionshipValue
    ? state.get('admin.campaigns.create.joiningOrganisationKey')
    : state.get('admin.campaigns.create.organisation.organisationKey');
  const startDatetime = new Date(
    moment
      .tz(`${infoForm.startDate.value} 00:01`, infoForm.timezone.value)
      .format()
  ).getTime();
  const endDatetime = new Date(
    moment
      .tz(`${infoForm.endDate.value} 23:59`, infoForm.timezone.value)
      .format()
  ).getTime();
  const campaignImage =
    props.campaignImage ||
    state.get('admin.campaigns.create.challenge.uploadedImages.desktop.url');

  const sections = Object.values(structureForm.sections).map(
    ({ name: sectionName, teams }) => ({
      name: sectionName,
      teams: Object.values(teams).map(
        ({ name: teamName, expectedParticipantCount, teamLeaderEmail }) => ({
          name: teamName,
          expectedParticipantCount: Number(expectedParticipantCount) || 0,
          teamLeaderEmail,
        })
      ),
    })
  );

  const campaignObject = {
    organisationKey,
    access: accessForm.access.value,
    domains:
      accessForm.access.value === 'email' ||
      accessForm.access.value === 'email_and_code'
        ? Object.values(accessForm.emails).map(field => field.value)
        : [],
    name: infoForm.name.value,
    description: infoForm.description.value,
    goal: infoForm.goal.value,
    tagline: infoForm.tagline.value,
    timezone: infoForm.timezone.value,
    isJoiningChampionship: isJoiningChampionshipValue || null,
    championshipKey: championshipKey || null,
    topic: focusForm.topic.value,
    audience: focusForm.audience.value,
    dataSource: focusForm.dataSource.value,
    indicator: configForm.indicator.value,
    series: configForm?.series?.value || null,
    startDatetime,
    endDatetime,
    sectionTerm: infoForm.sectionTerm.value,
    image: campaignImage,
    sections,
  };

  if (infoForm.campaignPageUrl) {
    campaignObject.campaignPageUrl = infoForm.campaignPageUrl.value;
  }

  return { campaignObject };
}

export function createChallengeObject({ state, forms }) {
  const challengeForm = forms.get('admin.campaigns.create.challenge.form');
  const activitiesForm = forms.get(
    'admin.campaigns.create.challenge.activities.form'
  );
  const challengeStartDatetime = new Date(
    moment
      .tz(
        `${challengeForm.startDate.value} 00:01`,
        state.get(`admin.campaigns.create.info.form.timezone.value`)
      )
      .format()
  ).getTime();
  const challengeEndDatetime = new Date(
    moment
      .tz(
        `${challengeForm.endDate.value} 23:59`,
        state.get(`admin.campaigns.create.info.form.timezone.value`)
      )
      .format()
  ).getTime();

  const challengeObject = {
    config: {
      backlogDays: Number(challengeForm.backlogDays.value),
      emailUpdateFrequency: Number(challengeForm.emailUpdateFrequency.value),
      abTestEnabled: challengeForm.abTestEnabled.value,
      startDatetime: challengeStartDatetime,
      endDatetime: challengeEndDatetime,
      winningParticipationPercentage: Number(
        challengeForm.winningParticipationPercentage.value
      ),
      winningMode: challengeForm.winningMode.value,
    },
    prize: challengeForm.prize.value,
    activities: Object.keys(activitiesForm).reduce(
      (activitiesObject, activityKey) => {
        if (activitiesForm[activityKey].value === true) {
          activitiesObject[activityKey] = true;
        }

        return activitiesObject;
      },
      {}
    ),
  };

  return { challengeObject };
}

export function createIntegrationObject({ forms, state }) {
  if (!state.get('admin.campaigns.create.integration.added')) {
    return { integrationObject: {} };
  }

  const integrationForm = forms.get('admin.campaigns.create.integration.form');

  return {
    integrationObject: Object.assign(
      {},
      ...Object.values(integrationForm)
        .filter(type => typeof type === 'object')
        .flatMap(type =>
          Object.entries(type).map(([key, { value }]) => ({ [key]: value }))
        )
    ),
  };
}

export function resetCreateCampaignData({ state, forms }) {
  forms.reset('admin.campaigns.create.config.form');
  forms.reset('admin.campaigns.create.info.form');
  forms.reset('admin.campaigns.create.focus.form');
  forms.reset('admin.campaigns.create.challenge.form');
  forms.reset('admin.campaigns.create.challenge.activities.form');
  forms.reset('admin.campaigns.create.integration.form');
  forms.reset('admin.campaigns.create.access.form');
  state.set(`admin.campaigns.create.info.selectedRegion`, null);
  state.set(`admin.campaigns.create.info.selectedTimezone`, null);
  state.set(`admin.campaigns.create.info.selectedSectionTerm`, null);
  state.set('admin.campaigns.create.config.createdSections', []);
  state.set('admin.campaigns.create.config.editingSectionIndex', null);
  state.set('admin.campaigns.create.config.sectionNameErrors', {});
  state.set('admin.campaigns.create.config.newTeamNameErrors', {});
  state.set('admin.campaigns.create.config.teamNameErrors', {});
  state.set('admin.campaigns.create.config.newSectionNameError', null);
  state.set('admin.campaigns.create.config.tempSectionName', '');
}

export function createCampaign({ firebase, props }) {
  const {
    campaignObject,
    challengeObject = null,
    integrationObject = null,
  } = props;

  return firebase
    .taskHeavy('create_campaign', {
      campaign: campaignObject,
      challenge: challengeObject,
      integration: integrationObject,
    })
    .catch(error => {
      console.log('CREATE CAMPAIGN TASK ERROR', error); // eslint-disable-line no-console
    });
}

export function isJoiningChampionship({ storage, state, path }) {
  const championshipKey = storage.get('championshipKey');
  const organisationKey = storage.get('organisationKey');
  storage.remove('championshipKey');
  storage.remove('organisationKey');

  if (championshipKey && organisationKey) {
    state.set('admin.campaigns.create.championshipKey', championshipKey);
    state.set('admin.campaigns.create.joiningOrganisationKey', organisationKey);
    state.set('admin.campaigns.create.isJoiningChampionship', true);

    return path.true();
  }

  return path.false();
}

export function getTemplate({ state, firebase }) {
  const championshipKey = state.get('admin.campaigns.create.championshipKey');

  return firebase
    .value(`championships.templates.${championshipKey}`)
    .then(result => ({ template: result.value }));
}

export function setTemplate({ props, state }) {
  const { template } = props;
  const currentForm = state.get('admin.campaigns.create');

  Object.keys(template || {}).forEach(templateKey => {
    state.set(
      `admin.campaigns.create.${templateKey}`,
      Object.assign(currentForm[templateKey] || {}, template[templateKey])
    );
  });
}

export function getSeries({ state, firebase }) {
  const championshipKey = state.get('admin.campaigns.create.championshipKey');
  state.set('admin.campaigns.create.isJoiningSeries', true);

  return firebase
    .value(`championships.series.${championshipKey}`)
    .then(result => ({ championshipSeries: result.value }));
}

export function setSeries({ props, state }) {
  const { championshipSeries } = props;
  state.set('admin.campaigns.create.series.createdSeries', championshipSeries);
}

export function dropdownSelected({ props, state }) {
  const { path, optionType, option } = props;

  state.set(`${path}.${optionType}Selected`, option);
  state.set(`${path}.form.${optionType}.value`, option.optionKey);
}

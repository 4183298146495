import React from 'react';
import * as PropTypes from 'prop-types';
import { Wrapper, Text } from './elements';

function NavIt01(props) {
  return (
    <Wrapper
      className={props.className}
      disabled={props.disabled}
      onClick={props.disabled ? null : props.onClick}
      underlined={props.underlined}
      selected={props.selected}
    >
      <Text
        disabled={props.disabled}
        selected={props.selected}
        indent={props.indent}
      >
        {props.children}
      </Text>
    </Wrapper>
  );
}

NavIt01.propTypes = {
  children: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  underlined: PropTypes.bool,
  indent: PropTypes.number,
  selected: PropTypes.bool,
};

export default NavIt01;

import styled from 'styled-components';
import { Typ35, Typ50 } from 'dc/Typ';
import Btn021 from 'dc/Btn021';

export const Wrapper = styled.div`
  text-align: center;
  margin: 16px;
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const Title = styled(Typ50)`
  margin-top: 64px;
`;

export const Body = styled(Typ35)`
  margin-top: 8px;
  margin-bottom: 32px;
`;

export const Button = styled(Btn021)``;

import styled from 'styled-components';
import { Typ30 } from '../Typ';
import Swi02 from '../Swi02';

export const ElementWrapper = styled('div')`
  ${props =>
    props.disabled
      ? `
      color: #90A4AE;
      cursor: default;
      `
      : `
    color: ${props.checked ? '#00AB97' : '#455A64'};
    cursor: pointer;
  `}
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LabelText = styled(Typ30)`
  color: ${props =>
    props.disabled ? '#90A4AE !important' : '#263238 !important'};
  padding-left: 8px;
  padding-top: 1px;
`;

export const RadioButton = styled(Swi02)`
  ${props =>
    props.disabled
      ? `
    cursor: default;
    i {color: #90A4AE;}
  `
      : ''}
`;

import React from 'react';
import * as PropTypes from 'prop-types';
import { ElementWrapper, Icon, TitleText } from './elements';

function Lab121(props) {
  return (
    <ElementWrapper className={props.className} onClick={props.onClick}>
      <Icon icon={props.icon} color={props.color} />
      <TitleText>{props.children}</TitleText>
    </ElementWrapper>
  );
}

Lab121.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default Lab121;

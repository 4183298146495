import React from 'react';
import * as PropTypes from 'prop-types';
import { ElementWrapper, Button, Icon } from './elements';

function SeH002(props) {
  return (
    <ElementWrapper className={props.className}>
      {props.showButton ? (
        <Button icon={'icon-keyboard_arrow_left'} onClick={props.onButtonClick}>
          {props.buttonText}
        </Button>
      ) : (
        <div />
      )}
      <Icon icon={'icon-close'} onClick={props.onClose} />
    </ElementWrapper>
  );
}

SeH002.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  showButton: PropTypes.bool,
  onButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
};

export default SeH002;

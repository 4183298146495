import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #263238;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  z-index: 1;
  color: #fff;
  text-align: left;
  font-size: 17px;
`;

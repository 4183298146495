import React from 'react';
import { Wrapper, Icon, ToolTip } from './elements';
import { useText } from 'common/hooks';

export default function EditableComponent(props) {
  const t = useText();

  return (
    <Wrapper>
      {props.isJoiningChampionship ? (
        <ToolTip text={t('championship.editable')}>
          <Icon icon={'icon-mode_edit'} color={'#03739A'} />
        </ToolTip>
      ) : null}
      {props.children}
    </Wrapper>
  );
}

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { Wrapper, Input, Description, LabelDate, Title } from './elements';
import { ChampionshipLabel } from 'admin/onboarding/App/Campaigns/CreateCampaign/elements';
import { useText } from 'common/hooks';

export default connect(
  {
    createChallengeForm: state`admin.campaigns.create.challenge.form`,
    isJoiningChampionship: state`admin.campaigns.create.isJoiningChampionship`,
    fieldChanged: sequences`admin.campaigns.create.fieldChanged`,
  },
  function Duration({
    createChallengeForm,
    isJoiningChampionship,
    fieldChanged,
  }) {
    const t = useText();

    return (
      <Wrapper>
        {isJoiningChampionship && (
          <ChampionshipLabel icon={'icon-visibility'} color={'#03739A'}>
            {t('championship.view_only')}
          </ChampionshipLabel>
        )}
        <Title>{t('admin.challenge.duration.choose_duration')}</Title>
        <Description>
          {t('admin.challenge.duration.recommended_duration')}
        </Description>

        <LabelDate>{t('admin.challenge.duration.start_date')}</LabelDate>
        <Input
          disabled={isJoiningChampionship}
          inputType="date"
          onChange={event =>
            fieldChanged({
              field: 'admin.campaigns.create.challenge.form.startDate',
              value: event.target.value,
            })
          }
          value={createChallengeForm.startDate.value}
          placeholder={'MM/DD/YYYY'}
        />

        <LabelDate>{t('admin.challenge.duration.end_date')}</LabelDate>
        <Input
          disabled={isJoiningChampionship}
          inputType="date"
          onChange={event =>
            fieldChanged({
              field: 'admin.campaigns.create.challenge.form.endDate',
              value: event.target.value,
            })
          }
          value={createChallengeForm.endDate.value}
          placeholder={'MM/DD/YYYY'}
        />
      </Wrapper>
    );
  }
);

import React from 'react';
import { connect } from '@cerebral/react';
import { state, sequences, props } from 'cerebral';
import { TextAreaInput } from './elements';

export default connect(
  {
    value: state`admin.campaigns.create.info.form.${props`field`}.value`,
    fieldChanged: sequences`admin.campaigns.create.fieldChanged`,
  },
  function TextArea({ field, value, fieldChanged, placeholder, disabled }) {
    return (
      <TextAreaInput
        isLocked={disabled}
        onChange={event =>
          fieldChanged({
            field: `admin.campaigns.create.info.form.${field}`,
            value: event.target.value,
          })
        }
        maxLength={500}
        icon={'icon-mode_edit'}
        value={value}
        placeholder={placeholder}
      />
    );
  }
);
